export const logoNegative = ['600 208', ` 
<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 608 208" class="c-icon c-icon-custom-size c-sidebar-brand-full" role="img"> 
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%" viewBox="0 0 600 208" enable-background="new 0 0 600 208" xml:space="preserve">
<path fill="#FEFDFD" opacity="1.000000" stroke="none" d="
M601.000000,108.000000 
C601.000000,141.646347 601.000000,175.292679 601.000000,208.961731 
C401.000000,208.961731 201.000000,208.961731 1.000000,208.961731 
C1.000000,201.261444 1.000000,193.599411 1.421846,185.468689 
C6.179308,184.849823 9.986793,185.691711 12.802365,189.526398 
C15.900156,193.745422 19.634602,193.031921 22.338314,187.999344 
C23.451244,187.664566 24.226162,187.332291 25.342163,186.943512 
C26.454174,185.591965 27.225103,184.296906 28.329090,183.002960 
C29.106026,183.006668 29.549904,183.009262 30.001560,183.390625 
C30.627089,188.008698 33.885040,187.884598 37.258244,188.105927 
C38.012184,188.476059 38.506760,188.738953 39.060627,189.358871 
C44.552017,194.203384 48.943890,193.568954 50.998779,188.000061 
C51.000000,188.000000 51.000820,187.997604 51.387146,187.992035 
C53.345810,187.761993 55.323780,186.816696 56.422039,187.434708 
C61.916370,190.526459 64.372337,187.837784 66.356796,183.155487 
C70.060944,184.449402 73.803345,184.885590 75.242401,181.119781 
C77.393661,175.490265 84.879555,169.737167 76.104202,163.440628 
C75.853813,163.260971 76.025253,162.493423 76.319199,161.864517 
C78.094078,162.501007 80.190712,162.905014 80.884895,164.114166 
C82.944130,167.700989 84.474892,168.056915 87.280128,165.265457 
C86.469376,163.039764 85.733925,161.020798 85.257431,158.893814 
C86.010994,158.523453 86.505600,158.261124 87.394363,157.998566 
C94.342239,158.177246 90.749474,153.707947 91.020889,150.678192 
C91.365295,149.572601 91.684547,148.786163 92.348785,147.965179 
C95.462814,144.620850 98.231865,141.311035 101.395584,137.999252 
C102.719093,137.748001 104.163063,137.786469 104.490097,137.201004 
C106.153198,134.223679 107.527023,131.084778 109.370163,127.929230 
C111.776649,125.200165 113.815102,122.544304 116.299049,119.308014 
C112.975487,117.117592 111.547691,113.235687 112.812889,108.352158 
C113.043747,107.461075 112.120995,106.200928 111.578430,105.193161 
C110.095566,102.438911 108.531372,99.728447 106.999695,97.000320 
C107.000000,97.000000 107.001122,96.998779 107.387138,96.999588 
C110.226677,96.651718 112.672745,96.225662 115.136986,95.988258 
C116.302818,95.875938 117.692314,95.766281 118.654739,96.273499 
C122.306763,98.198196 125.745422,96.798286 125.898476,92.747208 
C126.053329,88.648308 125.509529,84.522629 125.264359,80.409126 
C125.101318,77.673523 124.488152,75.719574 121.394409,74.327545 
C118.465416,73.009651 118.379425,69.665314 120.604691,67.121170 
C122.539925,64.908615 124.854080,63.027504 127.286011,61.266399 
C127.706718,62.172070 127.957092,62.810741 127.960030,63.450546 
C127.990906,70.171188 128.167450,76.898697 127.925331,83.610649 
C127.734779,88.892967 129.790192,94.631073 125.545990,98.549347 
C126.481873,101.392693 127.240173,103.696503 127.633499,105.993553 
C125.429764,104.387802 123.591011,102.788803 121.382111,100.867920 
C118.966599,105.111687 116.762062,108.984795 114.557526,112.857895 
C114.998924,113.119598 115.440323,113.381302 115.881721,113.643005 
C117.920418,112.428497 119.959114,111.213982 121.998322,110.380127 
C122.005219,111.523033 122.011612,112.285286 122.038734,113.467842 
C122.132812,115.551674 122.206161,117.215202 121.947609,119.032547 
C119.482559,121.019325 120.171318,121.637619 122.621246,122.066223 
C124.126526,122.329559 126.361557,122.995033 126.774216,124.059418 
C127.938805,127.063309 128.331650,130.366394 129.043060,133.617493 
C127.246300,133.942474 125.162048,134.319458 123.028862,134.705276 
C124.245972,136.871567 125.121155,138.429276 125.750725,139.993118 
C125.505112,139.999268 125.013786,140.006973 124.612129,140.004181 
C123.140205,140.001343 122.069931,140.001282 120.793320,139.771423 
C119.960732,141.687225 119.334473,143.832825 118.728912,146.448486 
C118.667580,157.237518 118.585548,167.556503 118.350266,177.902740 
C118.197021,177.929962 117.887245,177.987274 117.513000,177.896027 
C116.496315,175.279999 115.853867,172.755219 115.211418,170.230423 
C114.737839,170.068985 114.264259,169.907547 113.790688,169.746109 
C113.111420,171.349899 111.765266,173.006088 111.886322,174.546997 
C112.172623,178.191360 112.994202,181.915558 117.962311,182.353760 
C118.658386,184.464188 119.329407,186.226456 120.002457,188.401398 
C120.766945,194.931229 125.949158,190.351639 128.266190,192.774307 
C130.550964,186.326813 132.719162,185.824036 137.465607,189.831604 
C138.590500,190.781387 140.474182,190.832520 142.008820,191.297028 
C141.787247,189.723328 141.576141,188.148056 141.337860,186.576889 
C141.264420,186.092575 141.116821,185.619476 141.466446,185.067230 
C175.514725,184.986206 209.099777,185.002365 242.684738,184.963242 
C261.798889,184.940979 280.912872,184.828354 300.488953,184.825958 
C307.289398,184.840866 313.627777,184.786240 320.429993,184.818298 
C329.606873,184.849564 338.319946,184.794128 347.487366,184.812836 
C351.951752,184.839798 355.961761,184.792618 360.434814,184.832748 
C385.600586,184.867172 410.303345,184.814285 435.468445,184.839966 
C442.951782,184.866287 449.972778,184.814041 457.456421,184.842361 
C469.281342,184.882202 480.643555,184.841461 492.376984,184.866882 
C493.496368,184.881866 494.244507,184.830673 495.367615,184.851074 
C496.498901,184.855865 497.255280,184.789062 498.471924,184.810928 
C511.721313,184.846313 524.510437,184.793030 537.596802,184.801849 
C538.496948,184.820496 539.099854,184.777054 540.052734,184.811462 
C541.599854,184.857956 542.797058,184.826584 544.456055,184.881409 
C553.402832,184.972870 561.893921,185.154266 570.367737,184.850891 
C572.315552,184.781143 574.203796,183.046524 576.317932,182.029694 
C576.516418,181.981293 576.900391,181.841324 577.266357,181.891022 
C578.709900,182.398407 579.787476,182.856094 581.693604,183.665665 
C582.134033,181.692230 582.917053,179.764420 582.937683,177.828491 
C583.050476,167.240341 582.976196,156.650192 582.996582,145.638428 
C582.933655,143.616989 582.840271,142.017853 582.764587,140.721054 
C578.145752,140.458099 574.097717,140.038239 570.048706,140.028183 
C545.778198,139.967911 521.507446,140.002258 497.236816,140.004150 
C476.401581,140.005768 455.566376,140.007278 434.731140,140.007294 
C389.908203,140.007339 345.085236,139.991913 300.262390,140.052917 
C298.231812,140.055679 295.251404,138.770096 294.540344,142.661179 
C293.501770,142.690323 292.806641,142.652634 292.051758,142.234161 
C290.155823,141.239670 288.320740,140.093643 286.483307,140.089813 
C235.989975,139.984558 185.496414,139.995621 134.966202,139.635315 
C134.812607,138.277237 134.695679,137.271133 134.636261,136.759827 
C137.278641,136.473282 139.422562,136.240799 141.566467,136.008316 
C141.517303,135.460754 141.468140,134.913193 141.418976,134.365631 
C138.755661,134.110321 136.092331,133.854996 133.954773,133.650070 
C134.156815,129.615891 134.331116,126.135963 134.538193,122.001297 
C136.326630,122.001297 138.102768,122.001328 139.878906,122.001297 
C159.008316,122.000931 178.137756,122.017883 197.267090,121.977936 
C199.621826,121.973022 202.572372,122.793617 203.301727,118.880203 
C204.188339,118.456375 204.771286,118.125931 205.354233,117.795486 
C204.829407,117.524109 204.304596,117.252716 203.970337,116.716148 
C205.117828,115.680634 206.074738,114.910301 207.405487,114.096725 
C211.807281,111.347260 215.835220,108.641029 220.179413,106.067276 
C221.553085,105.925293 222.617401,105.674660 223.666245,105.370667 
C225.141663,104.943039 226.605591,104.475807 228.037201,104.470215 
C227.947601,107.585106 227.895248,110.255287 227.652100,112.963058 
C227.461319,113.000633 227.090210,113.117165 226.778290,113.077988 
C225.973877,113.343361 225.481369,113.647903 224.661774,114.100243 
C221.752365,115.717484 220.468002,117.174820 224.383514,118.567825 
C225.910324,119.111015 227.442032,119.640411 229.078003,120.541092 
C230.032150,121.265709 230.873871,121.918884 231.728424,121.936127 
C236.415451,122.030678 241.144089,122.317795 245.777573,121.806290 
C247.913391,121.570511 249.865158,119.667686 252.121292,118.463852 
C252.342194,118.406952 252.789810,118.376450 252.789810,118.376450 
C252.789810,118.376450 253.236511,118.417908 253.646240,118.561218 
C256.498230,118.827942 259.655792,118.017349 261.254761,119.241920 
C267.116272,123.731049 273.649902,121.371193 279.864868,121.974998 
C282.766418,122.256889 284.249908,120.920631 283.985443,117.550545 
C283.987610,116.508194 283.958710,115.813148 284.061462,114.791916 
C283.445343,112.457863 282.147125,110.478035 282.043182,108.437378 
C281.536255,98.483986 281.309479,88.515305 281.062103,78.550087 
C280.916534,72.685654 280.917358,66.817589 280.893799,60.580360 
C281.363007,57.885845 282.237152,57.291801 284.001892,59.309322 
C285.987610,61.579483 288.047485,63.785992 290.111084,65.986420 
C291.879608,67.872246 293.698975,69.710464 295.495850,71.569725 
C295.238586,71.944275 294.981354,72.318825 294.724091,72.693375 
C296.172882,72.759827 297.621674,72.826279 299.290588,73.146751 
C304.375214,77.649391 309.218079,81.923111 314.110809,86.139015 
C318.369873,89.808914 322.685181,93.413513 327.211334,97.298767 
C329.882416,99.459305 332.317871,101.368034 334.832397,103.629387 
C337.576324,107.806534 339.907104,112.017746 345.511292,112.900543 
C346.919830,113.269180 347.982239,113.578049 349.234070,113.962975 
C349.423523,114.039032 349.817596,114.145966 349.852356,114.459564 
C349.918274,115.192345 349.949463,115.611534 349.946228,116.434341 
C350.026337,120.302963 352.519012,120.610291 354.835022,119.890144 
C357.015198,119.212250 360.428314,119.578735 360.072296,115.380569 
C359.752899,111.613800 360.019226,107.797356 360.436890,104.001709 
C366.294586,103.601151 366.889496,102.702629 364.515289,98.469383 
C363.022278,98.982880 361.527100,99.489716 360.009064,99.528572 
C360.021362,79.710129 360.056580,60.359665 360.457062,41.012058 
C364.199738,40.678276 367.577179,40.341637 371.140594,40.382759 
C371.551147,41.379021 371.978851,42.000137 371.970947,42.615650 
C371.694672,64.064499 371.348419,85.512505 371.115845,106.961754 
C371.095184,108.863937 371.777039,110.773735 371.973328,112.710098 
C371.814087,112.740204 371.499481,112.689072 371.128296,112.487686 
C367.808167,112.276276 365.594543,113.524544 365.530365,116.584526 
C365.515259,117.304581 367.952240,118.076080 369.628174,118.827522 
C370.731323,118.778542 371.467438,118.725960 372.189453,118.724228 
C372.175354,118.775085 372.266327,118.828651 372.210754,119.067276 
C372.393707,119.170189 372.632233,119.034485 373.250732,118.835983 
C374.119293,118.634598 374.607880,118.496010 375.501526,118.484879 
C377.612457,118.654701 379.318298,118.697060 381.080170,119.075104 
C381.749847,119.193924 382.363495,118.977058 383.431946,118.724258 
C386.926117,118.770309 389.965454,118.852295 393.066193,119.252739 
C393.751373,119.375763 394.375153,119.180336 395.368591,118.893143 
C396.497192,118.812531 397.256134,118.823677 398.147766,119.217850 
C400.566803,119.283333 402.853149,118.965782 405.597412,118.673279 
C411.709747,118.750778 417.364136,118.803230 423.162354,119.226952 
C425.198608,119.348282 427.091034,119.098343 429.407074,118.803726 
C432.887238,118.504005 435.943756,118.248970 439.112823,118.254272 
C439.896790,118.105812 440.568237,117.697014 441.491760,117.117203 
C441.802094,116.558250 441.860352,116.170311 442.214600,115.759529 
C443.015533,115.503510 443.520477,115.270325 444.389893,115.042664 
C445.573578,114.425499 446.392853,113.802818 447.543823,113.034653 
C450.600189,111.271454 453.324829,109.653748 456.422546,107.991608 
C463.564270,105.615852 466.183197,99.724083 469.215363,93.867874 
C470.752136,92.991333 471.935547,92.130615 474.006897,90.624062 
C474.006897,93.695152 473.643616,95.836113 474.137115,97.756927 
C474.455963,98.997986 476.029572,99.916679 477.113068,101.341415 
C480.377686,108.928246 485.154907,114.526176 493.164948,117.339592 
C495.359100,117.882309 497.322968,118.126480 499.310059,118.497910 
C499.333221,118.625160 499.132660,118.788574 499.131958,119.198639 
C500.218262,120.398766 501.315430,121.885651 502.390778,121.870056 
C512.128052,121.728828 521.869812,121.454468 531.585632,120.834099 
C532.802368,120.756409 533.876465,118.444946 535.364807,117.102890 
C537.145081,116.025848 538.577209,115.014397 540.385620,113.981430 
C542.844910,113.328941 544.927856,112.697960 546.984070,112.437157 
C547.881775,113.903915 548.806213,115.000504 549.840454,116.456520 
C550.740417,117.178894 551.530518,117.541840 552.319641,118.071381 
C552.318604,118.237968 552.183716,118.542625 552.162476,118.969276 
C552.825684,120.218971 553.525391,121.767311 554.191956,121.753174 
C561.058594,121.607559 567.931885,121.339844 574.767517,120.715240 
C575.617737,120.637550 576.260864,118.293358 577.324402,116.854462 
C578.391052,115.583061 579.133240,114.456589 579.875488,113.330116 
C577.061951,112.531929 574.248413,111.733734 571.009766,110.814941 
C571.009766,90.056511 571.009766,69.028961 571.393188,48.007019 
C573.517700,48.341953 575.258850,48.671280 577.162720,49.373337 
C577.881226,53.493771 579.421814,57.429420 578.751221,60.943085 
C577.850220,65.664482 580.646484,71.851776 574.286438,75.059769 
C573.801270,75.304482 574.183777,79.241600 574.854492,79.474976 
C578.340698,80.688019 578.123413,83.292366 577.988342,85.968819 
C577.692627,91.830299 577.409424,97.665184 583.308228,101.247047 
C581.988464,103.301193 581.040649,104.776382 579.818604,106.678391 
C581.509277,107.530014 582.859863,108.210350 584.291809,108.931656 
C586.357117,102.427864 586.827271,102.452805 592.505981,105.579231 
C595.019653,106.963150 598.149719,107.227448 601.000000,108.000000 
M110.480293,177.303101 
C109.486961,175.902039 108.493637,174.500992 107.500305,173.099930 
C106.709488,173.829651 105.154846,174.717758 105.267326,175.260178 
C105.644218,177.077728 106.616470,178.771820 107.360779,180.513184 
C108.366058,179.677994 109.371338,178.842804 110.480293,177.303101 
z"></path>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" d="
M601.000000,107.600006 
C598.149719,107.227448 595.019653,106.963150 592.505981,105.579231 
C586.827271,102.452805 586.357117,102.427864 584.291809,108.931656 
C582.859863,108.210350 581.509277,107.530014 579.818604,106.678391 
C581.040649,104.776382 581.988464,103.301193 583.308228,101.247047 
C577.409424,97.665184 577.692627,91.830299 577.988342,85.968819 
C578.123413,83.292366 578.340698,80.688019 574.854492,79.474976 
C574.183777,79.241600 573.801270,75.304482 574.286438,75.059769 
C580.646484,71.851776 577.850220,65.664482 578.751221,60.943085 
C579.421814,57.429420 577.881226,53.493771 577.162720,48.923080 
C577.390991,45.459877 577.792725,42.821194 578.169617,40.178959 
C578.465149,38.107288 578.729736,36.031216 579.005005,33.587921 
C579.001343,32.479237 579.000671,31.739733 578.988647,30.612152 
C578.863708,29.747604 578.750183,29.271135 578.636597,28.794664 
C573.112183,30.163462 567.586609,31.527485 562.073120,32.938992 
C561.973938,32.964371 562.020142,33.557667 561.555603,33.968670 
C558.408813,34.007206 555.704956,33.963036 553.000427,33.553986 
C552.999878,32.459354 552.999939,31.729601 553.370483,31.004835 
C555.426392,30.711712 557.111816,30.413599 558.797180,30.115490 
C557.514099,28.259905 556.231018,26.404320 554.947937,24.548735 
C554.298584,24.824734 553.649292,25.100731 553.000000,25.376728 
C553.000000,27.251204 553.000000,29.125677 552.602173,30.999189 
C550.802246,29.985235 549.379761,28.999035 548.004517,27.950911 
C546.880005,27.093832 544.885010,25.341639 544.850098,25.381079 
C540.794312,29.958385 536.742371,28.098885 532.678772,25.063227 
C532.384583,36.706512 532.411072,36.761486 539.612793,38.367661 
C536.640503,39.791965 534.240295,40.942142 531.840088,42.092319 
C532.048096,42.680225 532.256165,43.268127 532.464172,43.856033 
C534.243530,43.544800 536.408508,43.767002 537.724854,42.815353 
C540.812622,40.583046 543.738464,40.222584 547.023438,42.447578 
C547.012878,45.597965 546.999084,48.298134 546.583923,50.997787 
C544.601990,50.846436 543.021484,50.695595 541.000000,50.502674 
C541.000000,52.324368 540.894836,53.805599 541.016541,55.267941 
C541.987671,66.934769 542.933105,78.604469 544.028992,90.259895 
C544.663635,97.010208 544.747009,97.042778 538.135010,98.169189 
C537.848938,92.025467 537.559753,85.812828 537.270508,79.600189 
C536.513672,79.602394 535.756836,79.604599 535.000000,79.606804 
C535.000000,82.301979 535.118958,85.003426 534.977478,87.691162 
C534.571472,95.403564 533.984619,103.070419 526.386536,107.737740 
C526.086792,107.921913 526.126953,108.659569 525.674072,109.173553 
C524.891724,109.191719 524.444092,109.175690 523.952515,108.800690 
C521.676392,106.571335 519.363220,104.055244 517.592163,109.214493 
C515.783142,109.200630 514.390991,109.154312 512.951904,108.752716 
C510.921234,105.261658 509.842621,100.588089 504.329010,103.549126 
C503.807678,103.829109 502.796112,103.196213 501.963928,102.617393 
C501.275696,100.642921 500.911285,98.835106 499.919617,97.489578 
C498.892578,96.096146 497.257233,95.151054 495.863251,93.541077 
C495.851227,78.713478 495.863953,64.354660 496.233307,49.950901 
C500.140259,47.400517 499.517578,45.451836 495.792297,43.551464 
C495.831207,42.455151 495.868744,41.724667 496.310730,40.989998 
C498.457428,40.040363 501.091278,39.503178 501.751129,38.062115 
C503.015656,35.300491 500.400970,35.201313 498.429504,35.043404 
C498.273132,35.030876 498.158844,34.499676 498.024048,34.209949 
C495.968658,29.791515 495.002563,32.436954 493.743805,34.913277 
C493.320221,35.049412 493.150543,35.103188 492.679169,35.012764 
C491.580017,34.585987 490.782593,34.303410 489.992554,34.010490 
C490.000000,34.000153 489.980072,34.015099 489.953857,33.741383 
C489.614136,33.642410 489.300598,33.817154 488.545715,34.045441 
C485.736664,34.034245 483.368896,33.969501 480.946045,33.533012 
C477.174225,29.510323 473.511169,30.244959 471.858643,35.057236 
C471.718414,35.169884 471.358948,35.156647 470.940338,35.104912 
C469.678894,35.034492 468.836029,35.015800 467.614685,34.991913 
C465.574158,34.498741 463.912170,34.010769 461.820557,33.396660 
C463.102417,31.743080 463.935577,30.668295 464.897705,29.427166 
C463.050537,28.323284 461.501068,27.449217 460.004547,26.492350 
C456.731934,24.399809 456.340393,24.829020 456.539520,30.100346 
C454.661926,31.326782 452.882294,32.489227 451.102692,33.651669 
C451.270721,34.037300 451.438721,34.422928 451.606750,34.808556 
C453.560791,34.660103 455.514862,34.511650 457.468903,34.363197 
C457.985596,34.991718 458.502289,35.620243 459.018982,36.248764 
C456.998108,37.540466 454.977234,38.832169 452.627258,40.072323 
C451.860535,39.960289 451.422943,39.899803 450.890808,39.662247 
C450.629700,39.264835 450.416168,39.108772 450.060669,38.663231 
C448.958832,37.893375 447.951996,37.477276 446.946594,37.041565 
C446.948090,37.021954 446.986877,37.015545 446.961121,36.695030 
C445.968079,35.840553 445.000763,35.306587 443.578613,34.706917 
C437.435608,34.520798 431.747467,34.400387 425.648560,34.172874 
C423.832764,34.003033 422.427734,33.940289 420.921997,33.588566 
C420.212646,33.525723 419.604004,33.751862 418.678772,34.042488 
C417.936401,34.161942 417.510590,34.216904 416.685364,34.172424 
C415.192078,34.005997 414.098267,33.939011 413.052246,33.513672 
C413.752930,31.760765 414.405762,30.366211 415.341248,28.367844 
C412.524628,28.367844 410.507446,28.367844 407.895569,28.367844 
C408.315826,30.477465 408.655914,32.184692 408.608276,33.965916 
C405.162842,33.155731 402.099609,32.582779 399.002075,33.475975 
C399.416534,29.207165 397.661346,27.326801 393.884247,27.234634 
C393.599518,27.643177 393.213257,27.960789 393.267456,28.156298 
C393.802399,30.085817 394.410400,31.995092 394.660645,33.975330 
C393.268738,33.720505 392.213959,33.400448 390.804504,32.903870 
C385.893097,32.041092 381.336365,31.354834 375.871094,30.531742 
C375.999664,30.751795 375.691650,29.446568 374.964264,29.141603 
C373.445251,28.504755 371.704895,28.395990 370.055450,28.070286 
C370.336182,29.386591 370.558136,30.719152 370.916107,32.014103 
C371.209534,33.075661 371.664856,34.092464 372.034851,35.443512 
C371.960602,36.170902 371.899933,36.583885 371.536163,36.834419 
C369.490326,36.112499 367.747620,35.553028 366.002808,34.614693 
C365.361694,32.940361 364.722656,31.644888 364.083618,30.349407 
C362.710022,31.934809 361.336426,33.520214 359.593964,35.051380 
C358.484222,34.939587 357.743378,34.882034 357.001709,34.389679 
C356.767883,31.071215 356.534851,28.187548 356.301819,25.303879 
C351.953369,29.993082 351.928253,29.838554 344.080475,31.114588 
C342.303955,31.403450 339.666840,32.927601 339.354614,34.316689 
C338.730530,37.092930 338.472992,40.711941 339.770325,43.026726 
C345.095917,52.529305 342.932861,63.149006 345.042603,73.312408 
C345.043182,73.542633 345.037842,74.004936 344.683533,74.063568 
C343.879364,75.937279 343.429474,77.752365 343.091553,79.115707 
C340.474426,78.811729 337.932129,78.516434 335.610352,78.246758 
C336.051208,76.243797 336.463989,74.368385 337.041962,71.742577 
C334.189301,72.601997 332.381287,73.146706 329.483459,74.019745 
C329.483459,69.974518 329.483459,66.577713 329.483459,63.180908 
C326.697174,65.170967 325.984100,70.005287 321.983429,66.622971 
C318.281036,63.492825 315.278046,59.535419 311.967834,55.577347 
C311.961243,54.479202 311.953918,53.746044 312.322876,53.001068 
C313.392212,52.319191 314.801178,51.320602 314.667725,51.029819 
C313.319763,48.092392 311.719421,45.270779 310.036652,42.130589 
C306.779541,45.445030 308.231537,48.766262 309.010376,52.012161 
C309.000000,52.000610 309.023010,52.019505 308.642212,52.012299 
C307.499786,52.010090 306.738159,52.015087 305.981964,52.013237 
C305.987427,52.006390 305.978394,52.024338 305.976379,51.647163 
C303.171326,48.069778 300.959625,44.772316 302.861298,40.157509 
C303.121765,39.525391 302.057770,37.620647 301.368011,37.480419 
C300.072479,37.217030 298.602356,37.812252 297.204834,38.050426 
C297.724792,38.384060 298.244751,38.717693 298.764740,39.051327 
C298.491638,42.425255 298.218567,45.799179 297.618530,49.120552 
C296.494263,48.776852 295.696930,48.485703 294.626099,48.057869 
C293.869019,47.288982 293.385437,46.656780 293.170380,45.848297 
C293.591370,44.852394 293.743835,44.032764 293.918579,42.816521 
C292.871613,41.650269 291.802338,40.880638 290.382996,40.099640 
C289.346558,39.965099 288.660217,39.841923 288.052490,39.411110 
C288.640442,38.766907 289.145111,38.145412 289.659729,38.137058 
C296.098297,38.032490 289.996826,32.516693 292.817719,30.891178 
C291.669556,29.947140 290.659119,28.645823 289.342651,28.138254 
C287.059601,27.258005 284.587097,26.868999 282.413269,26.328487 
C280.483459,27.966257 278.024872,29.989656 275.660645,32.117847 
C275.239471,32.496967 275.209656,33.310863 274.567932,34.017807 
C271.792023,34.176910 269.447357,34.242207 266.761566,34.192276 
C265.212250,34.711029 264.004028,35.345009 262.418182,35.966621 
C258.361725,35.488693 254.678223,32.095001 251.004150,35.563248 
C249.707291,31.737995 248.412552,28.321264 247.117813,24.904533 
C246.652710,24.972586 246.187622,25.040638 245.722534,25.108690 
C245.261749,27.321434 244.800949,29.534176 244.340164,31.746918 
C244.012970,31.347902 243.685760,30.948889 243.358551,30.549873 
C241.238388,31.657896 239.118210,32.765919 236.674194,33.956337 
C235.909424,34.093445 235.468506,34.148155 234.622925,34.113480 
C232.380447,34.326786 230.480194,34.441109 228.733765,35.019688 
C227.975784,35.270798 227.534348,36.477444 226.944839,37.703068 
C226.940552,52.630680 226.940552,67.105309 226.940552,81.740967 
C224.815063,81.874428 223.348419,81.966515 221.575317,81.916550 
C220.479065,80.862503 219.689240,79.950500 218.992645,78.648720 
C218.551956,75.498528 212.333084,71.155212 209.548157,71.168541 
C207.704651,71.177361 205.857452,70.415878 204.361023,69.958847 
C206.523682,68.419525 206.539444,67.377831 203.989334,66.655426 
C204.670685,65.479828 205.329208,64.643333 206.337372,63.776329 
C208.144836,61.833199 209.602646,59.920574 211.392273,57.881516 
C211.771957,56.830818 211.819809,55.906559 211.946045,54.615173 
C211.968124,53.503437 211.911819,52.758827 211.928925,51.670143 
C211.688873,50.547600 211.375381,49.769131 211.034393,48.621452 
C207.008713,44.227535 203.010529,40.202824 198.633286,35.994873 
C190.571426,35.332073 182.888626,34.852516 174.744873,34.221062 
C167.840363,34.165874 161.396820,34.262589 154.604645,34.265144 
C153.792007,34.258842 153.327988,34.346703 152.536331,34.389290 
C150.792007,34.316250 149.375336,34.288479 147.501968,34.187782 
C142.709488,34.162174 138.373688,34.209496 133.717102,34.156998 
C132.565964,34.685421 131.735626,35.313667 130.581528,36.002697 
C129.262070,35.747921 128.266357,35.432358 126.863663,35.043144 
C124.756615,34.919266 123.056526,34.869041 121.356445,34.818817 
C121.600700,36.684242 121.844948,38.549667 122.070900,40.275311 
C124.540833,40.133614 126.362740,40.029091 128.548157,39.906429 
C129.503815,40.918602 130.095932,41.948914 130.339081,42.993340 
C128.810089,44.629887 126.752663,46.184952 126.621887,47.887962 
C126.288956,52.223351 126.817207,56.624874 127.000793,60.999928 
C124.854080,63.027504 122.539925,64.908615 120.604691,67.121170 
C118.379425,69.665314 118.465416,73.009651 121.394409,74.327545 
C124.488152,75.719574 125.101318,77.673523 125.264359,80.409126 
C125.509529,84.522629 126.053329,88.648308 125.898476,92.747208 
C125.745422,96.798286 122.306763,98.198196 118.654739,96.273499 
C117.692314,95.766281 116.302818,95.875938 115.136986,95.988258 
C112.672745,96.225662 110.226677,96.651718 107.341827,96.624626 
C105.090225,92.109085 103.233253,87.984688 101.485489,83.814522 
C101.191658,83.113449 101.419472,82.208405 101.321404,81.407997 
C100.950867,78.383476 100.545074,75.363281 100.153008,72.341393 
C99.861084,70.091293 98.976608,67.719704 99.406761,65.617188 
C100.207756,61.702015 100.445374,58.195572 98.003242,54.700821 
C97.315994,53.717339 97.705849,51.939243 97.706276,50.525646 
C97.707237,47.313114 97.852905,44.098709 97.776787,40.888931 
C97.742111,39.426632 97.069710,37.964756 97.119934,36.517723 
C97.320831,30.729271 97.988739,24.939020 97.839005,19.166162 
C97.786484,17.141399 95.879852,15.164727 94.819870,13.166096 
C93.224968,15.052618 90.247490,16.978537 90.299896,18.818121 
C90.444855,23.905519 86.964188,25.498423 84.117897,27.829002 
C79.191261,25.728096 82.502472,22.344358 82.181625,19.581924 
C81.890114,17.072044 81.477509,13.188783 79.912041,12.443013 
C70.819473,8.111427 66.063110,11.015867 64.736824,20.919012 
C64.704750,21.158463 64.298332,21.347780 63.509720,22.071362 
C61.487690,20.355606 59.377178,18.617823 57.329769,16.808619 
C55.989441,15.624227 54.950966,13.854711 53.412369,13.182423 
C48.130417,10.874479 47.497334,8.777243 51.096256,4.357339 
C51.897198,3.373685 52.373970,2.126077 53.000000,1.000000 
C235.543106,1.000000 418.086212,1.000000 601.000000,1.000000 
C601.000000,33.686626 601.000000,66.374657 600.632812,99.536148 
C599.292847,100.795242 598.320007,101.580879 597.347107,102.366501 
C598.564758,102.910980 599.782349,103.455482 601.000000,103.999992 
C601.000000,105.066673 601.000000,106.133339 601.000000,107.600006 
M482.989044,27.315413 
C482.664673,26.704906 482.340271,26.094400 482.015869,25.483902 
C481.734650,25.780184 481.453400,26.076466 481.172180,26.372747 
C481.781464,26.915136 482.390717,27.457525 483.019348,28.757650 
C486.377380,34.999702 489.885101,25.249680 493.267822,28.853580 
C494.117310,28.051611 494.986420,27.268143 495.787201,26.420170 
C495.870941,26.331470 495.550720,25.861259 495.070587,24.801277 
C493.285736,25.225952 491.326996,25.649668 489.392303,26.163647 
C487.250092,26.732759 485.129822,27.384430 482.989044,27.315413 
M270.161163,30.990953 
C273.591248,30.279406 273.075928,28.526100 271.861298,26.997051 
C265.783997,26.997051 260.208710,26.997051 253.609039,26.997051 
C254.526337,28.371468 255.253662,30.589796 256.201782,30.688555 
C260.558350,31.142363 264.976624,31.004124 270.161163,30.990953 
M523.810791,100.115898 
C520.065186,99.210022 516.344910,98.164627 512.559509,97.477875 
C511.321747,97.253311 509.914673,97.961708 508.585022,98.243423 
C508.774780,98.960228 508.813995,100.210503 509.177094,100.312965 
C513.816650,101.622253 518.455811,103.062683 523.199036,103.812668 
C525.179077,104.125748 526.744446,102.623177 523.810791,100.115898 
M199.012772,30.961550 
C202.283554,30.798828 205.554321,30.636105 208.825104,30.473383 
C208.775742,29.712547 208.726395,28.951710 208.677048,28.190874 
C205.212418,28.190874 201.747253,28.176334 198.287048,28.299772 
C198.220825,28.302134 198.212692,29.933046 199.012772,30.961550 
M310.436615,33.055874 
C308.922760,32.039131 307.408905,31.022388 305.895050,30.005644 
C305.542542,30.333946 305.190002,30.662249 304.837494,30.990551 
C305.685547,32.615643 306.333221,34.400707 307.455597,35.806839 
C308.147156,36.673214 309.563171,36.961323 310.653931,37.509060 
C310.710022,36.225670 310.766144,34.942276 310.436615,33.055874 
M420.740814,25.009306 
C419.936066,25.407187 419.131348,25.805067 418.326599,26.202957 
C418.602417,26.729662 418.852295,27.694767 419.158234,27.712881 
C421.368439,27.843748 423.589996,27.783068 425.807922,27.783068 
C425.845215,27.079947 425.882507,26.376825 425.919800,25.673702 
C424.473694,25.449665 423.027618,25.225628 420.740814,25.009306 
M505.884796,27.549612 
C504.284424,28.101013 502.684082,28.652414 501.083710,29.203814 
C501.250214,29.723112 501.416718,30.242409 501.583221,30.761707 
C503.024567,30.832815 504.507385,31.111067 505.879608,30.836615 
C506.245331,30.763468 506.272522,28.997530 505.884796,27.549612 
M499.551544,85.154266 
C499.966309,86.329010 500.381042,87.503754 500.795807,88.678505 
C502.175842,87.921333 503.555878,87.164162 504.935883,86.406998 
C504.819458,86.011742 504.703033,85.616486 504.586609,85.221230 
C503.073853,85.029404 501.561066,84.837578 499.551544,85.154266 
M363.996216,25.237116 
C362.732056,25.761925 361.467926,26.286734 360.203766,26.811535 
C360.831726,27.476833 361.459686,28.142134 362.087646,28.807444 
C362.918243,27.775936 363.748810,26.744429 363.996216,25.237116 
M443.691193,31.081348 
C443.693604,30.114845 443.696014,29.148340 443.698395,28.181850 
C442.879639,28.513348 442.060883,28.844843 441.242096,29.176332 
C441.843292,29.924101 442.444489,30.671871 443.691193,31.081348 
M507.864105,41.463528 
C506.860352,41.259460 505.856567,41.055393 504.852814,40.851322 
C504.823029,41.579952 504.793243,42.308586 504.763458,43.037220 
C505.773041,42.745018 506.782654,42.452816 507.864105,41.463528 
M341.200867,26.489483 
C342.195709,26.865004 343.190582,27.240524 344.185425,27.616047 
C344.342682,27.053751 344.499969,26.491455 344.657227,25.929157 
C343.565277,25.879726 342.473297,25.830294 341.200867,26.489483 
M566.549561,25.176556 
C566.261230,26.229195 565.972839,27.281832 565.684448,28.334469 
C566.385132,28.361176 567.085754,28.387880 567.786377,28.414587 
C567.601746,27.397396 567.417053,26.380207 566.549561,25.176556 
M504.749542,36.332134 
C505.743958,36.224545 506.738403,36.116951 507.732788,36.009365 
C507.534088,35.443810 507.335388,34.878250 507.136719,34.312683 
C506.153931,34.885624 505.171143,35.458565 504.749542,36.332134 
M330.589142,51.855270 
C331.593964,51.855270 332.598785,51.855270 333.603577,51.855270 
C333.497772,51.278923 333.391968,50.702576 333.286163,50.126228 
C332.305267,50.502018 331.324341,50.877811 330.589142,51.855270 
M241.971054,27.790634 
C241.742615,26.979671 241.514175,26.168709 241.285721,25.357748 
C240.674194,25.748640 240.062653,26.139532 239.451126,26.530426 
C240.072098,26.999355 240.693085,27.468285 241.971054,27.790634 
M338.823639,59.502773 
C338.137482,59.133335 337.451355,58.763893 336.765198,58.394459 
C336.615204,58.959408 336.465240,59.524357 336.315277,60.089306 
C337.099060,60.118881 337.882812,60.148457 338.823639,59.502773 
M402.175537,26.497168 
C402.862152,26.866936 403.548798,27.236704 404.235443,27.606485 
C404.385498,27.041109 404.535583,26.475731 404.685638,25.910349 
C403.901306,25.880703 403.116943,25.851057 402.175537,26.497168 
M447.105621,25.612417 
C446.997589,26.273823 446.889587,26.935230 446.781586,27.596647 
C447.351501,27.531662 447.921387,27.466675 448.491302,27.401686 
C448.226471,26.716440 447.961670,26.031195 447.105621,25.612417 
M276.589203,27.851885 
C276.917053,27.153347 277.244904,26.454809 277.572754,25.756268 
C277.153931,25.681217 276.735077,25.606167 276.316223,25.531111 
C276.191345,26.272343 276.066467,27.013575 276.589203,27.851885 
M436.704895,27.565489 
C436.989258,27.294111 437.273590,27.022734 437.557953,26.751366 
C437.445435,26.633678 437.241272,26.407478 437.233612,26.413942 
C436.933746,26.667229 436.651703,26.941690 436.704895,27.565489 
z"></path>
<path fill="#FEFDFD" opacity="1.000000" stroke="none" d="
M52.536816,1.000000 
C52.373970,2.126077 51.897198,3.373685 51.096256,4.357339 
C47.497334,8.777243 48.130417,10.874479 53.412369,13.182423 
C54.950966,13.854711 55.989441,15.624227 57.329769,16.808619 
C59.377178,18.617823 61.487690,20.355606 63.509720,22.071362 
C64.298332,21.347780 64.704750,21.158463 64.736824,20.919012 
C66.063110,11.015867 70.819473,8.111427 79.912041,12.443013 
C81.477509,13.188783 81.890114,17.072044 82.181625,19.581924 
C82.502472,22.344358 79.191261,25.728096 84.117897,27.829002 
C86.964188,25.498423 90.444855,23.905519 90.299896,18.818121 
C90.247490,16.978537 93.224968,15.052618 94.819870,13.166096 
C95.879852,15.164727 97.786484,17.141399 97.839005,19.166162 
C97.988739,24.939020 97.320831,30.729271 97.119934,36.517723 
C97.069710,37.964756 97.742111,39.426632 97.776787,40.888931 
C97.852905,44.098709 97.707237,47.313114 97.706276,50.525646 
C97.705849,51.939243 97.315994,53.717339 98.003242,54.700821 
C100.445374,58.195572 100.207756,61.702015 99.406761,65.617188 
C98.976608,67.719704 99.861084,70.091293 100.153008,72.341393 
C100.545074,75.363281 100.950867,78.383476 101.321404,81.407997 
C101.419472,82.208405 101.191658,83.113449 101.485489,83.814522 
C103.233253,87.984688 105.090225,92.109085 106.955811,96.623817 
C107.001122,96.998779 107.000000,97.000000 106.600525,96.999283 
C98.670692,95.929558 98.219582,95.173698 98.896370,88.199707 
C99.504234,81.936035 99.334389,75.766609 91.991554,72.581100 
C92.618607,69.807991 93.595123,67.478859 93.775391,65.089645 
C93.986664,62.289608 93.901108,59.339962 93.235077,56.629486 
C92.117905,52.083096 89.214363,47.657173 89.277580,43.212055 
C89.378525,36.112762 86.074905,31.887831 80.273956,29.917902 
C75.915535,28.437841 70.912422,28.854074 66.195763,28.432249 
C64.455940,28.276649 62.549809,28.546175 61.002819,27.920918 
C54.811672,25.418594 52.543205,26.835913 51.542747,33.922333 
C46.723297,33.961197 42.368561,33.967945 37.967369,33.619030 
C31.508224,26.206539 19.679344,25.202654 13.217484,32.448166 
C8.870640,37.322159 8.565829,45.200508 12.994488,49.257057 
C12.995852,49.504807 12.998749,50.000305 12.997405,50.468941 
C12.993344,82.287125 12.990627,113.636665 12.668100,145.017059 
C11.564878,145.698914 10.781466,146.349915 9.649427,147.038757 
C6.111428,149.249008 4.200292,151.477310 9.145595,154.337326 
C9.529985,156.116882 9.766847,157.558136 9.749018,159.220642 
C10.277526,160.609924 11.111321,162.790298 11.834333,162.754150 
C13.913095,162.650253 15.947071,161.650299 18.427225,161.001831 
C20.570618,161.000610 22.285429,161.000000 23.963154,161.345154 
C21.149908,163.629425 18.373751,165.567932 15.597593,167.506424 
C16.019901,168.378036 16.442207,169.249649 16.864515,170.121246 
C19.576292,171.414230 22.288071,172.707230 24.995255,174.368958 
C24.658895,176.156097 24.327126,177.574524 23.558334,178.994751 
C21.080847,178.997925 19.040382,178.999268 16.782364,178.735779 
C14.929194,178.938385 13.291370,179.398270 11.658409,179.874817 
C8.104294,180.912033 4.552653,181.957764 1.000000,183.000000 
C1.000000,169.312439 1.000000,155.624878 1.384655,141.513306 
C2.979212,141.338287 4.189114,141.587296 5.250487,141.805725 
C6.175591,121.401314 7.240506,101.477356 7.863773,81.539597 
C8.010758,76.837654 6.670336,72.089203 5.980148,67.117126 
C3.721771,68.424438 2.360886,69.212219 1.000000,70.000000 
C1.000000,46.999954 1.000000,23.999905 1.000000,1.000000 
C4.750079,1.000000 8.395864,1.000000 12.520813,1.417559 
C12.442603,3.558717 12.414296,6.004041 11.229891,6.872281 
C7.489959,9.613877 5.441230,12.949445 6.816515,17.393127 
C8.558052,23.020206 12.681572,26.164803 18.137901,24.779148 
C24.270384,23.221783 30.163887,23.747463 36.238468,23.917233 
C42.943821,24.104628 45.075863,20.780409 43.250328,14.314589 
C42.009789,9.920746 41.072754,5.441214 40.000000,1.000000 
C44.024544,1.000000 48.049088,1.000000 52.536816,1.000000 
M91.532455,34.372253 
C92.299538,33.168564 93.066620,31.964874 93.833702,30.761185 
C93.217651,30.517479 92.601601,30.273775 91.985550,30.030069 
C91.707260,31.286186 91.428970,32.542305 91.532455,34.372253 
M68.295120,23.434465 
C68.010742,23.705843 67.726364,23.977221 67.441986,24.248600 
C67.554504,24.366297 67.758652,24.592514 67.766319,24.586040 
C68.066254,24.332766 68.348282,24.058292 68.295120,23.434465 
z"></path>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" d="
M39.531342,1.000000 
C41.072754,5.441214 42.009789,9.920746 43.250328,14.314589 
C45.075863,20.780409 42.943821,24.104628 36.238468,23.917233 
C30.163887,23.747463 24.270384,23.221783 18.137901,24.779148 
C12.681572,26.164803 8.558052,23.020206 6.816515,17.393127 
C5.441230,12.949445 7.489959,9.613877 11.229891,6.872281 
C12.414296,6.004041 12.442603,3.558717 12.999989,1.417559 
C21.687563,1.000000 30.375128,1.000000 39.531342,1.000000 
z"></path>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" d="
M1.000000,70.468658 
C2.360886,69.212219 3.721771,68.424438 5.980148,67.117126 
C6.670336,72.089203 8.010758,76.837654 7.863773,81.539597 
C7.240506,101.477356 6.175591,121.401314 5.250487,141.805725 
C4.189114,141.587296 2.979212,141.338287 1.384655,141.044647 
C1.000000,130.312439 1.000000,119.624878 1.333856,108.418488 
C1.445141,106.933105 1.222570,105.966553 1.000000,105.000000 
C1.000000,93.645775 1.000000,82.291542 1.000000,70.468658 
z"></path>
<path fill="#FAFBF9" opacity="1.000000" stroke="none" d="
M1.000000,183.333328 
C4.552653,181.957764 8.104294,180.912033 11.658409,179.874817 
C13.291370,179.398270 14.929194,178.938385 16.740501,179.094879 
C15.869518,181.072968 14.822846,182.427139 13.352816,184.329056 
C14.385933,188.032532 18.062901,188.256424 22.000305,188.001831 
C19.634602,193.031921 15.900156,193.745422 12.802365,189.526398 
C9.986793,185.691711 6.179308,184.849823 1.421846,185.000000 
C1.000000,184.555557 1.000000,184.111099 1.000000,183.333328 
z"></path>
<path fill="#FEFDFD" opacity="1.000000" stroke="none" d="
M601.000000,103.599998 
C599.782349,103.455482 598.564758,102.910980 597.347107,102.366501 
C598.320007,101.580879 599.292847,100.795242 600.632812,100.004807 
C601.000000,101.066673 601.000000,102.133339 601.000000,103.599998 
z"></path>
<path fill="#FEFDFD" opacity="1.000000" stroke="none" d="
M1.000000,105.375000 
C1.222570,105.966553 1.445141,106.933105 1.333856,107.949829 
C1.000000,107.250000 1.000000,106.500000 1.000000,105.375000 
z"></path>
<path fill="#A0A0A0" opacity="1.000000" stroke="none" d="
M292.111481,142.614960 
C292.806641,142.652634 293.501770,142.690323 295.007690,142.777664 
C307.801819,142.867340 319.785217,142.942337 331.768585,142.941711 
C410.996826,142.937592 490.225128,142.886307 569.453125,143.013657 
C572.669189,143.018829 575.882568,144.659561 578.985107,145.899811 
C577.884399,146.856659 576.895752,147.451965 575.569824,147.849884 
C573.626099,147.046722 572.042725,146.009476 570.408630,145.922043 
C566.626099,145.719620 562.818970,145.976883 558.647400,145.975632 
C557.513733,145.941437 556.754578,145.979355 555.530518,145.953766 
C544.042114,145.936752 533.018677,145.983276 521.530701,145.969452 
C515.377869,145.940979 509.689545,145.972855 503.535858,145.941528 
C487.381134,145.921295 471.691772,145.964279 455.548065,145.940308 
C451.734253,145.903183 448.374756,145.932999 444.547241,145.918747 
C432.051300,145.923325 420.023376,145.971970 407.623779,145.964081 
C406.503632,145.948639 405.755127,145.989731 404.631653,145.971985 
C403.501129,145.957962 402.745636,146.002747 401.620361,145.982605 
C400.504059,145.962402 399.757568,146.007111 398.546722,145.976196 
C370.725586,145.944031 343.368805,145.987503 315.548096,145.961273 
C310.064026,145.912476 305.043823,145.933380 299.554993,145.912094 
C289.055267,145.905350 279.024200,145.940796 268.621460,145.919373 
C267.501160,145.903488 266.752533,145.944473 265.537720,145.928711 
C256.375916,145.900879 247.680283,145.929794 238.614868,145.900742 
C237.499832,145.885529 236.754593,145.928284 235.541977,145.925323 
C228.382355,145.926086 221.690125,145.972580 214.627197,145.941620 
C213.505356,145.918915 212.754196,145.973679 211.577881,145.969193 
C209.439133,145.950897 207.725540,145.991867 205.555054,145.960632 
C201.399704,145.923080 197.701279,145.957718 193.536835,145.934036 
C172.442978,145.862259 151.815063,145.820755 131.187500,145.886246 
C129.457687,145.891754 127.730186,146.616348 125.833504,146.730591 
C125.367744,146.139633 125.070091,145.824966 124.819847,145.114502 
C126.562836,144.117401 128.257034,142.995789 129.954178,142.991302 
C167.382401,142.892822 204.810989,142.948471 242.239426,142.905655 
C258.863556,142.886642 275.487457,142.716507 292.111481,142.614960 
z"></path>
<path fill="#E6E6E6" opacity="1.000000" stroke="none" d="
M579.097229,145.538269 
C575.882568,144.659561 572.669189,143.018829 569.453125,143.013657 
C490.225128,142.886307 410.996826,142.937592 331.768585,142.941711 
C319.785217,142.942337 307.801819,142.867340 295.351135,142.710831 
C295.251404,138.770096 298.231812,140.055679 300.262390,140.052917 
C345.085236,139.991913 389.908203,140.007339 434.731140,140.007294 
C455.566376,140.007278 476.401581,140.005768 497.236816,140.004150 
C521.507446,140.002258 545.778198,139.967911 570.048706,140.028183 
C574.097717,140.038239 578.145752,140.458099 582.764587,140.721054 
C582.840271,142.017853 582.933655,143.616989 582.615967,145.550323 
C581.169067,145.769089 580.133118,145.653671 579.097229,145.538269 
z"></path>
<path fill="#131213" opacity="1.000000" stroke="none" d="
M267.102661,34.307503 
C269.447357,34.242207 271.792023,34.176910 274.921387,34.117371 
C276.436371,34.183208 277.166718,34.243290 277.884705,34.689270 
C277.806488,38.954441 280.714722,37.867500 282.944336,38.423508 
C284.380554,39.504883 285.729095,40.222866 287.083252,41.257759 
C287.149811,41.990757 287.210754,42.406849 287.235779,43.208778 
C288.482056,44.375465 289.764191,45.156315 291.354187,45.973671 
C292.075317,46.014977 292.488617,46.019775 292.901886,46.024574 
C293.385437,46.656780 293.869019,47.288982 294.591370,48.380341 
C294.769562,53.072048 297.574005,55.556026 302.375732,55.553513 
C302.393921,55.600296 302.465118,55.671078 302.277588,55.981709 
C302.410645,57.159782 302.731232,58.027222 303.066620,59.232597 
C303.733521,60.041401 304.385651,60.512272 305.253906,61.081108 
C305.756012,61.350586 305.945526,61.596439 306.059448,62.259949 
C306.767273,63.045948 307.454193,63.488628 308.347412,64.014717 
C308.821106,64.255432 309.007874,64.479874 309.109497,65.130173 
C309.793335,65.964989 310.481628,66.441093 311.370514,67.005714 
C311.828583,67.256470 312.010071,67.480347 312.112823,68.127548 
C312.813660,68.956291 313.517181,69.423363 314.512207,70.028732 
C315.859497,71.154602 316.915283,72.142181 317.985840,73.487167 
C318.985840,74.571693 319.971100,75.298805 320.977936,76.012581 
C320.999542,75.999245 320.974274,76.043205 320.984497,76.413101 
C322.012054,77.511368 323.029327,78.239746 324.023315,78.983719 
C324.000000,78.999321 324.032013,78.953194 324.018036,79.316727 
C324.004059,80.761467 324.004059,81.842682 324.004059,83.039627 
C325.182892,82.623894 326.141510,82.285843 327.464722,81.971626 
C330.950928,84.246750 334.072510,86.498032 337.467468,88.877151 
C338.460358,89.700813 339.179901,90.396645 339.912720,91.425659 
C340.360901,92.723587 340.795715,93.688339 341.179260,95.048462 
C342.909882,97.238525 344.691711,99.033211 346.473572,100.827896 
C346.982727,99.796143 347.491913,98.764397 348.354309,97.702789 
C349.397949,97.551048 350.088379,97.429176 350.843994,97.734406 
C351.022217,100.346642 350.848724,102.599228 351.342163,104.694794 
C351.712341,106.266968 352.940155,107.637199 353.824951,109.473404 
C353.118164,111.849640 353.336151,114.757202 349.817596,114.145966 
C349.817596,114.145966 349.423523,114.039032 349.197906,113.603989 
C347.584564,111.130020 346.196869,109.091103 344.726135,106.724983 
C343.442322,105.564735 342.241547,104.731697 340.744263,103.865402 
C340.052582,103.813210 339.657471,103.794258 339.192566,103.400352 
C338.012878,102.100174 336.902985,101.174942 335.839233,100.180000 
C335.885406,100.110291 335.751556,100.210526 335.800262,99.817108 
C334.797028,98.764137 333.745056,98.104576 332.664795,97.407265 
C332.636505,97.369507 332.553741,97.324203 332.624390,96.963135 
C329.584686,94.002274 326.474396,91.402489 323.289368,88.794998 
C323.214661,88.787285 323.196167,88.638222 323.148438,88.319916 
C322.216736,87.258583 321.332764,86.515564 320.359314,85.755203 
C320.269867,85.737854 320.216766,85.563530 320.140503,85.243286 
C319.059723,84.269859 318.055237,83.616684 317.025024,82.980728 
C316.999268,82.997940 317.036926,82.948563 317.049133,82.605476 
C316.350189,81.566071 315.639008,80.869759 314.761963,79.879639 
C311.645142,77.502686 308.694183,75.419525 305.732361,73.307114 
C305.721497,73.277870 305.660156,73.266487 305.705261,72.889664 
C304.703156,71.821426 303.655914,71.130013 302.351440,70.219963 
C300.756744,69.280441 299.419312,68.559540 297.929749,67.533859 
C295.936493,65.714256 294.037292,64.263832 292.268280,62.668983 
C289.835815,60.475994 287.501923,58.173691 285.057678,55.557453 
C284.040497,52.278442 282.709320,49.809971 278.785828,49.820778 
C277.369324,48.205124 276.180695,46.892433 274.997894,45.574471 
C274.275635,44.769676 273.562408,43.956776 272.588074,43.076645 
C271.982971,42.935120 271.634949,42.864468 271.266266,42.461578 
C270.476868,41.468731 269.708160,40.808113 268.649017,40.055984 
C267.881287,39.677151 267.403992,39.389835 266.958679,38.703430 
C267.028046,36.972057 267.065338,35.639782 267.102661,34.307503 
z"></path>
<path fill="#656364" opacity="1.000000" stroke="none" d="
M300.026947,184.756409 
C280.912872,184.828354 261.798889,184.940979 242.684738,184.963242 
C209.099777,185.002365 175.514725,184.986206 141.008698,184.989929 
C136.066711,184.935059 132.045776,184.883484 128.019836,184.517776 
C128.019897,183.784744 128.024963,183.365875 128.450241,182.972046 
C131.290451,182.656082 133.789383,182.579651 136.105087,181.888977 
C137.904587,181.352249 139.475601,180.049454 141.451599,179.066101 
C142.160355,179.049561 142.565659,179.054092 143.178192,179.337418 
C145.256256,180.408478 147.127106,181.200760 149.403824,181.998032 
C151.873962,181.332428 153.938232,180.661819 156.288330,180.099640 
C157.679398,180.177628 158.784607,180.147186 160.013580,180.503937 
C161.148270,181.260498 162.157104,181.941772 163.170517,181.948669 
C169.795059,181.993790 176.420593,181.892944 183.454636,181.880646 
C185.232376,181.915405 186.601273,181.909332 188.399719,181.950287 
C191.990479,182.339264 194.632156,182.016830 194.620575,177.823959 
C198.946442,178.173584 202.867859,178.483780 206.872253,179.202118 
C208.720001,180.405075 210.468307,181.840652 212.252335,181.886490 
C221.159744,182.115417 230.076538,181.979507 239.440933,181.993973 
C242.599258,181.997299 245.306671,181.978577 248.466888,181.980118 
C251.945450,181.991440 254.971176,181.982498 258.378540,181.961945 
C259.393463,181.757401 260.029785,181.387177 260.659607,181.397934 
C271.095734,181.576248 281.531555,181.951096 291.966156,181.915314 
C294.637817,181.906174 297.305145,180.633118 299.982178,180.342285 
C300.002228,182.081345 300.014587,183.418884 300.026947,184.756409 
z"></path>
<path fill="#E6E6E6" opacity="1.000000" stroke="none" d="
M292.051758,142.234161 
C275.487457,142.716507 258.863556,142.886642 242.239426,142.905655 
C204.810989,142.948471 167.382401,142.892822 129.954178,142.991302 
C128.257034,142.995789 126.562836,144.117401 124.496368,145.168457 
C123.693169,145.683746 123.260857,145.749268 122.417961,145.786972 
C120.907661,145.832245 119.807945,145.905350 118.708221,145.978439 
C119.334473,143.832825 119.960732,141.687225 120.852219,140.118713 
C122.806458,142.673431 124.161308,142.760986 125.013786,140.006973 
C125.013786,140.006973 125.505112,139.999268 126.201134,140.000366 
C129.599060,139.996750 132.300980,139.992035 135.002884,139.987305 
C185.496414,139.995621 235.989975,139.984558 286.483307,140.089813 
C288.320740,140.093643 290.155823,141.239670 292.051758,142.234161 
z"></path>
<path fill="#FAFBF9" opacity="1.000000" stroke="none" d="
M134.966202,139.635315 
C132.300980,139.992035 129.599060,139.996750 126.446747,139.994217 
C125.121155,138.429276 124.245972,136.871567 123.028862,134.705276 
C125.162048,134.319458 127.246300,133.942474 129.043060,133.617493 
C128.331650,130.366394 127.938805,127.063309 126.774216,124.059418 
C126.361557,122.995033 124.126526,122.329559 122.621246,122.066223 
C120.171318,121.637619 119.482559,121.019325 122.379539,119.004227 
C125.512123,118.744949 127.880890,118.667801 130.699219,118.617126 
C137.104141,118.672707 143.059464,118.701820 149.074890,119.062920 
C149.753265,119.192177 150.371536,118.989449 151.404770,118.741150 
C153.485764,118.692863 155.151810,118.690147 157.148041,118.917572 
C159.318710,119.078995 161.159149,119.010269 163.442154,118.847183 
C166.576416,118.600784 169.268112,118.448769 172.362091,118.465187 
C174.515305,118.701797 176.266251,118.769974 178.154114,119.197266 
C179.854019,119.315453 181.417053,119.074509 183.447327,118.794128 
C189.193298,118.670013 194.472046,118.585327 200.138855,118.642838 
C201.350647,118.847885 202.174362,118.910736 202.998062,118.973587 
C202.572372,122.793617 199.621826,121.973022 197.267090,121.977936 
C178.137756,122.017883 159.008316,122.000931 139.878906,122.001297 
C138.102768,122.001328 136.326630,122.001297 134.538193,122.001297 
C134.331116,126.135963 134.156815,129.615891 133.954773,133.650070 
C136.092331,133.854996 138.755661,134.110321 141.418976,134.365631 
C141.468140,134.913193 141.517303,135.460754 141.566467,136.008316 
C139.422562,136.240799 137.278641,136.473282 134.636261,136.759827 
C134.695679,137.271133 134.812607,138.277237 134.966202,139.635315 
z"></path>
<path fill="#A7A6A6" opacity="1.000000" stroke="none" d="
M130.249649,118.590652 
C127.880890,118.667801 125.512123,118.744949 122.711433,118.850410 
C122.206161,117.215202 122.132812,115.551674 122.442917,113.472115 
C126.533531,112.223450 131.019272,112.609177 130.973801,106.712746 
C130.809998,85.468765 130.772980,64.223816 130.688065,42.979229 
C130.095932,41.948914 129.503815,40.918602 128.473709,39.555984 
C127.780701,37.854717 127.525665,36.485756 127.270638,35.116795 
C128.266357,35.432358 129.262070,35.747921 130.731110,36.291946 
C132.038391,37.730682 132.872375,38.940948 133.789886,40.601906 
C133.894836,63.004925 133.942856,84.957314 133.890457,106.909470 
C133.885956,108.797371 133.215561,110.683670 132.505402,112.617981 
C130.920990,113.573105 129.685654,114.480949 128.486115,115.362495 
C129.167236,116.609283 129.708450,117.599968 130.249649,118.590652 
z"></path>
<path fill="#FAFBF9" opacity="1.000000" stroke="none" d="
M130.339081,42.993340 
C130.772980,64.223816 130.809998,85.468765 130.973801,106.712746 
C131.019272,112.609177 126.533531,112.223450 122.422180,113.051804 
C122.011612,112.285286 122.005219,111.523033 122.257439,110.100159 
C124.282333,108.195580 128.304962,110.548431 127.998474,106.000305 
C127.240173,103.696503 126.481873,101.392693 125.545990,98.549347 
C129.790192,94.631073 127.734779,88.892967 127.925331,83.610649 
C128.167450,76.898697 127.990906,70.171188 127.960030,63.450546 
C127.957092,62.810741 127.706718,62.172070 127.286011,61.266399 
C126.817207,56.624874 126.288956,52.223351 126.621887,47.887962 
C126.752663,46.184952 128.810089,44.629887 130.339081,42.993340 
z"></path>
<path fill="#CDCBCC" opacity="1.000000" stroke="none" d="
M360.027191,104.002831 
C360.019226,107.797356 359.752899,111.613800 360.072296,115.380569 
C360.428314,119.578735 357.015198,119.212250 354.835022,119.890144 
C352.519012,120.610291 350.026337,120.302963 350.298859,116.541748 
C352.115021,116.917297 353.544098,117.589058 355.685120,118.595474 
C356.182373,116.181892 356.880127,114.344521 356.890259,112.503357 
C356.979309,96.339981 356.958435,80.175911 356.943146,64.012085 
C356.935547,55.984253 356.871429,47.956470 356.984436,39.652924 
C358.067657,38.548260 358.999420,37.719337 359.960205,37.301533 
C360.023407,38.811501 360.057617,39.910351 360.091797,41.009197 
C360.056580,60.359665 360.021362,79.710129 359.986084,99.928856 
C359.999725,101.865685 360.013458,102.934258 360.027191,104.002831 
z"></path>
<path fill="#EBEAEA" opacity="1.000000" stroke="none" d="
M571.009766,48.001415 
C571.009766,69.028961 571.009766,90.056511 571.009766,110.814941 
C574.248413,111.733734 577.061951,112.531929 579.875488,113.330116 
C579.133240,114.456589 578.391052,115.583061 576.973022,116.853111 
C575.828674,116.995354 575.360046,116.994026 574.849487,116.625443 
C573.522827,115.482071 572.108704,114.856049 570.988037,113.889732 
C569.742065,112.815338 568.763489,111.430824 567.775452,109.709312 
C567.949036,85.532188 568.015686,61.827057 568.331665,38.009190 
C568.772705,37.592785 568.964417,37.289112 569.502930,37.064667 
C570.241028,38.430305 570.632324,39.716728 571.011719,41.431000 
C571.002258,43.571339 571.004822,45.283829 571.005371,47.247597 
C571.005554,47.666389 571.007629,47.833900 571.009766,48.001415 
z"></path>
<path fill="#FAFBF9" opacity="1.000000" stroke="none" d="
M91.988785,72.998489 
C99.334389,75.766609 99.504234,81.936035 98.896370,88.199707 
C98.219582,95.173698 98.670692,95.929558 106.600220,96.999603 
C108.531372,99.728447 110.095566,102.438911 111.578430,105.193161 
C112.120995,106.200928 113.043747,107.461075 112.812889,108.352158 
C111.547691,113.235687 112.975487,117.117592 116.299049,119.308014 
C113.815102,122.544304 111.776649,125.200165 109.369095,127.928009 
C109.000000,128.000000 108.997284,127.997498 108.998550,127.594543 
C110.046295,118.923592 108.136330,111.028351 106.296371,103.057625 
C105.542618,99.792343 103.995552,98.066681 100.969574,97.520882 
C96.697098,96.750259 94.757744,94.278275 95.339127,89.808533 
C96.119232,87.771095 96.901260,85.921577 96.883179,84.079918 
C96.870132,82.751457 95.792290,81.433441 95.189362,80.110771 
C93.699806,80.959366 92.004936,81.592522 90.800537,82.740334 
C90.118286,83.390526 90.240753,84.885132 89.657684,85.965179 
C86.106529,84.428619 83.855347,82.605232 86.983475,78.732422 
C86.985481,78.487274 86.992508,77.997025 87.338684,77.944611 
C88.117523,76.921356 88.550186,75.950516 88.986725,74.735817 
C88.990601,74.491951 89.000916,74.004272 89.319168,73.978806 
C90.421211,73.635056 91.204994,73.316772 91.988785,72.998489 
z"></path>
<path fill="#605F60" opacity="1.000000" stroke="none" d="
M356.832947,39.928669 
C356.871429,47.956470 356.935547,55.984253 356.943146,64.012085 
C356.958435,80.175911 356.979309,96.339981 356.890259,112.503357 
C356.880127,114.344521 356.182373,116.181892 355.685120,118.595474 
C353.544098,117.589058 352.115021,116.917297 350.333313,116.138130 
C349.949463,115.611534 349.918274,115.192345 349.852356,114.459564 
C353.336151,114.757202 353.118164,111.849640 353.890625,108.999680 
C353.919952,87.096657 353.903381,66.043365 353.953888,44.990227 
C353.958984,42.873497 353.026886,39.985950 356.832947,39.928669 
z"></path>
<path fill="#FDFDFD" opacity="1.000000" stroke="none" d="
M76.000000,162.000000 
C76.025253,162.493423 75.853813,163.260971 76.104202,163.440628 
C84.879555,169.737167 77.393661,175.490265 75.242401,181.119781 
C73.803345,184.885590 70.060944,184.449402 66.356720,182.780518 
C66.000008,181.499924 66.000313,180.749954 66.376160,179.921265 
C68.324547,177.361542 69.511826,174.922897 65.967995,172.654739 
C65.623482,171.206314 65.311592,170.103165 65.278259,168.795746 
C67.704651,165.727463 69.852478,162.863419 72.294815,160.141022 
C73.726219,160.855087 74.863106,161.427551 76.000000,162.000000 
M74.798737,167.394058 
C74.794518,167.786713 74.790298,168.179367 74.786087,168.572021 
C74.949028,168.573563 75.253586,168.586044 75.254425,168.575058 
C75.284592,168.184357 75.286331,167.791443 74.798737,167.394058 
z"></path>
<path fill="#4D4B4C" opacity="1.000000" stroke="none" d="
M540.009338,114.002945 
C538.577209,115.014397 537.145081,116.025848 534.973022,117.050316 
C530.163757,117.604462 526.106140,118.468674 522.022583,118.616974 
C514.400574,118.893776 506.763489,118.754631 499.132660,118.788574 
C499.132660,118.788574 499.333221,118.625160 499.238098,118.151642 
C497.471832,116.942917 495.800720,116.207718 493.824249,115.301697 
C492.325439,114.111038 491.131958,113.091217 490.355713,112.019188 
C496.417511,113.119537 502.041626,114.402458 507.727051,115.295074 
C508.663849,115.442162 509.869446,113.877472 511.410858,112.982956 
C520.259460,112.436958 528.933167,114.373856 537.134216,110.016052 
C538.297607,111.456337 539.153442,112.729637 540.009338,114.002945 
z"></path>
<path fill="#817F80" opacity="1.000000" stroke="none" d="
M227.842896,112.925476 
C227.895248,110.255287 227.947601,107.585106 228.436829,104.407509 
C229.259171,101.599037 229.644623,99.297981 230.295532,96.709274 
C230.413544,93.614853 230.266113,90.808075 230.142334,87.532898 
C230.045654,70.791695 229.925323,54.518879 230.037598,38.176254 
C230.270203,38.106453 230.748398,38.021549 230.970551,38.353779 
C231.803192,40.654819 232.897797,42.612549 232.943176,44.594303 
C233.182999,55.067017 233.158615,65.545776 233.150574,76.452789 
C233.161545,78.913109 233.247818,80.943100 233.252380,83.436478 
C233.057755,93.530487 232.944824,103.161118 232.485748,112.827026 
C231.445847,112.919037 230.752075,112.975769 229.689026,113.015900 
C228.827469,112.974686 228.335190,112.950081 227.842896,112.925476 
z"></path>
<path fill="#D2D0D1" opacity="1.000000" stroke="none" d="
M372.132599,112.680000 
C371.777039,110.773735 371.095184,108.863937 371.115845,106.961754 
C371.348419,85.512505 371.694672,64.064499 371.970947,42.615650 
C371.978851,42.000137 371.551147,41.379021 371.189270,40.068130 
C371.421204,38.907238 371.790497,38.438736 372.446686,38.043865 
C373.467590,38.766743 374.201599,39.415989 374.975220,40.528152 
C375.027374,63.998333 375.039948,87.005592 374.966675,110.325111 
C373.964752,111.318245 373.048676,111.999123 372.132599,112.680000 
z"></path>
<path fill="#656364" opacity="1.000000" stroke="none" d="
M435.006104,184.761383 
C410.303345,184.814285 385.600586,184.867172 360.437866,184.440552 
C359.987488,182.651062 359.997101,181.341080 360.324066,180.034241 
C361.063538,180.044601 361.485626,180.051819 362.130676,180.390488 
C363.049866,181.131027 363.743164,181.890472 364.442810,181.896347 
C373.952850,181.976288 383.463776,181.951813 393.404633,181.977570 
C395.730316,182.242325 397.648041,182.767670 399.513641,182.622742 
C400.759644,182.525940 401.931519,181.475372 403.483337,180.878845 
C404.523651,180.936218 405.217194,180.966354 406.314697,181.024216 
C408.151276,181.356918 409.579895,181.902725 411.017273,181.926956 
C419.005676,182.061722 426.996246,182.069885 434.989624,182.451355 
C434.997498,183.441360 435.001801,184.101379 435.006104,184.761383 
z"></path>
<path fill="#535253" opacity="1.000000" stroke="none" d="
M300.488953,184.825958 
C300.014587,183.418884 300.002228,182.081345 299.997467,180.353806 
C300.005066,179.963791 300.031708,179.989044 300.414734,179.979919 
C302.233490,179.546219 303.669189,179.121628 305.516479,178.751770 
C307.588898,178.803146 309.249817,178.799759 311.284851,178.870056 
C312.644440,177.910660 313.629883,176.877594 314.690063,175.461349 
C313.444275,171.087616 310.316864,173.508194 307.585815,173.103577 
C303.532349,173.146164 302.607574,170.763062 302.076538,166.897171 
C305.228455,166.897171 308.081360,166.897171 310.992859,167.282669 
C313.342407,168.495499 315.633331,169.322830 317.920349,170.459427 
C317.932678,171.180634 317.948883,171.592590 317.962280,172.443176 
C317.958038,174.935562 317.956604,176.989349 317.818054,179.340607 
C318.468353,180.450546 319.255737,181.262985 320.026367,182.407227 
C319.995117,183.403229 319.980652,184.067413 319.966187,184.731613 
C313.627777,184.786240 307.289398,184.840866 300.488953,184.825958 
z"></path>
<path fill="#DDDCDC" opacity="1.000000" stroke="none" d="
M280.852325,60.951157 
C280.917358,66.817589 280.916534,72.685654 281.062103,78.550087 
C281.309479,88.515305 281.536255,98.483986 282.043182,108.437378 
C282.147125,110.478035 283.445343,112.457863 283.706604,114.755814 
C281.554504,114.296150 279.888855,113.546394 278.167786,112.599213 
C278.112305,112.401772 277.972076,112.016296 278.124573,111.566559 
C278.319641,94.418007 278.362213,77.719177 278.712616,61.013176 
C279.631042,60.987720 280.241699,60.969437 280.852325,60.951157 
z"></path>
<path fill="#605F60" opacity="1.000000" stroke="none" d="
M537.299561,184.739746 
C524.510437,184.793030 511.721313,184.846313 498.469330,184.480835 
C497.993286,183.399902 497.980103,182.737686 498.399597,182.057343 
C501.428864,181.680832 504.025452,181.322464 506.622040,180.964111 
C506.544739,180.409683 506.467438,179.855240 506.390137,179.300797 
C504.774841,179.172882 503.159515,179.044968 501.470642,178.569611 
C501.637177,177.142395 501.877289,176.062622 502.504578,174.976212 
C504.380737,175.183060 505.869690,175.396545 507.358643,175.610046 
C507.320740,174.216934 507.293182,172.823441 507.239380,171.430939 
C507.220520,170.942474 507.137207,170.456482 507.349792,169.957642 
C507.793762,169.936203 507.971313,169.926468 508.495178,169.977631 
C509.541351,171.040771 510.241272,172.043015 510.936401,173.464142 
C510.931641,176.139496 510.931641,178.395950 510.931641,182.080658 
C519.938599,182.080658 528.974365,182.080658 537.860229,182.399475 
C537.573425,183.392105 537.436462,184.065933 537.299561,184.739746 
z"></path>
<path fill="#FEFAEC" opacity="1.000000" stroke="none" d="
M39.001331,189.001831 
C38.506760,188.738953 38.012184,188.476059 37.124893,187.796478 
C36.486671,186.303467 36.241173,185.227142 36.400101,184.137527 
C40.370567,185.033112 43.987705,186.485321 47.444939,182.979919 
C49.531708,181.554993 51.328236,180.301056 53.517513,179.033844 
C55.390114,178.143387 56.998394,177.421951 58.309917,176.340576 
C59.394184,175.446579 60.127861,174.127396 61.340919,173.144165 
C65.942780,174.992615 63.306679,178.445908 62.651455,180.347168 
C61.981171,182.292114 59.150192,183.609207 57.088097,184.923538 
C55.180740,186.139236 53.039215,186.987518 51.000824,187.997604 
C51.000820,187.997604 51.000000,188.000000 50.620316,188.045837 
C46.686317,190.767548 42.872261,190.235855 39.001331,189.001831 
z"></path>
<path fill="#0F0D0E" opacity="1.000000" stroke="none" d="
M219.863159,105.934799 
C215.835220,108.641029 211.807281,111.347260 207.276108,113.779198 
C205.465118,112.280098 204.157364,111.055283 202.989944,109.533310 
C203.521378,108.474480 203.912460,107.712791 204.666077,106.975754 
C206.211212,106.873421 207.393814,106.746445 209.118011,106.561310 
C208.129791,105.368744 207.554062,104.673973 207.241425,103.705795 
C211.274872,103.523682 212.504974,101.472343 212.443298,97.965836 
C215.525787,96.951271 218.236450,95.988724 220.962631,95.366226 
C221.588120,96.479576 222.198105,97.252876 222.890900,98.334152 
C222.943298,99.064148 222.912918,99.486168 222.568268,100.068298 
C221.457047,102.130524 220.660095,104.032661 219.863159,105.934799 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M571.023621,41.003143 
C570.632324,39.716728 570.241028,38.430305 569.413452,36.684105 
C567.659241,35.798428 566.341309,35.372532 564.869141,34.638214 
C563.809387,34.181854 562.903931,34.033909 561.998474,33.885963 
C562.020142,33.557667 561.973938,32.964371 562.073120,32.938992 
C567.586609,31.527485 573.112183,30.163462 578.636597,28.794664 
C578.750183,29.271135 578.863708,29.747604 578.625122,30.651272 
C577.077332,32.227146 575.881714,33.375828 574.686035,34.524506 
C575.055298,34.942078 575.424561,35.359653 575.793823,35.777225 
C576.865234,35.170517 577.936646,34.563808 579.008057,33.957100 
C578.729736,36.031216 578.465149,38.107288 578.169617,40.178959 
C577.792725,42.821194 577.390991,45.459877 577.000000,48.550354 
C575.258850,48.671280 573.517700,48.341953 571.393188,48.007019 
C571.007629,47.833900 571.005554,47.666389 571.322021,47.087761 
C573.860229,44.521744 576.377991,42.334431 571.023621,41.003143 
z"></path>
<path fill="#343233" opacity="1.000000" stroke="none" d="
M576.900391,181.841324 
C576.900391,181.841324 576.516418,181.981293 575.885376,182.009735 
C566.844788,181.771896 558.435242,181.505585 550.019165,180.839157 
C549.649658,179.285309 549.286621,178.131577 548.923584,176.555634 
C549.486267,172.015015 550.048889,167.896606 550.628113,163.431259 
C550.671021,162.390381 550.697388,161.696442 551.087585,161.013702 
C551.932007,160.961716 552.412476,160.898514 552.933777,161.311707 
C552.989319,167.191757 553.004089,172.595398 553.110840,178.223679 
C553.397766,178.730057 553.663696,178.901932 554.331421,178.970200 
C555.103088,178.967484 555.544067,178.958496 556.413452,178.969559 
C558.552979,178.980103 560.263977,178.970596 562.355957,178.982574 
C563.499084,178.990799 564.261353,178.977524 565.468506,178.974213 
C568.560791,177.834686 573.115479,181.850723 574.408630,175.994156 
C575.818665,176.006912 576.843140,176.015198 577.923706,176.400238 
C577.619995,178.465103 577.260193,180.153214 576.900391,181.841324 
z"></path>
<path fill="#888787" opacity="1.000000" stroke="none" d="
M577.266357,181.891022 
C577.260193,180.153214 577.619995,178.465103 577.979980,176.065720 
C577.977905,174.902863 577.975586,174.451294 578.375305,173.916809 
C579.024170,167.081635 579.282776,160.329697 579.461487,153.575638 
C579.466858,153.372955 578.634766,153.148117 578.139526,152.615860 
C577.570312,151.511642 577.054688,150.725174 576.442017,149.679962 
C576.223999,149.282242 576.103027,149.143280 575.969727,148.764572 
C575.957397,148.524811 575.907104,148.047287 575.907104,148.047272 
C576.895752,147.451965 577.884399,146.856659 578.985107,145.899811 
C580.133118,145.653671 581.169067,145.769089 582.585571,145.972610 
C582.976196,156.650192 583.050476,167.240341 582.937683,177.828491 
C582.917053,179.764420 582.134033,181.692230 581.693604,183.665665 
C579.787476,182.856094 578.709900,182.398407 577.266357,181.891022 
z"></path>
<path fill="#FEFAEC" opacity="1.000000" stroke="none" d="
M87.000214,157.998779 
C86.505600,158.261124 86.010994,158.523453 84.871323,158.938477 
C79.334229,161.326721 76.190948,159.277496 74.243080,154.659698 
C75.985565,153.596695 77.477066,152.788239 79.214737,151.963104 
C79.460899,151.946411 79.952591,151.904465 80.327209,151.941299 
C81.505013,151.011063 82.308197,150.043991 83.446938,149.038391 
C84.563927,148.034210 85.345360,147.068512 86.463654,146.057129 
C88.148979,144.960907 89.497459,143.910339 91.233650,142.896378 
C92.395660,142.923111 93.169968,142.913223 93.930481,143.253632 
C93.279068,145.069168 92.641434,146.534439 92.003799,147.999710 
C91.684547,148.786163 91.365295,149.572601 90.664597,150.745178 
C89.188835,153.420456 88.094528,155.709610 87.000214,157.998779 
z"></path>
<path fill="#FAFBF9" opacity="1.000000" stroke="none" d="
M23.995356,178.992935 
C24.327126,177.574524 24.658895,176.156097 25.321274,174.226898 
C26.736149,173.319489 27.845028,172.978973 28.900290,172.516190 
C32.811470,170.800980 35.022060,167.422699 34.502441,163.562332 
C34.344917,162.392014 29.629110,161.801758 26.977737,161.025757 
C26.366457,160.846848 25.658979,160.996613 25.083122,160.609039 
C28.117620,159.998230 31.262547,159.008575 33.970573,159.708725 
C39.536827,161.147888 39.688332,162.740143 36.874046,167.787933 
C34.902401,171.324326 33.592098,175.229446 31.741299,178.979507 
C31.496157,178.989120 31.005617,178.999985 30.622013,179.009491 
C29.490950,180.346603 28.743488,181.674210 27.996029,183.001831 
C27.225103,184.296906 26.454174,185.591965 25.345041,186.584320 
C24.669676,183.852036 24.332516,181.422485 23.995356,178.992935 
z"></path>
<path fill="#B4B2B3" opacity="1.000000" stroke="none" d="
M298.081848,67.838638 
C299.419312,68.559540 300.756744,69.280441 302.368500,70.571869 
C303.648590,71.850426 304.654358,72.558456 305.660156,73.266487 
C305.660156,73.266487 305.721497,73.277870 305.757874,73.661179 
C308.823212,76.785835 311.852173,79.527176 315.072418,82.515205 
C315.854767,82.824120 316.445831,82.886337 317.036926,82.948563 
C317.036926,82.948563 316.999268,82.997940 316.952515,83.345139 
C318.009430,84.316063 319.113098,84.939796 320.216766,85.563530 
C320.216766,85.563530 320.269867,85.737854 320.255432,86.110474 
C321.226074,87.201462 322.211121,87.919846 323.196167,88.638229 
C323.196167,88.638222 323.214661,88.787285 323.188171,89.136482 
C324.496552,91.235809 325.831421,92.985947 327.125275,95.120285 
C327.048096,96.018646 327.011871,96.532799 326.975647,97.046951 
C322.685181,93.413513 318.369873,89.808914 314.110809,86.139015 
C309.218079,81.923111 304.375214,77.649391 299.241760,72.780525 
C298.675812,70.719734 298.378845,69.279190 298.081848,67.838638 
z"></path>
<path fill="#343233" opacity="1.000000" stroke="none" d="
M444.025452,115.037132 
C443.520477,115.270325 443.015533,115.503510 441.970703,115.486229 
C439.558228,115.461601 437.688538,115.716286 435.812256,115.905472 
C433.756744,116.112747 431.818451,116.091034 432.419647,113.073410 
C434.558228,112.674721 436.315460,112.353172 438.442322,112.020714 
C441.197052,110.304359 445.368805,110.376823 444.957214,105.557419 
C445.651703,105.148544 446.319153,105.043793 447.143280,105.212029 
C448.113007,105.652710 448.926086,105.820389 450.123749,106.009766 
C451.385010,105.389420 452.261688,104.747368 453.510406,104.083260 
C454.874664,104.026642 455.866852,103.992088 456.922119,104.321594 
C456.673248,105.802444 456.361359,106.919235 456.049469,108.036041 
C453.324829,109.653748 450.600189,111.271454 447.169861,112.939301 
C445.651276,113.672005 444.838348,114.354568 444.025452,115.037132 
z"></path>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" d="
M283.954346,117.897850 
C284.249908,120.920631 282.766418,122.256889 279.864868,121.974998 
C273.649902,121.371193 267.116272,123.731049 261.254761,119.241920 
C259.655792,118.017349 256.498230,118.827942 253.775818,118.289032 
C251.644089,115.935806 249.792480,113.998093 247.969147,111.674629 
C248.032745,110.517159 248.068085,109.745445 248.475616,108.990929 
C253.688995,111.616692 256.916962,118.305161 264.040283,115.396255 
C265.072815,116.661148 266.148651,117.562271 267.571838,118.528244 
C268.621582,118.675064 269.323975,118.757034 270.121460,119.158493 
C270.830505,119.197166 271.444397,118.916359 272.416565,118.634415 
C273.480804,118.552322 274.186798,118.471359 275.267212,118.534111 
C276.443359,118.708702 277.245087,118.739586 278.123169,119.073883 
C279.168365,118.949829 280.137238,118.522369 281.461578,118.059647 
C282.529510,117.982201 283.241913,117.940025 283.954346,117.897850 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M128.024826,184.831924 
C132.045776,184.883484 136.066711,184.935059 140.545410,185.063934 
C141.116821,185.619476 141.264420,186.092575 141.337860,186.576889 
C141.576141,188.148056 141.787247,189.723328 142.008820,191.297028 
C140.474182,190.832520 138.590500,190.781387 137.465607,189.831604 
C132.719162,185.824036 130.550964,186.326813 128.266190,192.774307 
C125.949158,190.351639 120.766945,194.931229 120.267120,188.182892 
C120.701767,186.427048 120.873756,185.302383 121.361038,184.257034 
C122.484329,184.470291 123.292320,184.604218 124.489647,184.802338 
C125.927589,184.854996 126.976212,184.843460 128.024826,184.831924 
z"></path>
<path fill="#717071" opacity="1.000000" stroke="none" d="
M540.385620,113.981430 
C539.153442,112.729637 538.297607,111.456337 537.176392,109.714272 
C536.948425,108.839607 536.985657,108.433708 537.341431,107.884872 
C538.753479,107.427628 539.846985,107.113319 541.299255,106.827995 
C543.477783,104.933731 545.297485,103.010483 547.061829,101.058517 
C547.006531,101.029808 547.093201,101.119347 547.428223,101.149391 
C548.513062,100.788643 549.262878,100.397850 550.349487,100.000397 
C551.357361,99.959961 552.028442,99.926186 552.679810,100.223038 
C551.401245,102.396233 550.142273,104.238792 549.072754,106.378693 
C550.553162,108.797668 551.844055,110.919296 553.080444,113.382874 
C552.950867,114.179863 552.875854,114.634911 552.467896,115.071259 
C551.470825,115.004204 550.806824,114.955856 550.100220,114.510849 
C549.525574,112.779488 548.993652,111.444778 548.461670,110.110069 
C547.978088,110.762390 547.494507,111.414703 547.010864,112.067001 
C544.927856,112.697960 542.844910,113.328941 540.385620,113.981430 
z"></path>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" d="
M499.131958,119.198639 
C506.763489,118.754631 514.400574,118.893776 522.022583,118.616974 
C526.106140,118.468674 530.163757,117.604462 534.624878,117.115906 
C533.876465,118.444946 532.802368,120.756409 531.585632,120.834099 
C521.869812,121.454468 512.128052,121.728828 502.390778,121.870056 
C501.315430,121.885651 500.218262,120.398766 499.131958,119.198639 
z"></path>
<path fill="#D2D0D1" opacity="1.000000" stroke="none" d="
M297.929749,67.533859 
C298.378845,69.279190 298.675812,70.719734 299.021637,72.526505 
C297.621674,72.826279 296.172882,72.759827 294.724091,72.693375 
C294.981354,72.318825 295.238586,71.944275 295.495850,71.569725 
C293.698975,69.710464 291.879608,67.872246 290.111084,65.986420 
C288.047485,63.785992 285.987610,61.579483 284.001892,59.309322 
C282.237152,57.291801 281.363007,57.885845 280.893799,60.580360 
C280.241699,60.969437 279.631042,60.987720 278.591095,60.604752 
C278.197510,58.514130 278.233246,56.824757 278.655396,55.085762 
C281.069824,55.330162 283.097809,55.624191 285.125793,55.918221 
C287.501923,58.173691 289.835815,60.475994 292.268280,62.668983 
C294.037292,64.263832 295.936493,65.714256 297.929749,67.533859 
z"></path>
<path fill="#656364" opacity="1.000000" stroke="none" d="
M492.005768,184.800720 
C480.643555,184.841461 469.281342,184.882202 457.457306,184.505630 
C457.002319,183.414902 457.009186,182.741486 457.353851,182.048248 
C458.142853,182.001938 458.594055,181.975433 459.510803,181.964264 
C468.644226,181.956345 477.312042,181.933090 486.366089,181.944885 
C488.500275,182.327606 490.248230,182.675293 491.999573,183.319244 
C492.003876,184.010605 492.004822,184.405655 492.005768,184.800720 
z"></path>
<path fill="#656364" opacity="1.000000" stroke="none" d="
M567.668518,110.181305 
C568.763489,111.430824 569.742065,112.815338 570.988037,113.889732 
C572.108704,114.856049 573.522827,115.482071 574.635681,116.924316 
C567.037170,117.907837 559.610413,118.225235 552.183716,118.542633 
C552.183716,118.542625 552.318604,118.237968 552.532593,117.889618 
C552.874329,116.987183 553.002136,116.433083 553.574097,115.885651 
C555.644653,115.930580 557.278320,115.883698 558.895935,116.023430 
C563.331421,116.406570 565.784729,114.861038 565.337646,110.025894 
C566.336670,110.097382 567.002625,110.139343 567.668518,110.181305 
z"></path>
<path fill="#A0A0A0" opacity="1.000000" stroke="none" d="
M124.100319,184.738144 
C123.292320,184.604218 122.484329,184.470291 121.025635,184.055832 
C119.751137,181.808701 119.127327,179.842102 118.503517,177.875504 
C118.585548,167.556503 118.667580,157.237518 118.728912,146.448486 
C119.807945,145.905350 120.907661,145.832245 122.411118,146.178421 
C122.272598,149.382446 121.381302,152.154358 121.278145,154.955276 
C121.154579,158.310349 122.243584,161.754868 121.849167,165.045807 
C121.032852,171.856918 120.278618,178.516373 124.100319,184.738144 
z"></path>
<path fill="#4D4B4C" opacity="1.000000" stroke="none" d="
M393.004822,118.934280 
C389.965454,118.852295 386.926117,118.770309 383.320435,118.489456 
C382.177490,118.440193 381.600830,118.589806 381.024170,118.739426 
C379.318298,118.697060 377.612457,118.654701 375.483459,118.138992 
C375.070129,116.741074 375.079956,115.816498 375.486237,114.948227 
C380.897858,115.306694 385.919556,115.874153 390.924225,115.750710 
C392.442474,115.713264 393.913574,113.766411 395.677063,112.633438 
C396.299225,112.685944 396.650574,112.799103 397.113434,113.269836 
C399.813446,114.423813 402.402008,115.220215 404.992523,116.320885 
C405.000244,117.030815 405.005981,117.436470 404.599457,117.901077 
C402.129822,118.251625 400.072449,118.543228 398.015076,118.834824 
C397.256134,118.823677 396.497192,118.812531 395.259827,118.645111 
C394.189240,118.637321 393.597015,118.785797 393.004822,118.934280 
z"></path>
<path fill="#DDDCDC" opacity="1.000000" stroke="none" d="
M477.040894,100.979843 
C476.029572,99.916679 474.455963,98.997986 474.137115,97.756927 
C473.643616,95.836113 474.006897,93.695152 474.006897,90.624062 
C471.935547,92.130615 470.752136,92.991333 469.206451,93.486343 
C468.849152,92.353104 468.854126,91.585564 468.892639,90.463554 
C468.900269,89.399902 468.874359,88.690720 468.900726,87.629059 
C468.844238,86.814514 468.735504,86.352455 469.030334,85.907837 
C470.106598,84.638611 470.779236,83.351952 471.788635,82.087952 
C473.765259,83.066231 475.405151,84.021843 477.092010,85.439590 
C477.232056,89.872757 477.325104,93.843788 477.296082,98.196945 
C477.129669,99.379333 477.085266,100.179596 477.040894,100.979843 
z"></path>
<path fill="#FAFBF9" opacity="1.000000" stroke="none" d="
M51.387146,187.992035 
C53.039215,186.987518 55.180740,186.139236 57.088097,184.923538 
C59.150192,183.609207 61.981171,182.292114 62.651455,180.347168 
C63.306679,178.445908 65.942780,174.992615 61.337074,172.771667 
C61.025372,171.506531 61.041553,170.761490 61.369312,169.985992 
C62.453609,169.637238 63.226334,169.318909 64.249214,169.000366 
C64.666145,169.000076 64.832924,169.000046 64.999695,169.000000 
C65.311592,170.103165 65.623482,171.206314 65.967720,173.092239 
C66.000244,175.916672 66.000427,177.958328 66.000610,180.000000 
C66.000313,180.749954 66.000008,181.499924 65.999786,182.624847 
C64.372337,187.837784 61.916370,190.526459 56.422039,187.434708 
C55.323780,186.816696 53.345810,187.761993 51.387146,187.992035 
z"></path>
<path fill="#FCFAF0" opacity="1.000000" stroke="none" d="
M23.558334,178.994751 
C24.332516,181.422485 24.669676,183.852036 25.003960,186.640793 
C24.226162,187.332291 23.451244,187.664566 22.338314,187.999344 
C18.062901,188.256424 14.385933,188.032532 13.352816,184.329056 
C14.822846,182.427139 15.869518,181.072968 16.958054,179.359711 
C19.040382,178.999268 21.080847,178.997925 23.558334,178.994751 
z"></path>
<path fill="#656364" opacity="1.000000" stroke="none" d="
M550.025635,181.239258 
C558.435242,181.505585 566.844788,181.771896 575.686890,182.058136 
C574.203796,183.046524 572.315552,184.781143 570.367737,184.850891 
C561.893921,185.154266 553.402832,184.972870 544.457275,184.577560 
C543.998779,183.782303 544.001038,183.377106 544.388306,182.979980 
C546.524048,182.405136 548.274841,181.822205 550.025635,181.239258 
z"></path>
<path fill="#535253" opacity="1.000000" stroke="none" d="
M360.006714,180.031097 
C359.997101,181.341080 359.987488,182.651062 359.974854,184.353241 
C355.961761,184.792618 351.951752,184.839798 347.484955,184.473694 
C347.013458,183.382217 346.998749,182.704071 347.329620,181.951431 
C348.102448,180.646973 348.529663,179.417007 349.346375,178.101227 
C353.130615,178.285278 356.525360,178.555099 359.944519,179.126282 
C359.981567,179.628769 359.994141,179.829941 360.006714,180.031097 
z"></path>
<path fill="#A7A6A6" opacity="1.000000" stroke="none" d="
M468.859100,90.818031 
C468.854126,91.585564 468.849152,92.353104 468.853088,93.502167 
C466.183197,99.724083 463.564270,105.615852 456.422546,107.991592 
C456.361359,106.919235 456.673248,105.802444 456.972015,104.017487 
C456.708832,102.591583 456.458801,101.833839 456.555298,101.078552 
C458.684143,99.891335 460.466370,98.701660 462.621582,97.435997 
C463.693909,96.453751 464.336182,95.495346 465.105743,94.653244 
C466.311676,93.333557 467.603607,92.092430 468.859100,90.818031 
z"></path>
<path fill="#FAFBF9" opacity="1.000000" stroke="none" d="
M73.992088,154.914261 
C76.190948,159.277496 79.334229,161.326721 84.612366,159.046494 
C85.733925,161.020798 86.469376,163.039764 87.280128,165.265457 
C84.474892,168.056915 82.944130,167.700989 80.884895,164.114166 
C80.190712,162.905014 78.094078,162.501007 76.319199,161.864517 
C74.863106,161.427551 73.726219,160.855087 72.229309,159.783112 
C70.579117,158.855103 69.288948,158.426636 67.999092,157.617249 
C68.000397,156.472885 68.001389,155.709457 68.430161,154.945282 
C70.569321,154.934464 72.280708,154.924362 73.992088,154.914261 
z"></path>
<path fill="#888787" opacity="1.000000" stroke="none" d="
M477.113037,101.341431 
C477.085266,100.179596 477.129669,99.379333 477.611694,98.225006 
C478.682678,97.928123 479.316010,97.985321 479.951508,98.449371 
C480.691559,103.338913 483.275452,106.646461 486.768738,109.759483 
C487.879578,110.748871 488.905884,111.407707 489.941071,112.064697 
C489.949951,112.062851 489.938477,112.071404 489.938477,112.071396 
C491.131958,113.091217 492.325439,114.111038 493.713013,115.626343 
C493.582977,116.428223 493.258850,116.734634 492.934692,117.041046 
C485.154907,114.526176 480.377686,108.928246 477.113037,101.341431 
z"></path>
<path fill="#656364" opacity="1.000000" stroke="none" d="
M346.984009,182.025909 
C346.998749,182.704071 347.013458,183.382217 347.030579,184.399536 
C338.319946,184.794128 329.606873,184.849564 320.429993,184.818298 
C319.980652,184.067413 319.995117,183.403229 320.490356,182.387787 
C329.642059,182.032990 338.313019,182.029449 346.984009,182.025909 
z"></path>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" d="
M127.633499,105.993553 
C128.304962,110.548431 124.282333,108.195580 122.256927,109.719505 
C119.959114,111.213982 117.920418,112.428497 115.881721,113.643005 
C115.440323,113.381302 114.998924,113.119598 114.557526,112.857895 
C116.762062,108.984795 118.966599,105.111687 121.382111,100.867920 
C123.591011,102.788803 125.429764,104.387802 127.633499,105.993553 
z"></path>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" d="
M552.162476,118.969284 
C559.610413,118.225235 567.037170,117.907837 574.677612,117.291573 
C575.360046,116.994026 575.828674,116.995354 576.648682,116.998032 
C576.260864,118.293358 575.617737,120.637550 574.767517,120.715240 
C567.931885,121.339844 561.058594,121.607559 554.191956,121.753174 
C553.525391,121.767311 552.825684,120.218971 552.162476,118.969284 
z"></path>
<path fill="#605F60" opacity="1.000000" stroke="none" d="
M457.016022,182.068054 
C457.009186,182.741486 457.002319,183.414902 456.994629,184.425064 
C449.972778,184.814041 442.951782,184.866287 435.468445,184.839966 
C435.001801,184.101379 434.997498,183.441360 435.414185,182.412277 
C437.536896,182.008820 439.238617,181.974457 441.395935,181.966660 
C446.906342,182.018158 451.961182,182.043106 457.016022,182.068054 
z"></path>
<path fill="#605F60" opacity="1.000000" stroke="none" d="
M405.011719,117.842125 
C405.005981,117.436470 405.000244,117.030815 405.460388,116.294098 
C411.248596,115.912018 416.570953,115.861000 422.249207,115.839241 
C423.742981,115.589554 424.880768,115.310608 426.010498,115.392288 
C426.008331,116.474281 426.014313,117.195633 425.689575,117.960800 
C424.578766,118.288292 423.798615,118.571983 423.018494,118.855682 
C417.364136,118.803230 411.709747,118.750778 405.582825,118.469048 
C405.110291,118.239761 405.011688,117.842125 405.011719,117.842125 
z"></path>
<path fill="#474546" opacity="1.000000" stroke="none" d="
M202.849609,109.830475 
C204.157364,111.055283 205.465118,112.280098 206.902252,113.822433 
C206.074738,114.910301 205.117828,115.680634 203.587372,116.665184 
C201.984680,116.843857 200.955551,116.808319 199.661118,116.519974 
C197.605881,115.212906 193.766724,117.607094 194.370544,112.898758 
C197.418381,111.752808 200.133987,110.791641 202.849609,109.830475 
z"></path>
<path fill="#FAFBF9" opacity="1.000000" stroke="none" d="
M372.159790,37.970230 
C371.790497,38.438736 371.421204,38.907238 371.003235,39.690369 
C367.577179,40.341637 364.199738,40.678276 360.457062,41.012058 
C360.057617,39.910351 360.023407,38.811501 360.001892,37.073883 
C360.014557,36.435112 360.168060,35.998421 360.544373,35.998386 
C362.615387,35.663422 364.310150,35.328487 366.004883,34.993553 
C367.747620,35.553028 369.490326,36.112499 371.631836,37.072639 
C372.073639,37.638947 372.116730,37.804585 372.159790,37.970230 
z"></path>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" d="
M251.900391,118.520752 
C249.865158,119.667686 247.913391,121.570511 245.777573,121.806290 
C241.144089,122.317795 236.415451,122.030678 231.728424,121.936127 
C230.873871,121.918884 230.032150,121.265709 229.441589,120.475121 
C230.454803,119.620636 231.210922,119.197121 232.428284,118.709793 
C236.867966,118.590218 240.846420,118.534462 245.177917,118.616318 
C247.654114,118.676208 249.777252,118.598480 251.900391,118.520752 
z"></path>
<path fill="#535253" opacity="1.000000" stroke="none" d="
M426.020264,117.916992 
C426.014313,117.195633 426.008331,116.474281 426.249268,115.240547 
C426.684937,114.206650 426.873718,113.685135 427.474426,113.099823 
C429.270325,113.074196 430.654327,113.112373 432.038300,113.150551 
C431.818451,116.091034 433.756744,116.112747 435.812256,115.905472 
C437.688538,115.716286 439.558228,115.461601 441.674713,115.509071 
C441.860352,116.170311 441.802094,116.558250 441.165039,116.988777 
C440.057587,117.352219 439.528961,117.673073 439.000305,117.993942 
C435.943756,118.248970 432.887238,118.504005 429.216339,118.539459 
C427.741425,118.185585 426.880829,118.051292 426.020264,117.916992 
z"></path>
<path fill="#EBEAEA" opacity="1.000000" stroke="none" d="
M220.179413,106.067276 
C220.660095,104.032661 221.457047,102.130524 222.904114,100.063103 
C224.102051,99.304909 224.649857,98.712006 225.555618,98.099991 
C227.285736,97.719566 228.657913,97.358238 230.030075,96.996918 
C229.644623,99.297981 229.259171,101.599037 228.474075,103.962807 
C226.605591,104.475807 225.141663,104.943039 223.666245,105.370667 
C222.617401,105.674660 221.553085,105.925293 220.179413,106.067276 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M117.937248,182.005615 
C112.994202,181.915558 112.172623,178.191360 111.886322,174.546997 
C111.765266,173.006088 113.111420,171.349899 113.790688,169.746109 
C114.264259,169.907547 114.737839,170.068985 115.211418,170.230423 
C115.853867,172.755219 116.496315,175.279999 117.543289,178.297424 
C117.944290,179.861923 117.940765,180.933762 117.937248,182.005615 
z"></path>
<path fill="#817F80" opacity="1.000000" stroke="none" d="
M252.121292,118.463852 
C249.777252,118.598480 247.654114,118.676208 245.235489,118.274399 
C245.006790,116.873177 245.073578,115.951492 245.365051,114.840256 
C245.458359,113.706665 245.326981,112.762627 245.535934,111.879776 
C246.564468,111.980766 247.252670,112.020569 247.940872,112.060379 
C249.792480,113.998093 251.644089,115.935806 253.366104,118.145721 
C253.236511,118.417908 252.789810,118.376450 252.789810,118.376450 
C252.789810,118.376450 252.342194,118.406952 252.121292,118.463852 
z"></path>
<path fill="#BBBBBB" opacity="1.000000" stroke="none" d="
M345.165131,112.840775 
C339.907104,112.017746 337.576324,107.806534 335.115631,103.545074 
C335.514526,103.084732 335.709229,103.061325 336.081848,103.279602 
C336.831604,103.684494 337.403442,103.847702 338.023560,104.372025 
C339.038666,105.199577 340.005493,105.666031 341.130005,106.284256 
C341.503296,106.632515 341.723328,106.823944 341.969177,107.370804 
C343.048737,109.434456 344.106934,111.137619 345.165131,112.840775 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M110.428459,177.655365 
C109.371338,178.842804 108.366058,179.677994 107.360779,180.513184 
C106.616470,178.771820 105.644218,177.077728 105.267326,175.260178 
C105.154846,174.717758 106.709488,173.829651 107.500305,173.099930 
C108.493637,174.500992 109.486961,175.902039 110.428459,177.655365 
z"></path>
<path fill="#656364" opacity="1.000000" stroke="none" d="
M283.985443,117.550545 
C283.241913,117.940025 282.529510,117.982201 281.129242,118.023438 
C279.643188,118.271820 278.845001,118.521141 278.046783,118.770462 
C277.245087,118.739586 276.443359,118.708702 275.286194,118.243942 
C274.977600,117.232574 275.024445,116.655083 275.309967,115.833221 
C276.440155,114.658112 277.331696,113.727379 278.223236,112.796646 
C279.888855,113.546394 281.554504,114.296150 283.574951,115.082001 
C283.958710,115.813148 283.987610,116.508194 283.985443,117.550545 
z"></path>
<path fill="#FAFBF9" opacity="1.000000" stroke="none" d="
M109.001068,128.001221 
C107.527023,131.084778 106.153198,134.223679 104.490097,137.201004 
C104.163063,137.786469 102.719093,137.748001 101.394974,137.998566 
C100.999695,137.999832 100.995728,137.995865 100.997971,137.616669 
C102.257729,132.938721 104.230789,129.259277 108.997284,127.997498 
C108.997284,127.997498 109.000000,128.000000 109.001068,128.001221 
z"></path>
<path fill="#9B999A" opacity="1.000000" stroke="none" d="
M231.967041,118.773605 
C231.210922,119.197121 230.454803,119.620636 229.335098,120.110123 
C227.442032,119.640411 225.910324,119.111015 224.383514,118.567825 
C220.468002,117.174820 221.752365,115.717484 224.809662,114.358582 
C225.835419,114.633453 226.386185,114.797791 227.067047,115.168465 
C227.794754,115.249870 228.392380,115.124939 228.988251,115.358704 
C229.979996,116.736130 230.973511,117.754868 231.967041,118.773605 
z"></path>
<path fill="#9B999A" opacity="1.000000" stroke="none" d="
M335.903931,103.037926 
C335.709229,103.061325 335.514526,103.084732 335.036560,103.192451 
C332.317871,101.368034 329.882416,99.459305 327.211304,97.298759 
C327.011871,96.532799 327.048096,96.018646 327.477661,95.168465 
C329.431946,95.663025 330.992859,96.493614 332.553741,97.324203 
C332.553741,97.324203 332.636505,97.369507 332.716431,97.743309 
C333.781433,98.814919 334.766510,99.512726 335.751556,100.210526 
C335.751556,100.210526 335.885406,100.110291 335.881073,100.528366 
C335.885803,101.643600 335.894867,102.340759 335.903931,103.037926 
z"></path>
<path fill="#FAFBF9" opacity="1.000000" stroke="none" d="
M92.348785,147.965179 
C92.641434,146.534439 93.279068,145.069168 93.963318,142.971527 
C94.313210,141.881104 94.616486,141.423050 95.269600,140.944305 
C97.411537,139.947693 99.203636,138.971786 100.995728,137.995865 
C100.995728,137.995865 100.999695,137.999832 101.000305,138.000519 
C98.231865,141.311035 95.462814,144.620850 92.348785,147.965179 
z"></path>
<path fill="#656364" opacity="1.000000" stroke="none" d="
M375.089783,114.891922 
C375.079956,115.816498 375.070129,116.741074 375.078369,118.011536 
C374.607880,118.496010 374.119293,118.634598 373.104797,118.775543 
C372.578888,118.777901 372.266327,118.828651 372.266327,118.828651 
C372.266327,118.828651 372.175354,118.775085 372.159668,118.368385 
C371.808655,116.646606 371.473328,115.331528 371.124939,113.779510 
C371.258148,113.265800 371.387360,112.981308 371.499481,112.689072 
C371.499481,112.689072 371.814087,112.740204 371.973328,112.710098 
C373.048676,111.999123 373.964752,111.318245 375.308411,110.316658 
C376.420074,109.981728 377.104156,109.967514 377.857971,110.290237 
C377.951508,111.084724 377.975372,111.542290 377.787476,112.245392 
C376.747101,113.291267 375.918457,114.091599 375.089783,114.891922 
z"></path>
<path fill="#FAFBF9" opacity="1.000000" stroke="none" d="
M39.060627,189.358871 
C42.872261,190.235855 46.686317,190.767548 50.619095,188.045898 
C48.943890,193.568954 44.552017,194.203384 39.060627,189.358871 
z"></path>
<path fill="#FAFBF9" opacity="1.000000" stroke="none" d="
M360.436890,104.001709 
C360.013458,102.934258 359.999725,101.865685 360.008972,100.396828 
C361.527100,99.489716 363.022278,98.982880 364.515289,98.469383 
C366.889496,102.702629 366.294586,103.601151 360.436890,104.001709 
z"></path>
<path fill="#FAFBF9" opacity="1.000000" stroke="none" d="
M35.995674,184.150818 
C36.241173,185.227142 36.486671,186.303467 36.865524,187.689240 
C33.885040,187.884598 30.627089,188.008698 30.219009,183.284698 
C30.703024,182.608734 30.894224,182.354858 31.374218,182.026688 
C32.490162,182.007706 33.234169,182.000412 33.986885,181.997772 
C33.995594,182.002457 33.988747,181.989197 34.015007,182.327148 
C34.692738,183.160339 35.344204,183.655579 35.995674,184.150818 
z"></path>
<path fill="#535253" opacity="1.000000" stroke="none" d="
M345.511292,112.900543 
C344.106934,111.137619 343.048737,109.434456 342.328583,107.364532 
C343.380798,107.015915 344.095001,107.034050 344.809174,107.052185 
C346.196869,109.091103 347.584564,111.130020 349.008453,113.527924 
C347.982239,113.578049 346.919830,113.269180 345.511292,112.900543 
z"></path>
<path fill="#EBEAEA" opacity="1.000000" stroke="none" d="
M371.128296,112.487686 
C371.387360,112.981308 371.258148,113.265800 370.792114,113.923645 
C370.068665,115.811127 369.664917,117.317520 369.261169,118.823914 
C367.952240,118.076080 365.515259,117.304581 365.530365,116.584526 
C365.594543,113.524544 367.808167,112.276276 371.128296,112.487686 
z"></path>
<path fill="#FAFBF9" opacity="1.000000" stroke="none" d="
M87.394363,157.998566 
C88.094528,155.709610 89.188835,153.420456 90.639435,151.064316 
C90.749474,153.707947 94.342239,158.177246 87.394363,157.998566 
z"></path>
<path fill="#DDDCDC" opacity="1.000000" stroke="none" d="
M546.984070,112.437172 
C547.494507,111.414703 547.978088,110.762390 548.461670,110.110069 
C548.993652,111.444778 549.525574,112.779488 550.000732,114.809654 
C549.872803,115.702438 549.801758,115.899765 549.730713,116.097092 
C548.806213,115.000504 547.881775,113.903915 546.984070,112.437172 
z"></path>
<path fill="#E6E6E6" opacity="1.000000" stroke="none" d="
M117.962311,182.353760 
C117.940765,180.933762 117.944290,179.861923 117.917526,178.388672 
C117.887245,177.987274 118.197021,177.929962 118.350266,177.902740 
C119.127327,179.842102 119.751137,181.808701 120.710342,183.976501 
C120.873756,185.302383 120.701767,186.427048 120.265106,187.770233 
C119.329407,186.226456 118.658386,184.464188 117.962311,182.353760 
z"></path>
<path fill="#959394" opacity="1.000000" stroke="none" d="
M493.164948,117.339592 
C493.258850,116.734634 493.582977,116.428223 494.018372,115.797165 
C495.800720,116.207718 497.471832,116.942917 499.214905,118.024384 
C497.322968,118.126480 495.359100,117.882309 493.164948,117.339592 
z"></path>
<path fill="#9B999A" opacity="1.000000" stroke="none" d="
M369.628174,118.827522 
C369.664917,117.317520 370.068665,115.811127 370.805176,114.160599 
C371.473328,115.331528 371.808655,116.646606 372.173767,118.317528 
C371.467438,118.725960 370.731323,118.778542 369.628174,118.827522 
z"></path>
<path fill="#9B999A" opacity="1.000000" stroke="none" d="
M404.599426,117.901077 
C405.011688,117.842125 405.110291,118.239761 405.124878,118.444000 
C402.853149,118.965782 400.566803,119.283333 398.147766,119.217850 
C400.072449,118.543228 402.129822,118.251625 404.599426,117.901077 
z"></path>
<path fill="#A7A6A6" opacity="1.000000" stroke="none" d="
M199.926407,116.772781 
C200.955551,116.808319 201.984680,116.843857 203.396790,116.930359 
C204.304596,117.252716 204.829407,117.524109 205.354233,117.795486 
C204.771286,118.125931 204.188339,118.456375 203.301727,118.880203 
C202.174362,118.910736 201.350647,118.847885 200.154037,118.353996 
C199.829559,117.539558 199.877975,117.156166 199.926407,116.772781 
z"></path>
<path fill="#605F60" opacity="1.000000" stroke="none" d="
M544.003296,182.971893 
C544.001038,183.377106 543.998779,183.782303 543.995361,184.491364 
C542.797058,184.826584 541.599854,184.857956 540.135986,184.369476 
C539.910461,183.252930 539.951538,182.656250 540.235901,182.015717 
C540.479126,181.971848 540.969910,181.915054 541.309692,181.948212 
C542.434021,182.311554 543.218689,182.641724 544.003296,182.971893 
z"></path>
<path fill="#FCFAF0" opacity="1.000000" stroke="none" d="
M31.002277,182.038361 
C30.894224,182.354858 30.703024,182.608734 30.211231,182.905914 
C29.549904,183.009262 29.106026,183.006668 28.329086,183.002960 
C28.743488,181.674210 29.490950,180.346603 30.617834,179.388824 
C30.998932,180.518539 31.000605,181.278458 31.002277,182.038361 
z"></path>
<path fill="#9B999A" opacity="1.000000" stroke="none" d="
M425.689575,117.960800 
C426.880829,118.051292 427.741425,118.185585 428.792725,118.584145 
C427.091034,119.098343 425.198608,119.348282 423.162354,119.226944 
C423.798615,118.571983 424.578766,118.288292 425.689575,117.960800 
z"></path>
<path fill="#656364" opacity="1.000000" stroke="none" d="
M497.966919,182.075485 
C497.980103,182.737686 497.993286,183.399902 498.009064,184.392181 
C497.255280,184.789062 496.498901,184.855865 495.332581,184.566544 
C494.574158,183.791229 494.225677,183.372055 494.135284,182.688034 
C495.607574,182.257645 496.821777,182.092102 498.018738,181.963806 
C498.001465,182.001022 497.966919,182.075485 497.966919,182.075485 
z"></path>
<path fill="#A0A0A0" opacity="1.000000" stroke="none" d="
M549.840454,116.456512 
C549.801758,115.899765 549.872803,115.702438 550.043335,115.206299 
C550.806824,114.955856 551.470825,115.004204 552.559082,115.264801 
C552.983398,115.477043 553.129944,115.878983 553.129944,115.878983 
C553.002136,116.433083 552.874329,116.987183 552.533569,117.723030 
C551.530518,117.541840 550.740417,117.178894 549.840454,116.456512 
z"></path>
<path fill="#FAFBF9" opacity="1.000000" stroke="none" d="
M124.612129,140.004181 
C124.161308,142.760986 122.806458,142.673431 121.058563,140.348511 
C122.069931,140.001282 123.140205,140.001343 124.612129,140.004181 
z"></path>
<path fill="#717071" opacity="1.000000" stroke="none" d="
M228.990021,115.000000 
C228.392380,115.124939 227.794754,115.249870 227.106506,114.862465 
C227.040680,113.939140 227.065445,113.528152 227.090210,113.117165 
C227.090210,113.117165 227.461319,113.000633 227.652115,112.963058 
C228.335190,112.950081 228.827469,112.974686 229.670929,113.341888 
C229.999054,114.119606 229.975998,114.554726 229.712234,114.994057 
C229.471527,114.998276 228.990021,115.000000 228.990021,115.000000 
z"></path>
<path fill="#767676" opacity="1.000000" stroke="none" d="
M444.389893,115.042656 
C444.838348,114.354568 445.651276,113.672005 446.838165,113.084793 
C446.392853,113.802818 445.573578,114.425499 444.389893,115.042656 
z"></path>
<path fill="#605F60" opacity="1.000000" stroke="none" d="
M493.877197,182.952881 
C494.225677,183.372055 494.574158,183.791229 494.957672,184.494949 
C494.244507,184.830673 493.496368,184.881866 492.376984,184.866882 
C492.004822,184.405655 492.003876,184.010605 492.313171,183.295044 
C493.041321,182.967331 493.459259,182.960098 493.877197,182.952881 
z"></path>
<path fill="#656364" opacity="1.000000" stroke="none" d="
M539.992676,182.059586 
C539.951538,182.656250 539.910461,183.252930 539.786072,184.291595 
C539.099854,184.777054 538.496948,184.820496 537.596802,184.801849 
C537.436462,184.065933 537.573425,183.392105 538.190002,182.368790 
C539.110657,182.032715 539.551636,182.046143 539.992676,182.059586 
z"></path>
<path fill="#9B999A" opacity="1.000000" stroke="none" d="
M439.112823,118.254272 
C439.528961,117.673073 440.057587,117.352219 440.912964,117.159790 
C440.568237,117.697014 439.896790,118.105812 439.112823,118.254272 
z"></path>
<path fill="#9B999A" opacity="1.000000" stroke="none" d="
M381.080170,119.075104 
C381.600830,118.589806 382.177490,118.440193 382.865662,118.525391 
C382.363495,118.977058 381.749847,119.193924 381.080170,119.075104 
z"></path>
<path fill="#9B999A" opacity="1.000000" stroke="none" d="
M393.066193,119.252739 
C393.597015,118.785797 394.189240,118.637321 394.890198,118.736877 
C394.375153,119.180336 393.751373,119.375763 393.066193,119.252739 
z"></path>
<path fill="#B4B2B3" opacity="1.000000" stroke="none" d="
M226.778305,113.077988 
C227.065445,113.528152 227.040680,113.939140 226.976440,114.656128 
C226.386185,114.797791 225.835419,114.633453 225.136749,114.210785 
C225.481369,113.647903 225.973877,113.343361 226.778305,113.077988 
z"></path>
<path fill="#9B999A" opacity="1.000000" stroke="none" d="
M372.210754,119.067276 
C372.266327,118.828651 372.578888,118.777901 372.724854,118.838348 
C372.632233,119.034485 372.393707,119.170189 372.210754,119.067276 
z"></path>
<path fill="#1B1A1A" opacity="1.000000" stroke="none" d="
M134.037903,34.256817 
C138.373688,34.209496 142.709488,34.162174 147.511200,34.554367 
C148.319275,36.664188 148.661423,38.334499 148.976929,40.472591 
C149.800888,51.289505 146.814377,61.901955 151.015945,72.326691 
C150.679489,73.416313 150.409943,74.195786 149.956223,75.359039 
C149.529282,85.628761 149.211838,95.514465 149.180344,105.401093 
C149.177719,106.225159 151.104156,107.055367 152.122925,108.216949 
C153.367554,109.397331 154.623627,110.243492 156.068237,111.391144 
C157.803162,112.197273 159.349533,112.701912 160.888885,113.479378 
C160.887878,114.116013 160.893890,114.479813 160.549805,114.882660 
C159.132599,115.256187 158.065475,115.590691 156.547363,115.919067 
C153.410156,115.788895 150.723923,115.664848 148.012329,115.191513 
C147.824936,114.223892 147.820435,113.489807 147.468582,113.011002 
C146.702469,111.968513 145.759354,111.056099 144.888596,110.090515 
C144.061600,111.123360 143.123672,112.089813 142.450470,113.214706 
C142.065353,113.858215 142.110931,114.759483 141.572144,115.633301 
C139.845566,115.768074 138.509476,115.814240 136.961304,115.601601 
C136.815643,111.901688 136.937225,108.460617 136.939392,105.019463 
C136.952164,84.744232 136.955261,64.468925 136.892059,44.193851 
C136.887695,42.791897 136.319565,41.391693 136.002457,39.632500 
C135.340164,37.601826 134.689041,35.929321 134.037903,34.256817 
z"></path>
<path fill="#191718" opacity="1.000000" stroke="none" d="
M486.684174,109.428925 
C483.275452,106.646461 480.691559,103.338913 480.004211,97.980942 
C480.016357,92.053291 479.977936,87.000931 479.954712,81.623199 
C479.963715,80.863068 479.957550,80.428307 480.216370,79.750854 
C480.288757,77.312332 480.096191,75.116501 479.928223,72.610260 
C479.947144,71.884789 479.941437,71.469742 480.016174,70.588188 
C480.185181,62.063583 480.273712,54.005482 480.727020,45.962723 
C482.530518,46.163418 483.969208,46.348766 485.398621,46.532921 
C487.229248,37.021912 487.229248,37.021912 479.606689,36.991203 
C478.868958,36.058304 478.468384,35.147682 478.434387,34.182194 
C479.534393,34.053139 480.267761,33.978947 481.001160,33.904762 
C483.368896,33.969501 485.736664,34.034245 488.793060,34.090961 
C489.647827,34.060326 489.813965,34.037712 489.980072,34.015099 
C489.980072,34.015099 490.000000,34.000153 489.953552,34.402298 
C489.731842,36.591259 489.556641,38.378078 489.267578,40.608265 
C489.148499,57.276379 489.097076,73.501419 489.202545,89.725441 
C489.214355,91.540886 490.116089,93.350540 490.697388,95.447113 
C491.167816,96.825508 491.546021,97.919685 491.657684,99.190948 
C494.591827,102.941734 497.582062,106.744682 501.099396,109.973465 
C502.458557,111.221138 505.013062,111.166664 507.339478,111.872498 
C508.754272,112.394341 509.852539,112.749115 510.950806,113.103882 
C509.869446,113.877472 508.663849,115.442162 507.727051,115.295074 
C502.041626,114.402458 496.417511,113.119537 490.355713,112.019196 
C489.938477,112.071404 489.949951,112.062851 489.927277,111.704590 
C488.831116,110.707199 487.757660,110.068062 486.684174,109.428925 
z"></path>
<path fill="#0F0D0E" opacity="1.000000" stroke="none" d="
M462.248596,97.511986 
C460.466370,98.701660 458.684143,99.891335 456.147034,101.050262 
C454.848114,100.980568 454.304108,100.941620 453.793335,100.515335 
C455.642456,96.428749 457.458344,92.729492 459.634094,89.013107 
C460.637085,87.946503 461.280182,86.897018 461.946198,85.432022 
C462.150513,83.369926 462.331879,81.723343 462.829712,80.040421 
C463.701416,79.400452 464.256592,78.796822 464.811798,78.193192 
C464.050323,77.769470 463.288879,77.345741 462.435120,76.486145 
C462.233551,74.084763 462.668671,71.859383 461.884247,70.216133 
C460.774353,67.890976 458.764252,65.995522 457.100281,63.543152 
C456.236084,61.522503 455.410492,59.875103 454.384369,57.976860 
C453.450836,56.846661 452.717834,55.967297 451.680237,54.927391 
C449.233215,52.220131 447.090759,49.673405 444.963074,46.723915 
C443.865021,44.693394 442.752228,43.065632 441.210419,40.810360 
C441.880585,39.924870 443.015869,38.424805 444.503052,36.975182 
C445.565582,37.022259 446.276245,37.018902 446.986877,37.015545 
C446.986877,37.015545 446.948090,37.021954 446.957520,37.429207 
C448.008911,38.517593 449.050903,39.198723 450.319031,39.935940 
C450.545135,39.992027 450.985321,39.839314 450.985321,39.839317 
C451.422943,39.899803 451.860535,39.960289 452.752930,40.393730 
C457.195435,44.463070 461.183228,48.159454 465.135956,52.234909 
C466.055084,53.421036 467.009247,54.228088 468.106537,55.348289 
C468.531677,56.755711 468.813690,57.849983 469.025269,59.322807 
C468.838043,60.461182 468.721222,61.221001 468.391296,62.334496 
C468.327698,70.422241 468.477234,78.156319 468.626770,85.890396 
C468.735504,86.352455 468.844238,86.814514 468.532715,87.642242 
C464.147827,89.935822 462.185089,93.098816 462.248596,97.511986 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M540.940430,106.799019 
C539.846985,107.113319 538.753479,107.427628 537.014648,107.819580 
C532.915771,108.311279 529.462280,108.725319 526.008728,109.139343 
C526.126953,108.659569 526.086792,107.921913 526.386536,107.737740 
C533.984619,103.070419 534.571472,95.403564 534.977478,87.691162 
C535.118958,85.003426 535.000000,82.301979 535.000000,79.606804 
C535.756836,79.604599 536.513672,79.602394 537.270508,79.600189 
C537.559753,85.812828 537.848938,92.025467 538.135010,98.169189 
C544.747009,97.042778 544.663635,97.010208 544.028992,90.259895 
C542.933105,78.604469 541.987671,66.934769 541.016541,55.267941 
C540.894836,53.805599 541.000000,52.324368 541.000000,50.502674 
C543.021484,50.695595 544.601990,50.846436 546.597656,51.466484 
C547.039551,68.330246 547.066345,84.724792 547.093140,101.119347 
C547.093201,101.119347 547.006531,101.029808 546.689331,101.053497 
C544.218262,102.658531 540.321960,102.585823 540.940430,106.799019 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M465.170990,51.855843 
C461.183228,48.159454 457.195435,44.463070 453.082031,40.445278 
C454.977234,38.832169 456.998108,37.540466 459.018982,36.248764 
C458.502289,35.620243 457.985596,34.991718 457.468903,34.363197 
C455.514862,34.511650 453.560791,34.660103 451.606750,34.808556 
C451.438721,34.422928 451.270721,34.037300 451.102692,33.651669 
C452.882294,32.489227 454.661926,31.326782 456.539520,30.100346 
C456.340393,24.829020 456.731934,24.399809 460.004547,26.492350 
C461.501068,27.449217 463.050537,28.323284 464.897705,29.427166 
C463.935577,30.668295 463.102417,31.743080 461.820557,33.396660 
C463.912170,34.010769 465.574158,34.498741 467.613525,35.417625 
C467.751343,40.097275 468.152130,43.812061 473.981293,43.439102 
C473.970276,45.566341 473.943390,47.268246 473.541443,48.988655 
C471.369812,50.043869 469.573212,51.080578 467.448486,52.116169 
C466.470551,52.028648 465.820770,51.942245 465.170990,51.855843 
z"></path>
<path fill="#161415" opacity="1.000000" stroke="none" d="
M220.947128,95.026176 
C218.236450,95.988724 215.525787,96.951271 212.419403,97.551193 
C212.371429,93.581680 211.637253,90.819336 207.105377,90.584259 
C206.432114,88.818481 206.141022,87.127953 205.153152,86.121017 
C202.635956,83.555237 199.807571,81.294754 197.055710,78.596405 
C197.002014,77.854698 196.996307,77.428688 197.350952,76.998001 
C199.448822,76.002388 201.186340,75.011452 203.186951,74.283218 
C208.599823,76.043442 213.749619,77.540970 218.899414,79.038490 
C219.689240,79.950500 220.479065,80.862503 221.625671,82.281387 
C221.992737,83.524208 222.003036,84.260132 221.627365,85.028358 
C220.127350,85.381218 219.002869,85.669769 217.901199,86.028389 
C214.650131,87.086708 214.352371,88.198952 216.496338,91.754646 
C217.467804,90.455544 218.387024,89.226326 219.306229,87.997108 
C219.717941,88.041382 220.129639,88.085655 220.541351,88.129929 
C220.676605,90.428680 220.811859,92.727425 220.947128,95.026176 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M345.042053,73.082191 
C342.932861,63.149006 345.095917,52.529305 339.770325,43.026726 
C338.472992,40.711941 338.730530,37.092930 339.354614,34.316689 
C339.666840,32.927601 342.303955,31.403450 344.080475,31.114588 
C351.928253,29.838554 351.953369,29.993082 356.301819,25.303879 
C356.534851,28.187548 356.767883,31.071215 356.629456,34.460373 
C355.504639,34.914017 354.751251,34.862171 353.994354,34.478394 
C353.627869,33.313992 353.264923,32.481518 352.901978,31.649044 
C352.602020,31.741495 352.302032,31.833948 352.002075,31.926401 
C352.002075,32.885960 352.002075,33.845516 351.631042,34.882214 
C349.914764,35.676952 348.569550,36.394554 346.862976,37.138390 
C344.001953,39.351490 344.736603,40.572723 347.751740,41.588245 
C347.803558,52.333134 347.789917,62.622417 347.434265,72.937546 
C346.408844,73.002998 345.725464,73.042595 345.042053,73.082191 
z"></path>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" d="
M266.926666,39.102516 
C267.403992,39.389835 267.881287,39.677151 268.648804,40.515121 
C269.108765,49.813480 269.278534,58.561180 269.430878,67.691643 
C269.421173,69.612404 269.428864,71.150398 269.285675,73.155380 
C269.039246,84.758530 268.943756,95.894714 268.466919,106.957268 
C267.390564,105.033974 266.109100,103.189713 266.091980,101.333794 
C265.937042,84.539139 266.001251,67.742439 266.005554,50.946342 
C266.007874,41.879150 263.713226,39.636467 254.547821,40.041050 
C252.422302,40.134876 250.317398,40.695934 248.147522,40.681900 
C248.080688,39.595325 248.069351,38.868847 248.317856,38.018257 
C248.991180,37.285812 249.404663,36.677486 250.115082,36.043591 
C250.610092,36.002605 250.808182,35.987186 251.006271,35.971771 
C254.678223,32.095001 258.361725,35.488693 262.483704,36.329025 
C264.260101,37.503365 265.593384,38.302940 266.926666,39.102516 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M311.967041,55.942322 
C315.278046,59.535419 318.281036,63.492825 321.983429,66.622971 
C325.984100,70.005287 326.697174,65.170967 329.483459,63.180908 
C329.483459,66.577713 329.483459,69.974518 329.483459,74.019745 
C332.381287,73.146706 334.189301,72.601997 337.041962,71.742577 
C336.463989,74.368385 336.051208,76.243797 335.610352,78.246758 
C337.932129,78.516434 340.474426,78.811729 343.091553,79.115707 
C343.429474,77.752365 343.879364,75.937279 344.727905,74.386810 
C345.997711,75.132477 346.868896,75.613525 347.785950,76.517822 
C347.827179,78.931801 347.822540,80.922539 347.496094,82.943176 
C346.614075,83.345741 346.053864,83.718407 345.493652,84.091080 
C346.264954,84.424324 347.036255,84.757561 347.844055,85.480400 
C347.988678,86.905273 348.096802,87.940552 347.896973,89.148361 
C346.646301,89.970894 345.703613,90.620880 344.746490,91.260910 
C344.732086,91.250961 344.695587,91.261520 344.769104,90.886963 
C342.091980,86.857117 339.609192,82.857277 334.028198,82.370575 
C326.450897,75.557365 319.230865,68.756828 312.028046,61.642551 
C311.254486,59.708206 310.463715,58.087601 309.558990,56.233398 
C310.384491,56.133614 311.175751,56.037968 311.967041,55.942322 
z"></path>
<path fill="#C4C2C3" opacity="1.000000" stroke="none" d="
M491.924255,99.013855 
C491.546021,97.919685 491.167816,96.825508 490.678345,95.437805 
C490.567047,95.144279 490.550201,95.114998 490.891968,95.083984 
C491.486053,94.704758 491.738403,94.356544 492.061707,93.543274 
C492.167572,75.448082 492.202423,57.817951 492.471619,39.978691 
C493.181061,38.859856 493.656189,37.950138 494.371338,36.929947 
C494.794006,36.521461 494.976654,36.223446 495.492432,36.012001 
C495.852448,37.730442 495.879364,39.362316 495.906250,40.994186 
C495.868744,41.724667 495.831207,42.455151 495.574493,43.808022 
C495.529114,46.285553 495.702911,48.140694 495.876709,49.995838 
C495.863953,64.354660 495.851227,78.713478 495.859833,93.966644 
C496.293365,96.522575 496.643524,98.202927 497.138306,99.839554 
C497.503967,101.049057 498.058014,102.201591 498.187622,103.348541 
C495.872803,101.882988 493.898529,100.448418 491.924255,99.013855 
z"></path>
<path fill="#2A2A2A" opacity="1.000000" stroke="none" d="
M372.446686,38.043861 
C372.116730,37.804585 372.073639,37.638947 371.934937,37.235092 
C371.899933,36.583885 371.960602,36.170902 372.495117,35.395397 
C377.603851,34.978878 382.238678,34.924877 387.185303,35.022419 
C387.716919,35.817520 387.936798,36.461079 388.109253,37.418880 
C388.044525,38.156113 388.027252,38.579098 387.656311,39.243408 
C387.193146,40.139072 386.988739,40.793285 386.987823,41.447784 
C386.959503,61.611702 386.935089,81.775688 386.986420,101.939468 
C386.989014,102.960800 387.671661,103.980392 388.057556,105.233109 
C388.265015,105.713684 388.442047,105.969238 388.185303,106.416275 
C383.555298,107.742371 384.004944,104.785400 384.003479,102.290787 
C383.991455,81.528862 383.993866,60.766933 383.993286,39.639282 
C382.768890,38.371788 381.543152,37.470024 380.317383,36.568264 
C379.525024,37.744419 378.732666,38.920574 377.567200,40.031204 
C376.441284,39.998867 375.688446,40.032047 374.935638,40.065228 
C374.201599,39.415989 373.467590,38.766743 372.446686,38.043861 
z"></path>
<path fill="#2F2E2E" opacity="1.000000" stroke="none" d="
M347.224335,37.112152 
C348.569550,36.394554 349.914764,35.676952 351.961365,34.949669 
C353.107819,34.896763 353.552856,34.853546 353.997894,34.810326 
C354.751251,34.862171 355.504639,34.914017 356.630249,34.895172 
C357.743378,34.882034 358.484222,34.939587 359.622955,35.279716 
C360.020813,35.562294 360.168060,35.998421 360.168060,35.998421 
C360.168060,35.998421 360.014557,36.435112 359.972870,36.662762 
C358.999420,37.719337 358.067657,38.548260 356.984436,39.652924 
C353.026886,39.985950 353.958984,42.873497 353.953888,44.990227 
C353.903381,66.043365 353.919952,87.096657 353.851868,108.623627 
C352.940155,107.637199 351.712341,106.266968 351.342163,104.694794 
C350.848724,102.599228 351.022217,100.346642 350.854218,97.368439 
C350.822723,95.038116 350.846252,93.500854 350.916595,91.499588 
C350.944885,76.428146 351.054657,61.818462 350.797882,47.215225 
C350.747559,44.352398 349.278992,41.514507 348.322815,38.443928 
C347.862152,37.852547 347.543243,37.482349 347.224335,37.112152 
z"></path>
<path fill="#131213" opacity="1.000000" stroke="none" d="
M199.012344,36.178116 
C203.010529,40.202824 207.008713,44.227535 210.679794,48.658165 
C209.941299,50.026505 209.529892,50.988930 209.093842,52.342712 
C209.102264,53.518215 209.135361,54.302357 209.136353,55.440727 
C209.756317,56.532612 210.408386,57.270279 211.060440,58.007946 
C209.602646,59.920574 208.144836,61.833199 206.333755,63.373558 
C206.623657,58.194374 203.704742,57.647282 200.010498,57.755249 
C199.992752,57.319893 199.983963,57.145935 199.975494,56.541237 
C199.379532,53.276962 201.889938,49.384796 197.006317,47.672832 
C195.867401,46.862415 194.916611,46.327286 193.972137,45.386425 
C194.067032,43.517998 194.155579,42.055298 194.258224,40.359970 
C195.907318,40.359970 197.276810,40.359970 198.806534,40.359970 
C198.883011,38.805927 198.947678,37.492020 199.012344,36.178116 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M553.000000,30.999847 
C552.999939,31.729601 552.999878,32.459354 552.652710,33.658028 
C551.582825,34.176723 550.860107,34.226501 549.761841,34.168381 
C547.606812,34.614437 545.827271,35.168396 544.047791,35.722355 
C544.075989,36.114285 544.104248,36.506218 544.132446,36.898148 
C545.069275,37.336037 546.006042,37.773922 546.954529,38.590294 
C546.984131,39.978306 547.002136,40.987831 547.020142,41.997356 
C543.738464,40.222584 540.812622,40.583046 537.724854,42.815353 
C536.408508,43.767002 534.243530,43.544800 532.464172,43.856033 
C532.256165,43.268127 532.048096,42.680225 531.840088,42.092319 
C534.240295,40.942142 536.640503,39.791965 539.612793,38.367661 
C532.411072,36.761486 532.384583,36.706512 532.678772,25.063227 
C536.742371,28.098885 540.794312,29.958385 544.850098,25.381079 
C544.885010,25.341639 546.880005,27.093832 548.004517,27.950911 
C549.379761,28.999035 550.802246,29.985235 552.602173,30.999113 
C553.000000,31.000000 553.000000,30.999847 553.000000,30.999847 
z"></path>
<path fill="#DDDCDC" opacity="1.000000" stroke="none" d="
M547.023438,42.447578 
C547.002136,40.987831 546.984131,39.978306 547.253662,38.597370 
C548.286926,38.884727 549.032593,39.543495 549.844116,40.653610 
C549.944214,60.738995 549.978455,80.373032 550.012695,100.007065 
C549.262878,100.397850 548.513062,100.788643 547.428223,101.149391 
C547.066345,84.724792 547.039551,68.330246 546.999023,51.466995 
C546.999084,48.298134 547.012878,45.597965 547.023438,42.447578 
z"></path>
<path fill="#DDDCDC" opacity="1.000000" stroke="none" d="
M222.013306,84.996063 
C222.003036,84.260132 221.992737,83.524208 221.932098,82.423439 
C223.348419,81.966515 224.815063,81.874428 226.940552,81.740967 
C226.940552,67.105309 226.940552,52.630680 227.260437,37.720337 
C228.321884,37.605106 229.063446,37.925583 229.804993,38.246063 
C229.925323,54.518879 230.045654,70.791695 229.763397,87.535263 
C228.605972,87.983688 227.851089,87.961372 227.050751,87.560120 
C225.621719,86.495361 224.238174,85.809525 222.648132,85.066422 
C222.441650,85.009171 222.013306,84.996063 222.013306,84.996063 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M287.077637,40.940853 
C285.729095,40.222866 284.380554,39.504883 282.912445,38.065666 
C281.160950,36.330742 279.528992,35.317055 277.897064,34.303368 
C277.166718,34.243290 276.436371,34.183208 275.352570,34.023563 
C275.209656,33.310863 275.239471,32.496967 275.660645,32.117847 
C278.024872,29.989656 280.483459,27.966257 282.413269,26.328487 
C284.587097,26.868999 287.059601,27.258005 289.342651,28.138254 
C290.659119,28.645823 291.669556,29.947140 292.817719,30.891178 
C289.996826,32.516693 296.098297,38.032490 289.659729,38.137058 
C289.145111,38.145412 288.640442,38.766907 287.889465,39.608200 
C287.457764,40.388905 287.267700,40.664879 287.077637,40.940853 
z"></path>
<path fill="#222021" opacity="1.000000" stroke="none" d="
M198.633286,35.994873 
C198.947678,37.492020 198.883011,38.805927 198.806534,40.359970 
C197.276810,40.359970 195.907318,40.359970 194.258224,40.359970 
C194.155579,42.055298 194.067032,43.517998 193.891052,45.509121 
C193.803635,46.037544 193.999191,46.257259 193.671249,46.083382 
C191.872742,44.886826 190.254196,44.022190 188.960388,42.810745 
C185.581604,39.647007 182.030807,37.122917 176.783936,37.977135 
C175.981628,37.239994 175.443314,36.650188 175.020447,35.790489 
C175.159210,35.138050 175.182510,34.755505 175.205826,34.372955 
C182.888626,34.852516 190.571426,35.332073 198.633286,35.994873 
z"></path>
<path fill="#201E1F" opacity="1.000000" stroke="none" d="
M444.151184,36.924747 
C443.015869,38.424805 441.880585,39.924870 441.210419,40.810360 
C442.752228,43.065632 443.865021,44.693394 444.593262,46.655113 
C438.150116,44.278728 432.091553,41.568386 426.018127,38.474731 
C426.021942,36.820938 426.040619,35.550457 426.059296,34.279972 
C431.747467,34.400387 437.435608,34.520798 443.571716,35.066662 
C444.063477,35.969662 444.107330,36.447208 444.151184,36.924747 
z"></path>
<path fill="#B4B2B3" opacity="1.000000" stroke="none" d="
M525.674072,109.173553 
C529.462280,108.725319 532.915771,108.311279 536.696106,107.962524 
C536.985657,108.433708 536.948425,108.839607 536.868896,109.547287 
C528.933167,114.373856 520.259460,112.436958 511.410858,112.982956 
C509.852539,112.749115 508.754272,112.394341 507.202759,111.601700 
C506.096588,110.481018 505.443665,109.798203 505.189880,109.074379 
C506.389282,109.024040 507.189484,109.014709 508.406982,109.021927 
C510.215820,109.061638 511.607361,109.084808 512.998901,109.107986 
C514.390991,109.154312 515.783142,109.200630 518.019043,109.236649 
C520.574036,109.204109 522.285278,109.181877 523.996460,109.159653 
C524.444092,109.175690 524.891724,109.191719 525.674072,109.173553 
z"></path>
<path fill="#535253" opacity="1.000000" stroke="none" d="
M480.362244,45.947376 
C480.273712,54.005482 480.185181,62.063583 479.683533,70.584244 
C478.617157,70.949745 477.963837,70.852699 477.291504,70.376595 
C477.285706,68.985664 477.298950,67.973793 477.506165,66.821808 
C477.564697,66.140259 477.429291,65.598831 477.300354,64.590775 
C477.167267,60.085159 477.027710,56.046177 477.207031,51.740009 
C477.665375,49.047630 478.074127,46.596279 477.842072,44.207165 
C477.705475,42.800659 476.551727,41.492950 475.633728,40.076370 
C475.411987,40.011936 474.950195,40.016605 474.901062,39.667202 
C473.687592,37.930748 472.523285,36.543697 471.358948,35.156647 
C471.358948,35.156647 471.718414,35.169884 472.252167,35.097569 
C474.546539,34.762524 476.307159,34.499794 478.067810,34.237064 
C478.468384,35.147682 478.868958,36.058304 479.615356,37.361893 
C480.040558,38.490395 480.119904,39.225929 480.152130,40.350548 
C480.196503,41.513855 480.288025,42.288082 480.304199,43.419815 
C480.273346,44.500675 480.317780,45.224026 480.362244,45.947376 
z"></path>
<path fill="#717071" opacity="1.000000" stroke="none" d="
M151.082855,72.016533 
C146.814377,61.901955 149.800888,51.289505 149.310089,40.406254 
C150.734604,38.059608 151.799286,36.247086 152.863983,34.434563 
C153.327988,34.346703 153.792007,34.258842 154.604691,34.613647 
C154.959335,35.986752 154.965317,36.917191 154.601120,37.972862 
C151.299759,41.834244 150.781052,65.031395 153.536041,69.038216 
C153.723007,69.310135 153.882187,69.601166 153.995621,70.231186 
C152.985580,71.058212 152.034225,71.537369 151.082855,72.016533 
z"></path>
<path fill="#767676" opacity="1.000000" stroke="none" d="
M388.156677,37.104637 
C387.936798,36.461079 387.716919,35.817520 387.369446,34.710526 
C388.547607,33.858192 389.853394,33.469296 391.159149,33.080391 
C392.213959,33.400448 393.268738,33.720505 395.057739,34.045021 
C396.862335,33.991512 397.932739,33.933544 399.003113,33.875576 
C402.099609,32.582779 405.162842,33.155731 409.004822,34.042114 
C410.860870,33.986885 411.932648,33.929451 413.004425,33.872025 
C414.098267,33.939011 415.192078,34.005997 416.665955,34.515675 
C417.023590,35.647358 417.001160,36.336349 416.513519,37.070271 
C406.751068,37.111683 397.453857,37.108162 388.156677,37.104637 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M390.804504,32.903873 
C389.853394,33.469296 388.547607,33.858192 387.057678,34.558983 
C382.238678,34.924877 377.603851,34.978878 372.508728,35.080994 
C371.664856,34.092464 371.209534,33.075661 370.916107,32.014103 
C370.558136,30.719152 370.336182,29.386591 370.055450,28.070286 
C371.704895,28.395990 373.445251,28.504755 374.964264,29.141603 
C375.691650,29.446568 375.999664,30.751795 375.871094,30.531742 
C381.336365,31.354834 385.893097,32.041092 390.804504,32.903873 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M269.766968,31.034641 
C264.976624,31.004124 260.558350,31.142363 256.201782,30.688555 
C255.253662,30.589796 254.526337,28.371468 253.609039,26.997051 
C260.208710,26.997051 265.783997,26.997051 271.861298,26.997051 
C273.075928,28.526100 273.591248,30.279406 269.766968,31.034641 
z"></path>
<path fill="#535253" opacity="1.000000" stroke="none" d="
M218.992645,78.648720 
C213.749619,77.540970 208.599823,76.043442 203.216385,73.869850 
C203.018143,72.091522 203.053543,70.989265 203.318695,69.923187 
C203.548462,69.959366 204.011917,69.999245 204.011917,69.999245 
C205.857452,70.415878 207.704651,71.177361 209.548157,71.168541 
C212.333084,71.155212 218.551956,75.498528 218.992645,78.648720 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M297.945465,49.173107 
C298.218567,45.799179 298.491638,42.425255 298.764740,39.051327 
C298.244751,38.717693 297.724792,38.384060 297.204834,38.050426 
C298.602356,37.812252 300.072479,37.217030 301.368011,37.480419 
C302.057770,37.620647 303.121765,39.525391 302.861298,40.157509 
C300.959625,44.772316 303.171326,48.069778 305.547791,51.643742 
C303.689423,52.039524 302.257660,52.061558 300.562256,51.944893 
C299.514191,50.928497 298.729828,50.050800 297.945465,49.173107 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M524.187805,100.261337 
C526.744446,102.623177 525.179077,104.125748 523.199036,103.812668 
C518.455811,103.062683 513.816650,101.622253 509.177094,100.312965 
C508.813995,100.210503 508.774780,98.960228 508.585022,98.243423 
C509.914673,97.961708 511.321747,97.253311 512.559509,97.477875 
C516.344910,98.164627 520.065186,99.210022 524.187805,100.261337 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M251.004150,35.563248 
C250.808182,35.987186 250.610092,36.002605 249.807373,35.906506 
C247.787964,35.290344 246.373169,34.785698 244.524506,34.189606 
C241.726425,34.023415 239.362228,33.948677 236.998047,33.873940 
C239.118210,32.765919 241.238388,31.657896 243.358551,30.549873 
C243.685760,30.948889 244.012970,31.347902 244.340164,31.746918 
C244.800949,29.534176 245.261749,27.321434 245.722534,25.108690 
C246.187622,25.040638 246.652710,24.972586 247.117813,24.904533 
C248.412552,28.321264 249.707291,31.737995 251.004150,35.563248 
z"></path>
<path fill="#817F80" opacity="1.000000" stroke="none" d="
M154.971283,37.847626 
C154.965317,36.917191 154.959335,35.986752 154.953308,34.707809 
C161.396820,34.262589 167.840363,34.165874 174.744873,34.221062 
C175.182510,34.755505 175.159210,35.138050 174.572113,35.804131 
C169.329117,36.790493 164.649948,37.493320 159.580078,38.157272 
C158.151260,38.123901 157.113144,38.129417 155.797485,38.069321 
C155.337051,37.951679 155.154160,37.899651 154.971283,37.847626 
z"></path>
<path fill="#E6E6E6" opacity="1.000000" stroke="none" d="
M300.825928,52.083595 
C302.257660,52.061558 303.689423,52.039524 305.549774,52.020912 
C305.978394,52.024338 305.987427,52.006390 305.990936,52.382069 
C306.613190,53.426018 307.231934,54.094288 307.850647,54.762554 
C308.241425,53.848198 308.632202,52.933849 309.023010,52.019501 
C309.023010,52.019505 309.000000,52.000610 309.322266,52.036255 
C310.411865,52.385563 311.179230,52.699226 311.946564,53.012886 
C311.953918,53.746044 311.961243,54.479202 311.967834,55.577343 
C311.175751,56.037968 310.384491,56.133614 309.558990,56.233398 
C310.463715,58.087601 311.254486,59.708206 311.671814,61.606720 
C309.230042,60.526455 307.161621,59.168274 305.026306,57.494572 
C304.127991,56.676392 303.296539,56.173733 302.465118,55.671074 
C302.465118,55.671078 302.393921,55.600296 302.294189,55.244049 
C301.738281,53.953068 301.282104,53.018333 300.825928,52.083595 
z"></path>
<path fill="#201E1F" opacity="1.000000" stroke="none" d="
M550.137390,34.276276 
C550.860107,34.226501 551.582825,34.176723 552.653320,34.022903 
C555.704956,33.963036 558.408813,34.007206 561.555603,33.968670 
C562.903931,34.033909 563.809387,34.181854 564.821594,34.984875 
C563.962769,36.770420 562.997253,37.900887 561.939697,38.652287 
C558.860840,38.191452 555.874023,38.109692 552.605347,37.867561 
C551.594849,36.563549 550.866150,35.419910 550.137390,34.276276 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M312.322876,53.001076 
C311.179230,52.699226 310.411865,52.385563 309.332642,52.047806 
C308.231537,48.766262 306.779541,45.445030 310.036652,42.130589 
C311.719421,45.270779 313.319763,48.092392 314.667725,51.029819 
C314.801178,51.320602 313.392212,52.319191 312.322876,53.001076 
z"></path>
<path fill="#070506" opacity="1.000000" stroke="none" d="
M200.019470,58.016644 
C203.704742,57.647282 206.623657,58.194374 205.984100,63.404060 
C205.329208,64.643333 204.670685,65.479828 203.777237,66.653381 
C203.542297,66.990440 203.123962,67.060806 202.778412,67.033745 
C201.971344,66.982971 201.509842,66.959259 201.035065,66.554626 
C200.673218,64.442703 200.324661,62.711678 199.981537,60.609882 
C199.997818,59.498287 200.008652,58.757465 200.019470,58.016644 
z"></path>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" d="
M470.940338,35.104912 
C472.523285,36.543697 473.687592,37.930748 474.631744,39.861279 
C474.262848,41.274429 474.114166,42.144096 473.965454,43.013767 
C468.152130,43.812061 467.751343,40.097275 467.992065,35.422821 
C468.836029,35.015800 469.678894,35.034492 470.940338,35.104912 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M496.310730,40.989998 
C495.879364,39.362316 495.852448,37.730442 495.368835,35.763737 
C494.607300,35.229576 494.302521,35.030251 493.997742,34.830921 
C495.002563,32.436954 495.968658,29.791515 498.024048,34.209949 
C498.158844,34.499676 498.273132,35.030876 498.429504,35.043404 
C500.400970,35.201313 503.015656,35.300491 501.751129,38.062115 
C501.091278,39.503178 498.457428,40.040363 496.310730,40.989998 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M512.951904,108.752716 
C511.607361,109.084808 510.215820,109.061638 508.347382,108.647011 
C505.829498,107.601639 503.788544,106.947723 501.804138,106.183739 
C501.860748,106.073669 501.707703,106.268227 501.797028,105.884811 
C501.868958,104.723061 501.851532,103.944740 501.878693,103.121643 
C501.923248,103.076881 502.012146,102.987144 502.012146,102.987144 
C502.796112,103.196213 503.807678,103.829109 504.329010,103.549126 
C509.842621,100.588089 510.921234,105.261658 512.951904,108.752716 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M483.000000,28.000151 
C485.129822,27.384430 487.250092,26.732759 489.392303,26.163647 
C491.326996,25.649668 493.285736,25.225952 495.070587,24.801277 
C495.550720,25.861259 495.870941,26.331470 495.787201,26.420170 
C494.986420,27.268143 494.117310,28.051611 493.267822,28.853579 
C489.885101,25.249680 486.377380,34.999702 483.009674,28.378826 
C483.000000,28.000000 483.000000,28.000153 483.000000,28.000151 
z"></path>
<path fill="#FAFBF9" opacity="1.000000" stroke="none" d="
M198.595123,30.882839 
C198.212692,29.933046 198.220825,28.302134 198.287048,28.299772 
C201.747253,28.176334 205.212418,28.190874 208.677048,28.190874 
C208.726395,28.951710 208.775742,29.712547 208.825104,30.473383 
C205.554321,30.636105 202.283554,30.798828 198.595123,30.882839 
z"></path>
<path fill="#FAFBF9" opacity="1.000000" stroke="none" d="
M126.863663,35.043144 
C127.525665,36.485756 127.780701,37.854717 128.110199,39.574127 
C126.362740,40.029091 124.540833,40.133614 122.070900,40.275311 
C121.844948,38.549667 121.600700,36.684242 121.356445,34.818817 
C123.056526,34.869041 124.756615,34.919266 126.863663,35.043144 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M413.052246,33.513672 
C411.932648,33.929451 410.860870,33.986885 409.392548,33.968117 
C408.655914,32.184692 408.315826,30.477465 407.895569,28.367844 
C410.507446,28.367844 412.524628,28.367844 415.341248,28.367844 
C414.405762,30.366211 413.752930,31.760765 413.052246,33.513672 
z"></path>
<path fill="#474546" opacity="1.000000" stroke="none" d="
M416.978760,37.025337 
C417.001160,36.336349 417.023590,35.647358 417.065399,34.615120 
C417.510590,34.216904 417.936401,34.161942 418.809082,34.290550 
C419.844849,34.275265 420.433746,34.076408 421.022675,33.877548 
C422.427734,33.940289 423.832764,34.003033 425.648560,34.172874 
C426.040619,35.550457 426.021942,36.820938 425.645691,38.622005 
C424.792908,39.348000 424.297668,39.543407 423.732941,39.351658 
C421.435211,38.318108 419.206970,37.671722 416.978760,37.025337 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M553.000000,31.000076 
C553.000000,29.125677 553.000000,27.251204 553.000000,25.376728 
C553.649292,25.100731 554.298584,24.824734 554.947937,24.548735 
C556.231018,26.404320 557.514099,28.259905 558.797180,30.115490 
C557.111816,30.413599 555.426392,30.711712 553.370483,31.004835 
C553.000000,30.999847 553.000000,31.000000 553.000000,31.000076 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M310.629456,33.357376 
C310.766144,34.942276 310.710022,36.225670 310.653931,37.509064 
C309.563171,36.961323 308.147156,36.673214 307.455597,35.806839 
C306.333221,34.400707 305.685547,32.615643 304.837494,30.990551 
C305.190002,30.662249 305.542542,30.333946 305.895050,30.005644 
C307.408905,31.022388 308.922760,32.039131 310.629456,33.357376 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M399.002075,33.475975 
C397.932739,33.933544 396.862335,33.991512 395.394836,33.979790 
C394.410400,31.995092 393.802399,30.085817 393.267456,28.156298 
C393.213257,27.960789 393.599518,27.643177 393.884247,27.234634 
C397.661346,27.326801 399.416534,29.207165 399.002075,33.475975 
z"></path>
<path fill="#2F2E2E" opacity="1.000000" stroke="none" d="
M236.674194,33.956337 
C239.362228,33.948677 241.726425,34.023415 244.521164,34.526608 
C244.034637,35.995407 243.117569,37.035755 241.912323,37.789345 
C239.749435,37.335209 237.874725,37.167831 235.897461,36.745819 
C235.532150,36.011337 235.269363,35.531494 235.015305,34.839539 
C235.024017,34.627430 235.027573,34.202862 235.027573,34.202866 
C235.468506,34.148155 235.909424,34.093445 236.674194,33.956337 
z"></path>
<path fill="#656364" opacity="1.000000" stroke="none" d="
M300.562256,51.944893 
C301.282104,53.018333 301.738281,53.953068 302.276001,55.197266 
C297.574005,55.556026 294.769562,53.072048 294.864868,48.517021 
C295.696930,48.485703 296.494263,48.776852 297.618530,49.120552 
C298.729828,50.050800 299.514191,50.928497 300.562256,51.944893 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M498.528137,103.379517 
C498.058014,102.201591 497.503967,101.049057 497.138306,99.839554 
C496.643524,98.202927 496.293365,96.522575 495.884583,94.435410 
C497.257233,95.151054 498.892578,96.096146 499.919617,97.489578 
C500.911285,98.835106 501.275696,100.642921 501.963928,102.617393 
C502.012146,102.987144 501.923248,103.076881 501.477539,103.083237 
C500.232788,103.212921 499.433777,103.336250 498.604858,103.443832 
C498.575012,103.428078 498.528107,103.379517 498.528137,103.379517 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M360.544373,35.998386 
C360.168060,35.998421 360.020813,35.562294 359.991821,35.333958 
C361.336426,33.520214 362.710022,31.934809 364.083618,30.349407 
C364.722656,31.644888 365.361694,32.940361 366.002808,34.614693 
C364.310150,35.328487 362.615387,35.663422 360.544373,35.998386 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M478.434387,34.182198 
C476.307159,34.499794 474.546539,34.762524 472.392395,34.984917 
C473.511169,30.244959 477.174225,29.510323 480.946045,33.533012 
C480.267761,33.978947 479.534393,34.053139 478.434387,34.182198 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M421.161163,25.005447 
C423.027618,25.225628 424.473694,25.449665 425.919800,25.673702 
C425.882507,26.376825 425.845215,27.079947 425.807922,27.783068 
C423.589996,27.783068 421.368439,27.843748 419.158234,27.712881 
C418.852295,27.694767 418.602417,26.729662 418.326599,26.202953 
C419.131348,25.805067 419.936066,25.407187 421.161163,25.005447 
z"></path>
<path fill="#474546" opacity="1.000000" stroke="none" d="
M234.622925,34.113480 
C235.027573,34.202862 235.024017,34.627430 234.664215,34.875145 
C233.119080,36.089088 231.933731,37.055317 230.748398,38.021545 
C230.748398,38.021549 230.270203,38.106453 230.037598,38.176258 
C229.063446,37.925583 228.321884,37.605106 227.264740,37.267357 
C227.534348,36.477444 227.975784,35.270798 228.733765,35.019688 
C230.480194,34.441109 232.380447,34.326786 234.622925,34.113480 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M506.169983,27.779196 
C506.272522,28.997530 506.245331,30.763468 505.879608,30.836615 
C504.507385,31.111067 503.024567,30.832815 501.583221,30.761707 
C501.416718,30.242409 501.250214,29.723112 501.083710,29.203814 
C502.684082,28.652414 504.284424,28.101013 506.169983,27.779196 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M499.799927,84.900009 
C501.561066,84.837578 503.073853,85.029404 504.586609,85.221230 
C504.703033,85.616486 504.819458,86.011742 504.935883,86.406998 
C503.555878,87.164162 502.175842,87.921333 500.795807,88.678497 
C500.381042,87.503754 499.966309,86.329010 499.799927,84.900009 
z"></path>
<path fill="#535253" opacity="1.000000" stroke="none" d="
M494.131317,37.040424 
C493.656189,37.950138 493.181061,38.859856 492.114075,39.919014 
C490.808594,40.100601 490.095001,40.132748 489.381409,40.164894 
C489.556641,38.378078 489.731842,36.591259 489.946106,34.412636 
C490.782593,34.303410 491.580017,34.585987 492.716644,35.306366 
C493.414307,36.176258 493.772827,36.608341 494.131317,37.040424 
z"></path>
<path fill="#383637" opacity="1.000000" stroke="none" d="
M133.717102,34.156998 
C134.689041,35.929321 135.340164,37.601826 135.617249,39.647659 
C134.730911,40.064400 134.218643,40.107803 133.706360,40.151211 
C132.872375,38.940948 132.038391,37.730682 131.054840,36.231163 
C131.735626,35.313667 132.565964,34.685421 133.717102,34.156998 
z"></path>
<path fill="#383637" opacity="1.000000" stroke="none" d="
M152.536331,34.389294 
C151.799286,36.247086 150.734604,38.059608 149.336746,39.938469 
C148.661423,38.334499 148.319275,36.664188 147.967896,34.627293 
C149.375336,34.288479 150.792007,34.316250 152.536331,34.389294 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M364.287811,25.475019 
C363.748810,26.744429 362.918243,27.775936 362.087646,28.807438 
C361.459686,28.142134 360.831726,27.476833 360.203766,26.811539 
C361.467926,26.286734 362.732056,25.761925 364.287811,25.475019 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M496.233337,49.950901 
C495.702911,48.140694 495.529114,46.285553 495.573120,44.173855 
C499.517578,45.451836 500.140259,47.400517 496.233337,49.950901 
z"></path>
<path fill="#535253" opacity="1.000000" stroke="none" d="
M266.958679,38.703426 
C265.593384,38.302940 264.260101,37.503365 262.861328,36.341385 
C264.004028,35.345009 265.212250,34.711029 266.761566,34.192276 
C267.065338,35.639782 267.028046,36.972057 266.958679,38.703426 
z"></path>
<path fill="#FEFDFD" opacity="1.000000" stroke="none" d="
M579.005005,33.587921 
C577.936646,34.563808 576.865234,35.170517 575.793823,35.777225 
C575.424561,35.359653 575.055298,34.942078 574.686035,34.524506 
C575.881714,33.375828 577.077332,32.227146 578.636475,31.039349 
C579.000671,31.739733 579.001343,32.479237 579.005005,33.587921 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M443.368469,31.250496 
C442.444489,30.671871 441.843292,29.924101 441.242096,29.176334 
C442.060883,28.844843 442.879639,28.513348 443.698425,28.181843 
C443.696014,29.148340 443.693604,30.114845 443.368469,31.250496 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M523.952515,108.800690 
C522.285278,109.181877 520.574036,109.204109 518.435974,109.204185 
C519.363220,104.055244 521.676392,106.571335 523.952515,108.800690 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M507.828186,41.812073 
C506.782654,42.452816 505.773041,42.745018 504.763458,43.037216 
C504.793243,42.308586 504.823029,41.579952 504.852814,40.851326 
C505.856567,41.055393 506.860352,41.259460 507.828186,41.812073 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M341.291107,26.135172 
C342.473297,25.830294 343.565277,25.879726 344.657227,25.929157 
C344.499969,26.491455 344.342682,27.053751 344.185425,27.616047 
C343.190582,27.240524 342.195709,26.865004 341.291107,26.135172 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M566.890991,25.269787 
C567.417053,26.380207 567.601746,27.397396 567.786377,28.414587 
C567.085754,28.387880 566.385132,28.361176 565.684448,28.334469 
C565.972839,27.281832 566.261230,26.229195 566.890991,25.269787 
z"></path>
<path fill="#BBBBBB" opacity="1.000000" stroke="none" d="
M287.083252,41.257759 
C287.267700,40.664879 287.457764,40.388905 287.810822,39.915840 
C288.660217,39.841923 289.346558,39.965099 290.438416,40.450817 
C290.915161,41.522778 290.986359,42.232193 290.946411,43.053795 
C290.835266,43.165974 290.612793,43.390224 290.290771,43.285252 
C289.069763,43.061165 288.170746,42.942051 287.271729,42.822937 
C287.210754,42.406849 287.149811,41.990757 287.083252,41.257759 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M504.468933,36.181820 
C505.171143,35.458565 506.153931,34.885624 507.136719,34.312691 
C507.335388,34.878250 507.534088,35.443810 507.732788,36.009361 
C506.738403,36.116951 505.743958,36.224545 504.468933,36.181820 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M330.466309,51.554436 
C331.324341,50.877811 332.305267,50.502018 333.286163,50.126228 
C333.391968,50.702576 333.497772,51.278923 333.603577,51.855270 
C332.598785,51.855270 331.593964,51.855270 330.466309,51.554436 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M241.642548,27.863926 
C240.693085,27.468285 240.072098,26.999355 239.451126,26.530424 
C240.062653,26.139532 240.674194,25.748640 241.285721,25.357746 
C241.514175,26.168709 241.742615,26.979671 241.642548,27.863926 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M338.745117,59.840401 
C337.882812,60.148457 337.099060,60.118881 336.315277,60.089306 
C336.465240,59.524357 336.615204,58.959408 336.765198,58.394455 
C337.451355,58.763893 338.137482,59.133335 338.745117,59.840401 
z"></path>
<path fill="#4D4B4C" opacity="1.000000" stroke="none" d="
M211.392273,57.881508 
C210.408386,57.270279 209.756317,56.532612 209.470886,55.412140 
C210.514236,55.013657 211.190948,54.997978 211.867645,54.982300 
C211.819809,55.906559 211.771957,56.830818 211.392273,57.881508 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M308.642212,52.012299 
C308.632202,52.933849 308.241425,53.848198 307.850647,54.762554 
C307.231934,54.094288 306.613190,53.426018 305.985474,52.388916 
C306.738159,52.015087 307.499786,52.010090 308.642212,52.012299 
z"></path>
<path fill="#383637" opacity="1.000000" stroke="none" d="
M211.946045,54.615173 
C211.190948,54.997978 210.514236,55.013657 209.502991,55.057915 
C209.135361,54.302357 209.102264,53.518215 209.433975,52.360352 
C210.484360,51.995827 211.169922,52.005020 211.855499,52.014214 
C211.911819,52.758827 211.968124,53.503437 211.946045,54.615173 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M402.254089,26.159290 
C403.116943,25.851057 403.901306,25.880703 404.685669,25.910351 
C404.535583,26.475731 404.385498,27.041109 404.235413,27.606480 
C403.548798,27.236704 402.862152,26.866936 402.254089,26.159290 
z"></path>
<path fill="#474546" opacity="1.000000" stroke="none" d="
M211.928925,51.670143 
C211.169922,52.005020 210.484360,51.995827 209.458649,51.968994 
C209.529892,50.988930 209.941299,50.026505 210.707291,49.027370 
C211.375381,49.769131 211.688873,50.547600 211.928925,51.670143 
z"></path>
<path fill="#DDDCDC" opacity="1.000000" stroke="none" d="
M347.740051,76.094582 
C346.868896,75.613525 345.997711,75.132477 345.082214,74.328186 
C345.037842,74.004936 345.043182,73.542633 345.042603,73.312408 
C345.725464,73.042595 346.408844,73.002998 347.522705,73.331894 
C347.882141,74.498459 347.811096,75.296524 347.740051,76.094582 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M291.057587,42.941612 
C290.986359,42.232193 290.915161,41.522778 290.788513,40.462181 
C291.802338,40.880638 292.871613,41.650269 293.567810,42.755562 
C292.482361,43.041351 291.769989,42.991482 291.057587,42.941612 
z"></path>
<path fill="#888787" opacity="1.000000" stroke="none" d="
M450.092896,39.879852 
C449.050903,39.198723 448.008911,38.517593 446.956024,37.448822 
C447.951996,37.477276 448.958832,37.893375 450.004608,38.876007 
C450.043518,39.442543 450.092896,39.879856 450.092896,39.879852 
z"></path>
<path fill="#BBBBBB" opacity="1.000000" stroke="none" d="
M203.123962,67.060806 
C203.123962,67.060806 203.542297,66.990440 203.754395,66.992485 
C206.539444,67.377831 206.523682,68.419525 204.361023,69.958847 
C204.011917,69.999245 203.548462,69.959366 203.269775,69.571411 
C203.035385,68.475899 203.079666,67.768356 203.123962,67.060806 
z"></path>
<path fill="#BBBBBB" opacity="1.000000" stroke="none" d="
M290.946411,43.053795 
C291.769989,42.991482 292.482361,43.041351 293.545532,43.152180 
C293.743835,44.032764 293.591370,44.852394 293.170380,45.848301 
C292.488617,46.019775 292.075317,46.014977 291.308868,45.651863 
C290.841400,44.659111 290.727112,44.024666 290.612793,43.390221 
C290.612793,43.390224 290.835266,43.165974 290.946411,43.053795 
z"></path>
<path fill="#A7A6A6" opacity="1.000000" stroke="none" d="
M444.503052,36.975182 
C444.107330,36.447208 444.063477,35.969662 444.026550,35.132370 
C445.000763,35.306587 445.968079,35.840553 446.961121,36.695030 
C446.276245,37.018902 445.565582,37.022259 444.503052,36.975182 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M447.401245,25.479183 
C447.961670,26.031195 448.226471,26.716440 448.491302,27.401688 
C447.921387,27.466675 447.351501,27.531662 446.781555,27.596642 
C446.889587,26.935230 446.997589,26.273823 447.401245,25.479183 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M276.265381,27.803345 
C276.066467,27.013575 276.191345,26.272343 276.316223,25.531113 
C276.735077,25.606167 277.153931,25.681217 277.572754,25.756269 
C277.244904,26.454809 276.917053,27.153347 276.265381,27.803345 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M483.000000,27.999958 
C482.390717,27.457525 481.781464,26.915136 481.172180,26.372746 
C481.453400,26.076466 481.734650,25.780184 482.015869,25.483898 
C482.340271,26.094400 482.664673,26.704906 482.994507,27.657784 
C483.000000,28.000153 483.000000,28.000000 483.000000,27.999958 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M436.536133,27.389439 
C436.651703,26.941690 436.933746,26.667229 437.233612,26.413942 
C437.241272,26.407478 437.445435,26.633678 437.557953,26.751360 
C437.273590,27.022734 436.989258,27.294111 436.536133,27.389439 
z"></path>
<path fill="#656364" opacity="1.000000" stroke="none" d="
M494.371338,36.929951 
C493.772827,36.608341 493.414307,36.176258 493.018341,35.450569 
C493.150543,35.103188 493.320221,35.049412 493.743805,34.913284 
C494.302521,35.030251 494.607300,35.229576 495.035675,35.677166 
C494.976654,36.223446 494.794006,36.521461 494.371338,36.929951 
z"></path>
<path fill="#767676" opacity="1.000000" stroke="none" d="
M420.921997,33.588570 
C420.433746,34.076408 419.844849,34.275265 419.125641,34.226059 
C419.604004,33.751862 420.212646,33.525723 420.921997,33.588570 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M450.319031,39.935944 
C450.092896,39.879856 450.043518,39.442543 450.099609,39.229767 
C450.416168,39.108772 450.629700,39.264835 450.890808,39.662247 
C450.985321,39.839314 450.545135,39.992027 450.319031,39.935944 
z"></path>
<path fill="#535253" opacity="1.000000" stroke="none" d="
M489.953857,33.741383 
C489.813965,34.037712 489.647827,34.060326 489.234375,34.037418 
C489.300598,33.817154 489.614136,33.642410 489.953857,33.741383 
z"></path>
<path fill="#FEFAEC" opacity="1.000000" stroke="none" d="
M91.991554,72.581100 
C91.204994,73.316772 90.421211,73.635056 89.317589,73.570381 
C89.619194,71.206917 90.011269,69.115112 90.907570,67.267967 
C93.092064,62.766075 92.365463,57.698189 88.862869,54.724258 
C88.538589,53.627048 88.253944,52.842068 87.980606,51.699196 
C87.348267,50.562912 86.704628,49.784523 86.021179,48.623276 
C84.236420,40.015114 77.423813,37.268505 70.409904,34.195702 
C66.006096,34.152000 62.052094,34.165844 57.662838,34.125080 
C55.489307,34.093063 53.751026,34.115658 52.014511,34.076180 
C52.016277,34.014107 52.007465,33.890221 52.007465,33.890221 
C52.543205,26.835913 54.811672,25.418594 61.002819,27.920918 
C62.549809,28.546175 64.455940,28.276649 66.195763,28.432249 
C70.912422,28.854074 75.915535,28.437841 80.273956,29.917902 
C86.074905,31.887831 89.378525,36.112762 89.277580,43.212055 
C89.214363,47.657173 92.117905,52.083096 93.235077,56.629486 
C93.901108,59.339962 93.986664,62.289608 93.775391,65.089645 
C93.595123,67.478859 92.618607,69.807991 91.991554,72.581100 
z"></path>
<path fill="#FEFAEC" opacity="1.000000" stroke="none" d="
M12.987909,144.986206 
C12.990627,113.636665 12.993344,82.287125 13.365295,50.469307 
C14.470227,50.004852 15.205924,50.008671 15.960703,50.479622 
C15.986254,81.312004 15.976914,111.677261 16.027327,142.042435 
C16.031651,144.646622 16.090067,147.079666 13.008501,147.662262 
C12.998850,146.515518 12.993380,145.750854 12.987909,144.986206 
z"></path>
<path fill="#FCFAF0" opacity="1.000000" stroke="none" d="
M12.668100,145.017059 
C12.993380,145.750854 12.998850,146.515518 12.804615,147.945648 
C10.123893,150.907013 10.289524,153.040726 13.014299,155.430603 
C14.405592,156.561630 15.766913,157.871994 17.156855,157.903091 
C25.766644,158.095703 34.382866,158.000809 43.003456,158.461304 
C42.995735,162.603165 42.981514,166.285080 42.594963,169.983292 
C37.670475,170.829483 36.702652,174.001083 36.970020,178.321747 
C36.648117,179.459305 36.353603,180.272705 35.879295,181.324005 
C35.129253,181.704346 34.559002,181.846771 33.988747,181.989197 
C33.988747,181.989197 33.995594,182.002457 34.099869,181.723145 
C33.464909,180.619171 32.725674,179.794540 31.986443,178.969894 
C33.592098,175.229446 34.902401,171.324326 36.874046,167.787933 
C39.688332,162.740143 39.536827,161.147888 33.970573,159.708725 
C31.262547,159.008575 28.117620,159.998230 24.834267,160.610138 
C24.498060,160.997330 24.000242,160.999390 24.000242,160.999390 
C22.285429,161.000000 20.570618,161.000610 18.038145,161.000198 
C14.814894,160.332581 12.409301,159.665985 10.003708,158.999390 
C9.766847,157.558136 9.529985,156.116882 9.146364,153.934753 
C9.332421,151.129547 9.665238,149.065247 9.998055,147.000931 
C10.781466,146.349915 11.564878,145.698914 12.668100,145.017059 
z"></path>
<path fill="#FEFAEC" opacity="1.000000" stroke="none" d="
M12.993125,49.009308 
C8.565829,45.200508 8.870640,37.322159 13.217484,32.448166 
C19.679344,25.202654 31.508224,26.206539 37.644539,33.657249 
C36.571938,34.376125 35.775711,34.701115 34.659363,34.872559 
C33.205147,34.516106 32.071049,34.313194 30.561554,34.081638 
C29.436049,34.117260 28.685936,34.181530 27.495644,34.183594 
C24.389448,34.160488 21.723434,34.199581 18.700821,34.162636 
C16.884909,34.725788 15.425591,35.364983 13.677137,36.299110 
C13.256376,40.732464 13.124750,44.870888 12.993125,49.009308 
z"></path>
<path fill="#FDFDFD" opacity="1.000000" stroke="none" d="
M23.963154,161.345154 
C24.000242,160.999390 24.498060,160.997330 24.746918,160.996216 
C25.658979,160.996613 26.366457,160.846848 26.977737,161.025757 
C29.629110,161.801758 34.344917,162.392014 34.502441,163.562332 
C35.022060,167.422699 32.811470,170.800980 28.900290,172.516190 
C27.845028,172.978973 26.736149,173.319489 25.325865,173.858170 
C22.288071,172.707230 19.576292,171.414230 16.864515,170.121246 
C16.442207,169.249649 16.019901,168.378036 15.597593,167.506424 
C18.373751,165.567932 21.149908,163.629425 23.963154,161.345154 
z"></path>
<path fill="#DFDCD7" opacity="1.000000" stroke="none" d="
M34.979481,35.026104 
C35.775711,34.701115 36.571938,34.376125 37.690998,34.012913 
C42.368561,33.967945 46.723297,33.961197 51.542747,33.922333 
C52.007465,33.890221 52.016277,34.014107 52.012333,34.429653 
C52.009998,35.558453 52.011608,36.271709 51.548912,37.003578 
C47.057507,37.029579 43.030407,37.036961 38.611092,37.032135 
C37.178139,37.049717 36.137409,37.079506 35.070541,36.762375 
C35.022762,35.952332 35.001122,35.489220 34.979481,35.026104 
z"></path>
<path fill="#FDF6E3" opacity="1.000000" stroke="none" d="
M12.994488,49.257057 
C13.124750,44.870888 13.256376,40.732464 13.944138,36.395569 
C14.972955,36.509750 15.445637,36.822411 15.933997,37.589539 
C15.946991,42.033501 15.944306,46.022995 15.941622,50.012486 
C15.205924,50.008671 14.470227,50.004852 13.366639,50.000671 
C12.998749,50.000305 12.995852,49.504807 12.994488,49.257057 
z"></path>
<path fill="#FAFBF9" opacity="1.000000" stroke="none" d="
M9.749018,159.220642 
C12.409301,159.665985 14.814894,160.332581 17.609566,161.000809 
C15.947071,161.650299 13.913095,162.650253 11.834333,162.754150 
C11.111321,162.790298 10.277526,160.609924 9.749018,159.220642 
z"></path>
<path fill="#FAFBF9" opacity="1.000000" stroke="none" d="
M9.649427,147.038757 
C9.665238,149.065247 9.332421,151.129547 8.998835,153.596436 
C4.200292,151.477310 6.111428,149.249008 9.649427,147.038757 
z"></path>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" d="
M91.341568,34.085339 
C91.428970,32.542305 91.707260,31.286186 91.985550,30.030069 
C92.601601,30.273775 93.217651,30.517479 93.833702,30.761185 
C93.066620,31.964874 92.299538,33.168564 91.341568,34.085339 
z"></path>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" d="
M68.463890,23.610529 
C68.348282,24.058292 68.066254,24.332766 67.766319,24.586040 
C67.758652,24.592514 67.554504,24.366297 67.441986,24.248600 
C67.726364,23.977221 68.010742,23.705843 68.463890,23.610529 
z"></path>
<path fill="#3C3A3B" opacity="1.000000" stroke="none" d="
M316.011993,146.030975 
C343.368805,145.987503 370.725586,145.944031 398.546143,146.365280 
C397.979797,148.560913 396.949646,150.291840 395.563904,152.009308 
C394.492523,152.002121 393.776764,152.008408 392.746094,151.870483 
C390.632233,150.804138 388.833221,149.881989 386.602905,148.951233 
C384.444672,148.952972 382.717804,148.963333 380.522949,148.962921 
C371.341919,148.952118 362.628876,148.952118 353.799530,148.952118 
C353.799530,150.217850 353.799530,151.184402 353.370605,152.102264 
C349.307495,152.713440 345.673340,153.373306 341.996094,153.598175 
C342.447052,149.975327 341.200317,148.614731 337.884552,148.903061 
C335.271881,149.130234 332.625092,148.964493 329.560547,148.966095 
C325.359467,148.972275 321.591339,148.985641 317.557251,148.843353 
C316.864899,147.802124 316.438446,146.916550 316.011993,146.030975 
z"></path>
<path fill="#383637" opacity="1.000000" stroke="none" d="
M126.001610,147.006866 
C127.730186,146.616348 129.457687,145.891754 131.187500,145.886246 
C151.815063,145.820755 172.442978,145.862259 193.474915,146.285065 
C191.296112,147.798386 188.713211,148.902374 185.793335,149.864136 
C184.300125,149.472656 183.143890,149.223419 181.522354,148.973038 
C176.374817,148.976471 171.692581,148.981079 166.630249,148.971344 
C165.489853,148.945267 164.729538,148.933563 163.769714,148.684769 
C162.054489,149.246353 160.538803,150.045029 158.645935,150.875183 
C157.513824,150.944244 156.758896,150.981796 155.721161,150.881790 
C154.295700,151.830826 153.153046,152.917404 151.778564,153.889572 
C151.343033,153.499741 151.139343,153.224335 150.951492,152.527679 
C151.345520,148.754684 149.038818,149.025009 146.838318,148.939911 
C140.157288,148.681503 133.479675,148.335098 126.588402,147.928848 
C126.134521,147.611588 126.009697,147.335617 126.001610,147.006866 
z"></path>
<path fill="#3F3D3E" opacity="1.000000" stroke="none" d="
M456.002411,146.007263 
C471.691772,145.964279 487.381134,145.921295 503.575867,146.289734 
C505.336548,147.467392 506.591888,148.233627 507.877808,149.412003 
C507.911621,150.924713 507.914764,152.025269 507.538086,153.085022 
C502.759583,151.946167 498.360931,150.848099 493.730652,149.467209 
C491.176544,148.653091 488.967499,148.331497 488.493286,151.795105 
C487.841278,151.946259 487.471344,152.041428 486.730225,152.094086 
C485.615509,152.072708 484.871979,152.093826 484.155823,151.787567 
C484.652679,147.481506 481.585876,149.445007 479.513000,148.942734 
C472.399231,148.956696 465.750153,148.985413 458.724365,149.001648 
C457.565887,147.995193 456.784149,147.001221 456.002411,146.007263 
z"></path>
<path fill="#3C3A3B" opacity="1.000000" stroke="none" d="
M521.995117,146.029800 
C533.018677,145.983276 544.042114,145.936752 555.528442,146.327484 
C555.993530,147.512497 555.995728,148.260284 555.626160,148.978912 
C554.510437,148.974045 553.766541,148.998337 552.649658,149.017029 
C551.531921,149.007553 550.786987,149.003677 549.645752,148.984085 
C547.715393,148.702774 546.181274,148.437180 544.379089,148.125168 
C544.137451,149.730194 543.949829,150.976822 543.401611,152.150833 
C542.310852,152.101746 541.580566,152.125275 540.443237,152.084900 
C539.310669,153.702133 538.585266,155.383240 537.510254,157.090927 
C536.075256,156.562180 534.264038,156.224365 534.021912,155.416351 
C532.400391,150.005707 529.147888,147.659897 523.506653,148.952942 
C523.050354,149.057541 522.535645,148.907104 522.037720,148.519501 
C522.016602,147.452484 522.005859,146.741135 521.995117,146.029800 
z"></path>
<path fill="#767676" opacity="1.000000" stroke="none" d="
M125.833504,146.730591 
C126.009697,147.335617 126.134521,147.611588 126.488861,148.223206 
C126.077782,149.400803 125.553932,150.189972 124.979248,151.447495 
C124.937035,157.729965 124.842491,163.546707 124.992432,169.357162 
C125.084396,172.920898 125.550926,176.474991 125.983093,180.309204 
C126.754639,181.372345 127.392334,182.159668 128.030029,182.946991 
C128.024963,183.365875 128.019897,183.784744 128.019836,184.517776 
C126.976212,184.843460 125.927589,184.854996 124.489647,184.802338 
C120.278618,178.516373 121.032852,171.856918 121.849167,165.045807 
C122.243584,161.754868 121.154579,158.310349 121.278145,154.955276 
C121.381302,152.154358 122.272598,149.382446 122.821701,146.206238 
C123.260857,145.749268 123.693169,145.683746 124.448959,145.564270 
C125.070091,145.824966 125.367744,146.139633 125.833504,146.730591 
z"></path>
<path fill="#2F2E2E" opacity="1.000000" stroke="none" d="
M212.003052,146.028442 
C212.754196,145.973679 213.505356,145.918915 214.628357,146.305099 
C215.007278,147.473206 215.014374,148.200378 214.636108,149.024353 
C213.498550,155.080521 212.746368,161.039871 211.601593,167.010742 
C210.498062,168.693069 209.787094,170.363876 209.047394,172.298141 
C208.996613,172.736893 208.974548,172.912186 208.638275,173.029358 
C207.537079,172.341370 206.750122,171.711487 205.975250,170.646759 
C205.948441,169.053513 205.909576,167.895096 206.071686,166.428375 
C206.481003,164.441330 206.689346,162.762573 206.923492,160.641174 
C206.925354,158.427505 206.901413,156.656464 206.927780,154.528900 
C206.942932,153.456985 206.907761,152.741592 206.914444,151.690582 
C206.929306,150.905197 206.902313,150.455414 207.190552,149.985168 
C208.016235,149.646851 208.526688,149.329025 209.403198,148.999222 
C210.513855,148.000992 211.258453,147.014725 212.003052,146.028442 
z"></path>
<path fill="#3C3A3B" opacity="1.000000" stroke="none" d="
M407.995422,146.020599 
C420.023376,145.971970 432.051300,145.923325 444.538391,146.261169 
C444.973175,147.331177 444.948822,148.014709 444.555542,148.796051 
C443.173401,148.961227 442.160156,149.028580 440.785583,149.057465 
C439.306030,149.365723 438.187836,149.712479 436.826050,150.043869 
C436.582397,150.028488 436.095734,149.988861 435.728333,149.834686 
C433.240997,149.455170 431.121124,149.229797 428.532776,148.991013 
C421.383728,148.945755 414.703125,148.913910 408.016846,148.524261 
C408.005920,147.451172 408.000671,146.735886 407.995422,146.020599 
z"></path>
<path fill="#383637" opacity="1.000000" stroke="none" d="
M238.984650,145.958710 
C247.680283,145.929794 256.375916,145.900879 265.490784,146.262299 
C264.919464,147.456421 263.928925,148.260178 262.588379,149.038177 
C260.481903,149.009354 258.725494,149.006271 256.595642,149.008331 
C255.474716,149.017548 254.727234,149.021622 253.617523,148.998367 
C252.531067,149.017136 251.806808,149.063263 250.742767,149.112091 
C249.880463,149.980225 249.357956,150.845673 248.620880,151.951874 
C248.377869,152.820297 248.349426,153.447968 248.218475,154.293793 
C248.115952,154.511932 247.920929,154.952789 247.524475,154.971436 
C245.553040,154.927521 243.978058,154.864960 242.331177,154.457855 
C242.169174,153.416626 242.079117,152.719925 241.983002,151.637711 
C240.979507,149.487717 239.982086,147.723206 238.984650,145.958710 
z"></path>
<path fill="#343233" opacity="1.000000" stroke="none" d="
M268.993164,145.976242 
C279.024200,145.940796 289.055267,145.905350 299.547302,146.270920 
C299.975586,147.387802 299.942871,148.103653 299.598816,148.918762 
C298.198547,150.020889 297.109650,151.023788 295.691040,152.050934 
C294.921173,152.065857 294.481018,152.056564 294.000732,151.647705 
C293.211090,147.218628 290.023376,149.652359 287.571289,148.993835 
C285.426544,148.998810 283.710541,149.009537 281.604218,149.010025 
C277.750793,148.742889 273.803467,146.994125 271.683746,151.926666 
C270.548706,149.909607 269.770935,147.942932 268.993164,145.976242 
z"></path>
<path fill="#535253" opacity="1.000000" stroke="none" d="
M444.924469,148.698227 
C444.948822,148.014709 444.973175,147.331177 445.006409,146.305237 
C448.374756,145.932999 451.734253,145.903183 455.548065,145.940308 
C456.784149,147.001221 457.565887,147.995193 458.722107,149.326660 
C459.148102,150.094345 459.199646,150.524506 459.182587,151.382721 
C458.448303,156.557510 457.782623,161.304260 456.824829,165.813477 
C456.352600,164.276932 456.320679,162.928146 455.910156,161.706573 
C455.771118,161.292847 454.675049,161.200729 453.634552,160.962997 
C452.493378,161.002441 451.734039,161.040527 450.744476,160.989471 
C450.514282,160.900360 450.064972,160.695755 450.071075,160.323608 
C451.152283,159.299072 452.332703,158.769287 453.273987,157.961060 
C454.287567,157.090775 455.077057,155.959534 456.005737,154.561966 
C455.152802,152.749695 454.552979,150.509048 453.302887,150.053696 
C450.691925,149.102646 447.737701,149.093994 444.924469,148.698227 
z"></path>
<path fill="#474546" opacity="1.000000" stroke="none" d="
M268.621460,145.919373 
C269.770935,147.942932 270.548706,149.909607 271.691223,152.352539 
C270.854248,158.370575 269.670288,163.916458 268.401703,169.442947 
C268.333832,169.738586 267.553802,169.870789 266.773041,170.042740 
C266.220459,169.973053 266.000122,169.939713 265.835754,169.481293 
C266.904968,164.372238 267.918243,159.688278 269.009888,154.651855 
C268.402954,153.174393 267.717621,152.049362 266.866699,150.637329 
C265.507751,150.621201 264.314392,150.892090 263.065552,150.815369 
C262.986206,149.999817 262.962280,149.531876 262.938385,149.063934 
C263.928925,148.260178 264.919464,147.456421 265.956970,146.319061 
C266.752533,145.944473 267.501160,145.903488 268.621460,145.919373 
z"></path>
<path fill="#2A2A2A" opacity="1.000000" stroke="none" d="
M575.569824,147.849899 
C575.907104,148.047287 575.957397,148.524811 575.513123,148.761841 
C571.712585,148.999054 568.356262,148.999222 564.604858,149.001404 
C563.487366,149.530380 562.124390,150.080597 562.142517,150.580658 
C562.308533,155.151215 559.292358,155.146851 556.055359,154.648819 
C555.964172,153.509155 555.934204,152.746414 555.960449,152.057693 
C556.016602,152.131699 556.197754,152.090317 556.580811,152.038101 
C557.649780,150.006500 558.335815,148.027115 559.021851,146.047745 
C562.818970,145.976883 566.626099,145.719620 570.408630,145.922043 
C572.042725,146.009476 573.626099,147.046722 575.569824,147.849899 
z"></path>
<path fill="#2A2A2A" opacity="1.000000" stroke="none" d="
M395.919495,152.022766 
C396.949646,150.291840 397.979797,148.560913 399.010498,146.440903 
C399.757568,146.007111 400.504059,145.962402 401.623230,146.337616 
C402.013245,147.468979 402.030609,148.180405 401.758362,149.059219 
C401.619629,154.860504 401.770508,160.494415 401.602539,165.954620 
C400.521820,164.234863 399.827057,162.650360 398.971924,161.157700 
C398.487976,160.312958 397.740723,159.619049 397.069946,158.513458 
C396.640167,157.118790 396.252502,156.067871 395.924530,154.645569 
C395.962646,153.523712 395.941071,152.773239 395.919495,152.022766 
z"></path>
<path fill="#383637" opacity="1.000000" stroke="none" d="
M215.021484,148.927536 
C215.014374,148.200378 215.007278,147.473206 214.999023,146.382568 
C221.690125,145.972580 228.382355,145.926086 235.543320,146.296204 
C236.012039,148.068375 236.012039,149.423920 236.012039,150.878616 
C234.994003,150.942947 234.059708,151.001984 233.011658,150.671509 
C231.775116,149.851028 230.663300,149.084396 229.527649,149.047333 
C224.696106,148.889648 219.857513,148.948044 215.021484,148.927536 
z"></path>
<path fill="#4D4B4C" opacity="1.000000" stroke="none" d="
M233.125397,151.061020 
C234.059708,151.001984 234.994003,150.942947 236.012039,150.878616 
C236.012039,149.423920 236.012039,148.068375 236.010696,146.341934 
C236.754593,145.928284 237.499832,145.885529 238.614868,145.900742 
C239.982086,147.723206 240.979507,149.487717 241.587219,151.607544 
C237.145493,151.884888 235.615158,153.682541 236.747009,157.703079 
C237.042572,158.753006 236.822754,159.948013 236.435333,161.046921 
C235.045227,160.028137 233.429016,159.161606 233.207291,158.023438 
C232.770721,155.782364 233.108139,153.390503 233.125397,151.061020 
z"></path>
<path fill="#4D4B4C" opacity="1.000000" stroke="none" d="
M209.037125,149.011185 
C208.526688,149.329025 208.016235,149.646851 206.873901,149.851990 
C205.141541,149.569046 204.041077,149.398819 202.583496,149.159332 
C201.503220,149.131866 200.780090,149.173660 199.632019,149.149124 
C195.787674,148.990341 192.100586,148.299698 190.380707,152.891159 
C189.227921,152.951691 188.457932,152.982010 187.432678,152.847656 
C186.828384,151.790771 186.479355,150.898560 186.130310,150.006363 
C188.713211,148.902374 191.296112,147.798386 193.940948,146.343384 
C197.701279,145.957718 201.399704,145.923080 205.603790,146.284546 
C207.085342,147.457474 208.061234,148.234329 209.037125,149.011185 
z"></path>
<path fill="#535253" opacity="1.000000" stroke="none" d="
M299.910156,148.819519 
C299.942871,148.103653 299.975586,147.387802 300.015991,146.313110 
C305.043823,145.933380 310.064026,145.912476 315.548096,145.961273 
C316.438446,146.916550 316.864899,147.802124 317.581177,149.232971 
C317.780731,150.549713 317.690430,151.321167 317.259796,152.070740 
C315.997742,152.033600 315.075989,152.018341 314.048370,151.625763 
C312.412506,150.474411 310.941101,149.224808 309.340149,149.027740 
C306.235321,148.645508 303.057617,148.855133 299.910156,148.819519 
z"></path>
<path fill="#2F2E2E" opacity="1.000000" stroke="none" d="
M521.530640,145.969452 
C522.005859,146.741135 522.016602,147.452484 521.568970,148.562164 
C516.689453,148.973633 512.268311,148.986740 507.847198,148.999847 
C506.591888,148.233627 505.336548,147.467392 504.041229,146.352951 
C509.689545,145.972855 515.377869,145.940979 521.530640,145.969452 
z"></path>
<path fill="#4D4B4C" opacity="1.000000" stroke="none" d="
M558.647400,145.975632 
C558.335815,148.027115 557.649780,150.006500 556.512573,151.656433 
C556.040161,150.554016 556.019104,149.781052 555.997925,149.008072 
C555.995728,148.260284 555.993530,147.512497 555.993347,146.390991 
C556.754578,145.979355 557.513733,145.941437 558.647400,145.975632 
z"></path>
<path fill="#383637" opacity="1.000000" stroke="none" d="
M209.403198,148.999222 
C208.061234,148.234329 207.085342,147.457474 206.060699,146.356720 
C207.725540,145.991867 209.439133,145.950897 211.577881,145.969193 
C211.258453,147.014725 210.513855,148.000992 209.403198,148.999222 
z"></path>
<path fill="#2A2A2A" opacity="1.000000" stroke="none" d="
M407.623779,145.964081 
C408.000671,146.735886 408.005920,147.451172 408.009521,148.558517 
C408.007935,148.950577 408.002441,149.020386 407.619934,149.008667 
C406.471893,148.960846 405.706299,148.924759 404.960205,148.531586 
C404.988647,147.459930 404.997650,146.745361 405.006653,146.030823 
C405.755127,145.989731 406.503632,145.948639 407.623779,145.964081 
z"></path>
<path fill="#3C3A3B" opacity="1.000000" stroke="none" d="
M404.631653,145.971985 
C404.997650,146.745361 404.988647,147.459930 404.599487,148.556763 
C403.495544,148.923325 402.771759,148.907578 402.047974,148.891830 
C402.030609,148.180405 402.013245,147.468979 401.992981,146.402542 
C402.745636,146.002747 403.501129,145.957962 404.631653,145.971985 
z"></path>
<path fill="#717071" opacity="1.000000" stroke="none" d="
M305.093231,57.810097 
C307.161621,59.168274 309.230042,60.526455 311.654663,61.920464 
C319.230865,68.756828 326.450897,75.557365 334.135010,82.703308 
C335.464111,84.948921 336.329102,86.849121 337.194061,88.749313 
C334.072510,86.498032 330.950928,84.246750 327.435699,81.622757 
C326.038696,80.484428 325.035339,79.718811 324.032013,78.953186 
C324.032013,78.953194 324.000000,78.999321 324.005188,78.635696 
C322.998322,77.529114 321.986298,76.786163 320.974274,76.043205 
C320.974274,76.043205 320.999542,75.999245 320.969299,75.666077 
C319.949707,74.598534 318.960388,73.864143 317.971069,73.129761 
C316.915283,72.142181 315.859497,71.154602 314.457916,69.692245 
C313.446594,68.733597 312.781067,68.249725 312.115540,67.765869 
C312.010071,67.480347 311.828583,67.256470 311.320557,66.673706 
C310.417999,65.759270 309.765991,65.265358 309.113953,64.771454 
C309.007874,64.479874 308.821106,64.255432 308.290955,63.684464 
C307.365021,62.819412 306.701813,62.368023 306.038605,61.916634 
C305.945526,61.596439 305.756012,61.350586 305.256683,60.746498 
C305.032532,59.867722 305.021729,59.421528 305.027191,58.683716 
C305.060059,58.198101 305.076660,58.004101 305.093231,57.810097 
z"></path>
<path fill="#B4B2B3" opacity="1.000000" stroke="none" d="
M269.448303,67.308884 
C269.278534,58.561180 269.108765,49.813480 268.939209,40.606636 
C269.708160,40.808113 270.476868,41.468731 271.132782,42.809818 
C271.326477,44.329285 271.633026,45.168278 272.042664,46.452454 
C272.240662,49.620506 272.335541,52.343372 272.295288,55.502243 
C272.123169,59.293049 272.086182,62.647846 271.779541,66.118607 
C270.822662,66.592682 270.135498,66.950783 269.448303,67.308884 
z"></path>
<path fill="#DDDCDC" opacity="1.000000" stroke="none" d="
M337.467468,88.877151 
C336.329102,86.849121 335.464111,84.948921 334.492340,82.715988 
C339.609192,82.857277 342.091980,86.857117 344.372833,90.873932 
C342.568512,91.187813 341.233948,91.140144 339.899414,91.092476 
C339.179901,90.396645 338.460358,89.700813 337.467468,88.877151 
z"></path>
<path fill="#888787" opacity="1.000000" stroke="none" d="
M339.912720,91.425659 
C341.233948,91.140144 342.568512,91.187813 344.299316,91.248497 
C344.695587,91.261520 344.732086,91.250961 344.767761,91.616028 
C346.188324,95.757950 347.670715,93.842232 349.437622,92.042839 
C350.106171,92.007965 350.487976,91.985779 350.869812,91.963600 
C350.846252,93.500854 350.822723,95.038116 350.789001,96.941338 
C350.088379,97.429176 349.397949,97.551048 348.100037,97.482910 
C345.405182,96.412956 343.317841,95.533020 341.230530,94.653084 
C340.795715,93.688339 340.360901,92.723587 339.912720,91.425659 
z"></path>
<path fill="#343233" opacity="1.000000" stroke="none" d="
M272.430450,55.066235 
C272.335541,52.343372 272.240662,49.620506 272.310669,46.273159 
C272.598755,44.814953 272.721954,43.981236 272.845123,43.147514 
C273.562408,43.956776 274.275635,44.769676 274.997894,45.574471 
C276.180695,46.892433 277.369324,48.205124 278.712219,50.081322 
C278.587830,51.101673 278.306702,51.560741 277.649109,52.023186 
C276.478729,53.035877 275.684814,54.045197 274.582764,55.049278 
C273.659912,55.051434 273.045166,55.058834 272.430450,55.066235 
z"></path>
<path fill="#535253" opacity="1.000000" stroke="none" d="
M314.881165,82.268509 
C311.852173,79.527176 308.823212,76.785835 305.768738,73.690430 
C308.694183,75.419525 311.645142,77.502686 314.771606,80.227455 
C314.925110,81.335548 314.903137,81.802025 314.881165,82.268509 
z"></path>
<path fill="#A0A0A0" opacity="1.000000" stroke="none" d="
M278.025574,52.019806 
C278.306702,51.560741 278.587830,51.101673 278.942566,50.382065 
C282.709320,49.809971 284.040497,52.278442 285.057678,55.557449 
C283.097809,55.624191 281.069824,55.330162 278.577606,54.700943 
C278.084106,53.583767 278.054840,52.801788 278.025574,52.019806 
z"></path>
<path fill="#474546" opacity="1.000000" stroke="none" d="
M332.624390,96.963135 
C330.992859,96.493614 329.431946,95.663025 327.518677,94.784256 
C325.831421,92.985947 324.496552,91.235809 323.262878,89.144196 
C326.474396,91.402489 329.584686,94.002274 332.624390,96.963135 
z"></path>
<path fill="#2F2E2E" opacity="1.000000" stroke="none" d="
M341.179260,95.048462 
C343.317841,95.533020 345.405182,96.412956 347.746796,97.512772 
C347.491913,98.764397 346.982727,99.796143 346.473572,100.827896 
C344.691711,99.033211 342.909882,97.238525 341.179260,95.048462 
z"></path>
<path fill="#535253" opacity="1.000000" stroke="none" d="
M277.884705,34.689270 
C279.528992,35.317055 281.160950,36.330742 282.824768,37.702274 
C280.714722,37.867500 277.806488,38.954441 277.884705,34.689270 
z"></path>
<path fill="#474546" opacity="1.000000" stroke="none" d="
M336.081848,103.279602 
C335.894867,102.340759 335.885803,101.643600 335.834900,100.598068 
C336.902985,101.174942 338.012878,102.100174 338.873260,103.478851 
C338.407593,103.958504 338.191467,103.984703 337.975311,104.010910 
C337.403442,103.847702 336.831604,103.684494 336.081848,103.279602 
z"></path>
<path fill="#717071" opacity="1.000000" stroke="none" d="
M290.290802,43.285248 
C290.727112,44.024666 290.841400,44.659111 291.001038,45.615360 
C289.764191,45.156315 288.482056,44.375465 287.235809,43.208775 
C288.170746,42.942051 289.069763,43.061165 290.290802,43.285248 
z"></path>
<path fill="#222021" opacity="1.000000" stroke="none" d="
M324.018036,79.316727 
C325.035339,79.718811 326.038696,80.484428 327.071045,81.598915 
C326.141510,82.285843 325.182892,82.623894 324.004059,83.039627 
C324.004059,81.842682 324.004059,80.761467 324.018036,79.316727 
z"></path>
<path fill="#474546" opacity="1.000000" stroke="none" d="
M344.726135,106.724976 
C344.095001,107.034050 343.380798,107.015915 342.307190,107.004044 
C341.723328,106.823944 341.503296,106.632515 341.143402,105.911819 
C341.013031,104.891289 341.026917,104.394966 341.040802,103.898651 
C342.241547,104.731697 343.442322,105.564735 344.726135,106.724976 
z"></path>
<path fill="#474546" opacity="1.000000" stroke="none" d="
M335.800262,99.817108 
C334.766510,99.512726 333.781433,98.814919 332.744751,97.781067 
C333.745056,98.104576 334.797028,98.764137 335.800262,99.817108 
z"></path>
<path fill="#656364" opacity="1.000000" stroke="none" d="
M305.026306,57.494576 
C305.076660,58.004101 305.060059,58.198101 304.701050,58.658401 
C303.923004,58.914684 303.487427,58.904675 303.051819,58.894661 
C302.731232,58.027222 302.410645,57.159782 302.277588,55.981705 
C303.296539,56.173733 304.127991,56.676392 305.026306,57.494576 
z"></path>
<path fill="#474546" opacity="1.000000" stroke="none" d="
M320.140503,85.243286 
C319.113098,84.939796 318.009430,84.316063 316.978271,83.327919 
C318.055237,83.616684 319.059723,84.269859 320.140503,85.243286 
z"></path>
<path fill="#474546" opacity="1.000000" stroke="none" d="
M323.148438,88.319916 
C322.211121,87.919846 321.226074,87.201462 320.344910,86.127823 
C321.332764,86.515564 322.216736,87.258583 323.148438,88.319916 
z"></path>
<path fill="#222021" opacity="1.000000" stroke="none" d="
M320.984497,76.413101 
C321.986298,76.786163 322.998322,77.529114 324.028503,78.620094 
C323.029327,78.239746 322.012054,77.511368 320.984497,76.413101 
z"></path>
<path fill="#222021" opacity="1.000000" stroke="none" d="
M317.985840,73.487167 
C318.960388,73.864143 319.949707,74.598534 320.947693,75.679413 
C319.971100,75.298805 318.985840,74.571693 317.985840,73.487167 
z"></path>
<path fill="#535253" opacity="1.000000" stroke="none" d="
M305.705261,72.889664 
C304.654358,72.558456 303.648590,71.850426 302.625732,70.790497 
C303.655914,71.130013 304.703156,71.821426 305.705261,72.889664 
z"></path>
<path fill="#535253" opacity="1.000000" stroke="none" d="
M340.744263,103.865402 
C341.026917,104.394966 341.013031,104.891289 340.985748,105.760040 
C340.005493,105.666031 339.038666,105.199577 338.023560,104.372025 
C338.191467,103.984703 338.407593,103.958504 338.943054,103.853798 
C339.657471,103.794258 340.052582,103.813210 340.744263,103.865402 
z"></path>
<path fill="#656364" opacity="1.000000" stroke="none" d="
M272.588074,43.076645 
C272.721954,43.981236 272.598755,44.814953 272.207581,45.827972 
C271.633026,45.168278 271.326477,44.329285 271.153442,43.142052 
C271.634949,42.864468 271.982971,42.935120 272.588074,43.076645 
z"></path>
<path fill="#474546" opacity="1.000000" stroke="none" d="
M315.072418,82.515205 
C314.903137,81.802025 314.925110,81.335548 314.937469,80.521255 
C315.639008,80.869759 316.350189,81.566071 317.049133,82.605476 
C316.445831,82.886337 315.854767,82.824120 315.072418,82.515205 
z"></path>
<path fill="#222021" opacity="1.000000" stroke="none" d="
M309.109497,65.130173 
C309.765991,65.265358 310.417999,65.759270 311.119995,66.585190 
C310.481628,66.441093 309.793335,65.964989 309.109497,65.130173 
z"></path>
<path fill="#222021" opacity="1.000000" stroke="none" d="
M303.066620,59.232597 
C303.487427,58.904675 303.923004,58.914684 304.684753,58.950012 
C305.021729,59.421528 305.032532,59.867722 305.040527,60.648529 
C304.385651,60.512272 303.733521,60.041401 303.066620,59.232597 
z"></path>
<path fill="#222021" opacity="1.000000" stroke="none" d="
M306.059448,62.259949 
C306.701813,62.368023 307.365021,62.819412 308.084686,63.601059 
C307.454193,63.488628 306.767273,63.045948 306.059448,62.259949 
z"></path>
<path fill="#222021" opacity="1.000000" stroke="none" d="
M312.112823,68.127541 
C312.781067,68.249725 313.446594,68.733597 314.166443,69.553955 
C313.517181,69.423363 312.813660,68.956291 312.112823,68.127541 
z"></path>
<path fill="#383637" opacity="1.000000" stroke="none" d="
M265.779816,169.906387 
C266.000122,169.939713 266.220459,169.973053 266.748901,170.445923 
C266.775696,179.107712 266.775696,179.107712 275.349670,178.971924 
C276.490051,178.985016 277.250000,178.979599 278.458984,178.987335 
C281.606720,178.987808 284.305420,178.975143 287.404968,178.981781 
C288.876465,178.985352 289.947174,178.969620 291.398834,178.889160 
C292.405670,177.759995 293.031525,176.695557 294.001709,175.706131 
C295.572327,175.846451 296.798645,175.911758 298.053223,175.960388 
C298.081421,175.943710 298.139679,175.913635 298.130615,176.248703 
C298.758270,177.718857 299.394989,178.853958 300.031708,179.989044 
C300.031708,179.989044 300.005066,179.963791 299.989746,179.952271 
C297.305145,180.633118 294.637817,181.906174 291.966156,181.915314 
C281.531555,181.951096 271.095734,181.576248 260.659607,181.397934 
C260.029785,181.387177 259.393463,181.757401 258.379547,181.556610 
C257.997772,179.808578 257.996613,178.454285 258.292755,177.015259 
C259.059021,176.615707 259.528015,176.300919 260.315002,175.943817 
C261.456573,175.317444 262.280121,174.733383 263.367981,173.949265 
C264.348145,172.468262 265.063965,171.187332 265.779816,169.906387 
z"></path>
<path fill="#161415" opacity="1.000000" stroke="none" d="
M159.889832,180.116730 
C158.784607,180.147186 157.679398,180.177628 156.273163,179.802032 
C155.667969,178.920227 155.363800,178.444473 155.012970,177.650528 
C155.006180,176.906982 155.046066,176.481644 155.356140,175.953552 
C156.104935,175.263138 156.583542,174.675507 157.393051,174.026917 
C158.739212,173.603378 159.754501,173.240784 161.168701,172.918304 
C162.366791,172.960312 163.165955,172.962189 163.983429,173.317474 
C164.479614,174.310806 164.957474,174.950729 165.435333,175.590668 
C165.962204,174.389984 166.489090,173.189285 167.414307,172.043488 
C170.422653,172.600586 173.032639,173.102798 175.835266,173.642090 
C175.527679,175.565338 175.255341,177.268112 174.521393,178.976547 
C169.336472,179.360382 164.613144,179.738556 159.889832,180.116730 
z"></path>
<path fill="#343233" opacity="1.000000" stroke="none" d="
M238.990021,181.971924 
C230.076538,181.979507 221.159744,182.115417 212.252335,181.886490 
C210.468307,181.840652 208.720001,180.405075 206.927612,178.858139 
C207.583618,176.732376 208.267242,175.358749 209.290756,174.126907 
C212.251297,176.107437 213.306717,180.747482 218.438446,178.950043 
C223.609482,178.964676 228.313004,178.958160 233.421844,178.967072 
C234.913284,178.991272 235.999405,179.000061 237.422943,179.059662 
C238.170227,180.064285 238.580124,181.018097 238.990021,181.971924 
z"></path>
<path fill="#B4B2B3" opacity="1.000000" stroke="none" d="
M208.950867,173.985123 
C208.267242,175.358749 207.583618,176.732376 206.844635,178.449982 
C202.867859,178.483780 198.946442,178.173584 194.457367,177.565857 
C193.565643,176.546371 193.241547,175.824448 192.957123,174.804504 
C193.027252,174.308502 193.057739,174.110474 193.338501,173.763092 
C194.113632,173.059143 194.638519,172.504562 195.559753,171.935486 
C199.291779,171.641220 202.627487,171.361404 205.963181,171.081604 
C206.750122,171.711487 207.537079,172.341370 208.663055,173.253815 
C209.002090,173.536407 208.950867,173.985123 208.950867,173.985123 
z"></path>
<path fill="#2A2A2A" opacity="1.000000" stroke="none" d="
M187.970184,181.903259 
C186.601273,181.909332 185.232376,181.915405 183.440979,181.531738 
C183.007660,180.437561 182.996826,179.733078 183.386353,178.999390 
C184.500015,177.771561 185.837051,176.564651 185.821259,175.375717 
C185.742630,169.456879 185.151077,163.543381 185.127411,157.626465 
C185.122498,156.399796 186.760788,155.166550 187.750702,154.398132 
C187.888168,159.778931 187.713318,164.711517 188.040329,169.610580 
C188.181000,171.718155 189.362320,173.756256 189.872589,176.141251 
C189.106293,178.272125 188.538239,180.087692 187.970184,181.903259 
z"></path>
<path fill="#343233" opacity="1.000000" stroke="none" d="
M257.995483,177.100006 
C257.996613,178.454285 257.997772,179.808578 257.997925,181.568207 
C254.971176,181.982498 251.945450,181.991440 248.464142,181.591522 
C247.635132,179.698807 247.261688,178.214920 247.017517,176.427673 
C247.514542,175.130753 247.882324,174.137192 248.663757,173.115646 
C250.741943,173.032333 252.406464,172.976974 254.348297,173.092972 
C255.748886,174.542877 256.872192,175.821442 257.995483,177.100006 
z"></path>
<path fill="#4D4B4C" opacity="1.000000" stroke="none" d="
M142.970947,179.058624 
C142.565659,179.054092 142.160355,179.049561 141.318283,178.799133 
C140.591599,178.039673 140.301682,177.526093 139.894913,176.679016 
C139.224625,174.583176 138.671158,172.820847 138.395798,170.794556 
C139.072922,165.190475 143.395905,167.526901 146.458481,166.792267 
C147.498032,166.861038 148.194626,166.864212 149.264740,166.918793 
C150.390030,166.960388 151.141815,166.950577 151.952179,167.306335 
C151.974213,168.410126 151.937668,169.148361 151.479248,169.953308 
C148.494736,170.152359 145.932083,170.284714 143.087341,170.431641 
C144.004654,172.936646 144.557541,174.446442 144.754028,176.016724 
C143.922073,177.071014 143.446518,178.064819 142.970947,179.058624 
z"></path>
<path fill="#383637" opacity="1.000000" stroke="none" d="
M182.985992,179.028610 
C182.996826,179.733078 183.007660,180.437561 183.032135,181.490921 
C176.420593,181.892944 169.795059,181.993790 163.170517,181.948669 
C162.157104,181.941772 161.148270,181.260498 160.013580,180.503937 
C164.613144,179.738556 169.336472,179.360382 174.924210,178.991638 
C176.862411,178.993774 177.936172,178.986465 179.386749,178.984833 
C180.516861,178.973007 181.270157,178.955475 182.263824,178.963486 
C182.504196,178.989029 182.985992,179.028610 182.985992,179.028610 
z"></path>
<path fill="#3F3D3E" opacity="1.000000" stroke="none" d="
M246.888245,176.731049 
C247.261688,178.214920 247.635132,179.698807 248.011337,181.571259 
C245.306671,181.978577 242.599258,181.997299 239.440933,181.993958 
C238.580124,181.018097 238.170227,180.064285 237.408066,178.676575 
C237.407379,176.770370 237.758972,175.298065 238.473450,173.879150 
C241.520325,174.865387 244.204285,175.798218 246.888245,176.731049 
z"></path>
<path fill="#343233" opacity="1.000000" stroke="none" d="
M140.011749,177.012527 
C140.301682,177.526093 140.591599,178.039673 141.014832,178.820221 
C139.475601,180.049454 137.904587,181.352249 136.105087,181.888977 
C133.789383,182.579651 131.290451,182.656082 128.450241,182.972046 
C127.392334,182.159668 126.754639,181.372345 126.249092,180.212708 
C126.868698,179.562180 127.356140,179.283966 128.310181,179.013504 
C132.521759,178.351685 136.266754,177.682114 140.011749,177.012527 
z"></path>
<path fill="#383637" opacity="1.000000" stroke="none" d="
M188.399704,181.950287 
C188.538239,180.087692 189.106293,178.272125 190.168335,176.093018 
C191.414047,175.520462 192.165756,175.311493 192.917465,175.102509 
C193.241547,175.824448 193.565643,176.546371 194.052917,177.526428 
C194.632156,182.016830 191.990479,182.339264 188.399704,181.950287 
z"></path>
<path fill="#474546" opacity="1.000000" stroke="none" d="
M143.178177,179.337418 
C143.446518,178.064819 143.922073,177.071014 144.942047,176.054749 
C145.486450,176.032257 145.849838,176.155777 145.878998,176.530319 
C146.989624,177.523605 148.071075,178.142349 149.153931,178.839508 
C149.155350,178.917938 149.011978,178.981583 149.007935,179.358078 
C149.001907,180.487396 148.999939,181.240204 148.997955,181.993027 
C147.127106,181.200760 145.256256,180.408478 143.178177,179.337418 
z"></path>
<path fill="#4D4B4C" opacity="1.000000" stroke="none" d="
M149.403824,181.998032 
C148.999939,181.240204 149.001907,180.487396 149.323929,179.317535 
C150.065048,178.849503 150.486115,178.798508 151.273285,178.838531 
C152.779465,178.609268 153.919556,178.289001 155.059647,177.968735 
C155.363800,178.444473 155.667969,178.920227 155.987320,179.693573 
C153.938232,180.661819 151.873962,181.332428 149.403824,181.998032 
z"></path>
<path fill="#535253" opacity="1.000000" stroke="none" d="
M148.037704,115.540802 
C150.723923,115.664848 153.410156,115.788895 156.546478,116.251442 
C156.991470,117.031487 156.986343,117.473030 156.921234,118.103745 
C156.861282,118.292915 156.817841,118.687431 156.817841,118.687431 
C155.151810,118.690147 153.485764,118.692863 151.288910,118.510040 
C150.176987,118.459984 149.595886,118.595459 149.014801,118.730934 
C143.059464,118.701820 137.104141,118.672707 130.699219,118.617119 
C129.708450,117.599968 129.167236,116.609283 128.486115,115.362495 
C129.685654,114.480949 130.920990,113.573105 132.575500,113.025421 
C134.387589,114.210526 135.780472,115.035461 137.173370,115.860405 
C138.509476,115.814240 139.845566,115.768074 141.997253,115.747513 
C144.554459,115.695686 146.296082,115.618248 148.037704,115.540802 
z"></path>
<path fill="#656364" opacity="1.000000" stroke="none" d="
M199.661118,116.519974 
C199.877975,117.156166 199.829559,117.539558 199.765961,118.211792 
C194.472046,118.585327 189.193298,118.670013 183.220734,118.537598 
C181.023682,118.493042 179.520447,118.665596 178.017212,118.838150 
C176.266251,118.769974 174.515305,118.701797 172.360657,118.180054 
C171.967636,117.154984 171.978287,116.583473 172.457230,116.001411 
C176.899704,115.989861 180.912094,116.315773 184.832886,115.857773 
C186.974792,115.607582 188.961700,114.030487 191.395874,113.024277 
C192.528275,113.025696 193.283310,113.054619 194.038345,113.083542 
C193.766724,117.607094 197.605881,115.212906 199.661118,116.519974 
z"></path>
<path fill="#535253" opacity="1.000000" stroke="none" d="
M171.988937,116.011971 
C171.978287,116.583473 171.967636,117.154984 171.958405,118.011620 
C169.268112,118.448769 166.576416,118.600784 163.296783,118.591209 
C162.138824,118.284470 161.568802,118.139336 161.001923,117.601578 
C160.970016,116.420517 160.934967,115.632065 160.899902,114.843620 
C160.893890,114.479813 160.887878,114.116013 161.293854,113.359390 
C162.534180,112.827873 163.362518,112.689178 164.402008,112.644028 
C164.613190,112.737572 165.048355,112.891907 165.088806,113.264893 
C167.415833,114.429237 169.702377,115.220604 171.988937,116.011971 
z"></path>
<path fill="#A7A6A6" opacity="1.000000" stroke="none" d="
M160.998764,117.994202 
C161.568802,118.139336 162.138824,118.284470 162.854233,118.685577 
C161.159149,119.010269 159.318710,119.078995 157.148041,118.917572 
C156.817841,118.687431 156.861282,118.292915 157.319702,118.171448 
C158.851669,118.031387 159.925217,118.012802 160.998764,117.994202 
z"></path>
<path fill="#A7A6A6" opacity="1.000000" stroke="none" d="
M178.154114,119.197273 
C179.520447,118.665596 181.023682,118.493042 182.753494,118.577026 
C181.417053,119.074509 179.854019,119.315453 178.154114,119.197273 
z"></path>
<path fill="#A7A6A6" opacity="1.000000" stroke="none" d="
M149.074890,119.062920 
C149.595886,118.595459 150.176987,118.459984 150.873932,118.555618 
C150.371536,118.989449 149.753265,119.192177 149.074890,119.062920 
z"></path>
<path fill="#474546" opacity="1.000000" stroke="none" d="
M136.961304,115.601601 
C135.780472,115.035461 134.387589,114.210526 132.924591,112.978149 
C133.215561,110.683670 133.885956,108.797371 133.890457,106.909470 
C133.942856,84.957314 133.894836,63.004925 133.789886,40.601898 
C134.218643,40.107803 134.730911,40.064400 135.628403,40.005833 
C136.319565,41.391693 136.887695,42.791897 136.892059,44.193851 
C136.955261,64.468925 136.952164,84.744232 136.939392,105.019463 
C136.937225,108.460617 136.815643,111.901688 136.961304,115.601601 
z"></path>
<path fill="#9B999A" opacity="1.000000" stroke="none" d="
M567.775452,109.709312 
C567.002625,110.139343 566.336670,110.097382 565.291260,109.786736 
C564.911682,109.518059 564.738647,109.062561 564.815430,108.629066 
C564.831482,106.441002 564.770813,104.686440 564.777466,102.529846 
C564.794434,100.773140 564.744019,99.418472 564.798584,97.601974 
C564.900024,79.433189 564.849060,61.725960 564.950745,44.019604 
C564.963989,41.711620 563.758240,38.324112 568.082336,38.121925 
C568.015686,61.827057 567.949036,85.532188 567.775452,109.709312 
z"></path>
<path fill="#383637" opacity="1.000000" stroke="none" d="
M568.331665,38.009190 
C563.758240,38.324112 564.963989,41.711620 564.950745,44.019604 
C564.849060,61.725960 564.900024,79.433189 564.462158,97.593376 
C563.346313,98.031067 562.671936,98.015533 562.001038,97.531357 
C561.971680,79.039421 561.938721,61.016129 561.934814,42.597015 
C561.986511,41.144581 562.009094,40.087975 562.031738,39.031364 
C562.997253,37.900887 563.962769,36.770420 564.975830,35.293293 
C566.341309,35.372532 567.659241,35.798428 569.066650,36.604885 
C568.964417,37.289112 568.772705,37.592785 568.331665,38.009190 
z"></path>
<path fill="#FEFDFD" opacity="1.000000" stroke="none" d="
M571.011719,41.430996 
C576.377991,42.334431 573.860229,44.521744 571.323975,46.836479 
C571.004822,45.283829 571.002258,43.571339 571.011719,41.430996 
z"></path>
<path fill="#FEFAEC" opacity="1.000000" stroke="none" d="
M100.997971,137.616669 
C99.203636,138.971786 97.411537,139.947693 95.283699,140.549332 
C94.967552,139.118805 94.987152,138.062561 95.358261,136.957672 
C98.790047,135.656433 100.306099,133.415756 100.056328,129.635681 
C100.079269,127.843727 100.087914,126.466698 100.143242,125.118607 
C100.189919,125.147530 100.298790,125.133102 100.599098,125.044586 
C100.733185,123.915932 100.566963,122.875786 100.352867,121.383575 
C100.263992,116.273933 100.223000,111.616348 100.425934,106.708733 
C101.523819,103.783829 100.434357,102.811966 97.581787,102.994644 
C96.919632,102.181641 96.480751,101.563438 96.036697,100.578247 
C93.572197,95.415710 91.073685,90.652451 85.947693,87.652985 
C84.586372,84.885094 80.611290,83.904114 82.399277,79.988556 
C84.167450,79.619125 85.574455,79.298347 86.981468,78.977562 
C83.855347,82.605232 86.106529,84.428619 89.948196,86.104492 
C92.054024,87.518631 93.526253,88.759232 94.998474,89.999825 
C94.757744,94.278275 96.697098,96.750259 100.969574,97.520882 
C103.995552,98.066681 105.542618,99.792343 106.296371,103.057625 
C108.136330,111.028351 110.046295,118.923592 108.998550,127.594543 
C104.230789,129.259277 102.257729,132.938721 100.997971,137.616669 
z"></path>
<path fill="#FAF0DB" opacity="1.000000" stroke="none" d="
M86.983475,78.732422 
C85.574455,79.298347 84.167450,79.619125 82.384628,79.974358 
C82.008820,80.008804 81.980598,79.982422 81.953781,79.649788 
C81.575760,78.497879 81.224556,77.678596 81.030098,76.603867 
C81.819176,75.592712 82.451508,74.836998 83.458847,74.033630 
C84.919952,72.985191 86.765045,72.114113 86.962029,70.961403 
C87.861763,65.696320 88.299049,60.352203 88.902512,55.036488 
C92.365463,57.698189 93.092064,62.766075 90.907570,67.267967 
C90.011269,69.115112 89.619194,71.206917 88.999329,73.595848 
C89.000916,74.004272 88.990601,74.491951 88.644859,74.795906 
C87.863579,76.065582 87.428040,77.031303 86.992508,77.997025 
C86.992508,77.997025 86.985481,78.487274 86.983475,78.732422 
z"></path>
<path fill="#FCFAF0" opacity="1.000000" stroke="none" d="
M95.339127,89.808533 
C93.526253,88.759232 92.054024,87.518631 90.291290,86.138733 
C90.240753,84.885132 90.118286,83.390526 90.800537,82.740334 
C92.004936,81.592522 93.699806,80.959366 95.189362,80.110771 
C95.792290,81.433441 96.870132,82.751457 96.883179,84.079918 
C96.901260,85.921577 96.119232,87.771095 95.339127,89.808533 
z"></path>
<path fill="#FEFAEC" opacity="1.000000" stroke="none" d="
M87.338676,77.944611 
C87.428040,77.031303 87.863579,76.065582 88.640984,75.039772 
C88.550186,75.950516 88.117523,76.921356 87.338676,77.944611 
z"></path>
<path fill="#FEFDFD" opacity="1.000000" stroke="none" d="
M67.998779,157.998169 
C69.288948,158.426636 70.579117,158.855103 71.934799,159.641479 
C69.852478,162.863419 67.704651,165.727463 65.278259,168.795746 
C64.832924,169.000046 64.666145,169.000076 64.249130,168.572021 
C64.003159,166.430252 64.007423,164.716568 64.354370,162.946014 
C65.464577,161.259811 66.232109,159.630524 67.249435,158.000259 
C67.499214,157.999298 67.998779,157.998169 67.998779,157.998169 
z"></path>
<path fill="#FEFDFD" opacity="1.000000" stroke="none" d="
M66.376152,179.921265 
C66.000427,177.958328 66.000244,175.916672 66.000336,173.437500 
C69.511826,174.922897 68.324547,177.361542 66.376152,179.921265 
z"></path>
<path fill="#FAFBF9" opacity="1.000000" stroke="none" d="
M75.045395,167.396454 
C75.286331,167.791443 75.284592,168.184357 75.254425,168.575058 
C75.253586,168.586044 74.949028,168.573563 74.786087,168.572021 
C74.790298,168.179367 74.794518,167.786713 75.045395,167.396454 
z"></path>
<path fill="#191718" opacity="1.000000" stroke="none" d="
M230.970551,38.353775 
C231.933731,37.055317 233.119080,36.089088 234.655502,35.087257 
C235.269363,35.531494 235.532150,36.011337 235.898407,37.208420 
C236.001617,40.886353 235.673584,43.899418 236.134857,46.786366 
C236.377441,48.304596 238.000336,49.602276 239.001511,51.467987 
C239.001236,58.292076 238.999405,64.647713 238.649323,71.058334 
C236.609344,72.749680 234.917603,74.386063 233.225861,76.022446 
C233.158615,65.545776 233.182999,55.067017 232.943176,44.594303 
C232.897797,42.612549 231.803192,40.654819 230.970551,38.353775 
z"></path>
<path fill="#191718" opacity="1.000000" stroke="none" d="
M232.831894,112.791740 
C232.944824,103.161118 233.057755,93.530487 233.643967,83.466011 
C234.743423,83.998108 235.894135,84.955460 235.910141,85.931419 
C236.009933,92.011116 235.802231,98.095329 235.841568,104.177109 
C235.847672,105.122276 236.597733,106.062622 237.058258,107.347870 
C238.732513,108.461563 240.352280,109.232590 242.006210,110.406357 
C242.146378,111.882759 242.252380,112.956429 241.965668,114.070473 
C238.669556,114.422119 235.766129,114.733391 232.891312,114.669434 
C232.890564,113.793388 232.861237,113.292564 232.831894,112.791740 
z"></path>
<path fill="#0F0D0E" opacity="1.000000" stroke="none" d="
M237.003769,107.005203 
C236.597733,106.062622 235.847672,105.122276 235.841568,104.177109 
C235.802231,98.095329 236.009933,92.011116 235.910141,85.931419 
C235.894135,84.955460 234.743423,83.998108 233.725677,83.002625 
C233.247818,80.943100 233.161545,78.913109 233.150574,76.452782 
C234.917603,74.386063 236.609344,72.749680 238.649200,71.526596 
C238.998245,81.959908 238.999191,91.979919 238.999466,102.416428 
C238.998474,104.221329 238.998138,105.609734 238.665436,106.998199 
C237.889969,107.000565 237.446869,107.002884 237.003769,107.005203 
z"></path>
<path fill="#C4C2C3" opacity="1.000000" stroke="none" d="
M227.096222,87.939049 
C227.851089,87.961372 228.605972,87.983688 229.739761,88.003647 
C230.266113,90.808075 230.413544,93.614853 230.295532,96.709274 
C228.657913,97.358238 227.285736,97.719566 225.399826,97.848724 
C224.565720,97.405716 224.245377,97.194878 223.987335,96.665848 
C224.115173,95.921570 224.180679,95.495499 224.637848,95.051895 
C225.718399,92.669266 226.407318,90.304161 227.096222,87.939049 
z"></path>
<path fill="#3C3A3B" opacity="1.000000" stroke="none" d="
M232.485748,112.827026 
C232.861237,113.292564 232.890564,113.793388 232.530563,114.657372 
C231.411804,115.010300 230.682373,115.000069 229.952942,114.989838 
C229.975998,114.554726 229.999054,114.119606 230.040192,113.358498 
C230.752075,112.975769 231.445847,112.919037 232.485748,112.827026 
z"></path>
<path fill="#767676" opacity="1.000000" stroke="none" d="
M374.975220,40.528149 
C375.688446,40.032047 376.441284,39.998867 377.591858,40.436756 
C378.048157,42.263802 378.106689,43.619781 378.094727,45.441216 
C377.945587,67.255547 377.866913,88.604431 377.788269,109.953308 
C377.104156,109.967514 376.420074,109.981728 375.394226,110.004395 
C375.039948,87.005592 375.027374,63.998333 374.975220,40.528149 
z"></path>
<path fill="#343233" opacity="1.000000" stroke="none" d="
M440.940338,181.940094 
C439.238617,181.974457 437.536896,182.008820 435.410614,182.082275 
C426.996246,182.069885 419.005676,182.061722 411.017273,181.926956 
C409.579895,181.902725 408.151276,181.356918 406.453156,180.726608 
C407.177917,179.597031 408.168152,178.792770 409.510864,178.035004 
C411.247345,178.383926 412.631439,178.686340 414.482788,178.994781 
C420.971008,178.986481 426.991974,178.972168 433.368408,178.913696 
C434.223602,177.879013 434.723297,176.888458 435.612183,175.919434 
C436.784424,175.896515 437.567474,175.852066 438.623871,175.927216 
C439.588501,176.404846 440.279724,176.762863 440.978271,177.523621 
C440.970490,179.264267 440.955414,180.602188 440.940338,181.940094 
z"></path>
<path fill="#383637" opacity="1.000000" stroke="none" d="
M392.974487,181.953094 
C383.463776,181.951813 373.952850,181.976288 364.442810,181.896347 
C363.743164,181.890472 363.049866,181.131027 362.370392,180.114227 
C363.574493,179.337112 364.761810,179.167694 366.354736,179.006134 
C367.838043,179.013306 368.915771,179.012589 370.431427,179.016510 
C372.911102,179.010422 374.952850,178.999725 377.432739,178.998962 
C379.917633,179.001404 381.964325,178.993896 384.428345,178.996643 
C386.984406,178.722336 390.116211,180.143677 390.247131,175.854095 
C390.642731,175.186417 390.800354,174.665787 391.271698,174.105652 
C392.006134,174.078735 392.426849,174.091339 392.965576,174.360703 
C393.431854,175.052628 393.780182,175.487808 394.072449,176.262848 
C394.031921,177.061096 394.047485,177.519470 393.779144,178.199463 
C393.321655,179.598419 393.148071,180.775757 392.974487,181.953094 
z"></path>
<path fill="#9B999A" opacity="1.000000" stroke="none" d="
M394.063049,177.977859 
C394.047485,177.519470 394.031921,177.061096 394.462646,176.287430 
C397.632141,174.614563 400.355438,173.256958 403.444305,171.936996 
C405.189575,172.355804 406.569214,172.736954 408.105164,173.449097 
C408.560455,175.182892 408.859436,176.585693 409.158417,177.988495 
C408.168152,178.792770 407.177917,179.597031 406.049194,180.698898 
C405.217194,180.966354 404.523651,180.936218 403.257355,180.575775 
C399.810760,179.489624 396.936890,178.733734 394.063049,177.977859 
z"></path>
<path fill="#4D4B4C" opacity="1.000000" stroke="none" d="
M365.949158,178.998260 
C364.761810,179.167694 363.574493,179.337112 362.147461,179.782806 
C361.485626,180.051819 361.063538,180.044601 360.324097,180.034241 
C359.994141,179.829941 359.981567,179.628769 359.975952,178.758270 
C361.821594,175.004547 361.145294,172.668930 358.174622,170.969452 
C358.944855,170.502808 359.441956,170.164413 360.358612,169.873322 
C362.180878,169.929016 363.583588,169.937393 365.202515,170.074799 
C365.615997,170.471527 365.813232,170.739227 365.994202,171.335876 
C365.995392,172.103226 366.012817,172.541611 366.010010,173.409485 
C365.976257,175.558716 365.962708,177.278503 365.949158,178.998260 
z"></path>
<path fill="#474546" opacity="1.000000" stroke="none" d="
M393.779144,178.199463 
C396.936890,178.733734 399.810760,179.489624 402.910583,180.548569 
C401.931519,181.475372 400.759644,182.525940 399.513641,182.622742 
C397.648041,182.767670 395.730316,182.242325 393.404633,181.977570 
C393.148071,180.775757 393.321655,179.598419 393.779144,178.199463 
z"></path>
<path fill="#C4C2C3" opacity="1.000000" stroke="none" d="
M310.934235,166.897171 
C308.081360,166.897171 305.228455,166.897171 302.076538,166.897171 
C302.607574,170.763062 303.532349,173.146164 307.580597,173.459152 
C306.972778,174.582336 305.987000,175.290710 304.570984,176.006927 
C302.140411,175.981049 300.140045,175.947342 298.139679,175.913635 
C298.139679,175.913635 298.081421,175.943710 297.940674,175.603333 
C297.447754,172.547562 297.095581,169.832184 297.068787,167.044952 
C299.637024,164.704926 300.385223,162.581512 296.652710,160.505356 
C296.438354,158.520477 296.375153,156.907593 296.648926,155.137268 
C298.877563,150.618622 302.661346,151.980270 305.997803,152.341446 
C305.949615,153.362900 305.904846,154.042114 305.580688,154.947891 
C302.121704,162.411026 308.768707,160.342667 311.717834,161.705963 
C311.717834,163.469635 311.717834,165.080948 311.516418,166.722382 
C311.315002,166.752502 310.934235,166.897171 310.934235,166.897171 
z"></path>
<path fill="#1B1A1A" opacity="1.000000" stroke="none" d="
M317.965088,172.004547 
C317.948883,171.592590 317.932678,171.180634 317.947662,170.063385 
C317.992615,168.571259 318.006317,167.784409 318.434387,166.998352 
C320.232910,166.999649 321.617065,167.000153 323.004944,167.367279 
C323.437317,169.057419 323.865997,170.380920 324.207886,171.436432 
C326.128632,171.279572 327.603394,170.864319 328.952728,171.117523 
C331.015656,171.504654 332.992065,172.353058 335.009399,173.386688 
C335.350311,175.482910 335.686981,177.198074 335.556030,178.940063 
C330.059753,178.975998 325.031097,178.985123 320.002441,178.569977 
C319.323334,176.098633 318.644226,174.051590 317.965088,172.004547 
z"></path>
<path fill="#383637" opacity="1.000000" stroke="none" d="
M320.002441,178.994263 
C325.031097,178.985123 330.059753,178.975998 335.969055,178.963409 
C338.231079,178.966690 339.612427,178.973419 341.287964,178.960846 
C341.720673,178.294556 341.859192,177.647583 341.996094,176.653152 
C342.026794,175.843658 342.059082,175.381622 342.443420,174.960526 
C343.918030,175.293030 345.040588,175.584610 346.410767,175.998016 
C347.084106,176.462875 347.509796,176.805908 348.018982,177.393066 
C348.387329,177.820480 348.672089,178.003754 348.956879,178.187027 
C348.529663,179.417007 348.102448,180.646973 347.329620,181.951416 
C338.313019,182.029449 329.642059,182.032990 320.507080,182.055969 
C319.255737,181.262985 318.468353,180.450546 318.159729,179.328857 
C319.093109,179.011169 319.547791,179.002716 320.002441,178.994263 
z"></path>
<path fill="#B4B2B3" opacity="1.000000" stroke="none" d="
M305.001221,175.999084 
C305.987000,175.290710 306.972778,174.582336 307.963806,173.518402 
C310.316864,173.508194 313.444275,171.087616 314.345978,175.501175 
C312.921722,176.881577 311.916199,177.838974 310.910706,178.796371 
C309.249817,178.799759 307.588898,178.803146 305.495361,178.416733 
C305.042206,177.350983 305.021729,176.675034 305.001221,175.999084 
z"></path>
<path fill="#959394" opacity="1.000000" stroke="none" d="
M304.570984,176.006927 
C305.021729,176.675034 305.042206,177.350983 305.083801,178.361969 
C303.669189,179.121628 302.233490,179.546219 300.414734,179.979919 
C299.394989,178.853958 298.758270,177.718857 298.130615,176.248703 
C300.140045,175.947342 302.140411,175.981049 304.570984,176.006927 
z"></path>
<path fill="#222021" opacity="1.000000" stroke="none" d="
M318.020050,166.997574 
C318.006317,167.784409 317.992615,168.571259 317.951538,169.754135 
C315.633331,169.322830 313.342407,168.495499 310.992859,167.282669 
C310.934235,166.897171 311.315002,166.752502 311.899719,166.853882 
C313.290253,166.946075 314.096039,166.936874 315.286041,166.958862 
C316.437592,166.989700 317.204956,166.989365 317.983704,166.991791 
C317.995117,166.994537 318.020050,166.997574 318.020050,166.997574 
z"></path>
<path fill="#222021" opacity="1.000000" stroke="none" d="
M320.002441,178.569977 
C319.547791,179.002716 319.093109,179.011169 318.296814,179.031372 
C317.956604,176.989349 317.958038,174.935562 317.962280,172.443176 
C318.644226,174.051590 319.323334,176.098633 320.002441,178.569977 
z"></path>
<path fill="#959394" opacity="1.000000" stroke="none" d="
M311.284851,178.870056 
C311.916199,177.838974 312.921722,176.881577 314.271301,175.884338 
C313.629883,176.877594 312.644440,177.910660 311.284851,178.870056 
z"></path>
<path fill="#1B1A1A" opacity="1.000000" stroke="none" d="
M272.295288,55.502243 
C273.045166,55.058834 273.659912,55.051434 274.623596,55.511406 
C275.011749,72.175629 274.929230,88.374641 275.217926,104.567039 
C275.262451,107.065704 277.011383,109.533974 277.972076,112.016296 
C277.972076,112.016296 278.112305,112.401772 278.167786,112.599205 
C277.331696,113.727379 276.440155,114.658112 274.935089,115.794540 
C273.573273,115.978691 272.825043,115.957169 272.022125,115.507263 
C271.992828,113.068977 272.018188,111.059067 272.044922,108.576469 
C272.031464,96.402397 272.016632,84.701004 272.265686,72.736488 
C272.369415,70.316452 272.209290,68.159546 272.049194,66.002640 
C272.086182,62.647846 272.123169,59.293049 272.295288,55.502243 
z"></path>
<path fill="#605F60" opacity="1.000000" stroke="none" d="
M278.124573,111.566559 
C277.011383,109.533974 275.262451,107.065704 275.217926,104.567039 
C274.929230,88.374641 275.011749,72.175629 274.931732,55.516647 
C275.684814,54.045197 276.478729,53.035877 277.649109,52.023182 
C278.054840,52.801788 278.084106,53.583767 278.191162,54.750568 
C278.233246,56.824757 278.197510,58.514130 278.283295,60.611927 
C278.362213,77.719177 278.319641,94.418007 278.124573,111.566559 
z"></path>
<path fill="#131213" opacity="1.000000" stroke="none" d="
M507.877808,149.412003 
C512.268311,148.986740 516.689453,148.973633 521.579346,148.917847 
C522.535645,148.907104 523.050354,149.057541 523.506653,148.952942 
C529.147888,147.659897 532.400391,150.005707 534.021912,155.416351 
C534.264038,156.224365 536.075256,156.562180 537.582031,157.085419 
C538.003540,157.053345 538.107117,156.953247 538.079529,157.237549 
C538.373352,157.617569 538.694763,157.713272 539.344238,157.863953 
C540.114624,157.946213 540.557068,157.973526 541.022461,158.329208 
C541.086365,159.093414 541.127136,159.529251 541.083069,160.342682 
C541.640930,161.820221 542.283752,162.920166 542.844971,164.378052 
C542.906677,167.801453 543.049927,170.866928 543.035706,174.253693 
C542.860229,175.050919 542.842163,175.526886 542.486450,176.026154 
C541.733154,176.992538 541.317627,177.935608 540.472656,178.908142 
C538.032104,178.949295 536.020935,178.960968 534.007019,178.538300 
C534.003601,176.069901 534.003052,174.035873 534.000854,171.585770 
C532.768616,161.395935 528.180298,154.487167 518.147583,152.694107 
C516.825012,157.027390 515.304382,161.553497 514.144897,166.170258 
C513.895508,167.163071 514.916565,168.497955 515.413818,169.648743 
C515.903564,170.782166 516.471313,171.881912 517.009766,173.381073 
C517.342285,175.507950 517.669922,177.250198 517.577271,178.999695 
C514.835205,179.158417 512.867737,179.064224 512.888123,175.810577 
C512.893921,174.892899 511.625275,173.967239 510.941162,173.045242 
C510.241272,172.043015 509.541351,171.040771 508.409912,169.570465 
C508.397644,167.307938 509.142670,165.512680 509.138245,163.719299 
C509.134369,162.146851 508.289429,160.576477 507.819336,158.583710 
C507.854401,156.483429 507.886169,154.804626 507.917938,153.125824 
C507.914764,152.025269 507.911621,150.924713 507.877808,149.412003 
z"></path>
<path fill="#343233" opacity="1.000000" stroke="none" d="
M534.009827,178.972656 
C536.020935,178.960968 538.032104,178.949295 540.507324,179.289734 
C540.970947,180.399582 540.970459,181.157318 540.969910,181.915054 
C540.969910,181.915054 540.479126,181.971848 540.235901,182.015717 
C539.551636,182.046143 539.110657,182.032715 538.339844,182.049973 
C528.974365,182.080658 519.938599,182.080658 510.931641,182.080658 
C510.931641,178.395950 510.931641,176.139496 510.936401,173.464142 
C511.625275,173.967239 512.893921,174.892899 512.888123,175.810577 
C512.867737,179.064224 514.835205,179.158417 518.044373,179.007690 
C523.957825,178.996506 528.983826,178.984573 534.009827,178.972656 
z"></path>
<path fill="#D2D0D1" opacity="1.000000" stroke="none" d="
M507.083313,169.969360 
C507.137207,170.456482 507.220520,170.942474 507.239380,171.430939 
C507.293182,172.823441 507.320740,174.216934 507.358643,175.610046 
C505.869690,175.396545 504.380737,175.183060 502.314087,174.672729 
C499.832306,172.545090 497.928253,170.714310 496.031128,168.667633 
C496.037994,168.451752 495.949097,168.029037 496.302856,167.868713 
C498.449371,167.470657 500.242126,167.232910 502.399292,166.989746 
C503.250275,166.962463 503.736816,166.940598 504.338654,167.246140 
C505.330414,168.372162 506.206848,169.170761 507.083313,169.969360 
z"></path>
<path fill="#0F0D0E" opacity="1.000000" stroke="none" d="
M496.024231,168.883514 
C497.928253,170.714310 499.832306,172.545090 501.926880,174.679352 
C501.877289,176.062622 501.637177,177.142395 501.017670,178.616440 
C499.748932,179.051208 498.859589,179.091690 497.592957,179.080231 
C496.610291,178.122391 495.518707,177.229752 495.493561,176.308014 
C495.426239,173.839844 495.816040,171.359207 496.024231,168.883514 
z"></path>
<path fill="#535253" opacity="1.000000" stroke="none" d="
M507.349792,169.957642 
C506.206848,169.170761 505.330414,168.372162 504.381989,166.891113 
C505.478668,163.807526 506.647369,161.406357 507.816040,159.005188 
C508.289429,160.576477 509.134369,162.146851 509.138245,163.719299 
C509.142670,165.512680 508.397644,167.307938 508.063599,169.509552 
C507.971313,169.926468 507.793762,169.936203 507.349792,169.957642 
z"></path>
<path fill="#343233" opacity="1.000000" stroke="none" d="
M497.970276,179.132187 
C498.859589,179.091690 499.748932,179.051208 501.091217,178.963882 
C503.159515,179.044968 504.774841,179.172882 506.390137,179.300797 
C506.467438,179.855240 506.544739,180.409683 506.622040,180.964111 
C504.025452,181.322464 501.428864,181.680832 498.399597,182.057343 
C497.966919,182.075485 498.001465,182.001022 498.004028,181.614578 
C497.994476,180.529480 497.982391,179.830841 497.970276,179.132187 
z"></path>
<path fill="#C6AD7D" opacity="1.000000" stroke="none" d="
M53.124763,179.047104 
C51.328236,180.301056 49.531708,181.554993 47.091385,182.802887 
C43.936771,181.862930 41.425957,180.929047 38.978596,179.696487 
C39.084267,178.997147 39.126480,178.596481 39.200073,178.197784 
C39.231449,178.199783 39.294029,178.205917 39.632744,178.139587 
C41.267750,177.653732 42.564045,177.234192 44.105782,177.114594 
C46.913219,176.940674 49.475212,176.466827 52.265724,176.118347 
C52.696224,176.524780 52.898209,176.805878 53.068504,177.413727 
C53.066135,178.175995 53.095448,178.611557 53.124763,179.047104 
z"></path>
<path fill="#F8E7CD" opacity="1.000000" stroke="none" d="
M53.517509,179.033844 
C53.095448,178.611557 53.066135,178.175995 53.380402,177.248810 
C57.639736,174.215012 58.355236,170.333405 58.483181,166.013977 
C59.525948,165.293396 60.276726,164.642532 61.285942,164.223053 
C61.382168,166.308441 61.219948,168.162445 61.057732,170.016449 
C61.041553,170.761490 61.025372,171.506531 61.013039,172.624084 
C60.127861,174.127396 59.394184,175.446579 58.309917,176.340576 
C56.998394,177.421951 55.390114,178.143387 53.517509,179.033844 
z"></path>
<path fill="#D8BE96" opacity="1.000000" stroke="none" d="
M38.915138,179.995148 
C41.425957,180.929047 43.936771,181.862930 46.801147,182.973862 
C43.987705,186.485321 40.370567,185.033112 36.400097,184.137527 
C35.344204,183.655579 34.692738,183.160339 34.015007,182.327148 
C34.559002,181.846771 35.129253,181.704346 36.185402,181.277679 
C37.419239,180.660660 38.167187,180.327896 38.915138,179.995148 
z"></path>
<path fill="#383637" opacity="1.000000" stroke="none" d="
M207.170212,90.970825 
C211.637253,90.819336 212.371429,93.581680 212.047592,97.603195 
C212.504974,101.472343 211.274872,103.523682 206.973450,103.711365 
C206.263809,103.980064 206.085251,103.969788 205.932602,103.534149 
C206.362411,99.062790 206.766312,95.016808 207.170212,90.970825 
z"></path>
<path fill="#222021" opacity="1.000000" stroke="none" d="
M224.246216,95.069420 
C224.180679,95.495499 224.115173,95.921570 223.717224,96.726944 
C223.192551,97.412888 223.000336,97.719536 222.808105,98.026184 
C222.198105,97.252876 221.588120,96.479576 220.962631,95.366226 
C220.811859,92.727425 220.676605,90.428680 220.541351,88.129929 
C220.129639,88.085655 219.717941,88.041382 219.306229,87.997108 
C218.387024,89.226326 217.467804,90.455544 216.496338,91.754646 
C214.352371,88.198952 214.650131,87.086708 217.901199,86.028389 
C219.002869,85.669769 220.127350,85.381218 221.627350,85.028358 
C222.013306,84.996063 222.441650,85.009171 222.821106,85.437469 
C223.549103,88.933655 223.897659,92.001541 224.246216,95.069420 
z"></path>
<path fill="#A7A6A6" opacity="1.000000" stroke="none" d="
M194.370544,112.898758 
C193.283310,113.054619 192.528275,113.025696 190.991547,112.980034 
C189.412613,112.830254 188.615387,112.697205 187.965103,112.381676 
C188.094223,111.505226 188.076385,110.811256 188.499420,110.098328 
C191.933716,109.621506 194.945724,109.257912 197.914047,108.672722 
C199.905106,108.280212 201.834229,107.573502 203.907974,106.891174 
C204.024887,106.774979 204.303558,106.951111 204.303558,106.951111 
C203.912460,107.712791 203.521378,108.474480 202.989944,109.533325 
C200.133987,110.791641 197.418381,111.752808 194.370544,112.898758 
z"></path>
<path fill="#1B1A1A" opacity="1.000000" stroke="none" d="
M205.906693,103.959518 
C206.085251,103.969788 206.263809,103.980064 206.710358,103.984772 
C207.554062,104.673973 208.129791,105.368744 209.118011,106.561310 
C207.393814,106.746445 206.211212,106.873421 204.666092,106.975754 
C204.303558,106.951111 204.024887,106.774979 204.033325,106.588089 
C204.663406,105.587303 205.285049,104.773407 205.906693,103.959518 
z"></path>
<path fill="#A0A0A0" opacity="1.000000" stroke="none" d="
M222.890900,98.334152 
C223.000336,97.719536 223.192551,97.412888 223.654907,97.045143 
C224.245377,97.194878 224.565720,97.405716 225.041870,97.867828 
C224.649857,98.712006 224.102051,99.304909 223.218384,99.903000 
C222.912918,99.486168 222.943298,99.064148 222.890900,98.334152 
z"></path>
<path fill="#222021" opacity="1.000000" stroke="none" d="
M565.023560,178.964264 
C564.261353,178.977524 563.499084,178.990799 562.365112,178.562927 
C561.732361,176.535385 561.636108,174.898331 561.168030,173.375580 
C560.600220,171.528473 559.718323,169.777924 558.979126,167.595245 
C559.452209,166.076141 559.848572,164.912018 560.398193,163.825394 
C561.895874,160.864502 563.458374,157.936386 565.405029,155.009155 
C570.742859,157.708450 566.551697,162.089233 568.168640,166.659149 
C569.350769,165.329712 570.131165,164.359329 571.010437,163.488907 
C571.912659,162.595840 572.911682,161.800507 573.899780,161.400543 
C573.664612,164.568329 573.398010,167.297958 572.695068,170.019257 
C565.945374,169.842453 568.055542,176.481064 565.023560,178.964264 
z"></path>
<path fill="#0F0D0E" opacity="1.000000" stroke="none" d="
M564.995117,154.995285 
C563.458374,157.936386 561.895874,160.864502 560.398193,163.825394 
C559.848572,164.912018 559.452209,166.076141 558.593994,167.655334 
C553.948181,171.001678 556.908813,175.372711 555.984985,178.949524 
C555.544067,178.958496 555.103088,178.967484 554.221558,178.760422 
C553.583923,178.282944 553.329956,178.101166 553.018921,177.999054 
C553.004089,172.595398 552.989319,167.191757 552.958679,160.966187 
C552.985291,159.453049 553.027710,158.761826 553.439575,158.025055 
C554.578247,156.994904 555.347473,156.010330 556.116638,155.025757 
C559.292358,155.146851 562.308533,155.151215 562.142517,150.580658 
C562.124390,150.080597 563.487366,149.530380 564.608398,149.429718 
C565.003113,151.569092 564.999084,153.282196 564.995117,154.995285 
z"></path>
<path fill="#3F3D3E" opacity="1.000000" stroke="none" d="
M573.131470,170.027588 
C573.398010,167.297958 573.664612,164.568329 573.934204,160.955200 
C574.870605,156.711029 571.781860,152.317566 576.539062,149.938705 
C577.054688,150.725174 577.570312,151.511642 578.033752,153.033081 
C577.978821,160.511932 577.976074,167.255829 577.973267,173.999710 
C577.975586,174.451294 577.977905,174.902863 577.923950,175.688965 
C576.843140,176.015198 575.818665,176.006912 574.390747,175.610428 
C573.702087,173.490692 573.416748,171.759140 573.131470,170.027588 
z"></path>
<path fill="#161415" opacity="1.000000" stroke="none" d="
M572.695068,170.019257 
C573.416748,171.759140 573.702087,173.490692 574.005249,175.605957 
C573.115479,181.850723 568.560791,177.834686 565.468506,178.974213 
C568.055542,176.481064 565.945374,169.842453 572.695068,170.019257 
z"></path>
<path fill="#3F3D3E" opacity="1.000000" stroke="none" d="
M541.309692,181.948212 
C540.970459,181.157318 540.970947,180.399582 540.936768,179.260254 
C541.317627,177.935608 541.733154,176.992538 542.858398,176.160950 
C545.353210,176.507553 547.138367,176.742691 548.923523,176.977844 
C549.286621,178.131577 549.649658,179.285309 550.019165,180.839142 
C548.274841,181.822205 546.524048,182.405136 544.388306,182.979980 
C543.218689,182.641724 542.434021,182.311554 541.309692,181.948212 
z"></path>
<path fill="#161415" opacity="1.000000" stroke="none" d="
M556.413452,178.969543 
C556.908813,175.372711 553.948181,171.001678 558.587219,168.045670 
C559.718323,169.777924 560.600220,171.528473 561.168030,173.375580 
C561.636108,174.898331 561.732361,176.535385 561.984192,178.541443 
C560.263977,178.970596 558.552979,178.980103 556.413452,178.969543 
z"></path>
<path fill="#A0A0A0" opacity="1.000000" stroke="none" d="
M548.923584,176.555634 
C547.138367,176.742691 545.353210,176.507553 543.196045,176.137634 
C542.842163,175.526886 542.860229,175.050919 543.418030,174.243713 
C548.892883,172.194992 546.878113,167.062714 549.162476,163.827698 
C549.836182,163.761505 550.223877,163.769852 550.611572,163.778198 
C550.048889,167.896606 549.486267,172.015015 548.923584,176.555634 
z"></path>
<path fill="#FAFBF9" opacity="1.000000" stroke="none" d="
M550.628113,163.431259 
C550.223877,163.769852 549.836182,163.761505 548.754883,163.828003 
C546.692200,163.936569 545.323059,163.970306 543.955261,163.627701 
C543.968079,162.499146 543.979553,161.746933 544.403442,160.992859 
C546.429626,161.047638 548.043396,161.104263 549.935120,161.197693 
C550.383301,161.157166 550.553467,161.079834 550.723694,161.002502 
C550.697388,161.696442 550.671021,162.390381 550.628113,163.431259 
z"></path>
<path fill="#B4B2B3" opacity="1.000000" stroke="none" d="
M551.087585,161.013702 
C550.553467,161.079834 550.383301,161.157166 550.057739,160.807739 
C549.919189,159.575226 549.936035,158.769455 550.305176,157.984589 
C551.346863,157.912857 552.036316,157.820206 552.811890,157.813263 
C552.898071,157.898956 553.070190,158.070618 553.070190,158.070618 
C553.027710,158.761826 552.985291,159.453049 552.917908,160.489792 
C552.412476,160.898514 551.932007,160.961716 551.087585,161.013702 
z"></path>
<path fill="#161415" opacity="1.000000" stroke="none" d="
M553.110840,178.223679 
C553.329956,178.101166 553.583923,178.282944 553.890747,178.754166 
C553.663696,178.901932 553.397766,178.730057 553.110840,178.223679 
z"></path>
<path fill="#191718" opacity="1.000000" stroke="none" d="
M576.442017,149.679962 
C571.781860,152.317566 574.870605,156.711029 573.902710,160.517059 
C572.911682,161.800507 571.912659,162.595840 571.010437,163.488907 
C570.131165,164.359329 569.350769,165.329712 568.168640,166.659149 
C566.551697,162.089233 570.742859,157.708450 565.405029,155.009155 
C564.999084,153.282196 565.003113,151.569092 565.003540,149.427704 
C568.356262,148.999222 571.712585,148.999054 575.525452,149.001617 
C576.103027,149.143280 576.223999,149.282242 576.442017,149.679962 
z"></path>
<path fill="#717071" opacity="1.000000" stroke="none" d="
M578.375244,173.916809 
C577.976074,167.255829 577.978821,160.511932 578.087402,153.350830 
C578.634766,153.148117 579.466858,153.372955 579.461487,153.575638 
C579.282776,160.329697 579.024170,167.081635 578.375244,173.916809 
z"></path>
<path fill="#C6AD7D" opacity="1.000000" stroke="none" d="
M90.845932,142.859772 
C89.497459,143.910339 88.148979,144.960907 86.081848,146.018341 
C84.262726,144.732681 83.162262,143.440125 82.237381,141.967743 
C82.578758,141.500214 82.744537,141.212509 83.241524,140.812164 
C87.446617,137.608521 89.031013,133.534454 89.019676,128.640732 
C89.016098,127.095810 89.643051,125.549416 90.261452,124.243195 
C91.714920,126.669540 92.888374,128.856400 94.056976,131.269928 
C94.052116,131.496613 94.022621,131.949097 94.022766,132.270126 
C94.040215,133.015686 94.057510,133.440231 93.727242,133.959991 
C92.669769,135.768936 91.959854,137.482635 91.173767,139.558975 
C91.013702,140.900986 90.929817,141.880386 90.845932,142.859772 
z"></path>
<path fill="#EEDABC" opacity="1.000000" stroke="none" d="
M74.243080,154.659698 
C72.280708,154.924362 70.569321,154.934464 68.212936,154.695099 
C66.054848,154.639023 64.541771,154.832397 62.696487,155.017822 
C61.922405,155.014038 61.480522,155.018204 61.028816,154.588303 
C61.049881,152.410538 61.080772,150.666840 61.534668,148.939026 
C64.641083,148.652817 67.324486,148.350708 70.007080,148.453751 
C70.048180,153.853592 73.817726,151.418640 76.470093,151.926605 
C77.541168,151.946960 78.254868,151.963364 78.968567,151.979782 
C77.477066,152.788239 75.985565,153.596695 74.243080,154.659698 
z"></path>
<path fill="#DEC799" opacity="1.000000" stroke="none" d="
M79.214729,151.963104 
C78.254868,151.963364 77.541168,151.946960 76.430984,151.501923 
C76.044060,149.077255 76.053627,147.081177 76.363281,144.928040 
C78.462845,143.896500 80.262321,143.022034 82.061798,142.147583 
C83.162262,143.440125 84.262726,144.732681 85.744995,146.064026 
C85.345360,147.068512 84.563927,148.034210 83.125137,149.123962 
C81.629379,150.133499 80.790993,151.018982 79.952591,151.904465 
C79.952591,151.904465 79.460899,151.946411 79.214729,151.963104 
z"></path>
<path fill="#EEDABC" opacity="1.000000" stroke="none" d="
M91.233643,142.896378 
C90.929817,141.880386 91.013702,140.900986 91.464020,139.450928 
C92.823280,138.261230 93.816109,137.542236 94.858391,136.869003 
C94.907837,136.914764 95.006752,137.006302 95.006752,137.006302 
C94.987152,138.062561 94.967552,139.118805 94.933861,140.570038 
C94.616486,141.423050 94.313210,141.881104 93.977097,142.621246 
C93.169968,142.913223 92.395660,142.923111 91.233643,142.896378 
z"></path>
<path fill="#E9D7B9" opacity="1.000000" stroke="none" d="
M80.327209,151.941299 
C80.790993,151.018982 81.629379,150.133499 82.789581,149.162460 
C82.308197,150.043991 81.505013,151.011063 80.327209,151.941299 
z"></path>
<path fill="#E6E6E6" opacity="1.000000" stroke="none" d="
M31.741299,178.979507 
C32.725674,179.794540 33.464909,180.619171 34.091156,181.718460 
C33.234169,182.000412 32.490162,182.007706 31.374218,182.026688 
C31.000605,181.278458 30.998932,180.518539 31.001438,179.379303 
C31.005617,178.999985 31.496157,178.989120 31.741299,178.979507 
z"></path>
<path fill="#FDFDFD" opacity="1.000000" stroke="none" d="
M446.986633,104.939026 
C446.319153,105.043793 445.651703,105.148544 444.633362,105.702255 
C443.198090,106.507477 442.113678,106.863747 440.834015,106.924484 
C437.926025,107.430969 435.252777,108.715889 432.492859,108.941818 
C425.072845,109.549187 417.616974,109.928986 410.173065,109.968559 
C404.069366,110.000999 397.962341,109.414841 391.555969,109.077179 
C390.857574,108.998711 390.460022,108.945953 390.028442,108.675919 
C389.994415,108.458649 389.935516,108.022766 390.297913,107.875710 
C391.435242,105.933472 392.869080,104.143860 392.884308,102.342255 
C393.059631,81.589310 393.019867,60.834553 393.402496,40.026020 
C394.503876,40.012280 395.240021,40.052795 395.993805,40.459213 
C398.186249,44.755455 399.720886,43.377605 401.462036,40.169334 
C403.578674,40.125244 405.274017,40.130371 406.996155,40.542381 
C408.775299,41.630558 410.569916,42.990891 412.273254,42.884853 
C424.968079,42.094551 436.324097,45.468311 446.483887,53.069439 
C447.925446,54.147987 449.518036,55.024666 450.905670,56.288071 
C450.522430,57.387245 450.274231,58.193119 449.665771,59.065502 
C448.814667,60.457054 448.323822,61.782101 447.638489,63.632149 
C450.032288,62.904121 451.514923,62.453201 453.001343,62.416279 
C456.839264,69.101677 455.818329,76.090996 456.046021,82.911964 
C456.093231,84.326347 456.715027,85.721550 456.913757,87.445068 
C455.538361,89.845001 454.321198,91.925468 452.716980,94.008453 
C450.884979,95.339127 449.440063,96.667274 447.603088,97.997765 
C446.207520,98.506081 445.203979,99.012062 444.200439,99.518036 
C444.741669,99.981644 445.282867,100.445251 445.824097,100.908859 
C446.551025,99.940758 447.277954,98.972664 448.367157,98.003738 
C449.694092,98.005585 450.658783,98.008255 451.695374,98.356163 
C451.799591,99.405472 451.831879,100.109535 451.546997,100.905418 
C450.812378,101.650032 450.394989,102.302834 449.667175,103.030212 
C448.566681,103.716209 447.776672,104.327614 446.986633,104.939026 
z"></path>
<path fill="#D2D0D1" opacity="1.000000" stroke="none" d="
M391.856812,109.102890 
C397.962341,109.414841 404.069366,110.000999 410.173065,109.968559 
C417.616974,109.928986 425.072845,109.549187 432.492859,108.941818 
C435.252777,108.715889 437.926025,107.430969 440.727173,107.229492 
C439.901276,109.230545 438.987000,110.631081 438.072693,112.031616 
C436.315460,112.353172 434.558228,112.674721 432.419647,113.073410 
C430.654327,113.112373 429.270325,113.074196 427.057617,113.052628 
C424.834961,113.118713 423.441040,113.168190 421.583435,113.122795 
C413.080505,112.989372 405.041229,112.950821 397.001953,112.912270 
C396.650574,112.799103 396.299225,112.685944 395.645630,112.619263 
C395.343414,112.665741 395.279846,112.644135 395.173462,112.347794 
C393.997009,111.068596 392.926910,110.085739 391.856812,109.102890 
z"></path>
<path fill="#4D4B4C" opacity="1.000000" stroke="none" d="
M438.442322,112.020714 
C438.987000,110.631081 439.901276,109.230545 440.922394,107.525009 
C442.113678,106.863747 443.198090,106.507477 444.606384,106.006371 
C445.368805,110.376823 441.197052,110.304359 438.442322,112.020714 
z"></path>
<path fill="#110F10" opacity="1.000000" stroke="none" d="
M453.760071,100.902664 
C454.304108,100.941620 454.848114,100.980568 455.800446,101.047806 
C456.458801,101.833839 456.708832,102.591583 456.908997,103.653427 
C455.866852,103.992088 454.874664,104.026642 453.429779,103.997559 
C452.977112,103.933922 452.842133,103.745689 452.882996,103.411125 
C452.952087,102.389755 452.980286,101.702950 453.192200,100.959930 
C453.375885,100.903725 453.760071,100.902664 453.760071,100.902664 
z"></path>
<path fill="#605F60" opacity="1.000000" stroke="none" d="
M447.143280,105.212021 
C447.776672,104.327614 448.566681,103.716209 449.701965,103.320694 
C450.066803,103.730637 450.086395,103.924698 450.043274,104.430321 
C449.900116,105.157280 449.819641,105.572670 449.739136,105.988068 
C448.926086,105.820389 448.113007,105.652710 447.143280,105.212021 
z"></path>
<path fill="#070506" opacity="1.000000" stroke="none" d="
M450.123718,106.009766 
C449.819641,105.572670 449.900116,105.157280 450.384033,104.367455 
C451.472351,103.910576 452.157227,103.828133 452.842133,103.745689 
C452.842133,103.745689 452.977112,103.933922 453.057739,104.019623 
C452.261688,104.747368 451.385010,105.389420 450.123718,106.009766 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M248.203018,41.042000 
C250.317398,40.695934 252.422302,40.134876 254.547821,40.041050 
C263.713226,39.636467 266.007874,41.879150 266.005554,50.946342 
C266.001251,67.742439 265.937042,84.539139 266.091980,101.333794 
C266.109100,103.189713 267.390564,105.033974 268.530365,107.335434 
C269.121613,108.273651 269.268097,108.760078 269.315125,109.261551 
C269.215698,109.276596 269.147797,109.087280 268.782593,109.092896 
C267.606323,110.366318 266.795288,111.634117 265.732788,113.057861 
C265.015442,113.820030 264.549530,114.426262 264.083618,115.032486 
C256.916962,118.305161 253.688995,111.616692 248.433334,108.526306 
C248.077545,88.378624 248.136230,68.712769 248.545090,49.025227 
C249.537064,48.454956 250.178894,47.906372 250.820724,47.357788 
C249.917038,46.895504 249.013367,46.433216 248.066895,45.560726 
C248.083740,43.781013 248.143387,42.411503 248.203018,41.042000 
z"></path>
<path fill="#BBBBBB" opacity="1.000000" stroke="none" d="
M248.194931,49.046913 
C248.136230,68.712769 248.077545,88.378624 248.061127,108.509102 
C248.068085,109.745445 248.032745,110.517159 247.969147,111.674622 
C247.252670,112.020569 246.564468,111.980766 245.507355,111.426826 
C245.171127,87.308998 245.203796,63.705292 245.506851,39.909859 
C246.537506,39.192875 247.297760,38.667625 248.058014,38.142372 
C248.069351,38.868847 248.080688,39.595325 248.147522,40.681900 
C248.143387,42.411503 248.083740,43.781013 248.026398,45.945183 
C248.084106,47.508869 248.139526,48.277889 248.194931,49.046913 
z"></path>
<path fill="#656364" opacity="1.000000" stroke="none" d="
M272.043518,109.049149 
C272.018188,111.059067 271.992828,113.068977 271.986450,115.838371 
C272.021698,117.039925 272.037994,117.482002 271.766327,117.979874 
C270.994324,118.303444 270.510345,118.571220 270.026367,118.839005 
C269.323975,118.757034 268.621582,118.675064 267.522949,118.192032 
C267.770355,115.891159 268.413971,113.991348 269.183472,111.728081 
C269.255493,110.605499 269.201660,109.846390 269.147827,109.087280 
C269.147797,109.087280 269.215698,109.276596 269.643188,109.233269 
C270.728302,109.143013 271.385925,109.096085 272.043518,109.049149 
z"></path>
<path fill="#959394" opacity="1.000000" stroke="none" d="
M269.057587,112.091545 
C268.413971,113.991348 267.770355,115.891159 267.175598,118.127182 
C266.148651,117.562271 265.072815,116.661148 264.040283,115.396255 
C264.549530,114.426262 265.015442,113.820030 266.059143,113.106659 
C267.443848,112.696861 268.250702,112.394203 269.057587,112.091545 
z"></path>
<path fill="#3F3D3E" opacity="1.000000" stroke="none" d="
M272.054321,117.924072 
C272.037994,117.482002 272.021698,117.039925 272.041077,116.266754 
C272.825043,115.957169 273.573273,115.978691 274.696411,116.038910 
C275.024445,116.655083 274.977600,117.232574 274.911774,118.100235 
C274.186798,118.471359 273.480804,118.552322 272.486847,118.456314 
C272.198883,118.279343 272.054321,117.924072 272.054321,117.924072 
z"></path>
<path fill="#959394" opacity="1.000000" stroke="none" d="
M278.123169,119.073883 
C278.845001,118.521141 279.643188,118.271820 280.773743,118.058701 
C280.137238,118.522369 279.168365,118.949829 278.123169,119.073883 
z"></path>
<path fill="#959394" opacity="1.000000" stroke="none" d="
M271.766327,117.979874 
C272.054321,117.924072 272.198883,118.279343 272.128601,118.457443 
C271.444397,118.916359 270.830505,119.197166 270.121460,119.158493 
C270.510345,118.571220 270.994324,118.303444 271.766327,117.979874 
z"></path>
<path fill="#201E1F" opacity="1.000000" stroke="none" d="
M561.997498,98.000000 
C562.671936,98.015533 563.346313,98.031067 564.357178,98.055199 
C564.744019,99.418472 564.794434,100.773140 564.404907,102.561691 
C560.962891,105.752251 561.183472,107.482056 564.738647,109.062561 
C564.738647,109.062561 564.911682,109.518059 564.958130,109.757217 
C565.784729,114.861038 563.331421,116.406570 558.895935,116.023430 
C557.278320,115.883698 555.644653,115.930580 553.574097,115.885651 
C553.129944,115.878983 552.983398,115.477043 552.892151,115.283508 
C552.875854,114.634911 552.950867,114.179863 553.425415,113.323288 
C554.383606,111.637566 555.558838,109.315414 555.407959,109.225769 
C553.340088,107.997475 551.089172,107.077293 548.883301,106.081360 
C550.142273,104.238792 551.401245,102.396233 552.758301,99.841347 
C552.961182,98.096016 553.065979,97.063004 553.458984,95.844559 
C553.555664,94.428474 553.364197,93.197830 553.095459,91.506294 
C553.052612,77.371178 553.086914,63.696949 553.440063,49.786915 
C553.562805,47.022911 553.366760,44.494713 553.075867,41.629532 
C552.818359,40.858879 552.655762,40.425213 552.670349,40.000980 
C552.847595,40.010414 553.200745,40.046688 553.548218,40.030945 
C555.245667,41.023075 556.595581,42.030949 557.950195,43.433617 
C557.307373,45.799591 556.116760,47.765686 556.098206,49.742744 
C555.944092,66.200073 556.039307,82.659554 555.977783,99.118080 
C555.963745,102.867874 557.345215,103.878639 560.468262,102.337677 
C560.154663,101.937881 559.841064,101.538086 559.527527,101.138298 
C560.350830,100.092201 561.174194,99.046104 561.997498,98.000000 
z"></path>
<path fill="#959394" opacity="1.000000" stroke="none" d="
M553.170715,96.029991 
C553.065979,97.063004 552.961182,98.096016 552.777954,99.510712 
C552.028442,99.926186 551.357361,99.959961 550.349487,100.000404 
C549.978455,80.373032 549.944214,60.738995 550.184204,40.614479 
C551.136658,40.079857 551.814880,40.035702 552.493103,39.991547 
C552.655762,40.425213 552.818359,40.858879 552.759644,41.875587 
C552.732605,44.979992 552.926941,47.501354 553.121277,50.022720 
C553.086914,63.696949 553.052612,77.371178 552.802002,91.700966 
C552.780701,93.581009 552.975708,94.805496 553.170715,96.029991 
z"></path>
<path fill="#343233" opacity="1.000000" stroke="none" d="
M549.072754,106.378700 
C551.089172,107.077293 553.340088,107.997475 555.407959,109.225769 
C555.558838,109.315414 554.383606,111.637566 553.479919,112.981346 
C551.844055,110.919296 550.553162,108.797668 549.072754,106.378700 
z"></path>
<path fill="#DDDCDC" opacity="1.000000" stroke="none" d="
M541.299255,106.827995 
C540.321960,102.585823 544.218262,102.658531 546.744629,101.082207 
C545.297485,103.010483 543.477783,104.933731 541.299255,106.827995 
z"></path>
<path fill="#343233" opacity="1.000000" stroke="none" d="
M485.979858,181.909851 
C477.312042,181.933090 468.644226,181.956345 459.495544,181.598053 
C458.367371,180.138306 457.720032,179.060074 457.031616,177.567398 
C457.014435,175.764847 457.038361,174.376724 457.402893,172.946960 
C458.151886,171.948822 458.560272,170.992310 459.343506,170.034256 
C460.185455,170.992371 461.280060,172.191635 461.019714,172.873566 
C459.038818,178.061844 459.540588,178.967239 465.024261,179.002029 
C469.352936,179.029480 473.682037,178.987930 478.399017,178.985825 
C480.199127,178.668793 481.611206,178.342941 483.028625,177.546707 
C483.040192,173.245682 482.904816,169.408051 483.094025,165.586456 
C483.269043,162.051971 483.791656,158.534698 484.455261,155.279221 
C485.300140,158.336014 485.850952,161.123444 486.411377,164.375046 
C486.338470,168.455200 486.256042,172.071182 486.122711,176.131775 
C486.041168,178.354218 486.010529,180.132034 485.979858,181.909851 
z"></path>
<path fill="#3C3A3B" opacity="1.000000" stroke="none" d="
M486.366089,181.944885 
C486.010529,180.132034 486.041168,178.354218 486.397461,176.321320 
C487.510162,176.492294 488.332886,177.339966 489.078461,177.276825 
C494.392853,176.826736 493.185883,172.312317 494.288757,168.986847 
C495.023041,168.612534 495.486084,168.320786 495.949097,168.029053 
C495.949097,168.029037 496.037994,168.451752 496.031128,168.667633 
C495.816040,171.359207 495.426239,173.839844 495.493561,176.308014 
C495.518707,177.229752 496.610291,178.122391 497.592957,179.080231 
C497.982391,179.830841 497.994476,180.529480 498.021271,181.577362 
C496.821777,182.092102 495.607574,182.257645 494.135284,182.688019 
C493.459259,182.960098 493.041321,182.967331 492.309784,182.998749 
C490.248230,182.675293 488.500275,182.327606 486.366089,181.944885 
z"></path>
<path fill="#4D4B4C" opacity="1.000000" stroke="none" d="
M457.072693,177.981842 
C457.720032,179.060074 458.367371,180.138306 459.029968,181.582733 
C458.594055,181.975433 458.142853,182.001938 457.353851,182.048248 
C451.961182,182.043106 446.906342,182.018158 441.395935,181.966660 
C440.955414,180.602188 440.970490,179.264267 441.296448,177.483765 
C442.033508,177.066544 442.459747,177.091949 443.153809,177.398041 
C447.971985,177.779770 452.522339,177.880814 457.072693,177.981842 
z"></path>
<path fill="#1B1A1A" opacity="1.000000" stroke="none" d="
M389.935516,108.022766 
C389.935516,108.022766 389.994415,108.458649 389.981873,109.095421 
C389.857758,114.134621 392.750031,113.058861 395.279846,112.644135 
C395.279846,112.644135 395.343414,112.665741 395.374847,112.679924 
C393.913574,113.766411 392.442474,115.713264 390.924225,115.750710 
C385.919556,115.874153 380.897858,115.306694 375.486237,114.948235 
C375.918457,114.091599 376.747101,113.291267 378.160156,112.237335 
C379.500671,110.981461 380.256805,109.979202 381.459045,108.988861 
C384.248718,108.570541 386.592316,108.140312 389.104187,107.768105 
C389.461304,108.000237 389.682312,108.065781 389.935516,108.022766 
z"></path>
<path fill="#888787" opacity="1.000000" stroke="none" d="
M397.113434,113.269836 
C405.041229,112.950821 413.080505,112.989372 421.567688,113.448227 
C421.974823,114.515694 421.934082,115.162842 421.893311,115.809982 
C416.570953,115.861000 411.248596,115.912018 405.458405,115.989822 
C402.402008,115.220215 399.813446,114.423813 397.113434,113.269836 
z"></path>
<path fill="#B4B2B3" opacity="1.000000" stroke="none" d="
M477.312195,66.961922 
C477.298950,67.973793 477.285706,68.985664 477.183136,70.783432 
C477.155212,72.120941 477.216614,72.672554 477.258087,73.617897 
C477.281189,75.585632 477.324219,77.159630 477.243469,79.169456 
C477.207031,80.497673 477.294403,81.390076 477.359375,82.622589 
C477.239624,83.634293 477.142303,84.305870 477.044983,84.977448 
C475.405151,84.021843 473.765259,83.066231 471.667786,81.688171 
C471.302399,79.883797 471.394623,78.501869 471.834961,77.070221 
C473.108185,75.726357 474.844666,74.354042 474.729645,73.160133 
C474.615204,71.972725 472.640472,70.964577 471.385254,69.524178 
C471.232422,68.439072 471.175842,67.707611 471.374176,66.867508 
C472.076996,66.182495 472.524902,65.606140 473.324860,65.020706 
C474.145782,65.030289 474.614685,65.048950 475.103577,65.410080 
C475.853119,66.155670 476.582672,66.558792 477.312195,66.961922 
z"></path>
<path fill="#474546" opacity="1.000000" stroke="none" d="
M471.486877,77.119942 
C471.394623,78.501869 471.302399,79.883797 471.331024,81.665512 
C470.779236,83.351952 470.106598,84.638611 469.030334,85.907837 
C468.477234,78.156319 468.327698,70.422241 468.750122,62.478279 
C469.921173,63.837646 470.520203,65.406898 471.119232,66.976151 
C471.175842,67.707611 471.232422,68.439072 471.287354,69.970642 
C471.352753,72.887146 471.419800,75.003540 471.486877,77.119942 
z"></path>
<path fill="#535253" opacity="1.000000" stroke="none" d="
M477.091980,85.439590 
C477.142303,84.305870 477.239624,83.634293 477.669312,82.538574 
C478.647644,82.059143 479.293579,82.003853 479.939514,81.948563 
C479.977936,87.000931 480.016357,92.053291 480.002075,97.574089 
C479.316010,97.985321 478.682678,97.928123 477.733734,97.842880 
C477.325104,93.843788 477.232056,89.872757 477.091980,85.439590 
z"></path>
<path fill="#222021" opacity="1.000000" stroke="none" d="
M462.621582,97.435989 
C462.185089,93.098816 464.147827,89.935822 468.480408,87.994720 
C468.874359,88.690720 468.900269,89.399902 468.892639,90.463554 
C467.603607,92.092430 466.311676,93.333557 465.105743,94.653244 
C464.336182,95.495346 463.693909,96.453751 462.621582,97.435989 
z"></path>
<path fill="#FEFAEC" opacity="1.000000" stroke="none" d="
M63.028694,155.025787 
C64.541771,154.832397 66.054848,154.639023 67.785156,154.695831 
C68.001389,155.709457 68.000397,156.472885 67.999092,157.617249 
C67.998779,157.998169 67.499214,157.999298 66.905151,158.054474 
C65.544624,159.740753 64.778160,161.371826 64.011696,163.002899 
C64.007423,164.716568 64.003159,166.430252 63.998978,168.572266 
C63.226334,169.318909 62.453609,169.637238 61.369308,169.985992 
C61.219948,168.162445 61.382168,166.308441 61.285149,163.795563 
C61.016979,161.424454 61.008038,159.712234 61.037571,157.766968 
C61.076042,157.533936 61.137913,157.065643 61.443939,157.006622 
C62.176208,156.306992 62.602451,155.666382 63.028694,155.025787 
z"></path>
<path fill="#C4C2C3" opacity="1.000000" stroke="none" d="
M349.346375,178.101227 
C348.672089,178.003754 348.387329,177.820480 348.066223,177.030609 
C347.991272,175.246765 348.224213,173.977982 347.863953,172.909164 
C346.703735,169.467209 348.152588,164.130020 350.790466,163.455444 
C350.892822,165.700912 350.636688,167.603104 351.116150,169.297104 
C351.337982,170.080826 352.994354,170.458496 354.390747,171.007721 
C355.821259,171.029343 356.861389,171.063522 357.901489,171.097702 
C361.145294,172.668930 361.821594,175.004547 359.951477,178.456940 
C356.525360,178.555099 353.130615,178.285278 349.346375,178.101227 
z"></path>
<path fill="#4D4B4C" opacity="1.000000" stroke="none" d="
M486.768738,109.759476 
C487.757660,110.068062 488.831116,110.707199 489.918396,111.706436 
C488.905884,111.407707 487.879578,110.748871 486.768738,109.759476 
z"></path>
<path fill="#767676" opacity="1.000000" stroke="none" d="
M422.249237,115.839249 
C421.934082,115.162842 421.974823,114.515694 422.031342,113.543106 
C423.441040,113.168190 424.834961,113.118713 426.645691,113.116425 
C426.873718,113.685135 426.684937,114.206650 426.257385,114.879913 
C424.880768,115.310608 423.742981,115.589554 422.249237,115.839249 
z"></path>
<path fill="#474546" opacity="1.000000" stroke="none" d="
M229.712250,114.994057 
C230.682373,115.000069 231.411804,115.010300 232.501968,115.032593 
C235.766129,114.733391 238.669556,114.422119 242.253571,114.086823 
C243.669586,114.385132 244.404968,114.707474 245.140366,115.029816 
C245.073578,115.951492 245.006790,116.873177 244.882431,118.136780 
C240.846420,118.534462 236.867966,118.590218 232.428284,118.709793 
C230.973511,117.754868 229.979996,116.736130 228.988251,115.358696 
C228.990021,115.000000 229.471527,114.998276 229.712250,114.994057 
z"></path>
<path fill="#535253" opacity="1.000000" stroke="none" d="
M245.365051,114.840256 
C244.404968,114.707474 243.669586,114.385132 242.646286,114.046440 
C242.252380,112.956429 242.146378,111.882759 242.026642,110.030731 
C242.001083,108.500359 241.989227,107.748352 241.991013,106.580673 
C241.995346,104.777916 241.986038,103.390831 241.993927,101.535126 
C242.024933,80.724937 242.038727,60.383385 242.106598,40.054703 
C242.160645,40.067574 242.270920,40.081387 242.641144,40.063118 
C243.753052,40.063763 244.494751,40.082676 245.236450,40.101589 
C245.203796,63.705292 245.171127,87.308998 245.167023,111.365646 
C245.326981,112.762627 245.458359,113.706665 245.365051,114.840256 
z"></path>
<path fill="#201E1F" opacity="1.000000" stroke="none" d="
M381.012939,108.976929 
C380.256805,109.979202 379.500671,110.981461 378.371887,111.991791 
C377.975372,111.542290 377.951508,111.084724 377.857971,110.290237 
C377.866913,88.604431 377.945587,67.255547 378.444275,45.449127 
C379.563782,45.004150 380.263214,45.016716 380.971436,45.496025 
C380.991119,66.967491 381.002014,87.972206 381.012939,108.976929 
z"></path>
<path fill="#D2D0D1" opacity="1.000000" stroke="none" d="
M155.879684,111.089645 
C154.623627,110.243492 153.367554,109.397331 152.199127,107.762070 
C152.268326,96.756805 152.249893,86.540657 152.570740,76.265121 
C153.591339,76.095695 154.272614,75.985649 155.423218,75.906128 
C160.582779,75.980865 165.272995,76.025078 170.247375,76.351593 
C173.030594,76.680748 175.529678,76.727615 178.484711,76.839180 
C183.329285,76.551384 187.717911,76.198891 192.425232,75.900208 
C193.482193,76.344574 194.220413,76.735123 194.973938,77.430618 
C194.993027,78.142632 194.996826,78.549706 194.736664,79.231834 
C182.768188,79.324028 171.063782,79.134247 159.359085,78.964584 
C157.202301,78.933319 154.635422,78.617447 154.998047,81.998169 
C155.988983,91.236229 152.285812,101.023895 157.915833,109.867767 
C157.044769,110.395500 156.462219,110.742569 155.879684,111.089645 
z"></path>
<path fill="#717071" opacity="1.000000" stroke="none" d="
M152.231445,76.324509 
C152.249893,86.540657 152.268326,96.756805 152.210556,107.427841 
C151.104156,107.055367 149.177719,106.225159 149.180344,105.401093 
C149.211838,95.514465 149.529282,85.628761 150.280823,75.408966 
C151.270218,75.491585 151.750824,75.908051 152.231445,76.324509 
z"></path>
<path fill="#817F80" opacity="1.000000" stroke="none" d="
M152.570740,76.265121 
C151.750824,75.908051 151.270218,75.491585 150.464996,75.025192 
C150.409943,74.195786 150.679489,73.416313 151.015945,72.326691 
C152.034225,71.537369 152.985580,71.058212 154.422791,70.286293 
C156.618713,70.142693 158.328827,70.291855 160.032104,70.752495 
C160.023148,71.696281 160.020996,72.328590 159.614090,72.974380 
C157.177216,73.046173 154.740845,72.508766 154.953903,75.875610 
C154.272614,75.985649 153.591339,76.095695 152.570740,76.265121 
z"></path>
<path fill="#201E1F" opacity="1.000000" stroke="none" d="
M148.012329,115.191513 
C146.296082,115.618248 144.554459,115.695686 142.387726,115.658905 
C142.110931,114.759483 142.065353,113.858215 142.450470,113.214706 
C143.123672,112.089813 144.061600,111.123360 144.888596,110.090515 
C145.759354,111.056099 146.702469,111.968513 147.468582,113.011002 
C147.820435,113.489807 147.824936,114.223892 148.012329,115.191513 
z"></path>
<path fill="#A7A6A6" opacity="1.000000" stroke="none" d="
M156.068237,111.391144 
C156.462219,110.742569 157.044769,110.395500 158.322235,109.899780 
C160.665390,109.870758 162.313629,109.990372 163.945190,110.417191 
C164.015961,111.333092 164.103409,111.941788 164.190842,112.550476 
C163.362518,112.689178 162.534180,112.827873 161.300873,113.086563 
C159.349533,112.701912 157.803162,112.197273 156.068237,111.391144 
z"></path>
<path fill="#474546" opacity="1.000000" stroke="none" d="
M161.001923,117.601585 
C159.925217,118.012802 158.851669,118.031387 157.379669,117.982269 
C156.986343,117.473030 156.991470,117.031487 156.997482,116.257568 
C158.065475,115.590691 159.132599,115.256187 160.549805,114.882660 
C160.934967,115.632065 160.970016,116.420517 161.001923,117.601585 
z"></path>
<path fill="#767676" opacity="1.000000" stroke="none" d="
M489.267578,40.608265 
C490.095001,40.132748 490.808594,40.100601 491.879761,40.128136 
C492.202423,57.817951 492.167572,75.448082 491.800964,93.696701 
C491.162903,94.581787 490.856537,94.848396 490.550201,95.114998 
C490.550201,95.114998 490.567047,95.144279 490.586090,95.153580 
C490.116089,93.350540 489.214355,91.540886 489.202545,89.725441 
C489.097076,73.501419 489.148499,57.276379 489.267578,40.608265 
z"></path>
<path fill="#535253" opacity="1.000000" stroke="none" d="
M504.790710,109.115379 
C505.443665,109.798203 506.096588,110.481018 506.886261,111.434631 
C505.013062,111.166664 502.458557,111.221138 501.099396,109.973465 
C497.582062,106.744682 494.591827,102.941734 491.657684,99.190948 
C493.898529,100.448418 495.872803,101.882988 498.187592,103.348541 
C498.528107,103.379517 498.575012,103.428078 498.614502,103.797211 
C499.671875,104.866974 500.689789,105.567604 501.707703,106.268234 
C501.707703,106.268227 501.860748,106.073669 501.841614,106.518646 
C502.811920,107.680885 503.801300,108.398132 504.790710,109.115379 
z"></path>
<path fill="#0F0D0E" opacity="1.000000" stroke="none" d="
M480.726990,45.962723 
C480.317780,45.224026 480.273346,44.500675 480.631897,43.388012 
C481.628510,42.440269 482.222137,41.881840 482.815796,41.323410 
C481.943634,40.869431 481.071442,40.415447 480.199280,39.961464 
C480.119904,39.225929 480.040558,38.490395 479.952515,37.384174 
C487.229248,37.021912 487.229248,37.021912 485.398621,46.532921 
C483.969208,46.348766 482.530518,46.163418 480.726990,45.962723 
z"></path>
<path fill="#535253" opacity="1.000000" stroke="none" d="
M477.367249,78.733627 
C477.324219,77.159630 477.281189,75.585632 477.579407,73.543938 
C478.581696,73.024384 479.242645,72.972527 479.903625,72.920670 
C480.096191,75.116501 480.288757,77.312332 479.944763,79.647812 
C478.727875,79.436188 478.047546,79.084908 477.367249,78.733627 
z"></path>
<path fill="#888787" opacity="1.000000" stroke="none" d="
M477.243469,79.169449 
C478.047546,79.084908 478.727875,79.436188 479.679779,79.890503 
C479.957550,80.428307 479.963715,80.863068 479.954712,81.623199 
C479.293579,82.003853 478.647644,82.059143 477.691711,82.198456 
C477.294403,81.390076 477.207031,80.497673 477.243469,79.169449 
z"></path>
<path fill="#888787" opacity="1.000000" stroke="none" d="
M479.928223,72.610260 
C479.242645,72.972527 478.581696,73.024384 477.599365,73.150208 
C477.216614,72.672554 477.155212,72.120941 477.202148,71.162491 
C477.963837,70.852699 478.617157,70.949745 479.603088,71.050743 
C479.941437,71.469742 479.947144,71.884789 479.928223,72.610260 
z"></path>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" d="
M451.040741,55.994778 
C449.518036,55.024666 447.925446,54.147987 446.483887,53.069439 
C436.324097,45.468311 424.968079,42.094551 412.273254,42.884853 
C410.569916,42.990891 408.775299,41.630558 407.462585,40.476700 
C413.202301,39.915699 418.502380,39.827263 423.802460,39.738823 
C424.297668,39.543407 424.792908,39.348000 425.660553,39.005318 
C432.091553,41.568386 438.150116,44.278728 444.578491,47.057877 
C447.090759,49.673405 449.233215,52.220131 451.576782,55.129051 
C451.587585,55.740196 451.341858,55.908035 451.040741,55.994778 
z"></path>
<path fill="#E6E6E6" opacity="1.000000" stroke="none" d="
M457.072021,87.125595 
C456.715027,85.721550 456.093231,84.326347 456.046021,82.911964 
C455.818329,76.090996 456.839264,69.101677 453.026581,62.097614 
C453.362152,60.579018 453.676270,59.793079 454.147980,58.819115 
C454.305573,58.631084 454.584900,58.227699 454.584900,58.227699 
C455.410492,59.875103 456.236084,61.522503 457.011963,63.949364 
C460.256439,72.013268 459.494415,79.490097 457.780029,87.017242 
C457.541443,87.033707 457.072052,87.125587 457.072021,87.125595 
z"></path>
<path fill="#656364" opacity="1.000000" stroke="none" d="
M458.018585,87.000786 
C459.494415,79.490097 460.256439,72.013268 457.050598,64.322617 
C458.764252,65.995522 460.774353,67.890976 461.884247,70.216133 
C462.668671,71.859383 462.233551,74.084763 462.318054,76.872971 
C462.366577,78.489372 462.439880,79.283058 462.513214,80.076752 
C462.331879,81.723343 462.150513,83.369926 461.671143,85.484253 
C460.729034,86.293747 460.084900,86.635490 459.085388,86.992508 
C458.492828,87.005455 458.255707,87.003120 458.018585,87.000786 
z"></path>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" d="
M467.776611,52.117279 
C469.573212,51.080578 471.369812,50.043869 473.573547,49.442581 
C473.097321,52.962219 476.339020,57.014366 471.447052,59.208420 
C470.438232,59.101177 469.766968,59.022713 469.095703,58.944252 
C468.813690,57.849983 468.531677,56.755711 468.114563,54.981823 
C467.911865,53.573898 467.844238,52.845589 467.776611,52.117279 
z"></path>
<path fill="#9B999A" opacity="1.000000" stroke="none" d="
M457.780029,87.017242 
C458.255707,87.003120 458.492828,87.005455 459.058838,87.335617 
C459.349854,88.119041 459.312042,88.574638 459.274231,89.030235 
C457.458344,92.729492 455.642456,96.428749 453.793335,100.515335 
C453.760071,100.902664 453.375885,100.903725 452.910248,100.885834 
C452.251129,100.849823 452.057648,100.831711 451.864166,100.813606 
C451.831879,100.109535 451.799591,99.405472 451.860413,98.026360 
C452.337006,96.236191 452.720520,95.121063 453.104004,94.005936 
C454.321198,91.925468 455.538361,89.845001 456.913788,87.445068 
C457.072052,87.125587 457.541443,87.033707 457.780029,87.017242 
z"></path>
<path fill="#9B999A" opacity="1.000000" stroke="none" d="
M469.025269,59.322807 
C469.766968,59.022713 470.438232,59.101177 471.492798,59.589825 
C472.241669,61.676601 472.607239,63.353195 472.972809,65.029785 
C472.524902,65.606140 472.076996,66.182495 471.374176,66.867500 
C470.520203,65.406898 469.921173,63.837646 468.963257,62.124611 
C468.721222,61.221001 468.838043,60.461182 469.025269,59.322807 
z"></path>
<path fill="#E6E6E6" opacity="1.000000" stroke="none" d="
M450.905670,56.288071 
C451.341858,55.908035 451.587585,55.740196 451.881348,55.289593 
C452.717834,55.967297 453.450836,56.846661 454.384369,57.976860 
C454.584900,58.227699 454.305573,58.631084 453.751465,58.814316 
C452.140228,58.998032 451.083160,58.998512 450.026062,58.998997 
C450.274231,58.193119 450.522430,57.387245 450.905670,56.288071 
z"></path>
<path fill="#817F80" opacity="1.000000" stroke="none" d="
M467.448486,52.116165 
C467.844238,52.845589 467.911865,53.573898 467.971436,54.668674 
C467.009247,54.228088 466.055084,53.421036 465.135956,52.234913 
C465.820770,51.942245 466.470551,52.028648 467.448486,52.116165 
z"></path>
<path fill="#1B1A1A" opacity="1.000000" stroke="none" d="
M462.829712,80.040421 
C462.439880,79.283058 462.366577,78.489372 462.410339,77.308853 
C463.288879,77.345741 464.050323,77.769470 464.811798,78.193192 
C464.256592,78.796822 463.701416,79.400452 462.829712,80.040421 
z"></path>
<path fill="#1B1A1A" opacity="1.000000" stroke="none" d="
M459.634125,89.013107 
C459.312042,88.574638 459.349854,88.119041 459.414215,87.320335 
C460.084900,86.635490 460.729034,86.293747 461.648193,85.899765 
C461.280182,86.897018 460.637085,87.946503 459.634125,89.013107 
z"></path>
<path fill="#CDCBCC" opacity="1.000000" stroke="none" d="
M473.324860,65.020706 
C472.607239,63.353195 472.241669,61.676601 471.830353,59.618603 
C476.339020,57.014366 473.097321,52.962219 473.948608,49.424072 
C473.943390,47.268246 473.970276,45.566341 473.981293,43.439102 
C474.114166,42.144096 474.262848,41.274429 474.680878,40.210682 
C474.950195,40.016605 475.411987,40.011936 475.669189,40.522125 
C476.247009,44.690605 476.567596,48.348900 476.888153,52.007195 
C477.027710,56.046177 477.167267,60.085159 476.930420,64.568298 
C476.063873,65.030838 475.573730,65.049225 475.083557,65.067619 
C474.614685,65.048950 474.145782,65.030289 473.324860,65.020706 
z"></path>
<path fill="#FDFDFD" opacity="1.000000" stroke="none" d="
M163.961853,110.109985 
C162.313629,109.990372 160.665390,109.870758 158.610779,109.719131 
C152.285812,101.023895 155.988983,91.236229 154.998047,81.998169 
C154.635422,78.617447 157.202301,78.933319 159.359085,78.964584 
C171.063782,79.134247 182.768188,79.324028 195.087402,79.250511 
C196.169312,78.966797 196.636505,78.939453 197.103699,78.912102 
C199.807571,81.294754 202.635956,83.555237 205.153152,86.121017 
C206.141022,87.127953 206.432114,88.818481 207.105377,90.584259 
C206.766312,95.016808 206.362411,99.062790 205.932602,103.534142 
C205.285049,104.773407 204.663406,105.587303 203.916412,106.704285 
C201.834229,107.573502 199.905106,108.280212 197.914047,108.672722 
C194.945724,109.257912 191.933716,109.621506 188.242340,109.793411 
C180.224304,109.373512 172.904449,109.217896 165.583771,109.150200 
C165.046143,109.145233 164.502625,109.775208 163.961853,110.109985 
z"></path>
<path fill="#201E1F" opacity="1.000000" stroke="none" d="
M194.958649,77.125671 
C194.220413,76.735123 193.482193,76.344574 192.424301,75.680130 
C192.104645,75.406242 192.180344,74.972656 192.445679,74.730652 
C195.153412,73.596222 197.595810,72.703796 199.776367,71.907043 
C198.861450,70.810501 197.989334,69.765236 197.117203,68.719971 
C198.427582,68.125160 199.737946,67.530350 201.048325,66.935539 
C201.509842,66.959259 201.971344,66.982971 202.778412,67.033745 
C203.079666,67.768356 203.035385,68.475899 203.040024,69.535225 
C203.053543,70.989265 203.018143,72.091522 202.953308,73.607147 
C201.186340,75.011452 199.448822,76.002388 197.010620,76.975906 
C195.859512,77.014214 195.409073,77.069946 194.958649,77.125671 
z"></path>
<path fill="#A7A6A6" opacity="1.000000" stroke="none" d="
M194.973938,77.430611 
C195.409073,77.069946 195.859512,77.014214 196.650269,76.980576 
C196.996307,77.428688 197.002014,77.854698 197.055710,78.596405 
C196.636505,78.939453 196.169312,78.966797 195.351379,78.975464 
C194.996826,78.549706 194.993027,78.142632 194.973938,77.430611 
z"></path>
<path fill="#A0A0A0" opacity="1.000000" stroke="none" d="
M350.916626,91.499588 
C350.487976,91.985779 350.106171,92.007965 349.387451,91.705399 
C348.768707,90.579056 348.486816,89.777443 348.204926,88.975830 
C348.096802,87.940552 347.988678,86.905273 347.929596,85.120682 
C347.925079,83.885338 347.871490,83.399315 347.817871,82.913284 
C347.822540,80.922539 347.827179,78.931801 347.785950,76.517822 
C347.811096,75.296524 347.882141,74.498459 347.864716,73.306046 
C347.789917,62.622417 347.803558,52.333134 347.854919,41.166653 
C348.083282,39.748009 348.273926,39.206562 348.464539,38.665115 
C349.278992,41.514507 350.747559,44.352398 350.797882,47.215225 
C351.054657,61.818462 350.944885,76.428146 350.916626,91.499588 
z"></path>
<path fill="#DDDCDC" opacity="1.000000" stroke="none" d="
M348.322815,38.443928 
C348.273926,39.206562 348.083282,39.748009 347.789490,40.711044 
C344.736603,40.572723 344.001953,39.351490 346.862976,37.138390 
C347.543243,37.482349 347.862152,37.852547 348.322815,38.443928 
z"></path>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" d="
M353.994354,34.478394 
C353.552856,34.853546 353.107819,34.896763 352.332428,34.872528 
C352.002075,33.845516 352.002075,32.885960 352.002075,31.926401 
C352.302032,31.833948 352.602020,31.741495 352.901978,31.649044 
C353.264923,32.481518 353.627869,33.313992 353.994354,34.478394 
z"></path>
<path fill="#B4B2B3" opacity="1.000000" stroke="none" d="
M272.044922,108.576462 
C271.385925,109.096085 270.728302,109.143013 269.742615,109.218231 
C269.268097,108.760078 269.121613,108.273651 268.911682,107.409058 
C268.943756,95.894714 269.039246,84.758530 269.601959,73.224197 
C270.713379,72.883896 271.357605,72.941757 272.001831,72.999619 
C272.016632,84.701004 272.031464,96.402397 272.044922,108.576462 
z"></path>
<path fill="#656364" opacity="1.000000" stroke="none" d="
M272.265686,72.736488 
C271.357605,72.941757 270.713379,72.883896 269.752869,72.757217 
C269.428864,71.150398 269.421173,69.612404 269.430878,67.691643 
C270.135498,66.950783 270.822662,66.592682 271.779541,66.118607 
C272.209290,68.159546 272.369415,70.316452 272.265686,72.736488 
z"></path>
<path fill="#474546" opacity="1.000000" stroke="none" d="
M245.506851,39.909859 
C244.494751,40.082676 243.753052,40.063763 242.584579,39.730705 
C242.172043,38.969742 242.186279,38.522923 242.200500,38.076103 
C243.117569,37.035755 244.034637,35.995407 244.955048,34.618057 
C246.373169,34.785698 247.787964,35.290344 249.510437,35.932076 
C249.404663,36.677486 248.991180,37.285812 248.317856,38.018257 
C247.297760,38.667625 246.537506,39.192875 245.506851,39.909859 
z"></path>
<path fill="#DDDCDC" opacity="1.000000" stroke="none" d="
M347.896973,89.148361 
C348.486816,89.777443 348.768707,90.579056 349.100769,91.718094 
C347.670715,93.842232 346.188324,95.757950 344.782166,91.625977 
C345.703613,90.620880 346.646301,89.970894 347.896973,89.148361 
z"></path>
<path fill="#DDDCDC" opacity="1.000000" stroke="none" d="
M347.496094,82.943176 
C347.871490,83.399315 347.925079,83.885338 347.893127,84.731087 
C347.036255,84.757561 346.264954,84.424324 345.493652,84.091080 
C346.053864,83.718407 346.614075,83.345741 347.496094,82.943176 
z"></path>
<path fill="#535253" opacity="1.000000" stroke="none" d="
M490.891968,95.083984 
C490.856537,94.848396 491.162903,94.581787 491.729980,94.161751 
C491.738403,94.356544 491.486053,94.704758 490.891968,95.083984 
z"></path>
<path fill="#070506" opacity="1.000000" stroke="none" d="
M381.459045,108.988853 
C381.002014,87.972206 380.991119,66.967491 380.984894,45.086487 
C381.003387,42.103790 380.585358,39.689079 383.991943,40.005009 
C383.993866,60.766933 383.991455,81.528862 384.003479,102.290787 
C384.004944,104.785400 383.555298,107.742371 388.353333,106.766678 
C388.941711,107.191948 388.938782,107.451012 388.935883,107.710068 
C386.592316,108.140312 384.248718,108.570541 381.459045,108.988853 
z"></path>
<path fill="#E6E6E6" opacity="1.000000" stroke="none" d="
M389.104187,107.768097 
C388.938782,107.451012 388.941711,107.191948 388.776550,106.582489 
C388.442047,105.969238 388.265015,105.713684 388.474548,105.188026 
C389.238983,103.254837 389.917480,101.600693 389.926208,99.943016 
C390.031525,79.985283 390.037720,60.027020 390.439514,40.040039 
C391.551971,40.034210 392.294617,40.057243 393.037292,40.080276 
C393.019867,60.834553 393.059631,81.589310 392.884308,102.342255 
C392.869080,104.143860 391.435242,105.933472 390.297913,107.875710 
C389.682312,108.065781 389.461304,108.000237 389.104187,107.768097 
z"></path>
<path fill="#9B999A" opacity="1.000000" stroke="none" d="
M390.069763,40.068893 
C390.037720,60.027020 390.031525,79.985283 389.926208,99.943016 
C389.917480,101.600693 389.238983,103.254837 388.454681,104.955704 
C387.671661,103.980392 386.989014,102.960800 386.986420,101.939468 
C386.935089,81.775688 386.959503,61.611702 386.987823,41.447784 
C386.988739,40.793285 387.193146,40.139072 387.965515,39.257847 
C389.108826,39.376942 389.589294,39.722919 390.069763,40.068893 
z"></path>
<path fill="#B4B2B3" opacity="1.000000" stroke="none" d="
M390.439514,40.040039 
C389.589294,39.722919 389.108826,39.376942 388.319153,39.016525 
C388.027252,38.579098 388.044525,38.156113 388.109222,37.418884 
C397.453857,37.108162 406.751068,37.111683 416.513519,37.070267 
C419.206970,37.671722 421.435211,38.318108 423.732941,39.351662 
C418.502380,39.827263 413.202301,39.915699 407.435791,40.069817 
C405.274017,40.130371 403.578674,40.125244 401.044800,40.091324 
C398.796234,40.072788 397.386200,40.083050 395.976196,40.093307 
C395.240021,40.052795 394.503876,40.012280 393.402496,40.026024 
C392.294617,40.057243 391.551971,40.034210 390.439514,40.040039 
z"></path>
<path fill="#131213" opacity="1.000000" stroke="none" d="
M383.993286,39.639282 
C380.585358,39.689079 381.003387,42.103790 380.976135,44.619743 
C380.263214,45.016716 379.563782,45.004150 378.514771,44.983673 
C378.106689,43.619781 378.048157,42.263802 377.964966,40.502274 
C378.732666,38.920574 379.525024,37.744419 380.317383,36.568264 
C381.543152,37.470024 382.768890,38.371788 383.993286,39.639282 
z"></path>
<path fill="#A7A6A6" opacity="1.000000" stroke="none" d="
M193.884720,45.914848 
C194.916611,46.327286 195.867401,46.862415 196.898987,48.117447 
C197.041931,51.568142 197.104095,54.298939 197.031982,57.316509 
C195.960968,58.435402 195.024261,59.267521 194.045258,59.631950 
C194.001709,54.861931 194.000443,50.559593 193.999191,46.257259 
C193.999191,46.257259 193.803635,46.037544 193.884720,45.914848 
z"></path>
<path fill="#343233" opacity="1.000000" stroke="none" d="
M197.166275,57.029736 
C197.104095,54.298939 197.041931,51.568142 197.087082,48.392731 
C201.889938,49.384796 199.379532,53.276962 199.624939,56.552597 
C198.571472,57.006378 197.868881,57.018055 197.166275,57.029736 
z"></path>
<path fill="#201E1F" opacity="1.000000" stroke="none" d="
M197.031982,57.316509 
C197.868881,57.018055 198.571472,57.006378 199.624634,56.983337 
C199.983963,57.145935 199.992752,57.319893 200.010498,57.755249 
C200.008652,58.757465 199.997818,59.498287 199.547043,60.617344 
C197.362106,60.981365 195.617126,60.967152 193.920288,60.738174 
C193.968430,60.523407 194.087555,60.099636 194.087555,60.099640 
C195.024261,59.267521 195.960968,58.435402 197.031982,57.316509 
z"></path>
<path fill="#717071" opacity="1.000000" stroke="none" d="
M552.670349,40.000980 
C551.814880,40.035702 551.136658,40.079857 550.118408,40.163136 
C549.032593,39.543495 548.286926,38.884727 547.242065,38.218887 
C546.006042,37.773922 545.069275,37.336037 544.132446,36.898148 
C544.104248,36.506218 544.075989,36.114285 544.047791,35.722355 
C545.827271,35.168396 547.606812,34.614437 549.761841,34.168373 
C550.866150,35.419910 551.594849,36.563549 552.594360,38.212997 
C552.977051,39.161438 553.088867,39.604065 553.200684,40.046692 
C553.200745,40.046688 552.847595,40.010414 552.670349,40.000980 
z"></path>
<path fill="#A0A0A0" opacity="1.000000" stroke="none" d="
M224.637848,95.051895 
C223.897659,92.001541 223.549103,88.933655 223.027588,85.494728 
C224.238174,85.809525 225.621719,86.495361 227.050751,87.560120 
C226.407318,90.304161 225.718399,92.669266 224.637848,95.051895 
z"></path>
<path fill="#FBFBFB" opacity="1.000000" stroke="none" d="
M194.045258,59.631950 
C194.087555,60.099636 193.968430,60.523407 193.613174,60.868374 
C190.564346,63.178413 187.870758,65.143486 184.699722,67.057465 
C175.895111,67.023338 167.565750,67.141426 159.242905,66.960480 
C157.809372,66.929314 155.166321,65.616287 155.172485,64.913750 
C155.250885,55.985523 155.711624,47.060658 156.075027,38.134933 
C157.113144,38.129417 158.151260,38.123901 159.670074,38.544861 
C165.505264,39.250206 170.858948,39.553051 176.216751,39.734734 
C176.475311,39.743500 176.770050,38.685631 177.047928,38.124466 
C182.030807,37.122917 185.581604,39.647007 188.960388,42.810745 
C190.254196,44.022190 191.872742,44.886826 193.671249,46.083382 
C194.000443,50.559593 194.001709,54.861931 194.045258,59.631950 
z"></path>
<path fill="#BBBBBB" opacity="1.000000" stroke="none" d="
M176.783936,37.977135 
C176.770050,38.685631 176.475311,39.743500 176.216751,39.734734 
C170.858948,39.553051 165.505264,39.250206 160.060760,38.583744 
C164.649948,37.493320 169.329117,36.790493 174.456650,36.074020 
C175.443314,36.650188 175.981628,37.239994 176.783936,37.977135 
z"></path>
<path fill="#C4C2C3" opacity="1.000000" stroke="none" d="
M505.189880,109.074379 
C503.801300,108.398132 502.811920,107.680885 501.785034,106.628723 
C503.788544,106.947723 505.829498,107.601639 507.930115,108.630463 
C507.189484,109.014709 506.389282,109.024040 505.189880,109.074379 
z"></path>
<path fill="#888787" opacity="1.000000" stroke="none" d="
M477.207031,51.740005 
C476.567596,48.348900 476.247009,44.690605 475.890930,40.586559 
C476.551727,41.492950 477.705475,42.800659 477.842072,44.207165 
C478.074127,46.596279 477.665375,49.047630 477.207031,51.740005 
z"></path>
<path fill="#191718" opacity="1.000000" stroke="none" d="
M480.152161,40.350548 
C481.071442,40.415447 481.943634,40.869431 482.815796,41.323410 
C482.222137,41.881840 481.628510,42.440269 480.707184,43.030502 
C480.288025,42.288082 480.196503,41.513855 480.152161,40.350548 
z"></path>
<path fill="#888787" opacity="1.000000" stroke="none" d="
M475.103577,65.410080 
C475.573730,65.049225 476.063873,65.030838 476.923950,65.034927 
C477.429291,65.598831 477.564697,66.140259 477.506165,66.821808 
C476.582672,66.558792 475.853119,66.155670 475.103577,65.410080 
z"></path>
<path fill="#CDCBCC" opacity="1.000000" stroke="none" d="
M155.797485,38.069321 
C155.711624,47.060658 155.250885,55.985523 155.172485,64.913750 
C155.166321,65.616287 157.809372,66.929314 159.242905,66.960480 
C167.565750,67.141426 175.895111,67.023338 184.651382,67.448685 
C183.928314,69.220367 182.776138,70.549744 181.581482,71.747292 
C181.539001,71.615456 181.262634,71.634712 181.073151,71.523323 
C180.883682,71.411934 180.444214,71.401794 180.380173,71.361664 
C180.316132,71.321526 180.199524,71.417694 180.273407,71.056259 
C179.922516,70.505783 179.497757,70.316727 178.694061,70.060303 
C177.549454,70.035110 176.783768,70.077293 175.636993,70.071152 
C174.496307,70.099068 173.736725,70.175301 172.519073,70.190285 
C168.053650,70.233032 164.046295,70.337021 160.038925,70.441010 
C158.328827,70.291855 156.618713,70.142693 154.481445,69.938431 
C153.882187,69.601166 153.723007,69.310135 153.536041,69.038216 
C150.781052,65.031395 151.299759,41.834244 154.601120,37.972862 
C155.154160,37.899651 155.337051,37.951679 155.797485,38.069321 
z"></path>
<path fill="#0F0D0E" opacity="1.000000" stroke="none" d="
M553.548218,40.030952 
C553.088867,39.604065 552.977051,39.161438 552.876221,38.373367 
C555.874023,38.109692 558.860840,38.191452 561.939697,38.652294 
C562.009094,40.087975 561.986511,41.144581 561.541992,42.599754 
C560.061951,43.011818 559.003784,43.025311 557.945557,43.038815 
C556.595581,42.030949 555.245667,41.023075 553.548218,40.030952 
z"></path>
<path fill="#131213" opacity="1.000000" stroke="none" d="
M181.623947,71.879128 
C182.776138,70.549744 183.928314,69.220367 185.128845,67.499771 
C187.870758,65.143486 190.564346,63.178413 193.565033,61.083138 
C195.617126,60.967152 197.362106,60.981365 199.541595,60.988117 
C200.324661,62.711678 200.673218,64.442703 201.035065,66.554626 
C199.737946,67.530350 198.427582,68.125160 197.117203,68.719971 
C197.989334,69.765236 198.861450,70.810501 199.776367,71.907043 
C197.595810,72.703796 195.153412,73.596222 192.173187,74.431152 
C188.298233,73.542145 184.961090,72.710632 181.623947,71.879128 
z"></path>
<path fill="#C4C2C3" opacity="1.000000" stroke="none" d="
M501.797028,105.884811 
C500.689789,105.567604 499.671875,104.866974 498.644348,103.812973 
C499.433777,103.336250 500.232788,103.212921 501.432983,103.127998 
C501.851532,103.944740 501.868958,104.723061 501.797028,105.884811 
z"></path>
<path fill="#0F0D0E" opacity="1.000000" stroke="none" d="
M241.912338,37.789345 
C242.186279,38.522923 242.172043,38.969742 242.214355,39.748970 
C242.270920,40.081387 242.160645,40.067574 241.690399,40.059174 
C240.482285,41.074696 239.241440,42.037125 239.107239,43.134850 
C238.790924,45.722115 239.002136,48.373875 238.999939,50.999542 
C238.000336,49.602276 236.377441,48.304596 236.134857,46.786366 
C235.673584,43.899418 236.001617,40.886353 236.000931,37.463058 
C237.874725,37.167831 239.749435,37.335209 241.912338,37.789345 
z"></path>
<path fill="#DEC799" opacity="1.000000" stroke="none" d="
M88.862869,54.724258 
C88.299049,60.352203 87.861763,65.696320 86.962029,70.961403 
C86.765045,72.114113 84.919952,72.985191 83.422440,73.686127 
C83.004066,72.922531 82.997108,72.458786 83.279762,71.733322 
C84.029305,66.323273 84.489227,61.174931 84.966591,55.614693 
C85.028442,54.102654 85.072845,53.002510 85.473938,51.926029 
C86.543526,51.985493 87.256416,52.021290 87.969299,52.057087 
C88.253944,52.842068 88.538589,53.627048 88.862869,54.724258 
z"></path>
<path fill="#D8BE96" opacity="1.000000" stroke="none" d="
M58.098087,34.179691 
C62.052094,34.165844 66.006096,34.152000 70.453613,34.537010 
C71.659126,36.591927 72.371140,38.247993 72.672470,39.931038 
C69.995453,41.326439 66.916512,36.366028 64.739120,40.817986 
C63.618740,40.485477 62.813744,40.218399 61.992935,39.575348 
C60.684113,37.526146 59.391098,35.852917 58.098087,34.179691 
z"></path>
<path fill="#E9D7B9" opacity="1.000000" stroke="none" d="
M73.083153,39.904057 
C72.371140,38.247993 71.659126,36.591927 70.903412,34.594555 
C77.423813,37.268505 84.236420,40.015114 85.777359,48.626282 
C85.573341,49.012142 85.087006,49.007114 84.728050,48.999199 
C83.662415,48.952717 82.955734,48.914146 82.184555,48.526855 
C82.060028,47.471386 81.999985,46.764645 81.952560,45.682415 
C80.980217,44.536263 79.995262,43.765594 78.670570,42.927937 
C76.581612,41.875317 74.832382,40.889687 73.083153,39.904057 
z"></path>
<path fill="#C4C2C3" opacity="1.000000" stroke="none" d="
M57.662838,34.125080 
C59.391098,35.852917 60.684113,37.526146 61.734562,39.602058 
C61.319584,40.019844 61.147163,40.034950 60.691849,39.904713 
C59.630230,39.459400 58.851505,39.159431 57.825409,38.604973 
C55.723099,37.895306 53.868160,37.440132 52.013218,36.984962 
C52.011608,36.271709 52.009998,35.558453 52.010567,34.491726 
C53.751026,34.115658 55.489307,34.093063 57.662838,34.125080 
z"></path>
<path fill="#FAF0DB" opacity="1.000000" stroke="none" d="
M85.087006,49.007114 
C85.087006,49.007114 85.573341,49.012142 85.817162,49.009140 
C86.704628,49.784523 87.348267,50.562912 87.980606,51.699196 
C87.256416,52.021290 86.543526,51.985493 85.464966,51.940147 
C85.099297,51.930595 85.095711,51.904957 85.106422,51.543613 
C85.107094,50.457218 85.097046,49.732166 85.087006,49.007114 
z"></path>
<path fill="#EEDABC" opacity="1.000000" stroke="none" d="
M15.960703,50.479622 
C15.944306,46.022995 15.946991,42.033501 16.308182,37.551476 
C17.413256,37.064083 18.159822,37.069225 18.949818,37.308670 
C18.993248,37.542969 19.095337,38.008484 19.060320,38.475151 
C19.019190,72.769020 19.026943,106.596245 18.970926,140.423355 
C18.967718,142.358978 18.776625,144.421127 18.094734,146.203125 
C16.538733,150.269470 17.182148,151.708115 21.543032,151.915207 
C25.699699,152.112595 29.872776,151.964447 34.034920,152.324249 
C34.062843,153.384827 34.094482,154.089355 33.653282,154.854050 
C26.452812,154.949036 19.725183,154.983826 12.997557,155.018631 
C10.289524,153.040726 10.123893,150.907013 12.808796,148.327744 
C16.090067,147.079666 16.031651,144.646622 16.027327,142.042435 
C15.976914,111.677261 15.986254,81.312004 15.960703,50.479622 
z"></path>
<path fill="#FDF6E3" opacity="1.000000" stroke="none" d="
M13.014299,155.430603 
C19.725183,154.983826 26.452812,154.949036 34.088867,154.895370 
C37.665493,154.916931 40.333691,154.957352 42.998989,155.373199 
C42.996376,156.499542 42.996666,157.250458 42.996956,158.001358 
C34.382866,158.000809 25.766644,158.095703 17.156855,157.903091 
C15.766913,157.871994 14.405592,156.561630 13.014299,155.430603 
z"></path>
<path fill="#F8E7CD" opacity="1.000000" stroke="none" d="
M43.003456,158.461304 
C42.996666,157.250458 42.996376,156.499542 43.367943,155.367218 
C44.477692,155.000351 45.215591,155.014893 45.967522,155.493835 
C46.001957,161.314484 46.022358,166.670746 46.008827,172.369232 
C45.270039,174.079193 44.565189,175.446930 43.860336,176.814651 
C42.564045,177.234192 41.267750,177.653732 39.564392,177.773743 
C40.427311,174.971802 41.697300,172.469406 42.967293,169.966995 
C42.981514,166.285080 42.995735,162.603165 43.003456,158.461304 
z"></path>
<path fill="#FAF0DB" opacity="1.000000" stroke="none" d="
M42.594963,169.983292 
C41.697300,172.469406 40.427311,174.971802 39.225677,177.840057 
C39.294029,178.205917 39.231449,178.199783 38.839981,178.128830 
C37.964809,178.037766 37.481106,178.017670 36.997406,177.997559 
C36.702652,174.001083 37.670475,170.829483 42.594963,169.983292 
z"></path>
<path fill="#F8E7CD" opacity="1.000000" stroke="none" d="
M36.970020,178.321747 
C37.481106,178.017670 37.964809,178.037766 38.808601,178.126831 
C39.126480,178.596481 39.084267,178.997147 38.978592,179.696472 
C38.167187,180.327896 37.419239,180.660660 36.365192,181.039764 
C36.353603,180.272705 36.648117,179.459305 36.970020,178.321747 
z"></path>
<path fill="#E9D7B9" opacity="1.000000" stroke="none" d="
M34.659363,34.872559 
C35.001122,35.489220 35.022762,35.952332 34.879417,37.126343 
C34.490273,38.956127 34.072701,40.074764 34.071239,41.193939 
C34.052208,55.779625 34.092140,70.365387 34.086433,85.406204 
C34.073868,88.582848 34.092102,91.304436 34.078453,94.492020 
C34.049461,108.310928 34.052349,121.663826 34.035606,135.429901 
C34.069450,137.217392 34.122921,138.591736 34.125847,140.228165 
C34.054928,140.667053 34.034557,140.843842 33.691193,141.013397 
C32.939709,141.014236 32.511211,141.022308 31.965237,140.763641 
C31.530228,140.008789 31.212692,139.520645 30.932961,138.563202 
C30.972712,107.066635 30.974659,76.039360 30.993340,44.554516 
C30.985699,40.768066 30.961323,37.439175 30.936949,34.110287 
C32.071049,34.313194 33.205147,34.516106 34.659363,34.872559 
z"></path>
<path fill="#C6AD7D" opacity="1.000000" stroke="none" d="
M19.095337,38.008484 
C19.095337,38.008484 18.993248,37.542969 18.981131,36.952576 
C18.998482,35.654346 19.027950,34.946514 19.057419,34.238678 
C21.723434,34.199581 24.389448,34.160488 27.514696,34.618179 
C27.994703,38.401356 28.015478,41.687748 28.022881,44.983665 
C28.009506,44.993195 27.985144,45.012478 27.599703,45.007835 
C25.480534,45.341309 23.746805,45.679428 22.008675,45.578114 
C20.984825,42.782265 24.399302,38.616924 19.095337,38.008484 
z"></path>
<path fill="#D8BE96" opacity="1.000000" stroke="none" d="
M28.036253,44.974140 
C28.015478,41.687748 27.994703,38.401356 27.954876,34.680382 
C28.685936,34.181530 29.436049,34.117260 30.561554,34.081638 
C30.961323,37.439175 30.985699,40.768066 30.625792,44.549213 
C29.506424,44.992363 28.771339,44.983253 28.036253,44.974140 
z"></path>
<path fill="#D8BE96" opacity="1.000000" stroke="none" d="
M18.700821,34.162636 
C19.027950,34.946514 18.998482,35.654346 18.937702,36.718277 
C18.159822,37.069225 17.413256,37.064083 16.292503,37.097008 
C15.445637,36.822411 14.972955,36.509750 14.233273,36.100632 
C15.425591,35.364983 16.884909,34.725788 18.700821,34.162636 
z"></path>
<path fill="#FEFAEC" opacity="1.000000" stroke="none" d="
M34.117233,84.951134 
C34.092140,70.365387 34.052208,55.779625 34.071239,41.193939 
C34.072701,40.074764 34.490273,38.956127 34.905556,37.473270 
C36.137409,37.079506 37.178139,37.049717 38.610207,37.401993 
C39.004013,38.526165 39.006477,39.268261 39.006954,40.452042 
C38.502216,43.960724 37.851414,47.012260 37.529110,50.098114 
C36.949020,55.652103 35.819443,61.306744 38.494595,66.535744 
C39.178181,66.623924 39.861763,66.712105 40.545349,66.800278 
C41.364952,63.865448 42.184555,60.930618 43.376122,57.997181 
C44.491680,58.006012 45.235275,58.013451 45.986897,58.356651 
C46.327690,59.464848 46.660461,60.237278 46.990921,61.373508 
C46.324810,63.488396 45.661011,65.239487 44.639042,67.000381 
C39.279770,67.599014 41.758312,69.964310 43.182327,72.353798 
C43.474743,73.448082 43.667423,74.244011 43.595032,75.336365 
C43.205822,78.751205 43.081684,81.869629 42.512329,84.990387 
C39.417149,84.978867 36.767193,84.964996 34.117233,84.951134 
z"></path>
<path fill="#FCFAF0" opacity="1.000000" stroke="none" d="
M39.008945,40.010361 
C39.006477,39.268261 39.004013,38.526165 39.002430,37.414204 
C43.030407,37.036961 47.057507,37.029579 51.548912,37.003578 
C53.868160,37.440132 55.723099,37.895306 57.847122,38.965355 
C59.074715,40.056751 60.033234,40.533276 60.995907,41.338821 
C61.008217,42.106495 61.016369,42.545147 61.005894,43.375896 
C58.744034,44.845074 56.504311,45.929550 54.256290,46.996552 
C52.839149,47.669186 51.409603,48.315685 49.746269,48.987511 
C49.506920,49.001118 49.028091,49.026012 48.653450,49.024033 
C47.519169,50.013687 46.759529,51.005318 45.998558,52.246552 
C45.997231,52.496147 45.994438,52.995346 45.620377,52.997322 
C44.498299,53.000526 43.750282,53.001751 43.001152,52.615677 
C43.191303,45.409939 50.857296,45.478176 54.284992,40.010361 
C48.656643,40.010361 43.832794,40.010361 39.008945,40.010361 
z"></path>
<path fill="#191718" opacity="1.000000" stroke="none" d="
M353.799500,152.150955 
C353.799530,151.184402 353.799530,150.217850 353.799530,148.952118 
C362.628876,148.952118 371.341919,148.952118 380.532501,149.356659 
C384.316864,154.057388 384.227539,159.039108 384.029541,164.096512 
C383.874146,168.065598 383.983337,172.045059 383.562195,175.996078 
C382.432953,174.740524 381.166687,173.538437 381.098389,172.271790 
C380.848724,167.640900 380.879669,162.984512 381.035248,158.345459 
C381.158722,154.663940 379.258667,153.944366 376.062897,153.965805 
C372.744720,153.988037 369.321594,152.859818 366.137421,153.391266 
C363.928223,153.759964 361.609375,155.757767 360.178406,157.659073 
C359.298492,158.828156 359.998993,161.186707 359.566803,163.008942 
C357.454681,163.012146 355.765625,163.017578 354.018738,162.568069 
C353.907104,158.792404 353.853302,155.471680 353.799500,152.150955 
z"></path>
<path fill="#605F60" opacity="1.000000" stroke="none" d="
M353.370605,152.102264 
C353.853302,155.471680 353.907104,158.792404 353.966064,163.008682 
C353.980927,166.276245 353.990662,168.648254 354.000366,171.020264 
C352.994354,170.458496 351.337982,170.080826 351.116150,169.297104 
C350.636688,167.603104 350.892822,165.700912 350.880554,163.021713 
C350.948608,160.170090 351.158844,158.160095 350.928680,156.201874 
C350.843536,155.477524 349.819366,154.617416 349.034454,154.322891 
C348.627716,154.170242 347.280640,155.089279 347.254761,155.572174 
C346.892273,162.339905 343.181488,168.941345 346.163116,175.876175 
C345.040588,175.584610 343.918030,175.293030 342.427063,174.508286 
C341.994537,170.349136 341.930511,166.683182 341.930573,162.680420 
C341.953827,161.890244 341.912994,161.436844 341.925903,160.551208 
C341.999481,158.090378 342.019318,156.061768 342.039124,154.033173 
C345.673340,153.373306 349.307495,152.713440 353.370605,152.102264 
z"></path>
<path fill="#191718" opacity="1.000000" stroke="none" d="
M323.001221,167.000641 
C321.617065,167.000153 320.232910,166.999649 318.434387,166.998352 
C318.020050,166.997574 317.995117,166.994537 317.985596,166.542633 
C317.958771,163.389954 317.941437,160.689178 317.957214,157.596252 
C317.935577,156.417465 317.880798,155.630859 317.842529,154.496094 
C317.772705,153.462845 317.686432,152.777740 317.600159,152.092636 
C317.690430,151.321167 317.780731,150.549713 317.847107,149.388626 
C321.591339,148.985641 325.359467,148.972275 329.562958,149.343781 
C330.000916,150.482605 330.003510,151.236557 329.629211,152.001831 
C325.758057,153.439606 323.768402,155.735016 323.924072,159.817047 
C324.014801,162.196381 323.334564,164.605118 323.001221,167.000641 
z"></path>
<path fill="#110F10" opacity="1.000000" stroke="none" d="
M383.974976,176.019913 
C383.983337,172.045059 383.874146,168.065598 384.029541,164.096512 
C384.227539,159.039108 384.316864,154.057388 381.000488,149.367462 
C382.717804,148.963333 384.444672,148.952972 386.594421,149.424255 
C387.010193,154.993698 387.142609,160.086746 386.951508,165.167603 
C386.800964,169.169479 387.005798,172.946564 390.009125,176.001129 
C390.116211,180.143677 386.984406,178.722336 384.422150,178.625900 
C383.990753,177.503235 383.982880,176.761566 383.974976,176.019913 
z"></path>
<path fill="#131213" opacity="1.000000" stroke="none" d="
M330.006104,151.990524 
C330.003510,151.236557 330.000916,150.482605 329.995850,149.350952 
C332.625092,148.964493 335.271881,149.130234 337.884552,148.903061 
C341.200317,148.614731 342.447052,149.975327 341.996063,153.598175 
C342.019318,156.061768 341.999481,158.090378 341.510925,160.555054 
C339.361481,160.994080 337.680725,160.997040 335.998901,160.585098 
C336.447327,155.651382 334.753662,152.702438 330.006104,151.990524 
z"></path>
<path fill="#222021" opacity="1.000000" stroke="none" d="
M390.247131,175.854095 
C387.005798,172.946564 386.800964,169.169479 386.951508,165.167603 
C387.142609,160.086746 387.010193,154.993698 387.025757,149.432892 
C388.833221,149.881989 390.632233,150.804138 392.719360,152.242325 
C393.004120,153.504974 393.000732,154.251572 392.984131,154.953094 
C392.970856,154.907990 392.877258,154.916565 392.547302,155.100388 
C387.959473,161.314926 389.990265,167.765533 390.958008,174.145172 
C390.800354,174.665787 390.642731,175.186417 390.247131,175.854095 
z"></path>
<path fill="#BBBBBB" opacity="1.000000" stroke="none" d="
M392.997375,154.998184 
C393.000732,154.251572 393.004120,153.504974 393.034241,152.386536 
C393.776764,152.008408 394.492523,152.002121 395.563904,152.009308 
C395.941071,152.773239 395.962646,153.523712 395.566193,154.635040 
C394.431244,154.996674 393.714325,154.997437 392.997375,154.998184 
z"></path>
<path fill="#191718" opacity="1.000000" stroke="none" d="
M182.023453,178.937958 
C181.270157,178.955475 180.516861,178.973007 179.382919,178.518524 
C179.001053,171.574921 179.000168,165.103333 178.998505,158.631744 
C178.996826,152.084320 178.998337,152.090408 172.770355,153.973114 
C172.014282,154.201675 171.225479,154.322021 169.997299,154.592697 
C169.997299,158.692764 170.227448,162.663086 169.861465,166.577682 
C169.747009,167.801956 168.024963,168.875916 167.028152,169.549103 
C167.015106,162.381577 167.012726,155.683624 167.010345,148.985687 
C171.692581,148.981079 176.374817,148.976471 181.523483,149.444489 
C182.001083,159.590729 182.012268,169.264343 182.023453,178.937958 
z"></path>
<path fill="#161415" opacity="1.000000" stroke="none" d="
M125.030083,150.979126 
C125.553932,150.189972 126.077782,149.400803 126.701172,148.317291 
C133.479675,148.335098 140.157288,148.681503 146.838318,148.939911 
C149.038818,149.025009 151.345520,148.754684 150.589035,152.621796 
C149.770432,154.780045 149.330124,156.422913 148.524109,158.056625 
C146.112854,159.033676 144.067276,160.019897 142.009918,161.004486 
C141.998123,161.002884 141.974380,161.001068 141.985687,160.628204 
C142.002014,159.510040 142.007034,158.764725 142.377274,158.006638 
C143.396820,157.307541 144.733017,156.411377 144.596680,155.968430 
C143.584518,152.679932 138.126678,151.053635 135.039261,152.617615 
C132.864685,153.719177 130.384537,154.217514 128.009201,154.625977 
C126.995079,153.170883 126.012581,152.075012 125.030083,150.979126 
z"></path>
<path fill="#0F0D0E" opacity="1.000000" stroke="none" d="
M182.263824,178.963501 
C182.012268,169.264343 182.001083,159.590729 181.988770,149.445648 
C183.143890,149.223419 184.300125,149.472656 185.793335,149.864136 
C186.479355,150.898560 186.828384,151.790771 187.454651,153.080353 
C187.731903,153.477737 187.641769,153.936325 187.641769,153.936325 
C186.760788,155.166550 185.122498,156.399796 185.127411,157.626465 
C185.151077,163.543381 185.742630,169.456879 185.821259,175.375717 
C185.837051,176.564651 184.500015,177.771561 183.386353,178.999390 
C182.985992,179.028610 182.504196,178.989029 182.263824,178.963501 
z"></path>
<path fill="#070506" opacity="1.000000" stroke="none" d="
M166.630249,148.971344 
C167.012726,155.683624 167.015106,162.381577 167.015717,169.877136 
C167.014618,171.112701 167.015289,171.550659 167.015961,171.988602 
C166.489090,173.189285 165.962204,174.389984 165.435333,175.590668 
C164.957474,174.950729 164.479614,174.310806 163.986725,172.940186 
C163.925552,171.455078 163.879379,170.700653 163.875458,169.485931 
C163.890411,164.034882 163.863159,159.044083 163.878418,153.628830 
C163.937027,151.776855 163.953125,150.349350 163.969238,148.921844 
C164.729538,148.933563 165.489853,148.945267 166.630249,148.971344 
z"></path>
<path fill="#888787" opacity="1.000000" stroke="none" d="
M151.901123,169.886597 
C151.937668,169.148361 151.974213,168.410126 151.992737,166.849899 
C151.986603,162.019943 151.998505,158.011963 152.010406,154.003998 
C153.153046,152.917404 154.295700,151.830826 155.716583,151.270340 
C155.979736,152.833359 155.964661,153.870270 155.626678,154.988174 
C155.472229,156.074692 155.640686,157.080231 155.829605,158.494720 
C155.525696,162.540787 155.201340,166.177872 154.509506,169.878738 
C153.395065,169.923874 152.648087,169.905243 151.901123,169.886597 
z"></path>
<path fill="#2A2A2A" opacity="1.000000" stroke="none" d="
M151.778549,153.889572 
C151.998505,158.011963 151.986603,162.019943 151.934143,166.484344 
C151.141815,166.950577 150.390030,166.960388 149.297668,166.484604 
C148.934647,163.354599 148.912231,160.710205 148.889801,158.065796 
C149.330124,156.422913 149.770432,154.780045 150.573196,153.043060 
C151.139343,153.224335 151.343033,153.499741 151.778549,153.889572 
z"></path>
<path fill="#2A2A2A" opacity="1.000000" stroke="none" d="
M163.769714,148.684753 
C163.953125,150.349350 163.937027,151.776855 163.538757,153.612915 
C162.470016,154.009537 161.783447,153.997589 161.025787,153.664825 
C160.310837,152.510559 159.666962,151.677139 159.023102,150.843704 
C160.538803,150.045029 162.054489,149.246353 163.769714,148.684753 
z"></path>
<path fill="#959394" opacity="1.000000" stroke="none" d="
M158.645935,150.875183 
C159.666962,151.677139 160.310837,152.510559 160.938538,153.927399 
C160.871704,154.688095 160.821014,154.865402 160.371017,155.023926 
C158.630997,154.972473 157.290283,154.939819 155.949585,154.907181 
C155.964661,153.870270 155.979736,152.833359 155.999390,151.407898 
C156.758896,150.981796 157.513824,150.944244 158.645935,150.875183 
z"></path>
<path fill="#161415" opacity="1.000000" stroke="none" d="
M483.023254,178.017090 
C481.611206,178.342941 480.199127,178.668793 478.350586,178.609467 
C477.608978,176.482651 477.303894,174.741028 477.001892,172.531189 
C477.003357,166.041992 477.001678,160.020996 476.928955,153.631653 
C475.013855,153.216003 473.167358,153.085114 471.326294,153.139236 
C467.799805,153.242920 463.514954,157.930939 461.082367,151.369247 
C460.963593,151.048798 459.883606,151.084717 459.251221,150.954681 
C459.199646,150.524506 459.148102,150.094345 459.098816,149.339142 
C465.750153,148.985413 472.399231,148.956696 479.520233,149.415939 
C479.994446,157.696609 479.829041,165.495911 480.136017,173.276611 
C480.199768,174.892227 482.015137,176.438736 483.023254,178.017090 
z"></path>
<path fill="#BBBBBB" opacity="1.000000" stroke="none" d="
M507.819336,158.583710 
C506.647369,161.406357 505.478668,163.807526 504.266663,166.563705 
C503.736816,166.940598 503.250275,166.962463 502.384094,166.649368 
C501.550385,165.643509 501.134613,164.942429 500.633911,164.308441 
C499.773590,163.219055 498.860291,162.171509 498.298706,161.018280 
C499.077332,160.860733 499.526489,160.791306 500.211304,160.747910 
C500.793518,160.608368 500.908783,160.342453 500.850281,159.632568 
C500.253174,158.579346 499.598480,157.869736 498.930847,156.803833 
C495.917145,153.109314 496.164856,156.785309 495.221619,158.342438 
C495.045258,158.724640 494.971710,158.876129 494.523071,159.009338 
C493.613831,157.704742 492.661163,156.420013 492.656311,155.131729 
C492.650818,153.671616 493.494659,152.208328 493.970673,150.497299 
C493.972107,150.248184 493.962280,149.750046 493.962280,149.750046 
C498.360931,150.848099 502.759583,151.946167 507.538086,153.085022 
C507.886169,154.804626 507.854401,156.483429 507.819336,158.583710 
z"></path>
<path fill="#B4B2B3" opacity="1.000000" stroke="none" d="
M494.017517,169.069397 
C493.185883,172.312317 494.392853,176.826736 489.078461,177.276825 
C488.332886,177.339966 487.510162,176.492294 486.448364,175.876678 
C486.256042,172.071182 486.338470,168.455200 486.631348,164.052231 
C486.928284,159.555679 487.014832,155.846115 487.101410,152.136566 
C487.471344,152.041428 487.841278,151.946259 488.808350,151.886108 
C489.618713,151.967331 489.831909,152.013565 490.008911,152.457169 
C489.972656,159.066864 489.972656,165.279175 489.972656,171.491486 
C490.445770,171.498230 490.918915,171.504990 491.392029,171.511734 
C491.713226,170.210709 492.034424,168.909683 492.499054,167.027603 
C493.279510,168.077042 493.648529,168.573212 494.017517,169.069397 
z"></path>
<path fill="#070506" opacity="1.000000" stroke="none" d="
M483.028625,177.546707 
C482.015137,176.438736 480.199768,174.892227 480.136017,173.276611 
C479.829041,165.495911 479.994446,157.696609 479.984955,149.430695 
C481.585876,149.445007 484.652679,147.481506 484.131134,152.148895 
C484.106445,153.561676 484.133850,154.285767 484.161224,155.009842 
C483.791656,158.534698 483.269043,162.051971 483.094025,165.586456 
C482.904816,169.408051 483.040192,173.245682 483.028625,177.546707 
z"></path>
<path fill="#817F80" opacity="1.000000" stroke="none" d="
M484.455261,155.279221 
C484.133850,154.285767 484.106445,153.561676 484.103760,152.476273 
C484.871979,152.093826 485.615509,152.072708 486.730225,152.094086 
C487.014832,155.846115 486.928284,159.555679 486.621765,163.588043 
C485.850952,161.123444 485.300140,158.336014 484.455261,155.279221 
z"></path>
<path fill="#817F80" opacity="1.000000" stroke="none" d="
M490.045135,152.059784 
C489.831909,152.013565 489.618713,151.967331 489.090424,151.830109 
C488.967499,148.331497 491.176544,148.653091 493.730652,149.467209 
C493.962280,149.750046 493.972107,150.248184 493.638550,150.607513 
C492.218384,151.331177 491.131775,151.695480 490.045135,152.059784 
z"></path>
<path fill="#110F10" opacity="1.000000" stroke="none" d="
M543.762085,152.223434 
C543.949829,150.976822 544.137451,149.730194 544.379089,148.125168 
C546.181274,148.437180 547.715393,148.702774 549.618042,149.346771 
C549.838013,150.432083 549.689453,151.138962 549.221008,151.886063 
C548.219604,152.847519 547.538025,153.768753 546.528320,154.799347 
C545.512390,154.914703 544.824707,154.920670 544.081543,154.589355 
C543.938049,153.575867 543.850098,152.899658 543.762085,152.223434 
z"></path>
<path fill="#DDDCDC" opacity="1.000000" stroke="none" d="
M540.999451,158.000824 
C540.557068,157.973526 540.114624,157.946213 539.239258,157.677490 
C538.612671,157.218033 538.379578,157.057098 538.107117,156.953247 
C538.107117,156.953247 538.003540,157.053345 537.931763,157.058838 
C538.585266,155.383240 539.310669,153.702133 540.441650,152.431808 
C540.920837,153.532013 540.994385,154.221420 541.037354,155.292938 
C541.015625,156.438675 541.024536,157.202332 541.024902,157.974670 
C541.016357,157.983337 540.999451,158.000824 540.999451,158.000824 
z"></path>
<path fill="#605F60" opacity="1.000000" stroke="none" d="
M549.540894,151.845840 
C549.689453,151.138962 549.838013,150.432083 550.014404,149.362488 
C550.786987,149.003677 551.531921,149.007553 552.643616,149.356491 
C552.957703,150.378799 552.904968,151.056061 552.644592,151.820724 
C552.436951,151.908127 552.012756,152.059967 551.691040,152.018921 
C550.938110,151.992188 550.506836,152.006485 549.938843,151.986481 
C549.802063,151.952179 549.540833,151.845840 549.540894,151.845840 
z"></path>
<path fill="#817F80" opacity="1.000000" stroke="none" d="
M541.067932,154.910843 
C540.994385,154.221420 540.920837,153.532013 540.848816,152.495697 
C541.580566,152.125275 542.310852,152.101746 543.401611,152.150833 
C543.850098,152.899658 543.938049,153.575867 543.806396,154.607819 
C543.402954,154.968231 543.219238,154.972931 542.708008,154.949768 
C541.942993,154.918213 541.505493,154.914536 541.067932,154.910843 
z"></path>
<path fill="#888787" opacity="1.000000" stroke="none" d="
M552.852295,151.733322 
C552.904968,151.056061 552.957703,150.378799 553.016479,149.362091 
C553.766541,148.998337 554.510437,148.974045 555.626221,148.978912 
C556.019104,149.781052 556.040161,150.554016 556.129517,151.708649 
C556.197754,152.090317 556.016602,152.131699 555.578735,152.034485 
C554.378052,151.869293 553.615173,151.801300 552.852295,151.733322 
z"></path>
<path fill="#3F3D3E" opacity="1.000000" stroke="none" d="
M124.979248,151.447495 
C126.012581,152.075012 126.995079,153.170883 127.995911,155.095398 
C128.003601,161.613525 127.992981,167.303009 127.971893,173.421997 
C127.922150,175.569580 127.882874,177.287659 127.843597,179.005737 
C127.356140,179.283966 126.868698,179.562180 126.115250,179.936890 
C125.550926,176.474991 125.084396,172.920898 124.992432,169.357162 
C124.842491,163.546707 124.937035,157.729965 124.979248,151.447495 
z"></path>
<path fill="#131213" opacity="1.000000" stroke="none" d="
M238.110535,173.825760 
C237.758972,175.298065 237.407379,176.770370 237.070648,178.625763 
C235.999405,179.000061 234.913284,178.991272 233.416168,178.512573 
C233.003006,175.576614 233.509247,172.971741 232.882584,170.676163 
C231.878036,166.996368 231.258484,162.816620 227.007629,160.568176 
C227.557999,156.518997 226.641190,152.594833 222.927353,152.603409 
C220.207764,152.609680 215.925095,153.713684 215.000763,158.433167 
C214.243713,161.669128 217.067337,165.793579 211.994171,166.999222 
C212.746368,161.039871 213.498550,155.080521 214.636108,149.024353 
C219.857513,148.948044 224.696106,148.889648 229.527649,149.047333 
C230.663300,149.084396 231.775116,149.851028 233.011658,150.671509 
C233.108139,153.390503 232.770721,155.782364 233.207291,158.023438 
C233.429016,159.161606 235.045227,160.028137 236.479065,161.489258 
C237.368469,165.660782 237.809799,169.360489 238.174271,173.243942 
C238.097412,173.427673 238.110535,173.825760 238.110535,173.825760 
z"></path>
<path fill="#1B1A1A" opacity="1.000000" stroke="none" d="
M211.601593,167.010742 
C217.067337,165.793579 214.243713,161.669128 215.397385,158.447006 
C216.464386,158.412354 217.551437,158.657471 217.736877,159.206909 
C219.202911,163.550446 220.497116,167.951981 222.111008,173.204132 
C224.493988,172.814026 227.163300,172.377060 229.832611,171.940094 
C229.806747,171.430267 229.780884,170.920456 229.755035,170.410645 
C227.898575,169.178802 225.275482,168.341431 224.385681,166.612183 
C223.345215,164.590179 223.250885,161.511139 227.011261,160.999039 
C231.258484,162.816620 231.878036,166.996368 232.882584,170.676163 
C233.509247,172.971741 233.003006,175.576614 233.010849,178.497162 
C228.313004,178.958160 223.609482,178.964676 218.448792,178.531769 
C217.838531,172.587936 217.157364,172.125122 209.076141,172.034683 
C209.787094,170.363876 210.498062,168.693069 211.601593,167.010742 
z"></path>
<path fill="#656364" opacity="1.000000" stroke="none" d="
M206.877457,154.885437 
C206.901413,156.656464 206.925354,158.427505 206.517502,160.596451 
C203.733444,161.328796 201.381195,161.663208 198.859726,161.668274 
C198.130203,159.893143 197.569885,158.447372 197.353043,156.839676 
C200.756836,156.080322 203.817139,155.482880 206.877457,154.885437 
z"></path>
<path fill="#3F3D3E" opacity="1.000000" stroke="none" d="
M205.975250,170.646759 
C202.627487,171.361404 199.291779,171.641220 195.515930,171.568359 
C195.691162,169.762238 196.306549,168.308762 197.370941,166.908234 
C200.503540,166.886368 203.187119,166.811523 205.870697,166.736694 
C205.909576,167.895096 205.948441,169.053513 205.975250,170.646759 
z"></path>
<path fill="#9B999A" opacity="1.000000" stroke="none" d="
M206.071686,166.428375 
C203.187119,166.811523 200.503540,166.886368 197.393097,166.557800 
C197.015198,165.453735 197.064148,164.753036 197.354187,163.891266 
C198.073166,163.152649 198.551041,162.575134 199.028931,161.997620 
C201.381195,161.663208 203.733444,161.328796 206.491699,161.039093 
C206.689346,162.762573 206.481003,164.441330 206.071686,166.428375 
z"></path>
<path fill="#A7A6A6" opacity="1.000000" stroke="none" d="
M206.927780,154.528900 
C203.817139,155.482880 200.756836,156.080322 197.082809,156.688736 
C195.979935,155.789215 195.490753,154.878723 195.167725,153.652008 
C196.908218,151.962341 198.482574,150.588898 200.056946,149.215454 
C200.780090,149.173660 201.503220,149.131866 202.607208,149.518280 
C204.282913,150.639709 205.577744,151.332947 206.872589,152.026199 
C206.907761,152.741592 206.942932,153.456985 206.927780,154.528900 
z"></path>
<path fill="#110F10" opacity="1.000000" stroke="none" d="
M209.047394,172.298141 
C217.157364,172.125122 217.838531,172.587936 217.981293,178.510605 
C213.306717,180.747482 212.251297,176.107437 209.290756,174.126907 
C208.950867,173.985123 209.002090,173.536407 208.977295,173.311951 
C208.974548,172.912186 208.996613,172.736893 209.047394,172.298141 
z"></path>
<path fill="#959394" opacity="1.000000" stroke="none" d="
M206.914444,151.690582 
C205.577744,151.332947 204.282913,150.639709 202.964340,149.587524 
C204.041077,149.398819 205.141541,149.569046 206.558655,149.872467 
C206.902313,150.455414 206.929306,150.905197 206.914444,151.690582 
z"></path>
<path fill="#191718" opacity="1.000000" stroke="none" d="
M408.015228,148.916321 
C414.703125,148.913910 421.383728,148.945755 428.533691,149.387146 
C432.521454,155.864334 432.105194,162.532990 432.022217,169.168671 
C431.995239,171.325592 431.947723,173.243423 429.001984,173.001999 
C428.999084,172.999115 428.993164,172.993286 428.994385,172.525864 
C428.995605,166.500259 428.995605,160.942078 428.995605,154.012344 
C421.452209,154.012344 414.733063,154.012344 408.010376,153.595917 
C408.005341,151.793121 408.003876,150.406754 408.002441,149.020386 
C408.002441,149.020386 408.007935,148.950577 408.015228,148.916321 
z"></path>
<path fill="#110F10" opacity="1.000000" stroke="none" d="
M429.004883,173.004883 
C431.947723,173.243423 431.995239,171.325592 432.022217,169.168671 
C432.105194,162.532990 432.521454,155.864334 429.002136,149.400543 
C431.121124,149.229797 433.240997,149.455170 435.551788,150.218567 
C435.569458,159.137054 435.396210,167.517487 435.222992,175.897919 
C434.723297,176.888458 434.223602,177.879013 433.324982,178.573853 
C431.618988,176.520401 430.311920,174.762650 429.004883,173.004883 
z"></path>
<path fill="#474546" opacity="1.000000" stroke="none" d="
M435.612183,175.919434 
C435.396210,167.517487 435.569458,159.137054 435.919189,150.372742 
C436.095734,149.988861 436.582397,150.028488 436.941406,150.335358 
C437.595001,151.199692 437.889618,151.757141 438.203552,152.759506 
C438.265411,160.738815 438.307953,168.273209 438.350494,175.807617 
C437.567474,175.852066 436.784424,175.896515 435.612183,175.919434 
z"></path>
<path fill="#959394" opacity="1.000000" stroke="none" d="
M444.555542,148.796051 
C447.737701,149.093994 450.691925,149.102646 453.302887,150.053696 
C454.552979,150.509048 455.152802,152.749695 455.614044,154.572281 
C454.405212,154.863190 453.629456,154.763565 452.857788,154.274796 
C451.940216,153.268188 451.058075,152.201645 450.089630,152.116501 
C447.041595,151.848557 443.961182,151.949219 440.940308,151.556549 
C441.040527,150.502579 441.093719,149.799271 441.146942,149.095947 
C442.160156,149.028580 443.173401,148.961227 444.555542,148.796051 
z"></path>
<path fill="#535253" opacity="1.000000" stroke="none" d="
M440.785583,149.057465 
C441.093719,149.799271 441.040527,150.502579 440.601868,151.610596 
C439.539032,152.115051 438.861603,152.214828 438.184204,152.314606 
C437.889618,151.757141 437.595001,151.199692 437.185028,150.350739 
C438.187836,149.712479 439.306030,149.365723 440.785583,149.057465 
z"></path>
<path fill="#B4B2B3" opacity="1.000000" stroke="none" d="
M238.251129,173.060211 
C237.809799,169.360489 237.368469,165.660782 236.883392,161.518723 
C236.822754,159.948013 237.042572,158.753006 236.747009,157.703079 
C235.615158,153.682541 237.145493,151.884888 241.593262,151.993042 
C242.079117,152.719925 242.169174,153.416626 242.358521,154.824554 
C243.791138,156.337524 245.124512,157.139267 246.457886,157.941010 
C246.945557,156.944931 247.433243,155.948853 247.920929,154.952789 
C247.920929,154.952789 248.115952,154.511932 248.540359,154.277740 
C249.609894,154.013901 250.255035,153.984253 251.030670,154.213959 
C251.435837,154.976807 251.710480,155.480286 251.636902,156.046875 
C249.538666,158.014648 246.416412,160.114090 252.236389,160.986908 
C252.663773,160.993195 252.834747,160.993225 253.003052,160.996521 
C253.000397,160.999771 253.007065,160.992340 253.005219,161.341461 
C253.011688,162.388779 253.020020,163.086975 252.694504,163.892639 
C251.009903,164.751297 249.659134,165.502487 248.155365,165.867828 
C246.251846,164.892761 244.501343,164.303558 242.003906,163.462936 
C242.003906,166.364868 241.865784,167.999023 242.033783,169.601074 
C242.345551,172.574173 240.936554,173.465897 238.251129,173.060211 
z"></path>
<path fill="#201E1F" opacity="1.000000" stroke="none" d="
M262.588379,149.038177 
C262.962280,149.531876 262.986206,149.999817 263.034760,151.020706 
C263.059418,151.573654 262.919189,151.964691 262.592468,151.962250 
C261.829254,151.962631 261.392761,151.965439 260.605713,151.970871 
C259.331543,151.963852 258.407959,151.954193 257.346985,151.594055 
C257.129395,150.496780 257.049225,149.749985 256.969055,149.003189 
C258.725494,149.006271 260.481903,149.009354 262.588379,149.038177 
z"></path>
<path fill="#656364" opacity="1.000000" stroke="none" d="
M247.524475,154.971436 
C247.433243,155.948853 246.945557,156.944931 246.457886,157.941010 
C245.124512,157.139267 243.791138,156.337524 242.430435,155.169083 
C243.978058,154.864960 245.553040,154.927521 247.524475,154.971436 
z"></path>
<path fill="#605F60" opacity="1.000000" stroke="none" d="
M256.595642,149.008331 
C257.049225,149.749985 257.129395,150.496780 257.203796,151.640045 
C257.198029,152.036514 256.900757,152.024887 256.541412,152.018234 
C255.463654,151.996857 254.745255,151.982147 254.043793,151.916107 
C254.060745,151.864761 254.103897,151.765640 254.069489,151.423920 
C254.016617,150.396698 253.998184,149.711197 253.979752,149.025711 
C254.727234,149.021622 255.474716,149.017548 256.595642,149.008331 
z"></path>
<path fill="#817F80" opacity="1.000000" stroke="none" d="
M253.617523,148.998367 
C253.998184,149.711197 254.016617,150.396698 253.687057,151.464798 
C252.578949,151.950241 251.818848,152.053070 251.028931,152.031677 
C250.999100,151.907455 250.914658,151.666321 250.949966,151.348663 
C251.017715,150.390457 251.050140,149.749924 251.082565,149.109375 
C251.806808,149.063263 252.531067,149.017136 253.617523,148.998367 
z"></path>
<path fill="#9B999A" opacity="1.000000" stroke="none" d="
M250.900177,153.954590 
C250.255035,153.984253 249.609894,154.013901 248.642883,154.059601 
C248.349426,153.447968 248.377869,152.820297 248.967804,152.015656 
C249.991089,151.781219 250.452866,151.723770 250.914658,151.666321 
C250.914658,151.666321 250.999100,151.907455 251.007294,152.331696 
C250.977051,153.155502 250.938614,153.555038 250.900177,153.954590 
z"></path>
<path fill="#605F60" opacity="1.000000" stroke="none" d="
M250.949966,151.348663 
C250.452866,151.723770 249.991089,151.781219 249.182373,151.774902 
C249.357956,150.845673 249.880463,149.980225 250.742767,149.112091 
C251.050140,149.749924 251.017715,150.390457 250.949966,151.348663 
z"></path>
<path fill="#131213" opacity="1.000000" stroke="none" d="
M274.969238,178.953430 
C266.775696,179.107712 266.775696,179.107712 267.081146,170.482254 
C267.553802,169.870789 268.333832,169.738586 268.401703,169.442947 
C269.670288,163.916458 270.854248,158.370575 272.048462,152.402893 
C273.803467,146.994125 277.750793,148.742889 281.601440,149.376434 
C281.657654,151.166122 281.326385,152.579147 280.686340,154.147186 
C278.878204,157.108322 276.577454,159.773956 276.055420,162.751434 
C275.125916,168.052612 275.272644,173.542480 274.969238,178.953430 
z"></path>
<path fill="#0F0D0E" opacity="1.000000" stroke="none" d="
M293.657410,175.631134 
C293.031525,176.695557 292.405670,177.759995 291.384705,178.522949 
C289.990143,177.485870 288.990662,176.750259 287.990356,175.677185 
C287.669281,174.560318 287.349030,173.780914 287.027161,172.538879 
C287.337280,165.392792 287.648987,158.709335 287.971497,151.647705 
C287.988190,150.512878 287.994110,149.756226 288.000000,148.999588 
C290.023376,149.652359 293.211090,147.218628 293.966064,152.072998 
C293.866760,160.475616 293.762085,168.053375 293.657410,175.631134 
z"></path>
<path fill="#656364" opacity="1.000000" stroke="none" d="
M294.001709,175.706131 
C293.762085,168.053375 293.866760,160.475616 294.006134,152.472565 
C294.481018,152.056564 294.921173,152.065857 295.715759,152.459473 
C296.150757,153.660767 296.231354,154.477737 296.311951,155.294708 
C296.375153,156.907593 296.438354,158.520477 296.487213,160.931580 
C296.563049,163.525467 296.653259,165.321136 296.743439,167.116806 
C297.095581,169.832184 297.447754,172.547562 297.912476,175.619995 
C296.798645,175.911758 295.572327,175.846451 294.001709,175.706131 
z"></path>
<path fill="#888787" opacity="1.000000" stroke="none" d="
M296.648926,155.137268 
C296.231354,154.477737 296.150757,153.660767 296.045471,152.435242 
C297.109650,151.023788 298.198547,150.020889 299.598816,148.918762 
C303.057617,148.855133 306.235321,148.645508 309.340149,149.027740 
C310.941101,149.224808 312.412506,150.474411 313.963806,151.628723 
C313.985138,152.009003 313.817719,152.005707 313.390198,152.002029 
C310.642212,151.998642 308.321716,151.998917 306.001221,151.999191 
C302.661346,151.980270 298.877563,150.618622 296.648926,155.137268 
z"></path>
<path fill="#191718" opacity="1.000000" stroke="none" d="
M287.571289,148.993835 
C287.994110,149.756226 287.988190,150.512878 287.590302,151.641693 
C285.130585,152.673309 283.062836,153.332748 280.995117,153.992188 
C281.326385,152.579147 281.657654,151.166122 281.991699,149.386673 
C283.710541,149.009537 285.426544,148.998810 287.571289,148.993835 
z"></path>
<path fill="#201E1F" opacity="1.000000" stroke="none" d="
M459.182617,151.382721 
C459.883606,151.084717 460.963593,151.048798 461.082367,151.369247 
C463.514954,157.930939 467.799805,153.242920 471.326294,153.139236 
C473.167358,153.085114 475.013855,153.216003 476.546051,153.637482 
C466.452118,156.472931 464.355713,159.657608 467.080200,169.183899 
C469.061737,165.060425 470.746002,161.555618 472.430237,158.050812 
C472.836792,158.121490 473.243317,158.192169 473.649872,158.262848 
C473.649872,161.546509 474.829865,165.509445 473.410095,167.975830 
C470.217438,173.522125 473.512695,172.995300 476.998779,172.999390 
C477.303894,174.741028 477.608978,176.482651 477.962524,178.600647 
C473.682037,178.987930 469.352936,179.029480 465.024261,179.002029 
C459.540588,178.967239 459.038818,178.061844 461.019714,172.873566 
C461.280060,172.191635 460.185455,170.992371 458.977722,170.017792 
C457.504913,169.958664 456.772858,169.914444 456.031799,169.534897 
C456.043671,168.526382 456.064514,167.853210 456.313049,167.047623 
C456.732788,166.627151 456.924866,166.339081 457.116943,166.051010 
C457.782623,161.304260 458.448303,156.557510 459.182617,151.382721 
z"></path>
<path fill="#BBBBBB" opacity="1.000000" stroke="none" d="
M438.203552,152.759506 
C438.861603,152.214828 439.539032,152.115051 440.554871,151.961243 
C443.961182,151.949219 447.041595,151.848557 450.089630,152.116501 
C451.058075,152.201645 451.940216,153.268188 452.437256,154.327682 
C450.430450,154.919586 448.848206,155.069443 446.964172,155.247894 
C447.570862,157.787018 445.161041,161.279480 450.064972,160.695755 
C450.064972,160.695755 450.514282,160.900360 450.704956,161.333801 
C450.150757,162.480865 449.270966,163.101181 448.696136,163.932388 
C448.020416,164.909531 447.609314,166.069672 447.080841,167.502197 
C447.149628,168.550537 447.221008,169.247131 447.238373,170.290268 
C447.790253,171.423050 448.396179,172.209305 448.878235,173.316727 
C446.798279,174.797714 444.842133,175.957535 442.885986,177.117340 
C442.459747,177.091949 442.033508,177.066544 441.289124,177.081024 
C440.279724,176.762863 439.588501,176.404846 438.623871,175.927216 
C438.307953,168.273209 438.265411,160.738815 438.203552,152.759506 
z"></path>
<path fill="#656364" opacity="1.000000" stroke="none" d="
M450.071075,160.323608 
C445.161041,161.279480 447.570862,157.787018 446.964172,155.247894 
C448.848206,155.069443 450.430450,154.919586 452.433228,154.716843 
C453.629456,154.763565 454.405212,154.863190 455.572632,154.952484 
C455.077057,155.959534 454.287567,157.090775 453.273987,157.961060 
C452.332703,158.769287 451.152283,159.299072 450.071075,160.323608 
z"></path>
<path fill="#A7A6A6" opacity="1.000000" stroke="none" d="
M447.083435,167.150436 
C447.609314,166.069672 448.020416,164.909531 448.696136,163.932388 
C449.270966,163.101181 450.150757,162.480865 450.935120,161.422913 
C451.734039,161.040527 452.493378,161.002441 453.618713,161.335480 
C453.590424,163.386658 453.196167,165.066727 452.394196,166.762360 
C450.352142,166.902100 448.717773,167.026276 447.083435,167.150436 
z"></path>
<path fill="#656364" opacity="1.000000" stroke="none" d="
M452.801910,166.746796 
C453.196167,165.066727 453.590424,163.386658 454.000519,161.334106 
C454.675049,161.200729 455.771118,161.292847 455.910156,161.706573 
C456.320679,162.928146 456.352600,164.276932 456.824829,165.813477 
C456.924866,166.339081 456.732788,166.627151 455.900879,166.997787 
C454.441345,166.969177 453.621613,166.857986 452.801910,166.746796 
z"></path>
<path fill="#BBBBBB" opacity="1.000000" stroke="none" d="
M262.919189,151.964691 
C262.919189,151.964691 263.059418,151.573654 263.090210,151.368317 
C264.314392,150.892090 265.507751,150.621201 266.855896,151.016083 
C267.650940,152.789322 268.291199,153.896805 268.931519,155.004303 
C267.918243,159.688278 266.904968,164.372238 265.835754,169.481293 
C265.063965,171.187332 264.348145,172.468262 263.315674,173.497208 
C262.996582,172.250992 263.288422,171.127670 262.922333,170.292938 
C262.379639,169.055588 261.370544,168.022797 260.559998,166.902939 
C259.675537,168.143570 258.061676,169.394653 258.079254,170.622360 
C258.105011,172.419083 259.300201,174.199036 259.997009,175.986145 
C259.528015,176.300919 259.059021,176.615707 258.292755,177.015259 
C256.872192,175.821442 255.748886,174.542877 254.324524,172.701355 
C254.204025,169.292969 256.667053,166.175613 253.028336,163.785156 
C253.020020,163.086975 253.011688,162.388779 253.401764,161.345062 
C254.858109,160.988495 255.916061,160.977448 256.993408,161.350189 
C258.006927,165.670410 260.780640,164.099625 262.063934,162.676376 
C263.903870,160.635803 266.629303,157.953522 263.008301,154.598038 
C263.025787,153.470795 262.972473,152.717743 262.919189,151.964691 
z"></path>
<path fill="#B4B2B3" opacity="1.000000" stroke="none" d="
M269.009888,154.651855 
C268.291199,153.896805 267.650940,152.789322 267.021484,151.303085 
C267.717621,152.049362 268.402954,153.174393 269.009888,154.651855 
z"></path>
<path fill="#B4B2B3" opacity="1.000000" stroke="none" d="
M552.644653,151.820724 
C553.615173,151.801300 554.378052,151.869293 555.522583,151.960480 
C555.934204,152.746414 555.964172,153.509155 556.055359,154.648834 
C555.347473,156.010330 554.578247,156.994904 553.439575,158.025055 
C553.070190,158.070618 552.898071,157.898956 552.895020,157.502777 
C552.580017,156.367737 552.267944,155.628860 551.969482,154.538055 
C551.992920,153.477417 552.002869,152.768692 552.012756,152.059967 
C552.012756,152.059967 552.436951,151.908127 552.644653,151.820724 
z"></path>
<path fill="#110F10" opacity="1.000000" stroke="none" d="
M407.619934,149.008667 
C408.003876,150.406754 408.005341,151.793121 408.003296,154.049347 
C408.454803,158.413727 406.399261,162.569489 410.632721,165.192078 
C410.708954,165.239304 409.571716,168.476746 408.558014,168.789139 
C406.598663,169.392944 404.322998,168.970337 402.106506,168.622574 
C401.996796,167.557404 401.959076,166.842850 401.921356,166.128311 
C401.770508,160.494415 401.619629,154.860504 401.758362,149.059219 
C402.771759,148.907578 403.495544,148.923325 404.580017,148.913879 
C405.706299,148.924759 406.471893,148.960846 407.619934,149.008667 
z"></path>
<path fill="#9B999A" opacity="1.000000" stroke="none" d="
M401.602539,165.954620 
C401.959076,166.842850 401.996796,167.557404 402.029938,168.901215 
C401.961029,169.712082 401.896698,169.893661 401.423004,170.061356 
C398.849976,169.807465 395.826080,171.052353 395.885376,166.628326 
C396.323608,163.756119 396.717804,161.306671 397.112030,158.857208 
C397.740723,159.619049 398.487976,160.312958 398.971924,161.157700 
C399.827057,162.650360 400.521820,164.234863 401.602539,165.954620 
z"></path>
<path fill="#CDCBCC" opacity="1.000000" stroke="none" d="
M397.069946,158.513458 
C396.717804,161.306671 396.323608,163.756119 395.554810,166.615448 
C394.513367,167.029129 393.846527,167.032928 393.120178,166.575256 
C392.999573,162.381393 392.938416,158.648972 392.877258,154.916565 
C392.877258,154.916565 392.970856,154.907990 392.984131,154.953094 
C393.714325,154.997437 394.431244,154.996674 395.506531,155.006424 
C396.252502,156.067871 396.640167,157.118790 397.069946,158.513458 
z"></path>
<path fill="#888787" opacity="1.000000" stroke="none" d="
M187.750702,154.398132 
C187.641769,153.936325 187.731903,153.477737 187.709930,153.245026 
C188.457932,152.982010 189.227921,152.951691 190.480148,152.953461 
C190.962387,152.985550 191.161606,153.107666 191.073517,153.550171 
C191.041580,157.319885 191.097733,160.647125 191.094879,164.397690 
C191.719986,167.851501 192.404114,170.881989 193.088242,173.912460 
C193.057739,174.110474 193.027252,174.308502 192.957123,174.804504 
C192.165756,175.311493 191.414047,175.520462 190.366592,175.777679 
C189.362320,173.756256 188.181000,171.718155 188.040329,169.610580 
C187.713318,164.711517 187.888168,159.778931 187.750702,154.398132 
z"></path>
<path fill="#959394" opacity="1.000000" stroke="none" d="
M199.632019,149.149124 
C198.482574,150.588898 196.908218,151.962341 194.854980,153.525085 
C193.304596,153.512131 192.233093,153.309906 191.161606,153.107666 
C191.161606,153.107666 190.962387,152.985550 190.862946,152.923248 
C192.100586,148.299698 195.787674,148.990341 199.632019,149.149124 
z"></path>
<path fill="#656364" opacity="1.000000" stroke="none" d="
M317.259827,152.070740 
C317.686432,152.777740 317.772705,153.462845 317.489868,154.544769 
C316.409821,155.958633 315.698883,156.975647 314.969910,157.971588 
C314.951843,157.950531 314.897034,157.941589 314.574554,157.962250 
C313.646973,158.343475 313.041870,158.704056 312.436768,159.064636 
C313.279633,159.714264 314.122528,160.363892 314.998840,161.436554 
C314.988800,163.548950 314.945312,165.238312 314.901825,166.927673 
C314.096039,166.936874 313.290253,166.946075 312.101135,166.823761 
C311.717834,165.080948 311.717834,163.469635 311.717834,161.705963 
C308.768707,160.342667 302.121704,162.411026 305.979980,155.036346 
C309.432373,154.892929 313.794922,158.819885 313.817719,152.005707 
C313.817719,152.005707 313.985138,152.009003 314.069702,152.006042 
C315.075989,152.018341 315.997742,152.033600 317.259827,152.070740 
z"></path>
<path fill="#222021" opacity="1.000000" stroke="none" d="
M280.686340,154.147186 
C283.062836,153.332748 285.130585,152.673309 287.579529,152.019897 
C287.648987,158.709335 287.337280,165.392792 286.603577,172.539413 
C278.016083,172.885117 278.016083,172.885117 278.009949,178.974182 
C277.250000,178.979599 276.490051,178.985016 275.349670,178.971924 
C275.272644,173.542480 275.125916,168.052612 276.055420,162.751434 
C276.577454,159.773956 278.878204,157.108322 280.686340,154.147186 
z"></path>
<path fill="#1B1A1A" opacity="1.000000" stroke="none" d="
M278.458984,178.987335 
C278.016083,172.885117 278.016083,172.885117 286.605194,173.002060 
C287.349030,173.780914 287.669281,174.560318 287.867249,175.967194 
C287.498016,177.383926 287.251099,178.173203 287.004150,178.962463 
C284.305420,178.975143 281.606720,178.987808 278.458984,178.987335 
z"></path>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" d="
M260.315002,175.943817 
C259.300201,174.199036 258.105011,172.419083 258.079254,170.622360 
C258.061676,169.394653 259.675537,168.143570 260.559998,166.902939 
C261.370544,168.022797 262.379639,169.055588 262.922333,170.292938 
C263.288422,171.127670 262.996582,172.250992 263.051361,173.697266 
C262.280121,174.733383 261.456573,175.317444 260.315002,175.943817 
z"></path>
<path fill="#191718" opacity="1.000000" stroke="none" d="
M287.404968,178.981781 
C287.251099,178.173203 287.498016,177.383926 287.868073,176.304657 
C288.990662,176.750259 289.990143,177.485870 291.003723,178.587692 
C289.947174,178.969620 288.876465,178.985352 287.404968,178.981781 
z"></path>
<path fill="#222021" opacity="1.000000" stroke="none" d="
M167.414307,172.043488 
C167.015289,171.550659 167.014618,171.112701 167.026367,170.346725 
C168.024963,168.875916 169.747009,167.801956 169.861465,166.577682 
C170.227448,162.663086 169.997299,158.692764 169.997299,154.592697 
C171.225479,154.322021 172.014282,154.201675 172.770355,153.973114 
C178.998337,152.090408 178.996826,152.084320 178.998505,158.631744 
C179.000168,165.103333 179.001053,171.574921 179.006104,178.512817 
C177.936172,178.986465 176.862411,178.993774 175.385818,178.985992 
C175.255341,177.268112 175.527679,175.565338 175.835266,173.642090 
C173.032639,173.102798 170.422653,172.600586 167.414307,172.043488 
z"></path>
<path fill="#CDCBCC" opacity="1.000000" stroke="none" d="
M154.876984,169.814957 
C155.201340,166.177872 155.525696,162.540787 156.093109,158.230713 
C156.890488,158.254608 157.889740,158.930771 157.923370,159.651947 
C158.083542,163.088043 157.981247,166.536377 157.688583,170.213684 
C157.290619,171.660645 157.176392,172.874252 157.062164,174.087860 
C156.583542,174.675507 156.104935,175.263138 155.014557,175.918030 
C153.945084,175.971954 153.487396,175.958664 153.061188,175.588501 
C153.687439,173.426071 154.282211,171.620514 154.876984,169.814957 
z"></path>
<path fill="#959394" opacity="1.000000" stroke="none" d="
M157.393051,174.026917 
C157.176392,172.874252 157.290619,171.660645 158.025879,170.226028 
C159.319504,170.022247 159.992096,170.039459 160.782623,170.026825 
C160.900558,169.996948 161.141541,169.963516 161.056885,170.321976 
C160.904755,171.413010 160.837265,172.145599 160.769775,172.878174 
C159.754501,173.240784 158.739212,173.603378 157.393051,174.026917 
z"></path>
<path fill="#9B999A" opacity="1.000000" stroke="none" d="
M153.029694,175.945374 
C153.487396,175.958664 153.945084,175.971954 154.744385,176.020782 
C155.046066,176.481644 155.006180,176.906982 155.012970,177.650528 
C153.919556,178.289001 152.779465,178.609268 151.297943,178.505127 
C151.647568,177.368927 152.338623,176.657150 153.029694,175.945374 
z"></path>
<path fill="#2A2A2A" opacity="1.000000" stroke="none" d="
M161.168701,172.918304 
C160.837265,172.145599 160.904755,171.413010 161.394714,170.333191 
C162.489182,169.972702 163.161209,169.959457 163.833221,169.946213 
C163.879379,170.700653 163.925552,171.455078 163.968414,172.586792 
C163.165955,172.962189 162.366791,172.960312 161.168701,172.918304 
z"></path>
<path fill="#D2D0D1" opacity="1.000000" stroke="none" d="
M193.338501,173.763092 
C192.404114,170.881989 191.719986,167.851501 191.515106,164.405762 
C193.700577,164.011108 195.406845,164.031723 197.113098,164.052353 
C197.064148,164.753036 197.015198,165.453735 196.944077,166.504852 
C196.306549,168.308762 195.691162,169.762238 195.119598,171.582840 
C194.638519,172.504562 194.113632,173.059143 193.338501,173.763092 
z"></path>
<path fill="#DDDCDC" opacity="1.000000" stroke="none" d="
M238.174271,173.243942 
C240.936554,173.465897 242.345551,172.574173 242.033783,169.601074 
C241.865784,167.999023 242.003906,166.364868 242.003906,163.462936 
C244.501343,164.303558 246.251846,164.892761 248.143661,166.291046 
C248.273346,169.114624 248.261703,171.129120 248.250076,173.143616 
C247.882324,174.137192 247.514542,175.130753 247.017517,176.427673 
C244.204285,175.798218 241.520325,174.865387 238.473450,173.879150 
C238.110535,173.825760 238.097412,173.427673 238.174271,173.243942 
z"></path>
<path fill="#767676" opacity="1.000000" stroke="none" d="
M248.663742,173.115646 
C248.261703,171.129120 248.273346,169.114624 248.296661,166.676910 
C249.659134,165.502487 251.009903,164.751297 252.694504,163.892639 
C256.667053,166.175613 254.204025,169.292969 254.047211,172.530029 
C252.406464,172.976974 250.741943,173.032333 248.663742,173.115646 
z"></path>
<path fill="#201E1F" opacity="1.000000" stroke="none" d="
M127.982353,172.992493 
C127.992981,167.303009 128.003601,161.613525 128.027527,155.454620 
C130.384537,154.217514 132.864685,153.719177 135.039261,152.617615 
C138.126678,151.053635 143.584518,152.679932 144.596680,155.968430 
C144.733017,156.411377 143.396820,157.307541 142.484344,157.696442 
C140.940430,154.938828 138.405167,154.081543 137.389984,155.896454 
C136.091202,158.218353 135.246994,160.794525 134.206894,163.261139 
C134.528198,163.710800 134.849503,164.160477 135.170807,164.610153 
C137.438660,163.407120 139.706528,162.204102 141.974380,161.001068 
C141.974380,161.001068 141.998123,161.002884 142.008499,161.382965 
C143.384430,163.417587 144.749985,165.072128 146.115540,166.726669 
C143.395905,167.526901 139.072922,165.190475 138.104630,170.576065 
C136.887024,169.709808 136.238693,168.798065 134.691147,166.621735 
C134.393036,172.481323 132.599991,174.854172 127.982353,172.992493 
z"></path>
<path fill="#161415" opacity="1.000000" stroke="none" d="
M127.971893,173.421997 
C132.599991,174.854172 134.393036,172.481323 134.691147,166.621735 
C136.238693,168.798065 136.887024,169.709808 137.826523,170.840057 
C138.671158,172.820847 139.224625,174.583176 139.894913,176.679016 
C136.266754,177.682114 132.521759,178.351685 128.310181,179.013519 
C127.882874,177.287659 127.922150,175.569580 127.971893,173.421997 
z"></path>
<path fill="#BBBBBB" opacity="1.000000" stroke="none" d="
M153.061188,175.588501 
C152.338623,176.657150 151.647568,177.368927 150.931854,178.414124 
C150.486115,178.798508 150.065048,178.849503 149.327972,178.941040 
C149.011978,178.981583 149.155350,178.917938 149.109039,178.480286 
C147.991760,177.413696 146.920807,176.784744 145.849838,176.155777 
C145.849838,176.155777 145.486450,176.032257 145.298431,175.994247 
C144.557541,174.446442 144.004654,172.936646 143.087341,170.431641 
C145.932083,170.284714 148.494736,170.152359 151.479248,169.953308 
C152.648087,169.905243 153.395065,169.923874 154.509491,169.878738 
C154.282211,171.620514 153.687439,173.426071 153.061188,175.588501 
z"></path>
<path fill="#070506" opacity="1.000000" stroke="none" d="
M146.458481,166.792267 
C144.749985,165.072128 143.384430,163.417587 142.020294,161.384583 
C144.067276,160.019897 146.112854,159.033676 148.524109,158.056625 
C148.912231,160.710205 148.934647,163.354599 148.924164,166.433197 
C148.194626,166.864212 147.498032,166.861038 146.458481,166.792267 
z"></path>
<path fill="#888787" opacity="1.000000" stroke="none" d="
M145.878998,176.530319 
C146.920807,176.784744 147.991760,177.413696 149.107635,178.401871 
C148.071075,178.142349 146.989624,177.523605 145.878998,176.530319 
z"></path>
<path fill="#767676" opacity="1.000000" stroke="none" d="
M187.818146,112.564163 
C188.615387,112.697205 189.412613,112.830254 190.614182,113.007545 
C188.961700,114.030487 186.974792,115.607582 184.832886,115.857773 
C180.912094,116.315773 176.899704,115.989861 172.457230,116.001411 
C169.702377,115.220604 167.415833,114.429237 165.545624,113.167831 
C173.247375,112.653244 180.532761,112.608704 187.818146,112.564163 
z"></path>
<path fill="#DDDCDC" opacity="1.000000" stroke="none" d="
M187.965103,112.381683 
C180.532761,112.608704 173.247375,112.653244 165.505173,112.794846 
C165.048355,112.891907 164.613190,112.737572 164.402008,112.644028 
C164.103409,111.941788 164.015961,111.333092 163.945190,110.417191 
C164.502625,109.775208 165.046143,109.145233 165.583771,109.150200 
C172.904449,109.217896 180.224304,109.373512 187.801453,109.812363 
C188.076385,110.811256 188.094223,111.505226 187.965103,112.381683 
z"></path>
<path fill="#383637" opacity="1.000000" stroke="none" d="
M564.815430,108.629066 
C561.183472,107.482056 560.962891,105.752251 564.337524,102.963715 
C564.770813,104.686440 564.831482,106.441002 564.815430,108.629066 
z"></path>
<path fill="#110F10" opacity="1.000000" stroke="none" d="
M557.950195,43.433609 
C559.003784,43.025311 560.061951,43.011818 561.513000,42.995583 
C561.938721,61.016129 561.971680,79.039421 562.001038,97.531357 
C561.174194,99.046104 560.350830,100.092201 559.527527,101.138298 
C559.841064,101.538086 560.154663,101.937881 560.468262,102.337677 
C557.345215,103.878639 555.963745,102.867874 555.977783,99.118080 
C556.039307,82.659554 555.944092,66.200073 556.098206,49.742744 
C556.116760,47.765686 557.307373,45.799591 557.950195,43.433609 
z"></path>
<path fill="#C6AD7D" opacity="1.000000" stroke="none" d="
M96.041862,100.945236 
C96.480751,101.563438 96.919632,102.181641 97.627045,103.335999 
C98.657707,104.901031 99.419861,105.929901 100.182007,106.958771 
C100.223000,111.616348 100.263992,116.273933 100.219772,121.784515 
C100.189293,123.469376 100.244041,124.301239 100.298790,125.133102 
C100.298790,125.133102 100.189919,125.147530 99.757896,125.090805 
C98.557266,125.032234 97.788658,125.030380 97.013237,124.561066 
C96.940880,118.277428 97.620819,112.429291 94.629295,106.911598 
C93.867546,105.506592 94.783081,103.192215 95.213028,101.229019 
C95.679344,101.089989 95.860603,101.017616 96.041862,100.945236 
z"></path>
<path fill="#EEDABC" opacity="1.000000" stroke="none" d="
M96.036697,100.578247 
C95.860603,101.017616 95.679344,101.089989 94.945808,101.004883 
C92.293381,98.859184 90.193245,96.870979 88.057350,94.516205 
C85.342705,92.375816 82.591835,90.699242 80.012924,88.790337 
C78.551994,87.708984 77.373192,86.246460 76.442596,84.970062 
C79.873978,85.997322 82.930107,87.010124 85.986229,88.022919 
C91.073685,90.652451 93.572197,95.415710 96.036697,100.578247 
z"></path>
<path fill="#FAF0DB" opacity="1.000000" stroke="none" d="
M85.947693,87.652985 
C82.930107,87.010124 79.873978,85.997322 76.424561,84.653824 
C76.046074,83.900917 76.060875,83.478706 76.436844,83.041161 
C78.525528,82.011353 80.253059,80.996887 81.980598,79.982422 
C81.980598,79.982422 82.008820,80.008804 82.023468,80.023003 
C80.611290,83.904114 84.586372,84.885094 85.947693,87.652985 
z"></path>
<path fill="#D8BE96" opacity="1.000000" stroke="none" d="
M97.020050,125.028519 
C97.788658,125.030380 98.557266,125.032234 99.711220,125.061882 
C100.087914,126.466698 100.079269,127.843727 99.709000,129.650818 
C97.903564,131.060028 96.459732,132.039169 94.897476,132.799377 
C94.583656,132.301987 94.331512,132.091537 94.022621,131.949097 
C94.022621,131.949097 94.052116,131.496613 94.305305,131.034180 
C95.379013,128.723999 96.199532,126.876259 97.020050,125.028519 
z"></path>
<path fill="#EEDABC" opacity="1.000000" stroke="none" d="
M95.015900,133.018326 
C96.459732,132.039169 97.903564,131.060028 99.694717,130.065750 
C100.306099,133.415756 98.790047,135.656433 95.358261,136.957672 
C95.006752,137.006302 94.907837,136.914764 94.905289,136.500015 
C94.865364,135.336960 94.827995,134.588669 94.857956,133.637878 
C94.925293,133.435394 95.015900,133.018326 95.015900,133.018326 
z"></path>
<path fill="#EEDABC" opacity="1.000000" stroke="none" d="
M100.425934,106.708733 
C99.419861,105.929901 98.657707,104.901031 97.850311,103.530807 
C100.434357,102.811966 101.523819,103.783829 100.425934,106.708733 
z"></path>
<path fill="#EEDABC" opacity="1.000000" stroke="none" d="
M100.599098,125.044586 
C100.244041,124.301239 100.189293,123.469376 100.267639,122.236572 
C100.566963,122.875786 100.733185,123.915932 100.599098,125.044586 
z"></path>
<path fill="#CBA765" opacity="1.000000" stroke="none" d="
M82.990143,71.995033 
C82.997108,72.458786 83.004066,72.922531 83.047424,73.733788 
C82.451508,74.836998 81.819176,75.592712 80.816071,76.641998 
C80.445290,76.935577 80.018539,77.023537 79.642776,77.012474 
C78.516373,77.002708 77.765747,77.004005 76.876190,76.685165 
C76.501450,75.248291 76.265640,74.131561 76.282028,72.746155 
C76.699478,70.994431 76.864731,69.511383 77.346893,67.998909 
C78.441727,67.641830 79.219658,67.314171 80.352066,67.053375 
C81.467743,68.745171 82.228943,70.370102 82.990143,71.995033 
z"></path>
<path fill="#EEDABC" opacity="1.000000" stroke="none" d="
M80.018539,77.023537 
C80.018539,77.023537 80.445290,76.935577 80.659317,76.897446 
C81.224556,77.678596 81.575760,78.497879 81.953781,79.649780 
C80.253059,80.996887 78.525528,82.011353 76.528557,82.727097 
C76.478622,81.602570 76.698135,80.776772 76.933289,79.949249 
C76.948921,79.947533 76.951057,79.922020 77.277481,79.908577 
C78.408783,78.937935 79.213661,77.980736 80.018539,77.023537 
z"></path>
<path fill="#FAFBF9" opacity="1.000000" stroke="none" d="
M64.354370,162.946014 
C64.778160,161.371826 65.544624,159.740753 66.655365,158.055450 
C66.232109,159.630524 65.464577,161.259811 64.354370,162.946014 
z"></path>
<path fill="#201E1F" opacity="1.000000" stroke="none" d="
M239.001511,51.467987 
C239.002136,48.373875 238.790924,45.722115 239.107239,43.134850 
C239.241440,42.037125 240.482285,41.074696 241.636353,40.046303 
C242.038727,60.383385 242.024933,80.724937 241.623917,101.533417 
C240.491180,102.000206 239.745667,102.000069 239.000137,101.999931 
C238.999191,91.979919 238.998245,81.959908 238.997437,71.471626 
C238.999405,64.647713 239.001236,58.292076 239.001511,51.467987 
z"></path>
<path fill="#201E1F" opacity="1.000000" stroke="none" d="
M241.977371,106.996353 
C241.989227,107.748352 242.001083,108.500359 241.992493,109.627991 
C240.352280,109.232590 238.732513,108.461563 237.058258,107.347870 
C237.446869,107.002884 237.889969,107.000565 239.039246,106.999100 
C240.489395,106.998764 241.233383,106.997559 241.977371,106.996353 
z"></path>
<path fill="#191718" opacity="1.000000" stroke="none" d="
M241.991013,106.580673 
C241.233383,106.997559 240.489395,106.998764 239.371613,106.999054 
C238.998138,105.609734 238.998474,104.221329 238.999466,102.416428 
C239.745667,102.000069 240.491180,102.000206 241.606720,102.002045 
C241.986038,103.390831 241.995346,104.777916 241.991013,106.580673 
z"></path>
<path fill="#191718" opacity="1.000000" stroke="none" d="
M429.001984,173.001999 
C430.311920,174.762650 431.618988,176.520401 432.969482,178.617996 
C426.991974,178.972168 420.971008,178.986481 414.474487,178.546204 
C414.009033,175.395508 414.019196,172.699402 414.445129,170.003418 
C417.268860,169.972626 419.713776,169.534409 420.664459,172.897720 
C420.877502,173.651367 423.086761,174.218185 424.365479,174.196243 
C425.914459,174.169647 427.451141,173.426422 428.993164,172.993286 
C428.993164,172.993286 428.999084,172.999115 429.001984,173.001999 
z"></path>
<path fill="#2A2A2A" opacity="1.000000" stroke="none" d="
M414.029327,170.003296 
C414.019196,172.699402 414.009033,175.395508 414.007202,178.540192 
C412.631439,178.686340 411.247345,178.383926 409.510864,178.035004 
C408.859436,176.585693 408.560455,175.182892 408.261963,173.128113 
C410.184753,171.651855 412.107025,170.827576 414.029327,170.003296 
z"></path>
<path fill="#222021" opacity="1.000000" stroke="none" d="
M383.562225,175.996078 
C383.982880,176.761566 383.990753,177.503235 384.004822,178.615631 
C381.964325,178.993896 379.917633,179.001404 377.434631,178.585373 
C378.107544,172.682465 372.253021,173.008850 370.277863,170.100952 
C369.536102,171.441208 368.198883,172.784073 368.204773,174.121017 
C368.211975,175.753906 369.346466,177.381821 369.993469,179.011871 
C368.915771,179.012589 367.838043,179.013306 366.354736,179.006149 
C365.962708,177.278503 365.976257,175.558716 366.230408,173.265381 
C366.317474,172.130173 366.163971,171.568542 366.010437,171.006927 
C365.813232,170.739227 365.615997,170.471527 365.212158,169.710083 
C367.138275,164.072037 363.327942,163.731247 359.993408,163.005219 
C359.997955,163.000854 359.989868,163.011169 359.989868,163.011169 
C359.998993,161.186707 359.298492,158.828156 360.178406,157.659073 
C361.609375,155.757767 363.928223,153.759964 366.137421,153.391266 
C369.321594,152.859818 372.744720,153.988037 376.062897,153.965805 
C379.258667,153.944366 381.158722,154.663940 381.035248,158.345459 
C380.879669,162.984512 380.848724,167.640900 381.098389,172.271790 
C381.166687,173.538437 382.432953,174.740524 383.562225,175.996078 
z"></path>
<path fill="#191718" opacity="1.000000" stroke="none" d="
M370.431427,179.016510 
C369.346466,177.381821 368.211975,175.753906 368.204773,174.121017 
C368.198883,172.784073 369.536102,171.441208 370.277863,170.100952 
C372.253021,173.008850 378.107544,172.682465 376.996460,178.575424 
C374.952850,178.999725 372.911102,179.010422 370.431427,179.016510 
z"></path>
<path fill="#DDDCDC" opacity="1.000000" stroke="none" d="
M393.179657,167.036713 
C393.846527,167.032928 394.513367,167.029129 395.510803,167.038208 
C395.826080,171.052353 398.849976,169.807465 401.528503,170.322296 
C402.388489,171.031204 402.733582,171.465271 403.078705,171.899353 
C400.355438,173.256958 397.632141,174.614563 394.518677,175.947571 
C393.780182,175.487808 393.431854,175.052628 393.016937,173.919708 
C393.026764,171.160202 393.103210,169.098465 393.179657,167.036713 
z"></path>
<path fill="#817F80" opacity="1.000000" stroke="none" d="
M393.120178,166.575256 
C393.103210,169.098465 393.026764,171.160202 392.898987,173.662949 
C392.426849,174.091339 392.006134,174.078735 391.271698,174.105652 
C389.990265,167.765533 387.959473,161.314926 392.547302,155.100388 
C392.938416,158.648972 392.999573,162.381393 393.120178,166.575256 
z"></path>
<path fill="#222021" opacity="1.000000" stroke="none" d="
M403.444305,171.936996 
C402.733582,171.465271 402.388489,171.031204 401.937866,170.336182 
C401.896698,169.893661 401.961029,169.712082 402.101929,169.251862 
C404.322998,168.970337 406.598663,169.392944 408.558014,168.789139 
C409.571716,168.476746 410.708954,165.239304 410.632721,165.192078 
C406.399261,162.569489 408.454803,158.413727 408.006866,154.465790 
C414.733063,154.012344 421.452209,154.012344 428.995605,154.012344 
C428.995605,160.942078 428.995605,166.500259 428.994385,172.525879 
C427.451141,173.426422 425.914459,174.169647 424.365479,174.196243 
C423.086761,174.218185 420.877502,173.651367 420.664459,172.897720 
C419.713776,169.534409 417.268860,169.972626 414.445129,170.003418 
C412.107025,170.827576 410.184753,171.651855 408.105652,172.797134 
C406.569214,172.736954 405.189575,172.355804 403.444305,171.936996 
z"></path>
<path fill="#070506" opacity="1.000000" stroke="none" d="
M354.390747,171.007721 
C353.990662,168.648254 353.980927,166.276245 354.023895,163.463623 
C355.765625,163.017578 357.454681,163.012146 359.566803,163.008942 
C359.989868,163.011169 359.997955,163.000854 359.995544,163.434265 
C359.975098,165.853790 359.957092,167.839905 359.939056,169.826019 
C359.441956,170.164413 358.944855,170.502808 358.174622,170.969452 
C356.861389,171.063522 355.821259,171.029343 354.390747,171.007721 
z"></path>
<path fill="#191718" opacity="1.000000" stroke="none" d="
M360.358612,169.873322 
C359.957092,167.839905 359.975098,165.853790 359.990997,163.438629 
C363.327942,163.731247 367.138275,164.072037 364.995941,169.581070 
C363.583588,169.937393 362.180878,169.929016 360.358612,169.873322 
z"></path>
<path fill="#191718" opacity="1.000000" stroke="none" d="
M365.994202,171.335876 
C366.163971,171.568542 366.317474,172.130173 366.250610,172.835907 
C366.012817,172.541611 365.995392,172.103226 365.994202,171.335876 
z"></path>
<path fill="#A0A0A0" opacity="1.000000" stroke="none" d="
M313.390198,152.002029 
C313.794922,158.819885 309.432373,154.892929 306.259308,154.809753 
C305.904846,154.042114 305.949615,153.362900 305.997803,152.341446 
C308.321716,151.998917 310.642212,151.998642 313.390198,152.002029 
z"></path>
<path fill="#B4B2B3" opacity="1.000000" stroke="none" d="
M297.068787,167.044952 
C296.653259,165.321136 296.563049,163.525467 296.638367,161.303574 
C300.385223,162.581512 299.637024,164.704926 297.068787,167.044952 
z"></path>
<path fill="#222021" opacity="1.000000" stroke="none" d="
M329.629211,152.001831 
C334.753662,152.702438 336.447327,155.651382 335.727936,160.780060 
C334.434723,162.687607 333.411407,163.985321 332.056366,165.703690 
C333.791992,165.836456 334.892944,165.920670 335.995819,166.422882 
C335.666870,168.895813 335.336029,170.950729 335.005188,173.005646 
C332.992065,172.353058 331.015656,171.504654 328.952728,171.117523 
C327.603394,170.864319 326.128632,171.279572 324.207886,171.436432 
C323.865997,170.380920 323.437317,169.057419 323.004913,167.367279 
C323.334564,164.605118 324.014801,162.196381 323.924072,159.817047 
C323.768402,155.735016 325.758057,153.439606 329.629211,152.001831 
z"></path>
<path fill="#131213" opacity="1.000000" stroke="none" d="
M335.009399,173.386688 
C335.336029,170.950729 335.666870,168.895813 336.044861,166.050873 
C338.016846,164.512985 339.941650,163.765091 341.866486,163.017212 
C341.930511,166.683182 341.994537,170.349136 342.074982,174.467346 
C342.059082,175.381622 342.026794,175.843658 341.752014,176.818207 
C341.337585,177.880554 341.165680,178.430359 340.993774,178.980164 
C339.612427,178.973419 338.231079,178.966690 336.436707,178.936600 
C335.686981,177.198074 335.350311,175.482910 335.009399,173.386688 
z"></path>
<path fill="#BBBBBB" opacity="1.000000" stroke="none" d="
M346.410767,175.998016 
C343.181488,168.941345 346.892273,162.339905 347.254761,155.572174 
C347.280640,155.089279 348.627716,154.170242 349.034454,154.322891 
C349.819366,154.617416 350.843536,155.477524 350.928680,156.201874 
C351.158844,158.160095 350.948608,160.170090 350.822998,162.593262 
C348.152588,164.130020 346.703735,169.467209 347.863953,172.909164 
C348.224213,173.977982 347.991272,175.246765 347.982666,176.786469 
C347.509796,176.805908 347.084106,176.462875 346.410767,175.998016 
z"></path>
<path fill="#1B1A1A" opacity="1.000000" stroke="none" d="
M341.287964,178.960846 
C341.165680,178.430359 341.337585,177.880554 341.753571,177.165680 
C341.859192,177.647583 341.720673,178.294556 341.287964,178.960846 
z"></path>
<path fill="#383637" opacity="1.000000" stroke="none" d="
M315.286041,166.958847 
C314.945312,165.238312 314.988800,163.548950 315.000549,161.052826 
C314.944916,159.477905 314.920990,158.709747 314.897034,157.941589 
C314.897034,157.941589 314.951843,157.950531 315.338013,157.974976 
C316.457489,157.995758 317.190796,157.992081 317.924103,157.988403 
C317.941437,160.689178 317.958771,163.389954 317.974182,166.539886 
C317.204956,166.989365 316.437592,166.989700 315.286041,166.958847 
z"></path>
<path fill="#222021" opacity="1.000000" stroke="none" d="
M517.004883,172.996429 
C516.471313,171.881912 515.903564,170.782166 515.413818,169.648743 
C514.916565,168.497955 513.895508,167.163071 514.144897,166.170258 
C515.304382,161.553497 516.825012,157.027390 518.147583,152.694107 
C528.180298,154.487167 532.768616,161.395935 533.578186,171.595367 
C527.773071,172.346161 522.388977,172.671295 517.004883,172.996429 
z"></path>
<path fill="#1B1A1A" opacity="1.000000" stroke="none" d="
M517.009766,173.381073 
C522.388977,172.671295 527.773071,172.346161 533.579834,172.011444 
C534.003052,174.035873 534.003601,176.069901 534.007019,178.538300 
C528.983826,178.984573 523.957825,178.996506 518.464722,179.000427 
C517.669922,177.250198 517.342285,175.507950 517.009766,173.381073 
z"></path>
<path fill="#D2D0D1" opacity="1.000000" stroke="none" d="
M543.953918,164.004028 
C545.323059,163.970306 546.692200,163.936569 548.468872,163.902542 
C546.878113,167.062714 548.892883,172.194992 543.575500,173.922424 
C543.049927,170.866928 542.906677,167.801453 543.101807,164.373718 
C543.611389,164.008972 543.782654,164.006500 543.953918,164.004028 
z"></path>
<path fill="#DDDCDC" opacity="1.000000" stroke="none" d="
M549.952881,157.963684 
C549.936035,158.769455 549.919189,159.575226 549.779724,160.770935 
C548.043396,161.104263 546.429626,161.047638 544.211670,160.741837 
C542.794250,160.316803 541.981140,160.140945 541.167969,159.965073 
C541.127136,159.529251 541.086365,159.093414 541.022461,158.329208 
C540.999451,158.000824 541.016357,157.983337 541.380493,157.985077 
C542.216431,157.931488 542.688354,157.876190 543.579590,157.888474 
C545.967163,157.921860 547.935425,157.887650 549.912231,157.882690 
C549.920715,157.911957 549.952881,157.963684 549.952881,157.963684 
z"></path>
<path fill="#BBBBBB" opacity="1.000000" stroke="none" d="
M541.083069,160.342682 
C541.981140,160.140945 542.794250,160.316803 543.799194,160.743683 
C543.979553,161.746933 543.968079,162.499146 543.955261,163.627686 
C543.782654,164.006500 543.611389,164.008972 543.183350,164.015778 
C542.283752,162.920166 541.640930,161.820221 541.083069,160.342682 
z"></path>
<path fill="#BBBBBB" opacity="1.000000" stroke="none" d="
M538.079529,157.237549 
C538.379578,157.057098 538.612671,157.218033 538.911255,157.622528 
C538.694763,157.713272 538.373352,157.617569 538.079529,157.237549 
z"></path>
<path fill="#DDDCDC" opacity="1.000000" stroke="none" d="
M490.008911,152.457169 
C491.131775,151.695480 492.218384,151.331177 493.637146,150.856628 
C493.494659,152.208328 492.650818,153.671616 492.656311,155.131729 
C492.661163,156.420013 493.613831,157.704742 494.619324,159.348846 
C496.050171,160.173218 497.009674,160.639801 497.969208,161.106400 
C498.860291,162.171509 499.773590,163.219055 500.633911,164.308441 
C501.134613,164.942429 501.550385,165.643509 502.019623,166.654785 
C500.242126,167.232910 498.449371,167.470657 496.302856,167.868713 
C495.486084,168.320786 495.023041,168.612534 494.288757,168.986847 
C493.648529,168.573212 493.279510,168.077042 492.499054,167.027603 
C492.034424,168.909683 491.713226,170.210709 491.392029,171.511734 
C490.918915,171.504990 490.445770,171.498230 489.972656,171.491486 
C489.972656,165.279175 489.972656,159.066864 490.008911,152.457169 
z"></path>
<path fill="#CDA76C" opacity="1.000000" stroke="none" d="
M44.105782,177.114594 
C44.565189,175.446930 45.270039,174.079193 46.379642,172.294800 
C50.935856,168.184845 48.209759,163.264343 49.505508,158.990814 
C51.631512,159.009750 53.336720,159.024261 55.045586,159.493057 
C54.045227,165.295898 53.041214,170.644440 52.037209,175.992996 
C49.475212,176.466827 46.913219,176.940674 44.105782,177.114594 
z"></path>
<path fill="#D8BE96" opacity="1.000000" stroke="none" d="
M52.265724,176.118347 
C53.041214,170.644440 54.045227,165.295898 55.042908,159.495056 
C55.036579,159.042755 55.055027,159.042114 55.424999,159.035095 
C56.537224,159.027222 57.279476,159.026337 58.033413,159.464569 
C58.093796,161.963684 58.142490,164.023697 58.191189,166.083694 
C58.355236,170.333405 57.639736,174.215012 53.412086,176.922089 
C52.898209,176.805878 52.696224,176.524780 52.265724,176.118347 
z"></path>
<path fill="#E9D7B9" opacity="1.000000" stroke="none" d="
M58.483181,166.013977 
C58.142490,164.023697 58.093796,161.963684 58.041706,159.221817 
C58.038315,158.539948 58.080498,158.056503 58.445175,158.041595 
C59.539604,158.017807 60.269352,158.008911 60.999100,158.000000 
C61.008038,159.712234 61.016979,161.424454 61.026711,163.564178 
C60.276726,164.642532 59.525948,165.293396 58.483181,166.013977 
z"></path>
<path fill="#D2D0D1" opacity="1.000000" stroke="none" d="
M551.955872,154.889984 
C552.267944,155.628860 552.580017,156.367737 552.808838,157.417084 
C552.036316,157.820206 551.346863,157.912857 550.305176,157.984589 
C549.952881,157.963684 549.920715,157.911957 549.903931,157.504333 
C549.837952,156.333206 549.788757,155.569702 549.760193,154.810822 
C549.780823,154.815445 549.821960,154.824707 550.177124,154.857773 
C551.006836,154.890564 551.481384,154.890274 551.955872,154.889984 
z"></path>
<path fill="#CBA765" opacity="1.000000" stroke="none" d="
M97.013237,124.561066 
C96.199532,126.876259 95.379013,128.723999 94.310165,130.807495 
C92.888374,128.856400 91.714920,126.669540 90.214417,123.835487 
C89.585915,121.235229 89.174103,119.291824 89.002304,117.327423 
C88.295357,109.243721 86.414291,101.763100 79.016968,96.611816 
C78.341011,95.503685 77.677185,94.745430 77.011597,93.646606 
C77.011459,92.851227 77.013077,92.396408 77.429276,91.946228 
C81.260269,92.928162 84.676689,93.905464 88.093102,94.882767 
C90.193245,96.870979 92.293381,98.859184 94.660736,101.071533 
C94.783081,103.192215 93.867546,105.506592 94.629295,106.911598 
C97.620819,112.429291 96.940880,118.277428 97.013237,124.561066 
z"></path>
<path fill="#DEC799" opacity="1.000000" stroke="none" d="
M79.029091,96.961685 
C86.414291,101.763100 88.295357,109.243721 89.002304,117.327423 
C89.174103,119.291824 89.585915,121.235229 89.934402,123.595993 
C89.643051,125.549416 89.016098,127.095810 89.019676,128.640732 
C89.031013,133.534454 87.446617,137.608521 83.247879,140.431549 
C83.403343,139.015091 84.090294,137.906158 84.319290,136.709564 
C84.745804,134.480850 84.905754,132.201141 85.162827,129.534546 
C85.156418,127.485367 85.163834,125.844292 85.448647,124.110703 
C85.536377,123.283218 85.346718,122.548225 85.156403,121.415359 
C85.150314,119.694305 85.144882,118.371132 85.382729,116.774796 
C84.995224,113.925652 84.772545,111.152237 83.643082,108.817490 
C81.689468,104.779175 79.173393,101.012970 77.247711,97.079666 
C78.077805,97.005043 78.553444,96.983360 79.029091,96.961685 
z"></path>
<path fill="#FAF0DB" opacity="1.000000" stroke="none" d="
M85.176659,129.942657 
C84.905754,132.201141 84.745804,134.480850 84.319290,136.709564 
C84.090294,137.906158 83.403343,139.015091 82.916672,140.544205 
C82.744537,141.212509 82.578758,141.500214 82.237381,141.967743 
C80.262321,143.022034 78.462845,143.896500 75.998199,144.899445 
C74.604073,145.049713 73.875130,145.071518 73.147972,144.736176 
C75.917252,141.566895 78.820557,138.872589 81.400787,135.898041 
C82.923813,134.142273 83.935188,131.942673 85.176659,129.942657 
z"></path>
<path fill="#D8BE96" opacity="1.000000" stroke="none" d="
M94.790627,133.840363 
C94.827995,134.588669 94.865364,135.336960 94.855835,136.454254 
C93.816109,137.542236 92.823280,138.261230 91.540192,139.088287 
C91.959854,137.482635 92.669769,135.768936 93.906143,133.964417 
C94.432594,133.873627 94.790627,133.840363 94.790627,133.840363 
z"></path>
<path fill="#CBA765" opacity="1.000000" stroke="none" d="
M94.857956,133.637878 
C94.790627,133.840363 94.432594,133.873627 94.253693,133.869202 
C94.057510,133.440231 94.040215,133.015686 94.022766,132.270126 
C94.331512,132.091537 94.583656,132.301987 94.897476,132.799377 
C95.015900,133.018326 94.925293,133.435394 94.857956,133.637878 
z"></path>
<path fill="#FAF0DB" opacity="1.000000" stroke="none" d="
M76.893265,97.132614 
C79.173393,101.012970 81.689468,104.779175 83.643082,108.817490 
C84.772545,111.152237 84.995224,113.925652 85.051201,116.613106 
C81.491486,111.565384 78.426109,106.450562 75.549301,101.231781 
C73.556046,97.615837 70.139389,96.842018 66.710266,97.177498 
C62.719582,97.567909 64.074104,101.180321 64.048569,103.569099 
C63.928654,114.783760 64.175720,126.004845 63.904564,137.214264 
C63.785435,142.138870 63.964569,146.295303 70.038826,147.350525 
C70.040062,147.583588 70.007896,148.048584 70.007896,148.048584 
C67.324486,148.350708 64.641083,148.652817 61.499599,148.577881 
C61.020512,147.472382 60.999496,146.743958 60.994904,145.545380 
C61.006565,128.387482 61.001801,111.699715 61.029877,94.780907 
C61.062721,94.549873 61.123169,94.087097 61.564209,94.064514 
C64.988617,94.041389 67.971985,94.040863 71.314133,94.051010 
C72.385178,94.089981 73.097450,94.118263 73.849358,94.131210 
C73.889000,94.115883 73.858673,94.195297 73.887680,94.543884 
C74.907547,95.629097 75.898399,96.365738 76.899780,97.105209 
C76.910316,97.108040 76.893265,97.132614 76.893265,97.132614 
z"></path>
<path fill="#E9D7B9" opacity="1.000000" stroke="none" d="
M70.007080,148.453751 
C70.007896,148.048584 70.040062,147.583588 70.281067,147.323196 
C70.522079,147.062805 71.006447,147.000687 71.322731,146.965729 
C72.115524,146.327972 72.592026,145.725189 73.085953,145.110809 
C73.103378,145.099213 73.146179,145.093323 73.146179,145.093323 
C73.875130,145.071518 74.604073,145.049713 75.698105,145.056519 
C76.053627,147.081177 76.044060,149.077255 76.073593,151.497986 
C73.817726,151.418640 70.048180,153.853592 70.007080,148.453751 
z"></path>
<path fill="#DEC799" opacity="1.000000" stroke="none" d="
M60.978481,146.015518 
C60.999496,146.743958 61.020512,147.472382 61.076595,148.561981 
C61.080772,150.666840 61.049881,152.410538 61.011257,154.929474 
C61.048325,156.158371 61.093117,156.612000 61.137913,157.065643 
C61.137913,157.065643 61.076042,157.533936 61.037571,157.766968 
C60.269352,158.008911 59.539604,158.017807 58.414425,157.573883 
C58.061443,153.379745 58.103889,149.638397 58.498268,145.926773 
C59.559631,145.976166 60.269054,145.995850 60.978481,146.015518 
z"></path>
<path fill="#F8E7CD" opacity="1.000000" stroke="none" d="
M61.443939,157.006622 
C61.093117,156.612000 61.048325,156.158371 61.021088,155.363556 
C61.480522,155.018204 61.922405,155.014038 62.696487,155.017822 
C62.602451,155.666382 62.176208,156.306992 61.443939,157.006622 
z"></path>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" d="
M449.665771,59.065502 
C451.083160,58.998512 452.140228,58.998032 453.593872,59.002342 
C453.676270,59.793079 453.362152,60.579018 453.022797,61.683617 
C451.514923,62.453201 450.032288,62.904121 447.638489,63.632149 
C448.323822,61.782101 448.814667,60.457054 449.665771,59.065502 
z"></path>
<path fill="#FAFBF9" opacity="1.000000" stroke="none" d="
M452.716980,94.008453 
C452.720520,95.121063 452.337006,96.236191 451.788452,97.681122 
C450.658783,98.008255 449.694092,98.005585 448.364716,98.001457 
C448.000000,98.000000 447.995117,97.995422 447.995117,97.995422 
C449.440063,96.667274 450.884979,95.339127 452.716980,94.008453 
z"></path>
<path fill="#4D4B4C" opacity="1.000000" stroke="none" d="
M395.173462,112.347794 
C392.750031,113.058861 389.857758,114.134621 390.015930,109.312698 
C390.460022,108.945953 390.857574,108.998711 391.555969,109.077171 
C392.926910,110.085739 393.997009,111.068596 395.173462,112.347794 
z"></path>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" d="
M395.993805,40.459217 
C397.386200,40.083050 398.796234,40.072788 400.623474,40.140541 
C399.720886,43.377605 398.186249,44.755455 395.993805,40.459217 
z"></path>
<path fill="#FAFBF9" opacity="1.000000" stroke="none" d="
M448.002441,98.002289 
C447.277954,98.972664 446.551025,99.940758 445.824097,100.908859 
C445.282867,100.445251 444.741669,99.981644 444.200439,99.518036 
C445.203979,99.012062 446.207520,98.506081 447.603088,97.997765 
C447.995117,97.995422 448.000000,98.000000 448.002441,98.002289 
z"></path>
<path fill="#3F3D3E" opacity="1.000000" stroke="none" d="
M451.546997,100.905418 
C452.057648,100.831711 452.251129,100.849823 452.726562,100.942039 
C452.980286,101.702950 452.952087,102.389755 452.882996,103.411125 
C452.157227,103.828133 451.472351,103.910576 450.446716,104.055885 
C450.086395,103.924698 450.066803,103.730637 450.012390,103.246109 
C450.394989,102.302834 450.812378,101.650032 451.546997,100.905418 
z"></path>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" d="
M269.183472,111.728081 
C268.250702,112.394203 267.443848,112.696861 266.310608,112.950722 
C266.795288,111.634117 267.606323,110.366318 268.782593,109.092896 
C269.201660,109.846390 269.255493,110.605499 269.183472,111.728081 
z"></path>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" d="
M248.545090,49.025227 
C248.139526,48.277889 248.084106,47.508869 248.069183,46.355389 
C249.013367,46.433216 249.917038,46.895504 250.820724,47.357788 
C250.178894,47.906372 249.537064,48.454956 248.545090,49.025227 
z"></path>
<path fill="#717071" opacity="1.000000" stroke="none" d="
M553.440063,49.786915 
C552.926941,47.501354 552.732605,44.979992 552.854492,42.212570 
C553.366760,44.494713 553.562805,47.022911 553.440063,49.786915 
z"></path>
<path fill="#717071" opacity="1.000000" stroke="none" d="
M553.458984,95.844559 
C552.975708,94.805496 552.780701,93.581009 552.879150,92.161850 
C553.364197,93.197830 553.555664,94.428474 553.458984,95.844559 
z"></path>
<path fill="#9B999A" opacity="1.000000" stroke="none" d="
M443.153809,177.398041 
C444.842133,175.957535 446.798279,174.797714 449.287048,173.273087 
C451.186127,172.850479 452.552582,172.792694 454.275055,172.812042 
C455.114075,172.859848 455.597107,172.830490 456.323608,172.858826 
C456.732117,172.940536 456.897186,172.964569 457.062286,172.988617 
C457.038361,174.376724 457.014435,175.764847 457.031616,177.567398 
C452.522339,177.880814 447.971985,177.779770 443.153809,177.398041 
z"></path>
<path fill="#474546" opacity="1.000000" stroke="none" d="
M457.402893,172.946960 
C456.897186,172.964569 456.732117,172.940536 456.294678,172.526184 
C455.999725,171.468979 455.977234,170.802094 455.976135,170.068939 
C455.997528,170.002640 456.040771,169.870224 456.040771,169.870224 
C456.772858,169.914444 457.504913,169.958664 458.602814,170.019348 
C458.560272,170.992310 458.151886,171.948822 457.402893,172.946960 
z"></path>
<path fill="#9B999A" opacity="1.000000" stroke="none" d="
M471.834961,77.070221 
C471.419800,75.003540 471.352753,72.887146 471.383575,70.324280 
C472.640472,70.964577 474.615204,71.972725 474.729645,73.160133 
C474.844666,74.354042 473.108185,75.726357 471.834961,77.070221 
z"></path>
<path fill="#767676" opacity="1.000000" stroke="none" d="
M181.581482,71.747292 
C184.961090,72.710632 188.298233,73.542145 191.907867,74.673157 
C192.180344,74.972656 192.104645,75.406242 192.105591,75.626312 
C187.717911,76.198891 183.329285,76.551384 178.465546,76.480927 
C177.332993,74.078468 176.675537,72.098969 176.018082,70.119469 
C176.783768,70.077293 177.549454,70.035110 178.845306,70.302490 
C179.650146,70.880592 179.924835,71.149147 180.199524,71.417694 
C180.199524,71.417694 180.316132,71.321526 180.128479,71.602081 
C179.870117,72.397141 179.799393,72.911629 179.728668,73.426125 
C180.239990,72.828987 180.751312,72.231850 181.262634,71.634712 
C181.262634,71.634712 181.539001,71.615456 181.581482,71.747292 
z"></path>
<path fill="#959394" opacity="1.000000" stroke="none" d="
M155.423218,75.906128 
C154.740845,72.508766 157.177216,73.046173 160.017029,72.985550 
C163.868835,73.000526 166.912949,73.017822 169.972687,73.413834 
C169.979950,74.551468 169.971588,75.310387 169.963226,76.069298 
C165.272995,76.025078 160.582779,75.980865 155.423218,75.906128 
z"></path>
<path fill="#817F80" opacity="1.000000" stroke="none" d="
M170.247375,76.351593 
C169.971588,75.310387 169.979950,74.551468 170.314255,73.402313 
C172.902237,73.347351 173.833740,72.556297 172.977127,70.251526 
C173.736725,70.175301 174.496307,70.099068 175.636993,70.071152 
C176.675537,72.098969 177.332993,74.078468 178.009598,76.416229 
C175.529678,76.727615 173.030594,76.680748 170.247375,76.351593 
z"></path>
<path fill="#767676" opacity="1.000000" stroke="none" d="
M172.519073,70.190285 
C173.833740,72.556297 172.902237,73.347351 170.298645,73.023590 
C166.912949,73.017822 163.868835,73.000526 160.421783,72.972061 
C160.020996,72.328590 160.023148,71.696281 160.032104,70.752495 
C164.046295,70.337021 168.053650,70.233032 172.519073,70.190285 
z"></path>
<path fill="#605F60" opacity="1.000000" stroke="none" d="
M181.073151,71.523323 
C180.751312,72.231850 180.239990,72.828987 179.728668,73.426125 
C179.799393,72.911629 179.870117,72.397141 180.192520,71.642220 
C180.444214,71.401794 180.883682,71.411934 181.073151,71.523323 
z"></path>
<path fill="#605F60" opacity="1.000000" stroke="none" d="
M180.273407,71.056259 
C179.924835,71.149147 179.650146,70.880592 179.224243,70.369858 
C179.497757,70.316727 179.922516,70.505783 180.273407,71.056259 
z"></path>
<path fill="#CDA76C" opacity="1.000000" stroke="none" d="
M83.279770,71.733322 
C82.228943,70.370102 81.467743,68.745171 80.352036,66.615028 
C79.674255,62.227348 80.782005,57.945621 76.647560,54.779564 
C75.255569,52.665695 77.446724,48.858074 73.040314,48.624260 
C72.964996,47.893162 72.930626,47.474487 73.177902,46.845566 
C74.772392,41.802528 77.197258,45.980736 79.455246,45.963078 
C80.519394,46.010677 81.229668,46.034290 81.939941,46.057899 
C81.999985,46.764645 82.060028,47.471386 82.124657,48.901245 
C82.138245,50.371880 82.147240,51.119400 82.119652,52.235680 
C83.038437,53.745155 83.993797,54.885868 84.949158,56.026585 
C84.489227,61.174931 84.029305,66.323273 83.279770,71.733322 
z"></path>
<path fill="#D0B57C" opacity="1.000000" stroke="none" d="
M84.966599,55.614693 
C83.993797,54.885868 83.038437,53.745155 82.481979,52.275490 
C83.619171,51.932678 84.357437,51.918819 85.095711,51.904957 
C85.095711,51.904957 85.099297,51.930595 85.108276,51.916481 
C85.072845,53.002510 85.028442,54.102654 84.966599,55.614693 
z"></path>
<path fill="#F8E7CD" opacity="1.000000" stroke="none" d="
M72.896255,47.055809 
C72.930626,47.474487 72.964996,47.893162 73.015060,49.068161 
C73.370888,54.105843 73.614113,58.399033 74.133980,62.658459 
C74.236298,63.496761 75.406120,64.204773 76.070320,65.311592 
C76.057152,66.102249 76.057289,66.554192 75.742081,67.162743 
C74.725220,69.266357 74.023712,71.213371 73.238235,73.505119 
C73.093529,74.556320 73.032784,75.262772 72.610176,75.980682 
C71.526031,76.018768 70.803757,76.045395 70.061752,75.661362 
C71.468941,69.585213 73.012131,63.765781 70.828621,58.128868 
C69.645905,55.075592 72.248802,50.305271 67.008774,48.605133 
C66.361595,44.945786 64.526970,43.015934 61.024521,42.983799 
C61.016369,42.545147 61.008217,42.106495 60.996300,41.098747 
C60.992531,40.529652 60.974739,40.050056 60.974739,40.050056 
C61.147163,40.034950 61.319584,40.019844 61.750374,39.978035 
C62.813744,40.218399 63.618740,40.485477 65.076828,40.883617 
C68.118698,43.028393 70.507477,45.042099 72.896255,47.055809 
z"></path>
<path fill="#C6AD7D" opacity="1.000000" stroke="none" d="
M73.177902,46.845566 
C70.507477,45.042099 68.118698,43.028393 65.392212,40.949051 
C66.916512,36.366028 69.995453,41.326439 72.672470,39.931038 
C74.832382,40.889687 76.581612,41.875317 78.674767,43.296867 
C79.046249,44.468224 79.073807,45.203655 79.101372,45.939087 
C77.197258,45.980736 74.772392,41.802528 73.177902,46.845566 
z"></path>
<path fill="#DEC799" opacity="1.000000" stroke="none" d="
M85.106422,51.543613 
C84.357437,51.918819 83.619171,51.932678 82.518570,51.906731 
C82.147240,51.119400 82.138245,50.371880 82.189148,49.249969 
C82.955734,48.914146 83.662415,48.952717 84.728043,48.999199 
C85.097046,49.732166 85.107094,50.457218 85.106422,51.543613 
z"></path>
<path fill="#DEC799" opacity="1.000000" stroke="none" d="
M79.455246,45.963078 
C79.073807,45.203655 79.046249,44.468224 79.014496,43.363861 
C79.995262,43.765594 80.980217,44.536263 81.952560,45.682415 
C81.229668,46.034290 80.519394,46.010677 79.455246,45.963078 
z"></path>
<path fill="#FAF0DB" opacity="1.000000" stroke="none" d="
M60.691849,39.904713 
C60.974739,40.050056 60.992531,40.529652 60.992142,40.769726 
C60.033234,40.533276 59.074715,40.056751 58.094490,39.219845 
C58.851505,39.159431 59.630230,39.459400 60.691849,39.904713 
z"></path>
<path fill="#D0B57C" opacity="1.000000" stroke="none" d="
M19.060320,38.475151 
C24.399302,38.616924 20.984825,42.782265 22.008636,46.044807 
C22.009716,79.334694 21.993446,111.718452 22.033360,144.102158 
C22.035927,146.186310 20.988815,149.181992 25.433449,148.963638 
C28.506018,148.974335 31.130560,148.974335 33.324913,148.974335 
C32.760452,145.996918 32.380871,143.994720 32.333710,142.000412 
C33.109344,142.005829 33.552563,142.003342 33.997818,142.001007 
C33.999855,142.001144 34.001255,142.007996 34.123268,142.330444 
C36.145180,144.138443 38.045074,145.623978 39.957634,147.523438 
C40.677235,153.230896 36.779362,151.748138 34.038639,151.968201 
C29.872776,151.964447 25.699699,152.112595 21.543032,151.915207 
C17.182148,151.708115 16.538733,150.269470 18.094734,146.203125 
C18.776625,144.421127 18.967718,142.358978 18.970926,140.423355 
C19.026943,106.596245 19.019190,72.769020 19.060320,38.475151 
z"></path>
<path fill="#DEC799" opacity="1.000000" stroke="none" d="
M34.034920,152.324265 
C36.779362,151.748138 40.677235,153.230896 40.375359,147.491394 
C42.165478,146.990387 43.550529,146.935364 45.053986,147.120148 
C45.452507,147.572800 45.732632,147.785645 46.010448,148.433411 
C46.045738,150.883331 46.083336,152.898361 46.080021,154.943787 
C46.039108,154.974167 45.953487,155.029449 45.953487,155.029449 
C45.215591,155.014893 44.477692,155.000351 43.370842,154.991791 
C40.333691,154.957352 37.665493,154.916931 34.561707,154.835190 
C34.094482,154.089355 34.062843,153.384827 34.034920,152.324265 
z"></path>
<path fill="#D8BE96" opacity="1.000000" stroke="none" d="
M49.084721,158.986404 
C48.209759,163.264343 50.935856,168.184845 46.413578,171.952576 
C46.022358,166.670746 46.001957,161.314484 45.967522,155.493835 
C45.953487,155.029449 46.039108,154.974167 46.433846,154.972458 
C47.543602,154.988846 48.258621,155.006927 48.980774,155.421478 
C49.020180,156.874100 49.052448,157.930252 49.084721,158.986404 
z"></path>
<path fill="#FCFAF0" opacity="1.000000" stroke="none" d="
M34.176392,139.966064 
C34.122921,138.591736 34.069450,137.217392 34.444908,135.407272 
C35.581802,133.563278 36.877789,132.165634 36.903259,130.745193 
C37.081955,120.779488 36.993015,110.809074 37.000881,100.840179 
C37.006172,94.136864 37.324162,93.938560 42.987679,97.478630 
C42.995396,113.531624 42.995396,129.117264 42.995396,145.817307 
C40.191994,144.030731 38.596111,143.013702 36.952522,141.661224 
C35.995338,140.872528 35.085865,140.419296 34.176392,139.966064 
z"></path>
<path fill="#D0B57C" opacity="1.000000" stroke="none" d="
M28.022881,44.983665 
C28.771339,44.983253 29.506424,44.992363 30.609058,45.006775 
C30.974659,76.039360 30.972712,107.066635 30.575216,138.553680 
C29.459633,139.001434 28.739597,138.989441 28.016571,138.511093 
C28.004105,107.033981 27.994625,76.023232 27.985144,45.012478 
C27.985144,45.012478 28.009506,44.993195 28.022881,44.983665 
z"></path>
<path fill="#FDF6E3" opacity="1.000000" stroke="none" d="
M42.979961,97.011276 
C37.324162,93.938560 37.006172,94.136864 37.000881,100.840179 
C36.993015,110.809074 37.081955,120.779488 36.903259,130.745193 
C36.877789,132.165634 35.581802,133.563278 34.464539,134.994110 
C34.052349,121.663826 34.049461,108.310928 34.434158,94.475975 
C37.544640,91.627121 40.262253,92.635429 42.994972,94.247208 
C43.010651,94.487732 43.040504,94.968979 43.028664,95.309235 
C43.004532,96.103416 42.992245,96.557343 42.979961,97.011276 
z"></path>
<path fill="#FAF0DB" opacity="1.000000" stroke="none" d="
M42.979298,94.006691 
C40.262253,92.635429 37.544640,91.627121 34.466042,94.009964 
C34.092102,91.304436 34.073868,88.582848 34.086433,85.406204 
C36.767193,84.964996 39.417149,84.978867 42.531864,84.996246 
C42.996620,84.999756 43.034161,85.010376 43.020882,85.459160 
C42.998165,88.607529 42.988731,91.307106 42.979298,94.006691 
z"></path>
<path fill="#CDA76C" opacity="1.000000" stroke="none" d="
M28.019560,138.977448 
C28.739597,138.989441 29.459633,139.001434 30.537415,139.022964 
C31.212692,139.520645 31.530228,140.008789 31.929634,141.002731 
C32.011505,141.508530 32.001293,141.992523 32.001293,141.992523 
C32.380871,143.994720 32.760452,145.996918 33.324913,148.974335 
C31.130560,148.974335 28.506018,148.974335 25.446751,148.588257 
C26.014534,145.127258 27.017046,142.052353 28.019560,138.977448 
z"></path>
<path fill="#FDF6E3" opacity="1.000000" stroke="none" d="
M34.125847,140.228165 
C35.085865,140.419296 35.995338,140.872528 36.577591,141.659439 
C35.500664,141.998077 34.750957,142.003036 34.001255,142.007996 
C34.001255,142.007996 33.999855,142.001144 34.004547,141.755981 
C34.009235,141.510818 34.014183,141.020630 34.014183,141.020630 
C34.034557,140.843842 34.054928,140.667053 34.125847,140.228165 
z"></path>
<path fill="#F8E7CD" opacity="1.000000" stroke="none" d="
M33.691193,141.013397 
C34.014183,141.020630 34.009235,141.510818 34.002510,141.755829 
C33.552563,142.003342 33.109344,142.005829 32.333710,142.000412 
C32.001293,141.992523 32.011505,141.508530 32.047112,141.269440 
C32.511211,141.022308 32.939709,141.014236 33.691193,141.013397 
z"></path>
<path fill="#CBA765" opacity="1.000000" stroke="none" d="
M28.016571,138.511093 
C27.017046,142.052353 26.014534,145.127258 24.998722,148.577545 
C20.988815,149.181992 22.035927,146.186310 22.033360,144.102158 
C21.993446,111.718452 22.009716,79.334694 22.013037,46.484245 
C23.746805,45.679428 25.480534,45.341309 27.599703,45.007835 
C27.994625,76.023232 28.004105,107.033981 28.016571,138.511093 
z"></path>
<path fill="#D8BE96" opacity="1.000000" stroke="none" d="
M46.012756,147.998505 
C45.732632,147.785645 45.452507,147.572800 45.130569,146.718384 
C45.400387,144.473328 45.977631,142.870605 45.982407,141.266174 
C46.028336,125.851105 45.977730,110.435745 45.969456,94.564163 
C45.967991,91.062119 45.951839,88.016388 45.958786,84.532280 
C45.274620,81.075920 44.567364,78.057938 43.860107,75.039948 
C43.667423,74.244011 43.474743,73.448082 43.482975,72.201920 
C46.068470,71.170135 48.446346,70.145851 50.839184,70.109634 
C54.528019,70.053802 58.227093,70.674957 61.950035,71.380669 
C61.651955,73.168335 61.325668,74.584282 60.687202,76.034195 
C59.593037,76.710327 58.811050,77.352478 57.737999,77.785507 
C56.457172,73.161026 52.814247,73.802269 49.039692,73.092384 
C49.039692,97.327721 49.039692,121.166336 49.019142,145.247208 
C48.998592,145.489471 48.967453,145.974716 48.658920,146.020142 
C47.571175,146.709869 46.791962,147.354187 46.012756,147.998505 
z"></path>
<path fill="#FCFCFC" opacity="1.000000" stroke="none" d="
M39.006954,40.452042 
C43.832794,40.010361 48.656643,40.010361 54.284992,40.010361 
C50.857296,45.478176 43.191303,45.409939 43.000992,53.030968 
C43.002689,55.220963 43.003429,56.608376 43.004166,57.995789 
C42.184555,60.930618 41.364952,63.865448 40.545349,66.800278 
C39.861763,66.712105 39.178181,66.623924 38.494595,66.535744 
C35.819443,61.306744 36.949020,55.652103 37.529110,50.098114 
C37.851414,47.012260 38.502216,43.960724 39.006954,40.452042 
z"></path>
<path fill="#FDF6E3" opacity="1.000000" stroke="none" d="
M44.997211,66.990578 
C45.661011,65.239487 46.324810,63.488396 47.325111,61.370499 
C48.107029,61.002666 48.552444,61.001640 49.446678,61.003639 
C53.490295,60.918488 57.743443,62.330452 59.422401,57.001907 
C66.151611,58.276394 63.351379,63.362526 63.519932,67.011932 
C57.034039,67.003670 51.015625,66.997124 44.997211,66.990578 
z"></path>
<path fill="#F8E7CD" opacity="1.000000" stroke="none" d="
M44.639042,67.000381 
C51.015625,66.997124 57.034039,67.003670 63.523529,67.390381 
C63.303684,68.850014 62.612759,69.929482 61.921829,71.008942 
C58.227093,70.674957 54.528019,70.053802 50.839184,70.109634 
C48.446346,70.145851 46.068470,71.170135 43.383240,71.903580 
C41.758312,69.964310 39.279770,67.599014 44.639042,67.000381 
z"></path>
<path fill="#F8E7CD" opacity="1.000000" stroke="none" d="
M43.595032,75.336365 
C44.567364,78.057938 45.274620,81.075920 45.596859,84.540955 
C44.485947,84.995453 43.760056,85.002914 43.034161,85.010376 
C43.034161,85.010376 42.996620,84.999756 42.977081,84.993904 
C43.081684,81.869629 43.205822,78.751205 43.595032,75.336365 
z"></path>
<path fill="#FAF0DB" opacity="1.000000" stroke="none" d="
M43.376125,57.997181 
C43.003429,56.608376 43.002689,55.220963 43.002110,53.418259 
C43.750282,53.001751 44.498299,53.000526 45.623188,53.414818 
C46.000401,55.219868 46.000732,56.609409 45.997368,58.002609 
C45.993668,58.006271 45.978874,58.020889 45.978874,58.020889 
C45.235275,58.013451 44.491680,58.006012 43.376125,57.997181 
z"></path>
<path fill="#FAF0DB" opacity="1.000000" stroke="none" d="
M48.997864,61.000610 
C48.552444,61.001640 48.107029,61.002666 47.327423,61.006702 
C46.660461,60.237278 46.327690,59.464848 45.986897,58.356651 
C45.978874,58.020889 45.993668,58.006271 46.264942,58.009682 
C46.713760,58.031349 46.891308,58.049614 47.141251,58.214783 
C47.213638,58.361694 47.633411,58.449211 47.633411,58.449211 
C47.633411,58.449211 47.920231,58.080185 48.176949,58.051895 
C48.605640,58.032051 48.777615,58.040497 48.965763,58.416756 
C48.987247,59.523247 48.992554,60.261929 48.997864,61.000610 
z"></path>
<path fill="#FAF0DB" opacity="1.000000" stroke="none" d="
M61.005894,43.375896 
C64.526970,43.015934 66.361595,44.945786 66.594215,48.612785 
C62.054390,47.475922 60.788990,51.749043 58.024910,52.648232 
C57.328194,51.194538 56.646172,50.120529 55.537758,49.039078 
C53.402786,49.012390 51.694202,48.993149 49.985619,48.973907 
C51.409603,48.315685 52.839149,47.669186 54.256290,46.996552 
C56.504311,45.929550 58.744034,44.845074 61.005894,43.375896 
z"></path>
<path fill="#EEDABC" opacity="1.000000" stroke="none" d="
M49.746269,48.987511 
C51.694202,48.993149 53.402786,49.012390 55.549755,49.436874 
C55.978436,50.907394 55.968731,51.972679 55.607773,52.872559 
C53.193073,52.479397 51.129627,52.251637 49.030697,52.013000 
C48.995213,52.002125 48.923264,51.983891 48.949093,51.614609 
C48.992645,50.505554 49.010368,49.765785 49.028091,49.026012 
C49.028091,49.026012 49.506920,49.001118 49.746269,48.987511 
z"></path>
<path fill="#F8E7CD" opacity="1.000000" stroke="none" d="
M47.068859,58.067875 
C46.891308,58.049614 46.713760,58.031349 46.268639,58.006020 
C46.000732,56.609409 46.000401,55.219868 45.997253,53.412838 
C45.994438,52.995346 45.997231,52.496147 46.364925,52.247047 
C47.462833,51.993263 48.193050,51.988579 48.923264,51.983891 
C48.923264,51.983891 48.995213,52.002125 49.009064,52.437004 
C49.024796,54.571743 49.026676,56.271606 49.008896,57.990921 
C48.989239,58.010380 48.949589,58.048946 48.949589,58.048946 
C48.777615,58.040497 48.605640,58.032051 47.964767,58.040421 
C47.495872,58.057236 47.068859,58.067875 47.068859,58.067875 
z"></path>
<path fill="#FAF0DB" opacity="1.000000" stroke="none" d="
M48.949093,51.614609 
C48.193050,51.988579 47.462833,51.993263 46.366257,51.997452 
C46.759529,51.005318 47.519169,50.013687 48.653450,49.024033 
C49.010368,49.765785 48.992645,50.505554 48.949093,51.614609 
z"></path>
<path fill="#1B1A1A" opacity="1.000000" stroke="none" d="
M341.930573,162.680420 
C339.941650,163.765091 338.016846,164.512985 336.042969,165.632874 
C334.892944,165.920670 333.791992,165.836456 332.056366,165.703690 
C333.411407,163.985321 334.434723,162.687607 335.729004,161.194946 
C337.680725,160.997040 339.361481,160.994080 341.457184,160.987289 
C341.912994,161.436844 341.953827,161.890244 341.930573,162.680420 
z"></path>
<path fill="#3C3A3B" opacity="1.000000" stroke="none" d="
M317.957214,157.596252 
C317.190796,157.992081 316.457489,157.995758 315.356079,157.996048 
C315.698883,156.975647 316.409821,155.958633 317.473389,154.892914 
C317.880798,155.630859 317.935577,156.417465 317.957214,157.596252 
z"></path>
<path fill="#222021" opacity="1.000000" stroke="none" d="
M141.985687,160.628204 
C139.706528,162.204102 137.438660,163.407120 135.170807,164.610153 
C134.849503,164.160477 134.528198,163.710800 134.206894,163.261139 
C135.246994,160.794525 136.091202,158.218353 137.389984,155.896454 
C138.405167,154.081543 140.940430,154.938828 142.119141,157.709229 
C142.007034,158.764725 142.002014,159.510040 141.985687,160.628204 
z"></path>
<path fill="#383637" opacity="1.000000" stroke="none" d="
M163.875458,169.485931 
C163.161209,169.959457 162.489182,169.972702 161.479355,169.974731 
C161.141541,169.963516 160.900558,169.996948 160.888336,169.566772 
C160.840866,164.438629 160.805603,159.740662 160.770340,155.042709 
C160.821014,154.865402 160.871704,154.688095 161.009628,154.248230 
C161.783447,153.997589 162.470016,154.009537 163.496246,154.037384 
C163.863159,159.044083 163.890411,164.034882 163.875458,169.485931 
z"></path>
<path fill="#A7A6A6" opacity="1.000000" stroke="none" d="
M160.371017,155.023926 
C160.805603,159.740662 160.840866,164.438629 160.770416,169.596634 
C159.992096,170.039459 159.319504,170.022247 158.309601,169.992676 
C157.981247,166.536377 158.083542,163.088043 157.923370,159.651947 
C157.889740,158.930771 156.890488,158.254608 156.072662,157.821732 
C155.640686,157.080231 155.472229,156.074692 155.626678,154.988174 
C157.290283,154.939819 158.630997,154.972473 160.371017,155.023926 
z"></path>
<path fill="#222021" opacity="1.000000" stroke="none" d="
M477.001892,172.531189 
C473.512695,172.995300 470.217438,173.522125 473.410095,167.975830 
C474.829865,165.509445 473.649872,161.546509 473.649872,158.262848 
C473.243317,158.192169 472.836792,158.121490 472.430237,158.050812 
C470.746002,161.555618 469.061737,165.060425 467.080200,169.183899 
C464.355713,159.657608 466.452118,156.472931 476.617096,154.005829 
C477.001678,160.020996 477.003357,166.041992 477.001892,172.531189 
z"></path>
<path fill="#474546" opacity="1.000000" stroke="none" d="
M498.298706,161.018280 
C497.009674,160.639801 496.050171,160.173218 494.994385,159.367126 
C494.971710,158.876129 495.045258,158.724640 495.546875,158.306305 
C496.964569,157.746353 497.954193,157.453232 498.943817,157.160126 
C499.598480,157.869736 500.253174,158.579346 500.682800,159.719742 
C500.241241,160.293884 500.080536,160.484329 499.975647,160.721863 
C499.526489,160.791306 499.077332,160.860733 498.298706,161.018280 
z"></path>
<path fill="#656364" opacity="1.000000" stroke="none" d="
M498.930847,156.803818 
C497.954193,157.453232 496.964569,157.746353 495.649689,158.075592 
C496.164856,156.785309 495.917145,153.109314 498.930847,156.803818 
z"></path>
<path fill="#605F60" opacity="1.000000" stroke="none" d="
M500.211304,160.747910 
C500.080536,160.484329 500.241241,160.293884 500.625244,160.063385 
C500.908783,160.342453 500.793518,160.608368 500.211304,160.747910 
z"></path>
<path fill="#959394" opacity="1.000000" stroke="none" d="
M549.739563,154.806213 
C549.788757,155.569702 549.837952,156.333206 549.895386,157.475067 
C547.935425,157.887650 545.967163,157.921860 543.552856,157.536499 
C543.082947,156.403854 543.059204,155.690735 543.035461,154.977631 
C543.219238,154.972931 543.402954,154.968231 543.861877,154.945099 
C544.824707,154.920670 545.512390,154.914703 546.885254,154.862183 
C548.293518,154.812500 549.016541,154.809357 549.739563,154.806213 
z"></path>
<path fill="#3F3D3E" opacity="1.000000" stroke="none" d="
M549.760193,154.810822 
C549.016541,154.809357 548.293518,154.812500 547.213501,154.752808 
C547.538025,153.768753 548.219604,152.847519 549.220947,151.886063 
C549.540833,151.845840 549.802063,151.952179 549.865967,152.332123 
C549.893860,153.416275 549.857910,154.120499 549.821960,154.824707 
C549.821960,154.824707 549.780823,154.815445 549.760193,154.810822 
z"></path>
<path fill="#BBBBBB" opacity="1.000000" stroke="none" d="
M542.708008,154.949768 
C543.059204,155.690735 543.082947,156.403854 543.133484,157.468933 
C542.688354,157.876190 542.216431,157.931488 541.389038,157.976395 
C541.024536,157.202332 541.015625,156.438675 541.037354,155.292938 
C541.505493,154.914536 541.942993,154.918213 542.708008,154.949768 
z"></path>
<path fill="#A7A6A6" opacity="1.000000" stroke="none" d="
M550.177124,154.857773 
C549.857910,154.120499 549.893860,153.416275 550.002686,152.366425 
C550.506836,152.006485 550.938110,151.992188 551.691040,152.018921 
C552.002869,152.768692 551.992920,153.477417 551.969482,154.538055 
C551.481384,154.890274 551.006836,154.890564 550.177124,154.857773 
z"></path>
<path fill="#222021" opacity="1.000000" stroke="none" d="
M227.007629,160.568176 
C223.250885,161.511139 223.345215,164.590179 224.385681,166.612183 
C225.275482,168.341431 227.898575,169.178802 229.755035,170.410645 
C229.780884,170.920456 229.806747,171.430267 229.832611,171.940094 
C227.163300,172.377060 224.493988,172.814026 222.111008,173.204132 
C220.497116,167.951981 219.202911,163.550446 217.736877,159.206909 
C217.551437,158.657471 216.464386,158.412354 215.398438,158.012390 
C215.925095,153.713684 220.207764,152.609680 222.927353,152.603409 
C226.641190,152.594833 227.557999,156.518997 227.007629,160.568176 
z"></path>
<path fill="#DDDCDC" opacity="1.000000" stroke="none" d="
M197.354187,163.891266 
C195.406845,164.031723 193.700577,164.011108 191.574097,163.982422 
C191.097733,160.647125 191.041580,157.319885 191.073517,153.550171 
C192.233093,153.309906 193.304596,153.512131 194.688828,153.841309 
C195.490753,154.878723 195.979935,155.789215 196.739334,156.850662 
C197.569885,158.447372 198.130203,159.893143 198.859726,161.668274 
C198.551041,162.575134 198.073166,163.152649 197.354187,163.891266 
z"></path>
<path fill="#CDCBCC" opacity="1.000000" stroke="none" d="
M256.974030,160.966400 
C255.916061,160.977448 254.858109,160.988495 253.403595,160.995941 
C253.007065,160.992340 253.000397,160.999771 252.913300,160.827133 
C252.544128,160.763214 252.262054,160.871933 251.979996,160.980667 
C246.416412,160.114090 249.538666,158.014648 251.697525,156.389740 
C252.683136,157.039536 253.259857,157.409576 253.836578,157.779633 
C254.214706,156.868591 254.592834,155.957550 255.352448,155.023010 
C256.240692,154.953934 256.747467,154.908356 257.595398,154.887100 
C258.616058,154.981247 259.295593,155.051086 259.980408,155.468109 
C258.981812,157.532318 257.977936,159.249359 256.974030,160.966400 
z"></path>
<path fill="#DDDCDC" opacity="1.000000" stroke="none" d="
M254.970978,155.046524 
C254.592834,155.957550 254.214706,156.868591 253.836578,157.779633 
C253.259857,157.409576 252.683136,157.039536 252.045776,156.326630 
C251.710480,155.480286 251.435837,154.976807 251.030685,154.213959 
C250.938614,153.555038 250.977051,153.155502 251.037125,152.455933 
C251.818848,152.053070 252.578949,151.950241 253.721466,151.806519 
C254.103897,151.765640 254.060745,151.864761 254.044113,152.257660 
C254.341980,153.449219 254.656479,154.247879 254.970978,155.046524 
z"></path>
<path fill="#BBBBBB" opacity="1.000000" stroke="none" d="
M252.236389,160.986908 
C252.262054,160.871933 252.544128,160.763214 252.915955,160.823883 
C252.834747,160.993225 252.663773,160.993195 252.236389,160.986908 
z"></path>
<path fill="#343233" opacity="1.000000" stroke="none" d="
M259.975098,155.120926 
C259.295593,155.051086 258.616058,154.981247 257.546112,154.532715 
C257.070679,153.444305 256.985718,152.734589 256.900757,152.024887 
C256.900757,152.024887 257.198029,152.036514 257.341187,151.990524 
C258.407959,151.954193 259.331543,151.963852 260.615356,152.316956 
C260.966827,153.350739 260.958099,154.041092 260.701843,154.818863 
C260.294556,154.977844 260.134827,155.049377 259.975098,155.120926 
z"></path>
<path fill="#4D4B4C" opacity="1.000000" stroke="none" d="
M260.949371,154.731430 
C260.958099,154.041092 260.966827,153.350739 260.965912,152.314316 
C261.392761,151.965439 261.829254,151.962631 262.592468,151.962250 
C262.972473,152.717743 263.025787,153.470795 262.673248,154.586334 
C261.828064,154.876343 261.388733,154.803894 260.949371,154.731430 
z"></path>
<path fill="#B4B2B3" opacity="1.000000" stroke="none" d="
M256.541412,152.018234 
C256.985718,152.734589 257.070679,153.444305 257.204956,154.508392 
C256.747467,154.908356 256.240692,154.953934 255.352448,155.023010 
C254.656479,154.247879 254.341980,153.449219 254.027161,152.309006 
C254.745255,151.982147 255.463654,151.996857 256.541412,152.018234 
z"></path>
<path fill="#383637" opacity="1.000000" stroke="none" d="
M452.394196,166.762360 
C453.621613,166.857986 454.441345,166.969177 455.673218,167.130188 
C456.064514,167.853210 456.043671,168.526382 456.031799,169.534897 
C456.040771,169.870224 455.997528,170.002640 455.658966,170.025070 
C454.893555,170.047729 454.466705,170.047958 453.615356,170.033493 
C451.224701,169.993774 449.258545,169.968750 447.292419,169.943726 
C447.221008,169.247131 447.149628,168.550537 447.080841,167.502197 
C448.717773,167.026276 450.352142,166.902100 452.394196,166.762360 
z"></path>
<path fill="#4D4B4C" opacity="1.000000" stroke="none" d="
M447.238373,170.290283 
C449.258545,169.968750 451.224701,169.993774 453.606018,170.369965 
C453.987152,171.392380 453.953094,172.063629 453.919006,172.734894 
C452.552582,172.792694 451.186127,172.850479 449.410889,172.951920 
C448.396179,172.209305 447.790253,171.423050 447.238373,170.290283 
z"></path>
<path fill="#B4B2B3" opacity="1.000000" stroke="none" d="
M260.701843,154.818863 
C261.388733,154.803894 261.828064,154.876343 262.602478,154.960510 
C266.629303,157.953522 263.903870,160.635803 262.063934,162.676376 
C260.780640,164.099625 258.006927,165.670410 256.993408,161.350189 
C257.977936,159.249359 258.981812,157.532318 259.980408,155.468109 
C260.134827,155.049377 260.294556,154.977844 260.701843,154.818863 
z"></path>
<path fill="#535253" opacity="1.000000" stroke="none" d="
M314.574554,157.962250 
C314.920990,158.709747 314.944916,159.477905 314.967133,160.629791 
C314.122528,160.363892 313.279633,159.714264 312.436768,159.064636 
C313.041870,158.704056 313.646973,158.343475 314.574554,157.962250 
z"></path>
<path fill="#D8BE96" opacity="1.000000" stroke="none" d="
M88.057350,94.516205 
C84.676689,93.905464 81.260269,92.928162 77.280411,91.632843 
C75.868073,90.363869 74.994186,89.433907 74.174934,88.458061 
C71.571121,85.356552 72.875328,81.334007 76.917648,79.950974 
C76.698135,80.776772 76.478622,81.602570 76.167404,82.742432 
C76.060875,83.478706 76.046074,83.900917 76.049301,84.639366 
C77.373192,86.246460 78.551994,87.708984 80.012924,88.790337 
C82.591835,90.699242 85.342705,92.375816 88.057350,94.516205 
z"></path>
<path fill="#C6AD7D" opacity="1.000000" stroke="none" d="
M76.933281,79.949249 
C72.875328,81.334007 71.571121,85.356552 74.174934,88.458061 
C74.994186,89.433907 75.868073,90.363869 76.865829,91.628212 
C77.013077,92.396408 77.011459,92.851227 76.985069,93.672958 
C76.960304,94.039864 76.907745,94.092430 76.528427,94.067612 
C75.385628,94.093628 74.622154,94.144463 73.858673,94.195297 
C73.858673,94.195297 73.889000,94.115883 73.873970,93.751175 
C71.689629,91.933228 69.670593,89.670593 67.312752,89.232857 
C64.404816,88.693001 63.702969,87.597717 64.051842,85.060303 
C64.050537,85.049873 64.067184,85.052155 64.445221,85.041000 
C68.562042,84.852371 70.035088,82.611076 70.534065,79.105217 
C71.586403,78.026680 72.279221,76.997955 72.972038,75.969231 
C73.032784,75.262772 73.093529,74.556320 73.569962,73.462463 
C74.667046,73.054985 75.348434,73.034912 76.029831,73.014832 
C76.265640,74.131561 76.501450,75.248291 76.875458,77.050583 
C76.992775,78.464767 76.971916,79.193398 76.951050,79.922020 
C76.951057,79.922020 76.948921,79.947533 76.933281,79.949249 
z"></path>
<path fill="#C6AD7D" opacity="1.000000" stroke="none" d="
M76.057419,67.006134 
C76.057289,66.554192 76.057152,66.102249 76.346672,65.022018 
C76.728378,61.280010 76.820442,58.166290 76.912498,55.052570 
C80.782005,57.945621 79.674255,62.227348 79.997559,66.548172 
C79.219658,67.314171 78.441727,67.641830 77.236168,67.782120 
C76.626106,67.311821 76.375732,67.115616 76.057419,67.006134 
z"></path>
<path fill="#DEC799" opacity="1.000000" stroke="none" d="
M75.742081,67.162743 
C76.375732,67.115616 76.626106,67.311821 76.919258,67.811539 
C76.864731,69.511383 76.699478,70.994431 76.282028,72.746155 
C75.348434,73.034912 74.667046,73.054985 73.653931,73.117722 
C74.023712,71.213371 74.725220,69.266357 75.742081,67.162743 
z"></path>
<path fill="#D8BE96" opacity="1.000000" stroke="none" d="
M77.277481,79.908577 
C76.971916,79.193398 76.992775,78.464767 77.014374,77.370728 
C77.765747,77.004005 78.516373,77.002708 79.642776,77.012474 
C79.213661,77.980736 78.408783,78.937935 77.277481,79.908577 
z"></path>
<path fill="#CBA765" opacity="1.000000" stroke="none" d="
M48.967453,145.974716 
C48.967453,145.974716 48.998592,145.489471 49.443108,145.245178 
C51.591953,144.998764 53.296284,144.996628 55.002281,145.459595 
C55.020977,150.297165 55.038002,154.669632 55.055027,159.042114 
C55.055027,159.042114 55.036579,159.042755 55.039253,159.040771 
C53.336720,159.024261 51.631512,159.009750 49.505508,158.990814 
C49.052448,157.930252 49.020180,156.874100 48.992142,154.968353 
C48.986732,151.404068 48.977093,148.689392 48.967453,145.974716 
z"></path>
<path fill="#D0B074" opacity="1.000000" stroke="none" d="
M55.424999,159.035095 
C55.038002,154.669632 55.020977,150.297165 55.002880,144.991577 
C55.332142,132.666229 55.864388,121.274666 55.902485,109.881462 
C55.919956,104.656540 55.478714,99.303070 54.243397,94.253029 
C53.598450,91.616425 50.442398,89.820801 53.513733,86.556107 
C55.470127,84.476555 56.522995,81.546982 58.003281,79.421883 
C58.066551,81.529625 58.107540,83.214043 58.117683,85.263359 
C58.909019,87.121567 59.731201,88.614868 60.204861,89.475151 
C59.402325,91.657700 58.768982,93.380119 58.117588,95.564209 
C58.115135,112.649605 58.130737,129.273331 58.146336,145.897064 
C58.103889,149.638397 58.061443,153.379745 58.049744,157.588791 
C58.080498,158.056503 58.038315,158.539948 58.030022,158.782715 
C57.279476,159.026337 56.537224,159.027222 55.424999,159.035095 
z"></path>
<path fill="#C6AD7D" opacity="1.000000" stroke="none" d="
M79.016968,96.611816 
C78.553444,96.983360 78.077805,97.005043 77.247711,97.079666 
C76.893265,97.132614 76.910316,97.108040 76.937759,96.727097 
C76.946060,95.594910 76.926903,94.843666 76.907745,94.092430 
C76.907745,94.092430 76.960304,94.039864 76.986832,94.013519 
C77.677185,94.745430 78.341011,95.503685 79.016968,96.611816 
z"></path>
<path fill="#FDFBF8" opacity="1.000000" stroke="none" d="
M71.006439,147.000687 
C71.006447,147.000687 70.522079,147.062805 70.279831,147.090149 
C63.964569,146.295303 63.785435,142.138870 63.904564,137.214264 
C64.175720,126.004845 63.928654,114.783760 64.048569,103.569099 
C64.074104,101.180321 62.719582,97.567909 66.710266,97.177498 
C70.139389,96.842018 73.556046,97.615837 75.549301,101.231781 
C78.426109,106.450562 81.491486,111.565384 84.807922,116.886261 
C85.144882,118.371132 85.150314,119.694305 85.101501,121.813568 
C85.088593,123.140846 85.129929,123.672028 85.171265,124.203209 
C85.163834,125.844292 85.156418,127.485367 85.162827,129.534546 
C83.935188,131.942673 82.923813,134.142273 81.400787,135.898041 
C78.820557,138.872589 75.917252,141.566895 73.147972,144.736176 
C73.146179,145.093323 73.103378,145.099213 72.757828,145.109863 
C71.943672,145.747223 71.475060,146.373947 71.006439,147.000687 
z"></path>
<path fill="#FAF0DB" opacity="1.000000" stroke="none" d="
M85.448654,124.110703 
C85.129929,123.672028 85.088593,123.140846 85.102165,122.211456 
C85.346718,122.548225 85.536377,123.283218 85.448654,124.110703 
z"></path>
<path fill="#EEDABC" opacity="1.000000" stroke="none" d="
M58.498268,145.926773 
C58.130737,129.273331 58.115135,112.649605 58.472153,95.533081 
C59.562191,95.030846 60.279613,95.021393 60.997036,95.011940 
C61.001801,111.699715 61.006565,128.387482 60.994904,145.545380 
C60.269054,145.995850 59.559631,145.976166 58.498268,145.926773 
z"></path>
<path fill="#DEC799" opacity="1.000000" stroke="none" d="
M61.029877,94.780907 
C60.279613,95.021393 59.562191,95.030846 58.490204,95.071419 
C58.768982,93.380119 59.402325,91.657700 60.204861,89.475151 
C59.731201,88.614868 58.909019,87.121567 58.531658,85.289040 
C60.668705,84.990120 62.360924,85.030426 64.053146,85.070724 
C63.702969,87.597717 64.404816,88.693001 67.312752,89.232857 
C69.670593,89.670593 71.689629,91.933228 73.834328,93.766510 
C73.097450,94.118263 72.385178,94.089981 71.245804,93.655716 
C67.430367,91.716873 64.045074,90.219307 61.123169,94.087097 
C61.123169,94.087097 61.062721,94.549873 61.029877,94.780907 
z"></path>
<path fill="#EEDABC" opacity="1.000000" stroke="none" d="
M61.564209,94.064514 
C64.045074,90.219307 67.430367,91.716873 70.887024,93.645035 
C67.971985,94.040863 64.988617,94.041389 61.564209,94.064514 
z"></path>
<path fill="#DEC799" opacity="1.000000" stroke="none" d="
M76.528427,94.067612 
C76.926903,94.843666 76.946060,95.594910 76.927231,96.724258 
C75.898399,96.365738 74.907547,95.629097 73.887680,94.543884 
C74.622154,94.144463 75.385628,94.093628 76.528427,94.067612 
z"></path>
<path fill="#FAF0DB" opacity="1.000000" stroke="none" d="
M71.322723,146.965729 
C71.475060,146.373947 71.943672,145.747223 72.740410,145.121460 
C72.592026,145.725189 72.115524,146.327972 71.322723,146.965729 
z"></path>
<path fill="#605F60" opacity="1.000000" stroke="none" d="
M454.275024,172.812042 
C453.953094,172.063629 453.987152,171.392380 454.030518,170.384644 
C454.466705,170.047958 454.893555,170.047729 455.637573,170.091370 
C455.977234,170.802094 455.999725,171.468979 456.051178,172.468506 
C455.597107,172.830490 455.114075,172.859848 454.275024,172.812042 
z"></path>
<path fill="#DEC799" opacity="1.000000" stroke="none" d="
M76.647560,54.779564 
C76.820442,58.166290 76.728378,61.280010 76.359970,64.683304 
C75.406120,64.204773 74.236298,63.496761 74.133980,62.658459 
C73.614113,58.399033 73.370888,54.105843 73.056015,49.380577 
C77.446724,48.858074 75.255569,52.665695 76.647560,54.779564 
z"></path>
<path fill="#FCFAF0" opacity="1.000000" stroke="none" d="
M58.039600,53.027908 
C60.788990,51.749043 62.054390,47.475922 66.602341,48.981926 
C72.248802,50.305271 69.645905,55.075592 70.828621,58.128868 
C73.012131,63.765781 71.468941,69.585213 70.054398,75.648384 
C70.066772,76.046082 70.067757,76.068436 69.713013,76.078445 
C68.616028,77.111603 67.873779,78.134766 67.113213,79.371529 
C67.094894,79.585136 67.020180,80.007362 66.696854,80.036240 
C65.902611,80.692223 65.431686,81.319321 64.737732,81.980392 
C64.514709,82.014366 64.075912,82.119453 63.700424,82.040344 
C62.549755,79.974228 61.774567,77.987228 60.999382,76.000221 
C61.325668,74.584282 61.651955,73.168335 61.950035,71.380669 
C62.612759,69.929482 63.303684,68.850014 63.991013,67.392097 
C63.351379,63.362526 66.151611,58.276394 59.261925,56.760078 
C58.177704,56.339981 57.640675,56.168144 57.095016,55.664108 
C57.094318,54.888893 57.102245,54.445881 57.317024,53.896584 
C57.769447,53.586010 57.941360,53.331879 58.039600,53.027908 
z"></path>
<path fill="#DEC799" opacity="1.000000" stroke="none" d="
M72.610176,75.980682 
C72.279221,76.997955 71.586403,78.026680 70.490509,79.068092 
C70.087433,79.080780 70.006508,79.006416 70.010727,78.638443 
C70.032547,77.536461 70.050156,76.802444 70.067764,76.068436 
C70.067757,76.068436 70.066772,76.046082 70.074127,76.059052 
C70.803757,76.045395 71.526031,76.018768 72.610176,75.980682 
z"></path>
<path fill="#F8E7CD" opacity="1.000000" stroke="none" d="
M45.954769,95.020470 
C45.977730,110.435745 46.028336,125.851105 45.982407,141.266174 
C45.977631,142.870605 45.400387,144.473328 45.012169,146.478592 
C43.550529,146.935364 42.165478,146.990387 40.362694,147.077454 
C38.045074,145.623978 36.145180,144.138443 34.123268,142.330444 
C34.750957,142.003036 35.500664,141.998077 36.625298,141.994904 
C38.596111,143.013702 40.191994,144.030731 42.995396,145.817307 
C42.995396,129.117264 42.995396,113.531624 42.987679,97.478630 
C42.992245,96.557343 43.004532,96.103416 43.392532,95.318832 
C44.497086,94.998947 45.225929,95.009712 45.954769,95.020470 
z"></path>
<path fill="#D0B57C" opacity="1.000000" stroke="none" d="
M48.658920,146.020142 
C48.977093,148.689392 48.986732,151.404068 48.985008,154.571869 
C48.258621,155.006927 47.543602,154.988846 46.474754,154.942078 
C46.083336,152.898361 46.045738,150.883331 46.010445,148.433411 
C46.791962,147.354187 47.571175,146.709869 48.658920,146.020142 
z"></path>
<path fill="#EEDABC" opacity="1.000000" stroke="none" d="
M45.969456,94.564163 
C45.225929,95.009712 44.497086,94.998947 43.404373,94.978577 
C43.040504,94.968979 43.010651,94.487732 42.994972,94.247208 
C42.988731,91.307106 42.998165,88.607529 43.020882,85.459152 
C43.760056,85.002914 44.485947,84.995453 45.573769,84.979324 
C45.951839,88.016388 45.967991,91.062119 45.969456,94.564163 
z"></path>
<path fill="#CDA76C" opacity="1.000000" stroke="none" d="
M57.980999,78.998550 
C56.522995,81.546982 55.470127,84.476555 53.513733,86.556107 
C50.442398,89.820801 53.598450,91.616425 54.243397,94.253029 
C55.478714,99.303070 55.919956,104.656540 55.902485,109.881462 
C55.864388,121.274666 55.332142,132.666229 55.001209,144.526489 
C53.296284,144.996628 51.591953,144.998764 49.463661,145.002914 
C49.039692,121.166336 49.039692,97.327721 49.039692,73.092384 
C52.814247,73.802269 56.457172,73.161026 57.743073,78.037338 
C58.019806,78.665054 58.000404,78.831802 57.980999,78.998550 
z"></path>
<path fill="#EEDABC" opacity="1.000000" stroke="none" d="
M58.003281,79.421883 
C58.000404,78.831802 58.019806,78.665054 58.034138,78.246468 
C58.811050,77.352478 59.593037,76.710327 60.687202,76.034195 
C61.774567,77.987228 62.549755,79.974228 63.687843,82.404205 
C64.056229,83.582169 64.061707,84.317162 64.067184,85.052155 
C64.067184,85.052155 64.050537,85.049873 64.051842,85.060303 
C62.360924,85.030426 60.668705,84.990120 58.562508,84.924133 
C58.107540,83.214043 58.066551,81.529625 58.003281,79.421883 
z"></path>
<path fill="#F8E7CD" opacity="1.000000" stroke="none" d="
M57.103645,55.996307 
C57.640675,56.168144 58.177704,56.339981 58.875214,56.753651 
C57.743443,62.330452 53.490295,60.918488 49.446678,61.003639 
C48.992554,60.261929 48.987247,59.523247 48.965763,58.416756 
C48.949589,58.048946 48.989239,58.010380 49.412613,57.997768 
C52.258541,57.322205 54.681095,56.659252 57.103645,55.996307 
z"></path>
<path fill="#FEFAEC" opacity="1.000000" stroke="none" d="
M47.141251,58.214783 
C47.068859,58.067875 47.495872,58.057236 47.708054,58.068710 
C47.920231,58.080185 47.633411,58.449211 47.633411,58.449211 
C47.633411,58.449211 47.213638,58.361694 47.141251,58.214783 
z"></path>
<path fill="#F8E7CD" opacity="1.000000" stroke="none" d="
M58.024910,52.648232 
C57.941360,53.331879 57.769447,53.586010 57.069862,53.808662 
C56.396912,53.564003 56.177971,53.300983 55.959026,53.037960 
C55.968731,51.972679 55.978436,50.907394 55.976143,49.444317 
C56.646172,50.120529 57.328194,51.194538 58.024910,52.648232 
z"></path>
<path fill="#DEC799" opacity="1.000000" stroke="none" d="
M55.607773,52.872559 
C56.177971,53.300983 56.396912,53.564003 56.863014,53.914948 
C57.102245,54.445881 57.094318,54.888893 57.095016,55.664108 
C54.681095,56.659252 52.258541,57.322205 49.432274,57.978310 
C49.026676,56.271606 49.024796,54.571743 49.044548,52.447880 
C51.129627,52.251637 53.193073,52.479397 55.607773,52.872559 
z"></path>
<path fill="#DEC799" opacity="1.000000" stroke="none" d="
M64.445221,85.041000 
C64.061707,84.317162 64.056229,83.582169 64.063332,82.483315 
C64.075912,82.119453 64.514709,82.014366 65.072327,81.977341 
C66.093346,81.295998 66.556763,80.651680 67.020180,80.007362 
C67.020180,80.007362 67.094894,79.585136 67.469650,79.340591 
C68.565102,79.066170 69.285805,79.036293 70.006508,79.006416 
C70.006508,79.006416 70.087433,79.080780 70.130989,79.117905 
C70.035088,82.611076 68.562042,84.852371 64.445221,85.041000 
z"></path>
<path fill="#F8E7CD" opacity="1.000000" stroke="none" d="
M70.010727,78.638443 
C69.285805,79.036293 68.565102,79.066170 67.487961,79.126984 
C67.873779,78.134766 68.616028,77.111603 69.713013,76.078445 
C70.050156,76.802444 70.032547,77.536461 70.010727,78.638443 
z"></path>
<path fill="#F8E7CD" opacity="1.000000" stroke="none" d="
M66.696854,80.036240 
C66.556763,80.651680 66.093346,81.295998 65.295349,81.943375 
C65.431686,81.319321 65.902611,80.692223 66.696854,80.036240 
z"></path>
</svg>
</svg>
`]
